import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { SellerComponent } from "./seller.component";
import {EditSellerComponent} from "./edit-seller/edit-seller.component";


export const SellerRoutes: Routes = [
  {
    path: 'seller',
    children: [
      {path: '', component: SellerComponent},
      { path: 'edit/', component: EditSellerComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(SellerRoutes)],
  exports: [RouterModule]
})
export class SellerRoutingModule {
}
