<div class="row content-header">
  <div class="col-md d-flex align-items-center p-0"
    [ngClass]="{'p-0': orderCustomBtn}">
    <div
      class="d-flex align-items-center back"
      *ngIf="hasBackButton"
      (click)="goBack()"
    >
      <i class="fas fa-chevron-left"></i>
    </div>
    <img
      class='order-custom-btn'
      src='assets/svgs/left_chevron.svg'
      width='25' height='25'
      *ngIf="orderCustomBtn"
      (click)="goToList.emit()">

    <h4 style="margin: 0">{{ title }}</h4>
  </div>
  <div class="col-md-6 text-right">
    <a
      [routerLink]="[btnCancelURL]"
      *ngIf="hasCancelButton && btnCancelURL"
      class="btn btn-link text-black-shade-2 mr-1"
    >
      {{ btnCancelTitle }}
    </a>
    <a
      href="javascript:void(0)"
      (click)="goBack()"
      *ngIf="hasCancelButton && !btnCancelURL"
      class="btn btn-link text-black-shade-2 mr-1"
    >
      {{ btnCancelTitle }}
    </a>
    <button
      class="btn btn-primary"
      *ngIf="hasSaveButton"
      (click)="onSaveButtonClicked()"
    >
      <i class="fas fa-save"></i>
      {{ btnSaveTitle }}
    </button>
    <a
      [routerLink]="[btnAddURL]"
      *ngIf="hasAddButton"
      class="btn btn-dark float-right"
    >
      <i class="fas fa-plus"></i>
      {{ btnAddTitle }}
    </a>
    <a
      [routerLink]=""
      *ngIf="hasCustomAddButton && !buttonDisabled"
      class="btn btn-sm btn-outline-primary d-flex align-items-center mx-auto float-right"
      (click)="onCustomButtonClicked()"
      style="margin-left: 10px"
    >
      <img src="assets/svgs/package.svg" height="22" width="auto" class="mr-2">
      {{ btnCustomAddTitle }}
    </a>
    <a
      [routerLink]="[btnEditURL]"
      class="btn btn-primary"
      *ngIf="hasEditButton"
    >
      <i class="fas fa-pencil-alt"></i>
      {{ btnEditTitle }}
    </a>
  </div>
</div>
