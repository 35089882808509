<app-header [title]=" 'Maintenance Simulation' | translate"></app-header>
<div class="content">
	<div class="bg-white custom-radius m-t-15 p-x-25 p-y-10">
		<div class="tabs-row">
			<div class="tab"
					[ngClass]="{'selected': selectedTab === 'general'}"
					(click)="changeTab('general')">
				{{'General Potential' | translate}}
			</div>
			<div class="tab"
					[ngClass]="{'selected': selectedTab === 'shop'}"
					(click)="changeTab('shop')">
				{{'Shop Potential' | translate}}
			</div>
		</div>
	</div>

	<div class="w-100percent p-3 px-4">
		<h5 class="row no-gutter p-b-10">
			{{ 'Basic Info' | translate}}
		</h5>
		<div class="row no-gutter p-y-5 col-md-5 d-flex align-items-center"
				[ngClass]="{'hidden': selectedTab === 'general'}">
			<div class="w-40percent">
				{{'Shop Code'| translate}}
			</div>
			<div class="w-50percent">
				<input type="text"
						class="w-100 form-control">
			</div>
		</div>
		<div class="row no-gutter p-y-5 col-md-5 d-flex align-items-center">
			<div class="w-40percent">
				{{'Operation Type' | translate}}
			</div>
			<div class="w-50percent d-flex justify-content-between">
				<button class="btn w-48percent button-color"
						[ngClass]="{'selected': selecedOperation === 'self'}"
						(click)="changeOperation('self')">{{'Self'|translate}}</button>
				<button class="btn w-48percent button-color"
						[ngClass]="{'selected': selecedOperation === 'full'}"
						(click)="changeOperation('full')">{{'Full'|translate}}</button>
			</div>
		</div>
		<div class="row no-gutter p-y-10">
			<div class="w-40percent">
				{{'Monthly fuel oil sales volume' | translate}}
			</div>
		</div>
		<div class="row no-gutter p-y-5 col-md-5 d-flex align-items-center">
			<div class="w-40percent">
				{{'Gasoline' | translate}}
			</div>
			<div class="w-50percent">
				<input type="text"
						[(ngModel)]="gasoline"
						class="w-100 form-control">
			</div>
			<div class="w-10percent text-center">Kl</div>
		</div>
		<div class="row no-gutter p-y-5 col-md-5">
			<div class="w-40percent">
				{{'Light Oil' | translate}}
			</div>
			<div class="w-50percent">
				<input type="text"
						[(ngModel)]="lightOil"
						class="w-100 form-control">
			</div>
			<div class="w-10percent text-center">Kl</div>
		</div>
    <div *ngIf="!selectedTab" class="row no-gutter p-y-5 col-md-5 d-flex align-items-center"
				>
			<div class="w-40percent">
				{{'Year/Month' | translate}}
			</div>
			<div class="w-50percent form-control d-flex align-items-center justify-content-between"
					(click)="d.toggle()">
				<input type="text"
						class="no-border"
						placeholder="yyyy-mm-dd"
						ngbDatepicker
						[readOnly]="true"
						#d="ngbDatepicker" />
				<i class="fa fa-calendar"></i>
			</div>
		</div>
		<div class="d-flex justify-content-between p-t-50">
			<div class="text-center">
				<h5>{{'Potential Data' | translate}}</h5>
			</div>
			<div class="row no-gutter">
				<button class="btn btn-sm d-flex align-items-center btn-secondary h-30" (click)="reset()">
					<img src="assets/svgs/reset_icon.svg"
							height="20"
							alt="reset icon"
							class="mr-1"
							width="auto">
					{{'Reset' | translate}}
				</button>
				<button class="btn btn-sm d-flex align-items-center btn-primary h-30 ml-3"
						(click)="searchItem()">
					<img src="assets/svgs/search_len.svg"
							height="20"
							alt="search lens icon"
							class="mr-1"
							width="auto">
					{{'Search' | translate}}
				</button>
				<button class="btn btn-sm d-flex align-items-center btn-primary h-30 ml-3"
						(click)="openPopup(addNewItem)">
					<img src="assets/svgs/plus_icon.svg"
							height="20"
							alt="search lens icon"
							class="mr-1"
							width="auto">
					{{'Add New Item' | translate}}
				</button>
				<button *ngIf="!selectedTab"
						class="btn btn-sm d-flex align-items-center btn-outline-primary h-30 ml-3">
					{{'Save' | translate}}
				</button>
			</div>
		</div>
	</div>
	<app-loading *ngIf="loading"></app-loading>
  <ng-container *ngIf="!loading && itemList.length">
    <div [ngClass]="selectedTab === 'general' ? 'col-md-6' : 'col-12'">
      <ng-template [ngTemplateOutlet]="selectedTab === 'general' ? generalPotential : shopPotential">
      </ng-template>
    </div>
  </ng-container>
</div>

<ng-template #generalPotential>
  <table class="table table-striped w-100percent">
    <thead>
      <tr>
        <th class="w-10percent"></th>
        <th class="w-50percent">{{'Analysis Item' | translate}}</th>
        <th class="w-10percent text-center">{{'Unit' | translate}}</th>
        <th class="w-30percent text-center">{{'Potential Sales Volume' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of itemList let index = index;">
        <td class="text-center">{{ index + 1 }}</td>
        <td>{{ item.item | translate }}</td>
        <td class="text-center">{{ item.unit | translate }}</td>
        <td class="text-center">{{ item.potential_sales |  number: '1.0-0' }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #shopPotential>
  <div class='overflow-x-auto'>
    <table class="table table-striped min-w-800px w-100percent">
      <thead>
      <tr>
        <th class="w-5percent"></th>
        <th class="w-22percent">{{'Analysis Item' | translate}}</th>
        <th class="w-7percent text-center">{{'Unit' | translate}}</th>
        <th class="w-7percent text-center">{{'Potential Sales Volume' | translate}}</th>
        <th class="w-10percent text-center">{{'Sales Performance' | translate}}</th>
        <th class="w-7percent text-center">{{'Achievement Ratio' | translate}}</th>
        <th class="w-7percent text-center">{{'Gap with Potentials' | translate}}</th>
        <th class="w-10percent text-center">{{'Greeting Ratio' | translate}}</th>
        <th class="w-10percent text-center">{{'Number of Checkup' | translate}}</th>
        <th class="w-10percent text-center">{{'Number of QT' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of itemList let index = index;">
        <td class="text-center">{{ index + 1 }}</td>
        <td>{{ item.item | translate }}</td>
        <td class="text-center">{{ item.unit | translate }}</td>
        <td class="text-center">{{ item.potential_sales |  number: '1.0-0' }}</td>
        <td><input type="number" [(ngModel)]="item.sales_performance"
                   class="form-control w-100percent text-center"></td>
        <td class="text-center">{{ item.achievement_ratio |  number: '1.0-0' }}</td>
        <td class="text-center">{{ item.gap_with_potential |  number: '1.0-0' }}</td>
        <td><input type="text" [(ngModel)]="item.greeting_ratio"
                   class="form-control w-100percent text-center"></td>
        <td><input type="text" [(ngModel)]="item.number_of_checkup"
                   class="form-control w-100percent text-center"></td>
        <td><input type="text" [(ngModel)]="item.number_of_qt"
                   class="form-control w-100percent text-center"></td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #addNewItem>
	<div class="modal-content">
		<div class="modal-header border-bottom-0">
			<h4 class="m-0">
				{{'Add New Item' | translate}}
			</h4>
		</div>
		<div class="modal-body mx-3 py-0 mb-3">
			<div class="row p-0 m-0 mt-3">
				<label class="col-4 p-0 m-0 d-flex align-items-center">
					{{ 'Analysis Item' | translate }}
				</label>
				<input type="text"
						[(ngModel)]="item"
						class="col-8 form-control">
			</div>
			<div class="row p-0 m-0 mt-3">
				<label class="col-4 p-0 m-0 d-flex align-items-center">
					{{ 'Unit' | translate }}
				</label>
				<input type="text"
						[(ngModel)]="unit"
						class="col-8 form-control">
			</div>
		</div>
		<div class="modal-footer border-top-0 mr-3">
			<button class="btn btn-secondary"
					(click)="dismissPopup()">
				{{'Cancel' | translate}}
			</button>
			<button class="btn btn-primary"
					(click)="addItem()">
				{{'Save' |translate}}
			</button>
		</div>
	</div>
</ng-template>
