import {Component, OnInit} from '@angular/core';
import {HttpClientService} from '../../services/http-client.service';
import {API_CONSTANT} from '../../services/api.constants';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslationService} from 'src/app/services/translate.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImportProductService} from './import-product.service';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'import-product',
  templateUrl: './import-product.component.html',
  styleUrls: ['./import-product.component.scss']
})
export class ImportProductComponent implements OnInit {
  public loading = true;
  page = 1;
  productList = [];
  productlistLength = 0;
  reason = [];
  currentPage: Number = 1;
  noOfPage: Number = 1;
  count = 3;
  interval: ReturnType<typeof setTimeout> = null;

  constructor(
    private api: HttpClientService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private translationSerivce: TranslationService,
    private modalService: NgbModal,
    private importProductService: ImportProductService,
    private notification: ToastrService,
  ) {
  }

  ngOnInit() {
    this.loadProducts();
    this.interval = setInterval(() => {
      this.loadProducts();
    }, 5000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  loadProducts() {
    let params = new HttpParams();
    params = params.append('page', this.currentPage.toString());
    this.importProductService.getImportResults(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.productList = res.results;
        this.productlistLength = res.results.length;
        this.noOfPage = res.total_pages;
        this.loading = false;
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  navigateTo(event) {
    this.currentPage = event;
    this.loadProducts();
  }

  handleError(err) {
    this.loading = false;
    this.notification.error(_.values(err)[0].toString());
  }

  translateMessage(messages) {
    const messageList = [];
    for (const message of messages) {
      messageList.push(
        this.translationSerivce.transformMessage(message) || message
      );
    }
    return messageList;
  }

  openErrorPopup(view, reason) {
    this.reason = this.translateMessage(reason);
    this.modalService.open(view);
  }

  getDate() {
    const today = new Date();
    const month = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    return today.getFullYear() + '-' + month + '-' + day;
  }
}
