import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { MonotaroComponent } from './monotaro.component';


export const monotaroRoutes: Routes = [
  {
    path: 'monotaro',
    children: [
      {path: '', component: MonotaroComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(monotaroRoutes)],
  exports: [RouterModule]
})
export class MonotaroRoutingModule {
}
