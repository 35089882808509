<div class="row no-gutter mb-5"
    [ngClass]="{'loading-effect': loadingTable}">
  <div class="row no-gutter align-self-center w-100percent pr-5">
    <div class="col-3"
        *ngIf="parentSearchData?.shop">
      <label class='row no-gutter pl-0 m-0 d-flex align-items-center'>
        {{ 'Shop' | translate }} : {{parentSearchData.shopName}}
      </label>
    </div>
  </div>
  <div class="row no-gutter align-self-center w-100percent pr-5 m-t-15">
    <div class="col-3"
        *ngIf="parentSearchData?.staff">
      <label class='row no-gutter pl-0 m-0 d-flex align-items-center'>
        {{ 'Staff' | translate }} : {{parentSearchData.staffName}}
      </label>
    </div>
  </div>
  <div class='row no-gutter align-self-center w-100percent pr-5 m-t-15'
      *ngIf="firstLayer">
    <div class="col-3">
      <label class='row no-gutter pl-0 m-0 d-flex align-items-center'>
        {{ 'Total Visits' | translate }}
      </label>
      <div class="row no-gutter">
        <input class="form-control h-35 align-self-center border rounded w-100percent px-1"
            type='text'
            [disabled]="true"
            [(ngModel)]="firstLayer.monthly_visit">
      </div>
    </div>
    <div class="col-3">
      <label class="row no-gutter pl-0 m-o d-flex align-items-center">
        {{ 'Greeting Rate' | translate }}
      </label>
      <div class="row no-gutter">
        <input class="form-control h-35 align-self-center border rounded w-100percent px-1"
            type="text"
            [disabled]="true"
            [(ngModel)]="formattedGreetingRate">
      </div>
    </div>
    <div class="col-3"
        *ngIf="parentSearchData?.staff">
      <label class='row no-gutter pl-0 m-0 d-flex align-items-center'>
        {{ 'Work Hours' | translate }}
      </label>
      <div class="row no-gutter">
        <input class="form-control h-35 align-self-center border rounded w-100percent px-1"
            type='text'
            [disabled]="true"
            [(ngModel)]="firstLayer.work_hours">
      </div>
    </div>
  </div>
</div>
<div class="row no-gutter"
     [ngClass]="{'loading-effect': loadingTable}"
     *ngIf="firstLayer && firstLayer?.data_items?.length">
  <table>
    <thead>
      <tr>
        <th class="w-16percent max-w-270 text-center top-left-round border-right min-w-150" rowspan="2">
          {{"Check Items / Shop / Staff" | translate}}
        </th>
        <th class="w-6percent text-center border-right border-bottom" rowspan="2">
          {{"Total Visiting Number" | translate}}
        </th>
        <th class="w-6percent text-center border-right border-bottom" colspan="5">
          {{"Number"|translate}}
        </th>
        <th class="w-6percent text-center border-right border-bottom"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.checkUpRatio}"
            colspan="1">
          {{"Checkup Ratio" | translate}}
        </th>
        <th class="w-7percent text-center border-right border-bottom"
            colspan="2"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio}">
          {{"Quotation Ratio" | translate}}
        </th>
        <th class="w-7percent text-center border-right border-bottom"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}"
            colspan="3">
          {{"Confirmed Order Ratio" | translate}}
        </th>
        <th class="w-7percent text-center top-right-round border-bottom"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.actualWorkRatio}">
          {{"Actual Work Ratio"| translate}}
        </th>
      </tr>
      <tr>
        <th class="w-6percent border-right text-center"
            (click)="setSortingField('tab_1')">
          {{"Greet" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'tab_1',
                            'fa-sort-down': currentSortingField == '-tab_1',
                            'fa-sort-up': currentSortingField == 'tab_1'}"
               aria-hidden="true"></i>
          </span>
        </th>
        <th class="w-6percent border-right text-center"
            (click)="setSortingField('tab_2')">
          {{"Checkup" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'tab_2',
                            'fa-sort-down': currentSortingField == '-tab_2',
                            'fa-sort-up': currentSortingField == 'tab_2'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-6percent border-right text-center"
            (click)="setSortingField('tab_3')">
          {{"Quotation" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'tab_3',
                            'fa-sort-down': currentSortingField == '-tab_3',
                            'fa-sort-up': currentSortingField == 'tab_3'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-6percent border-right text-center"
            (click)="setSortingField('tab_5')">
          {{"Confirmed Order" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'tab_5',
                            'fa-sort-down': currentSortingField == '-tab_5',
                            'fa-sort-up': currentSortingField == 'tab_5'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-6percent border-right text-center"
            (click)="setSortingField('tab_4')">
          {{"Actual Work" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'tab_4',
                            'fa-sort-down': currentSortingField == '-tab_4',
                            'fa-sort-up': currentSortingField == 'tab_4'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-6percent text-center border-right"
            (click)="setSortingField('checkup_ratio_greet')"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.checkUpRatio}">
          {{"Greet" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'checkup_ratio_greet',
                            'fa-sort-down': currentSortingField == '-checkup_ratio_greet',
                            'fa-sort-up': currentSortingField == 'checkup_ratio_greet'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-7percent text-center border-right"
            (click)="setSortingField('quotation_ratio_greet')"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio}">
          {{"Greet" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'quotation_ratio_greet',
                            'fa-sort-down': currentSortingField == '-quotation_ratio_greet',
                            'fa-sort-up': currentSortingField == 'quotation_ratio_greet'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-7percent text-center border-right"
            (click)="setSortingField('quotation_ratio_checkup')"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio}">
          {{"Checkup" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'quotation_ratio_checkup',
                            'fa-sort-down': currentSortingField == '-quotation_ratio_checkup',
                            'fa-sort-up': currentSortingField == 'quotation_ratio_checkup'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-7percent text-center border-right"
            (click)="setSortingField('confirmed_order_ratio_greet')"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
          {{"Greet" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'confirmed_order_ratio_greet',
                            'fa-sort-down': currentSortingField == '-confirmed_order_ratio_greet',
                            'fa-sort-up': currentSortingField == 'confirmed_order_ratio_greet'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-7percent text-center border-right"
            (click)="setSortingField('confirmed_order_ratio_checkup')"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
          {{"Checkup" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'confirmed_order_ratio_checkup',
                            'fa-sort-down': currentSortingField == '-confirmed_order_ratio_checkup',
                            'fa-sort-up': currentSortingField == 'confirmed_order_ratio_checkup'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-7percent text-center border-right"
            (click)="setSortingField('confirmed_order_ratio_quotation')"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
          {{"Quotation" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'confirmed_order_ratio_quotation',
                            'fa-sort-down': currentSortingField == '-confirmed_order_ratio_quotation',
                            'fa-sort-up': currentSortingField == 'confirmed_order_ratio_quotation'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
        <th class="w-7percent text-center"
            (click)="setSortingField('actual_work_ration_confirmed_order')"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.actualWorkRatio}">
          {{"Confirmed Order" | translate}}
          <span>
            <i class="fa"
               [ngClass]="{'fa-sort':  currentSortingField !== 'actual_work_ration_confirmed_order',
                            'fa-sort-down': currentSortingField == '-actual_work_ration_confirmed_order',
                            'fa-sort-up': currentSortingField == 'actual_work_ration_confirmed_order'}"
               aria-hidden="true">
            </i>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <!-------------------------------------------Monthly Visit Layer-------------------------------------------->
      <ng-container>
        <tr class='first-layer-bg border-y'>
          <td (click)="toggleMonthlyVisit()">
            <div class="w-100percent p-y-5 p-l-5 row no-gutters justify-content-between align-items-center flex-nowrap">
              <span class='max-w-80percent'>
                {{ 'Total Visiting Number' | translate }}
              </span>
              <div class="collapse-btn pointer">
                <img src="assets/svgs/chevron_down.svg"
                     class="rotate"
                     [ngClass]="showMonthlyVisit? 'up': 'down'"
                     width="30"
                     height="30"
                     alt="down arrow">
              </div>
            </div>
          </td>
          <td class="text-center p-y-5 p-x-10">
            {{ firstLayer.monthly_visit }}
          </td>
          <td class="text-center p-y-5 p-x-10" *ngFor="let index of createRange(12)">
            {{ "-" | translate}}
          </td>
        </tr>
        <!-------------------------------------------Monthly Visit Shop Layer-------------------------------------------->
        <ng-container *ngIf="showMonthlyVisit">
          <ng-container *ngFor="let eachShop of monthlyVisit">
            <tr>
              <td class="p-l-15">
                <div class="row no-gutters justify-content-between flex-nowrap align-items-center">
                  <span class="max-w-80percent">
                    {{ eachShop.shop_name }}
                  </span>
                  <div class="collapse-btn pointer"></div>
                </div>
              </td>
              <td class="text-center p-x-10">
                {{ eachShop.value }}
              </td>
              <td class="text-center p-y-5 p-x-10" *ngFor="let index of createRange(12)">
                {{ "-" | translate}}
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <tr *ngIf="isUnderline(firstLayerIndex)">
          <td class="p-0"
              *ngFor="let index of createRange(14)">
            <hr>
          </td>
        </tr>
      </ng-container>

      <!-------------------------------------------Daily Check Item Layer-------------------------------------------->
      <ng-container *ngFor="let checkItems of firstLayer.data_items; let firstLayerIndex = index;">
        <tr class='first-layer-bg border-y'>
          <td (click)="toggleFirstLayer(firstLayerIndex)">
            <div class="w-100percent p-y-5 p-l-5 row no-gutters justify-content-between align-items-center flex-nowrap">
              <span class='max-w-80percent'>
                {{ checkItems.name | translate }}
              </span>
              <div class="collapse-btn pointer">
                <img src="assets/svgs/chevron_down.svg"
                     class="rotate"
                     [ngClass]="checkItems.showChild ? 'up': 'down'"
                     width="30"
                     height="30"
                     alt="down arrow">
              </div>
            </div>
          </td>
          <td class="text-center p-y-5 p-x-10">
            {{ "-" | translate}}
          </td>
          <td class="text-center p-y-5 p-x-10">
            <!-- Greet Column (First Layer) -->
            {{ checkItems.tab_1 }}
          </td>
          <td class="text-center p-y-5 p-x-10">
            <!-- Checkup Column (First Layer) -->
            {{ checkItems.tab_2 }}
          </td>
          <td class="text-center p-y-5 p-x-10">
            <!-- Quotation Column (First Layer) -->
            {{ checkItems.tab_3 }}
          </td>
          <td class="text-center p-y-5 p-x-10">
            <!-- Confirmed Order Column (First Layer) -->
            {{ checkItems.tab_4 }}
          </td>
          <td class="text-center p-y-5 p-x-10">
            <!-- Actual Work Column (First Layer) -->
            {{ checkItems.tab_5 }}
          </td>
          <td class="text-center p-y-5 p-x-10"
              [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.checkUpRatio}">
            {{ (checkItems.checkup_ratio_greet || 0) | number: '1.1-1' }}%
          </td>
          <td class="text-center p-y-5 p-x-10"
              [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio}">
            {{ (checkItems.quotation_ratio_greet || 0) | number: '1.1-1' }}%
          </td>
          <td class="text-center p-y-5 p-x-10"
              [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio}">
            {{ (checkItems.quotation_ratio_checkup || 0) | number: '1.1-1' }}%
          </td>
          <td class="text-center p-y-5 p-x-10"
              [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
            {{ (checkItems.confirmed_order_ratio_greet || 0) | number: '1.1-1' }}%
          </td>
          <td class="text-center p-y-5 p-x-10"
              [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
            {{ (checkItems.confirmed_order_ratio_checkup || 0) | number: '1.1-1' }}%
          </td>
          <td class="text-center p-y-5 p-x-10"
              [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
            {{ (checkItems.confirmed_order_ratio_quotation || 0) | number: '1.1-1' }}%
          </td>
          <td class="text-center p-y-5 p-x-10"
              [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.actualWorkRatio}">
            {{ (checkItems.actual_work_ratio_confirmed_order || 0) | number: '1.1-1' }}%
          </td>
        </tr>

        <!-------------------------------------------Shop Layer------------------------------------------------>
        <ng-container *ngIf="checkItems.showChild">
          <ng-container *ngFor="let eachShop of checkItems.shops; let secondLayerIndex = index">
            <tr>
              <td class='p-l-15'
                  (click)="toggleSecondLayer(firstLayerIndex, secondLayerIndex)">
                <div class="row no-gutters justify-content-between flex-nowrap align-items-center">
                  <span class="max-w-80percent">
                    {{ eachShop.name }}
                  </span>
                  <div class="collapse-btn pointer">
                    <img src="assets/svgs/chevron_down.svg"
                         class="rotate"
                         [ngClass]="eachShop.showChild ? 'up': 'down'"
                         width="30"
                         height="30"
                         alt="down arrow">
                  </div>
                </div>
              </td>
              <td class="text-center p-x-10">
                {{ "-" | translate}}
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'max-number': checkItems.maxNumbers.tab_1 === secondLayerIndex}">
                <!-- Greet Column (Second Layer) -->
                {{ eachShop.tab_1 }}
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'max-number': checkItems.maxNumbers.tab_2 === secondLayerIndex}">
                <!-- Checkup Column (Second Layer) -->
                {{ eachShop.tab_2 }}
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'max-number': checkItems.maxNumbers.tab_3 === secondLayerIndex}">
                <!-- Quotation Column (Second Layer) -->
                {{ eachShop.tab_3 }}
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'max-number': checkItems.maxNumbers.tab_4 === secondLayerIndex}">
                <!-- Confirmed Order Column (Second Layer) -->
                {{ eachShop.tab_4 }}
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'max-number': checkItems.maxNumbers.tab_5 === secondLayerIndex}">
                <!-- Actual Work Column (Second Layer) -->
                {{ eachShop.tab_5 }}
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.checkUpRatio,
                                            'max-number': checkItems.maxNumbers.checkup_ratio_greet === secondLayerIndex}">
                {{ (eachShop.checkup_ratio_greet || 0) | number: '1.1-1' }}%
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio,
                                            'max-number': checkItems.maxNumbers.quotation_ratio_greet === secondLayerIndex}">
                {{ (eachShop.quotation_ratio_greet || 0) | number: '1.1-1' }}%
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio,
                                            'max-number': checkItems.maxNumbers.quotation_ratio_checkup === secondLayerIndex}">
                {{ (eachShop.quotation_ratio_checkup || 0) | number: '1.1-1' }}%
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio,
                                            'max-number': checkItems.maxNumbers.confirmed_order_ratio_greet === secondLayerIndex}">
                {{ (eachShop.confirmed_order_ratio_greet || 0) | number: '1.1-1' }}%
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio,
                                            'max-number': checkItems.maxNumbers.confirmed_order_ratio_checkup === secondLayerIndex}">
                {{ (eachShop.confirmed_order_ratio_checkup || 0) | number: '1.1-1' }}%
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio,
                                            'max-number': checkItems.maxNumbers.confirmed_order_ratio_quotation === secondLayerIndex}">
                {{ (eachShop.confirmed_order_ratio_quotation || 0) | number: '1.1-1' }}%
              </td>
              <td class="text-center p-x-10"
                  [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.actualWorkRatio,
                                            'max-number': checkItems.maxNumbers.actual_work_ratio_confirmed_order === secondLayerIndex}">
                {{ (eachShop.actual_work_ratio_confirmed_order || 0) | number: '1.1-1' }}%
              </td>
            </tr>

            <!-------------------------------------------Staff Layer------------------------------------------------>
            <ng-container *ngIf="eachShop.showChild">
              <ng-container *ngFor="let thirdItem of eachShop.staffs; let thirdLayerIndex = index">
                <tr class='third-layer-bg'>
                  <td class="p-l-30 p-r-5">
                    <div class='min-h-30 d-flex align-items-center'>
                      {{ thirdItem.name }}
                    </div>
                  </td>
                  <td class="text-center p-x-10">
                    {{ "-" | translate}}
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'max-number': eachShop.maxNumbers.tab_1 === thirdLayerIndex}">
                    <!-- Greet Column (Third Layer) -->
                    {{ thirdItem.tab_1 }}
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'max-number': eachShop.maxNumbers.tab_2 === thirdLayerIndex}">
                    <!-- Checkup Column (Third Layer) -->
                    {{ thirdItem.tab_2 }}
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'max-number': eachShop.maxNumbers.tab_3 === thirdLayerIndex}">
                    <!-- Quotation Column (Third Layer) -->
                    {{ thirdItem.tab_3 }}
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'max-number': eachShop.maxNumbers.tab_4 === thirdLayerIndex}">
                    <!-- Confirmed Order Column (Third Layer)  -->
                    {{ thirdItem.tab_4 }}
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'max-number': eachShop.maxNumbers.tab_5 === thirdLayerIndex}">
                    <!-- Actual Work Column (Third Layer) -->
                    {{ thirdItem.tab_5 }}
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.checkUpRatio,
                                 'max-number': eachShop.maxNumbers.checkup_ratio_greet === thirdLayerIndex}">
                    {{ (thirdItem.checkup_ratio_greet || 0) | number: '1.1-1' }}%
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio,
                                 'max-number': eachShop.maxNumbers.quotation_ratio_greet === thirdLayerIndex}">
                    {{ (thirdItem.quotation_ratio_greet || 0) | number: '1.1-1' }}%
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio,
                                 'max-number': eachShop.maxNumbers.quotation_ratio_checkup === thirdLayerIndex}">
                    {{ (thirdItem.quotation_ratio_checkup || 0) | number: '1.1-1' }}%
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio,
                                 'max-number': eachShop.maxNumbers.confirmed_order_ratio_greet === thirdLayerIndex}">
                    {{ (thirdItem.confirmed_order_ratio_greet || 0) | number: '1.1-1' }}%
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio,
                                 'max-number': eachShop.maxNumbers.confirmed_order_ratio_checkup === thirdLayerIndex}">
                    {{ (thirdItem.confirmed_order_ratio_checkup || 0) | number: '1.1-1' }}%
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio,
                                 'max-number': eachShop.maxNumbers.confirmed_order_ratio_quotation === thirdLayerIndex}">
                    {{ (thirdItem.confirmed_order_ratio_quotation || 0) | number: '1.1-1' }}%
                  </td>
                  <td class="v-middle text-center p-x-10"
                       [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.actualWorkRatio,
                                 'max-number': eachShop.maxNumbers.actual_work_ratio_confirmed_order === thirdLayerIndex}">
                    {{ (thirdItem.actual_work_ratio_confirmed_order || 0) | number: '1.1-1' }}%
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr *ngIf="isUnderline(firstLayerIndex)">
          <td class="p-0"
              *ngFor="let index of createRange(14)">
            <hr>
          </td>
        </tr>
      </ng-container>

      <!----------------------------------------------Total Row ------------------------------------------------->
      <tr class="darker-grey-bg">
        <td class="p-x-5">
          <div class='min-h-30 d-flex align-items-center'>
            {{ 'Total' | translate }}
          </div>
        </td>
        <td class="v-middle text-center p-x-10">
          {{ "-" | translate}}
        </td>
        <td class="v-middle text-center p-x-10">
          <!-- Greet Column (Total) -->
          {{ firstLayer.total.tab_1 }}
        </td>
        <td class="v-middle text-center p-x-10">
          <!-- Checkup Column (Total) -->
          {{ firstLayer.total.tab_2 }}
        </td>
        <td class="v-middle text-center p-x-10">
          <!-- Quotation Column (Total) -->
          {{ firstLayer.total.tab_3 }}
        </td>
        <td class="v-middle text-center p-x-10">
          <!-- Confirmed Order Column (Total) -->
          {{ firstLayer.total.tab_4 }}
        </td>
        <td class="v-middle text-center p-x-10">
          <!-- Actual Work Column (Total) -->
          {{ firstLayer.total.tab_5 }}
        </td>
        <td class="v-middle text-center p-x-10"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.checkUpRatio}">
          {{ (firstLayer.total.checkup_ratio_greet || 0) | number: '1.1-1' }}%
        </td>
        <td class="v-middle text-center p-x-10"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio}">
          {{ (firstLayer.total.quotation_ratio_greet || 0) | number: '1.1-1' }}%
        </td>
        <td class="v-middle text-center p-x-10"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.quotationRatio}">
          {{ (firstLayer.total.quotation_ratio_checkup || 0) | number: '1.1-1' }}%
        </td>
        <td class="v-middle text-center p-x-10"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
          {{ (firstLayer.total.confirmed_order_ratio_greet || 0) | number: '1.1-1' }}%
        </td>
        <td class="v-middle text-center p-x-10"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
          {{ (firstLayer.total.confirmed_order_ratio_checkup || 0) | number: '1.1-1' }}%</td>
        <td class="v-middle text-center p-x-10"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.confirmedOrderRatio}">
          {{ (firstLayer.total.confirmed_order_ratio_quotation || 0) | number: '1.1-1' }}%</td>
        <td class="v-middle text-center p-x-10"
            [ngClass]="{'grey-out': !parentSearchData?.dailyCheck?.childItems?.actualWorkRatio}">
          {{ (firstLayer.total.actual_work_ratio_confirmed_order || 0) | number: '1.1-1' }}%</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-center align-items-center"
    *ngIf="!firstLayer?.data_items?.length && !loadingTable">
  <app-list-empty title="{{ 'noDataFound' | translate }}"></app-list-empty>
</div>
<app-loading class="w-100 my-3" *ngIf="loadingTable"></app-loading>
