export class Staff {
  id: number;
  name: string;
  role: number;
  role_name: string;
  shop: number;
  sequence: number;
  alive: boolean;
  working_hour?: number;

  constructor() {
    this.id = null;
    this.name = '';
    this.role = null;
    this.role_name = '';
    this.shop = null;
    this.sequence = null;
    this.alive = true;
  }
}
