import { HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalService } from 'src/app/components/confirm-modal/confirm-modal.service';
import { ShopService } from 'src/app/services/shop.service';
import { Dealer } from '../../dealer/dealer.model';
import {
  BuyerDealerWebUser,
  CompactShop,
  ExportUserDealersAndShop,
} from '../buyer-dealer-web-user.model';
import { BuyerDealerWebUserService } from '../buyer-dealer-web-user.service';

type Mode = 'edit' | 'add';
@Component({
  selector: 'app-edit-export-user',
  templateUrl: './edit-export-user.component.html',
  styleUrls: ['./edit-export-user.component.scss'],
})
export class EditExportUserComponent implements OnInit {
  loading: boolean = false;
  loadingDealers: boolean = false;
  loadingShops: boolean = false;
  saving: boolean = false;
  savingUser: boolean = false;
  popUpMode: Mode;

  id: number;
  exportUser: BuyerDealerWebUser;
  userShopsAndDealers: ExportUserDealersAndShop[];

  dealers: Dealer[] = [];
  shops: CompactShop | [] = [];
  selectedDealer: number = null;
  selectedShop: number[] = [];

  initialShopIdEditList: number[] = [];

  defaultPassword: string = 'PASSWORD';

  constructor(
    private buyerDealerWebUserService: BuyerDealerWebUserService,
    private notification: ToastrService,
    private confirmModal: ConfirmModalService,
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private shopService: ShopService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activedRoute.params.subscribe((params: Params) => {
      this.id = +params['id'];
    });
    this.getEditExportWebUserData();
  }

  getEditExportWebUserData(): void {
    this.buyerDealerWebUserService.getExportWebUser(this.id).subscribe(
      (res) => {
        this.exportUser = res;
        this.exportUser.password = this.defaultPassword;
        this.getExportUserDealersAndShops();
      },
      (err) => {
        this.notification.error(err);
      }
    );
  }

  saveEditExportWebUserData(): void {
    this.savingUser = true;
    const data = {
      name: this.exportUser.name,
      username: this.exportUser.username,
      password:
        this.exportUser.password === this.defaultPassword
          ? ''
          : this.exportUser.password,
    };
    this.buyerDealerWebUserService.patchExportWebUser(this.id, data).subscribe(
      (res) => {
        this.savingUser = false;
        this.cancelEditExportWebUserData();
      },
      (err) => {
        this.savingUser = false;
        this.notification.error(err);
      }
    );
  }

  cancelEditExportWebUserData(): void {
    this.router.navigate(['/buyer-dealer-web-user'], {
      queryParams: { tab: 'export' },
    });
  }

  delete(item: ExportUserDealersAndShop): void {
    this.confirmModal.open(
      'Delete Confirmation',
      'Are u sure you want to delete?',
      () => {
        const removeShopIdList = [];
        item.shops.forEach((shop) => {
          removeShopIdList.push(shop.id);
        });
        const data = {
          user_id: this.id,
          remove_shop_id_list: removeShopIdList,
        };
        this.buyerDealerWebUserService.editShopExportWebUser(data).subscribe(
          (res) => {
            this.getExportUserDealersAndShops();
          },
          (err) => {
            this.notification.error(err);
          }
        );
      }
    );
  }

  getDealers(editingDealerId?: number): void {
    this.loadingDealers = true;
    let params = new HttpParams();
    params = params
      .set('user_id', this.id.toString())
      .set(
        'editing_dealer_id',
        editingDealerId ? editingDealerId.toString() : ''
      );
    this.buyerDealerWebUserService.getDealerListExportWebUser(params).subscribe(
      (res) => {
        this.dealers = res;
        this.loadingDealers = false;
      },
      (err) => {
        this.notification.error(err);
        this.loadingDealers = false;
      }
    );
  }

  getShops(resetOutput: boolean = true): void {
    this.loadingShops = true;
    if (resetOutput) {
      this.shops = [];
      this.selectedShop = [];
    }
    if (!this.selectedDealer) {
      this.loadingShops = false;
      return;
    }
    this.shopService.getAll({ dealer_id: this.selectedDealer }).subscribe(
      (res: any) => {
        this.shops = res;
        this.loadingShops = false;
      },
      (err) => {
        this.notification.error(err);
        this.loadingShops = false;
      }
    );
  }

  getExportUserDealersAndShops(): void {
    this.loading = true;
    let params = new HttpParams();
    params = params.set('user_id', this.id.toString());
    this.buyerDealerWebUserService
      .getExportUserDealersAndShops(params)
      .subscribe(
        (res) => {
          this.userShopsAndDealers = res;
          this.loading = false;
        },
        (err) => {
          this.notification.error(err);
          this.loading = false;
        }
      );
  }

  addOrEditDealersAndShops(
    template: TemplateRef<NgbModalRef>,
    item?: ExportUserDealersAndShop
  ): void {
    this.reset();
    if (item) {
      this.selectedDealer = item.id;
      item.shops.forEach((shop) => {
        this.selectedShop.push(shop.id);
      });
      this.initialShopIdEditList = this.selectedShop;
    }
    this.popUpMode = item ? 'edit' : 'add';
    this.getDealers(item?.id);
    this.getShops(false);
    this.modalService.open(template);
  }

  close(): void {
    this.modalService.dismissAll();
  }

  reset(): void {
    this.selectedDealer = null;
    this.dealers = [];
    this.selectedShop = [];
    this.shops = [];
  }

  saveNewDealerAndShop(): void {
    this.saving = true;
    const shopList = [];
    this.selectedShop.forEach((id) => {
      shopList.push(id);
    });
    const data = {
      user_id: this.id,
      add_shop_id_list: shopList,
    };
    this.buyerDealerWebUserService.editShopExportWebUser(data).subscribe(
      (res) => {
        this.close();
        this.getExportUserDealersAndShops();
        this.saving = false;
      },
      (err) => {
        this.notification.error(err);
        this.saving = false;
      }
    );
  }

  saveEditDealerAndShop(): void {
    this.saving = true;
    const shopList = [];
    this.selectedShop.forEach((id) => {
      shopList.push(id);
    });
    const data = {
      user_id: this.id,
      add_shop_id_list: shopList.filter(
        (item) => this.initialShopIdEditList.indexOf(item) < 0
      ),
      remove_shop_id_list: this.initialShopIdEditList.filter(
        (item) => shopList.indexOf(item) < 0
      ),
    };
    this.buyerDealerWebUserService.editShopExportWebUser(data).subscribe(
      (res) => {
        this.close();
        this.getExportUserDealersAndShops();
        this.saving = false;
      },
      (err) => {
        this.notification.error(err);
        this.saving = false;
      }
    );
  }
}
