import { OnInit, Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-loading',
  templateUrl: './fileLoading.component.html',
  styleUrls: ['./fileLoading.component.scss']
})
export class FileLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
