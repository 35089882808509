import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../components/core/core.module';
import { SharedModule } from 'src/app/shared.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ImportUpdateHistoryComponent} from './import-update-history.component';
import {ImportUpdateHistoryRoutingModule} from './import-update-history-routing.module';

@NgModule({
  declarations: [
    ImportUpdateHistoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    ImportUpdateHistoryRoutingModule,
    CoreModule,
    SharedModule,
    NgxFileDropModule,
    NgSelectModule,
    NgbDatepickerModule,
  ],
  providers: []
})
export class ImportUpdateHistoryModule {}
