
<app-header
  title="{{ (showManagerDealerList ? 'supervisor.SupervisorDealerManagers' : 'supervisor.Supervisors') | translate }}"
  [hasCustomAddButton]="false"
  [orderCustomBtn]="showManagerList || showManagerDealerList"
  (goToList)="showingSupervisorOrDealerManager()"
  >
</app-header>

<div class="main">

  <div *ngIf="showManagerDealerList">
    <app-supervisor-dealer-manager-list></app-supervisor-dealer-manager-list>
  </div>

  <div *ngIf="showManagerList">
    <app-supervisors-manager-list></app-supervisors-manager-list>
  </div>

  <div class="content" *ngIf="!(showManagerList || showManagerDealerList)">
  <div class="row no-gutter d-flex align-content-center justify-content-end mr-1">
      <div class="d-flex align-items-center">



  <button class="btn btn-primary d-flex align-items-center mr-3"
          *ngIf="isAdmin || isSupervisorDealerManager"
          (click)="showSupervisorManagerList()">
          {{ 'supervisor.Supervisors' | translate }}
  </button>

  <button class="btn btn-dark d-flex align-items-center mr-3"
          (click)="uploadPrice(content)">
          <img src="assets/svgs/white_download.svg" height="20" width="auto" class="mr-2">
          {{ 'supervisor.importSupervisor' | translate }}
  </button>

  <button class="btn btn-primary d-flex align-items-center mr-3"
          *ngIf="isAdmin"
          (click)="showSupervisorDealerManagerList()">
          {{ 'supervisor.SupervisorDealerManagers' | translate }}
  </button>

  <button class="btn btn-danger" (click)="openDeleteAll(confirmDeleteAll)" *ngIf="hasRouteAndSelected()">
    {{ 'Delete All Approval Settings' | translate }}
  </button>

</div>

    </div>

    <div class="row mt-2">
      <div class='col-4'>
        <div class='one-col-table shadow'>
          <div class='col-header'>
            {{ 'Select Shop' | translate }}
<!--              <button class='select-all-button' (click)="selectForCheckboxAllShops(true)">Select All</button>-->
          </div>

          <div class='row no-gutter m-2'>

            <div class="col-12">
              <input class="w-100 form-control" type="text" placeholder="{{ 'Search Shop' | translate }}"
                [(ngModel)]="filterargs.name" (ngModelChange)="filterShop()">
            </div>
          </div>

          <div class='col-body'>
            <div class='row no-gutter dealer-row' *ngFor='let shop of filteredShops; let editingRow = index'
              [ngClass]="{ 'selected': shop.selected}">
              <div class="col-12 row no-gutter">
                <span class='col-11 px-0 py-2 m-0' (click)='onSelectShop(shop)'>
                  {{ shop.name }}
                </span>
              </div>
            </div>
            <app-loading *ngIf='loadingDealers'></app-loading>
          </div>
        </div>
      </div>
      <div class='col-4'>
        <div class='one-col-table shadow'>
          <div class='col-header'>
            {{ 'product-set.selectCategory' | translate }}
            <button class='select-all-button' (click)="onSelectAllCategories()">{{ (getCategorySelectAllTitle() ? 'supervisor.Deselect All' : 'supervisor.Select All') | translate }}</button>
          </div>

          <div class='row no-gutter m-2'>
            <div class="col-12">
              <input class="w-100 form-control" type="text" placeholder="{{ 'searchCategory' | translate }}"
                [(ngModel)]="filterargs.category" (ngModelChange)="filterCategory()">
            </div>
          </div>

          <div class='col-body'>
            <div class='row no-gutter dealer-row' *ngFor="let category of filteredCategoryList let index = index"
              (click)="onSelectCategory(category)" [ngClass]="{ 'selected': category.selected}">
              <div class="col-12 row no-gutters align-items-center">
                <span class="col-11 px-0 py-2" *ngIf='!isLoading'>{{ category.number_and_name }}</span>
                <span class="col-1 px-0 py-2" *ngIf='!isLoading && category.has_route'>*</span>
              </div>
            </div>
            <app-loading *ngIf='isLoading'></app-loading>
          </div>
        </div>
      </div>

      <div class='col-4' *ngIf="selectedCategories.length > 0">

        <div class="row m-0">


          <div class='one-col-table shadow mb-3 w-100' *ngFor="let approval of approvals; let sectionIdx = index">
            <div class='col-header d-flex align-items-center'>
              <div class="col-10">{{ 'supervisor.Approver Settings' | translate }}</div>
                <div class="col-2 p-0 d-flex justify-content-end">
                  <i class="fas fa-times clickable mr-0" (click)="openDelete(confirmDelete, sectionIdx, approval)"></i>
                </div>
            </div>

            <div class='row no-gutter m-3'>
              <div class="col-4 m-0 px-1">
                <label class="py-1">{{ 'supervisor.Amount of money' | translate}}</label>
              </div>
              <div class="col-8 m-0 p-0">
                <div class="input-group">
                  <input class="form-control pr-5" type="number" [(ngModel)]="approval.amount_limit"
                    [disabled]="!isEditingAmount[sectionIdx]">
                  <div class="input-group-append">
                    <img src='assets/svgs/edit_pen.svg' width='30' height='30' (click)="toggleEditingAmount(sectionIdx)"
                      style="cursor: pointer;">
                  </div>
                </div>
              </div>
            </div>

            <div class="row no-gutter px-2 m-3">
              <label class="col-4 mt-2 pt-1 p-0 align-self-top">{{ 'Supervisor' | translate }}</label>
              <div class="col-8 p-0">
                <div class="d-flex py-1 align-items-center"
                  *ngFor="let dropdown of dropdowns[sectionIdx].slice(0, 3); let i = index">
                  <div class="mr-2">{{ i+1 }}</div>
                  <div class="row w-100 p-0 m-0">
                    <ng-select class="w-100 p-0" [items]="supervisorList" [loading]="loadingSupervisors"
                      bindLabel="email" bindValue="id" [(ngModel)]="approval.supervisors[i]"
                      [placeholder]="'supervisor.Select Supervisor' | translate" (clear)='removeDropdown(sectionIdx, i)'
                      [notFoundText]="'notFound' | translate">
                        <ng-template ng-option-tmp let-item="item">
                          <div>{{ item.family_name }} {{ item.given_name }} : {{ item.email }}</div>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item">
                          <div>{{ item.family_name }} {{ item.given_name }} : {{ item.email }}</div>
                        </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2" *ngIf="dropdowns[sectionIdx].length < 3">
                  <i class="fas fa-plus-circle clickable" (click)="addDropdown(sectionIdx)"></i>
                  <div class="m-2 text-primary"><span>{{'supervisor.Add Approvers'| translate}}</span></div>
                </div>
              </div>
            </div>
            <app-loading *ngIf='loadingSupervisors'></app-loading>
            <div class="row no-gutter mb-2 p-2">

              <div class="col-6 d-flex justify-content-start">
                <div class="d-flex align-items-center">
                  <i *ngIf="approvals.length-1 === sectionIdx" class="fas fa-plus clickable" (click)="addSection()"></i>
                </div>
              </div>

              <div class="col-6 d-flex justify-content-end">
                <button class="btn btn-primary position-relative" [class.disable]="buttonLoadingState[sectionIdx]"
                  (click)="save(sectionIdx)">
                  <span *ngIf="!buttonLoadingState[sectionIdx]">{{ 'Settings' | translate }}</span>
                  <span class="spinner-border spinner-border-sm" *ngIf="buttonLoadingState[sectionIdx]" role="status"
                    aria-hidden="true"></span>
                  <span class="visually-hidden" *ngIf="buttonLoadingState[sectionIdx]">{{ 'Saving...' | translate }}</span>
                </button>
              </div>
            </div>
          </div>

          <h2>{{ 'supervisor.Select SS' | translate }}</h2>
          <ul>
            <li *ngFor="let shop of affectedShops">
               Name: {{ shop.name }}
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-loading style="position: center;vertical-align: middle; text-align: center;"></app-loading>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'supervisor.importSupervisor' | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <input type="file"
        id="file"
        (change)="handleFileInput($event.target.files)"
        accept=".xls, .xlsx">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="uploadFile()" translate>
      Import
    </button>
  </div>
</ng-template>

<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'category.deleteConfirmation' | translate }}
    </h4>
    <button
      type="button"
      class="close btn p-2"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'category.areYouSure' | translate }}</p>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ 'category.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.close('Ok click')"
      >
        {{ 'category.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>


<ng-template #confirmDeleteAll let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Delete All Approval Settings' | translate }}
    </h4>
    <button
      type="button"
      class="close btn p-2"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'category.areYouSure' | translate }}</p>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ 'category.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.close('Ok click')"
      >
        {{ 'category.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>
