import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SellerWebuserService } from './seller-webuser.service';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../../services/seller.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-seller-webuser',
  templateUrl: './seller-webuser.component.html',
  styleUrls: ['./seller-webuser.component.scss']
})
export class SellerWebuserComponent implements OnInit {

  currentpage = 1;
  sellers = [];
  pageCount = 0;
  loading = false;
  sellerWebUsers;
  saveError = {};
  user = {};
  errorMsg = {};
  fileToUpload: File = null;
  type = '';
  search = {
    userName: '',
    email: '',
  }

  constructor(
    private modalService: NgbModal,
    private sellerWebUserService: SellerWebuserService,
    private notiService: ToastrService,
    private sellerService: SellerService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getSellerWebUsers();
    this.getAllSellers();
    this.setUser();
  }

  getSellerWebUsers() {
    this.loading = true;
    let params = new HttpParams();
    params = params.append('page', this.currentpage.toString());
    params = params.append('username', this.search['userName']);
    params = params.append('email', this.search['email']);
    this.sellerWebUserService.getSellerWebUsers(params).subscribe(
      (res: any) => {
        this.pageCount = res.total_pages;
        this.sellerWebUsers = res.results;
        this.loading = false;
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  getAllSellers() {
    this.sellerService.getALlFromSellerWebUser().subscribe(
      (res) => {
        this.sellers = res;
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  showErrorMsg(msg) {
    this.notiService.error(_.values(msg.error)[0].toString());
  }

  navigateTo(event) {
    this.currentpage = event;
    this.getSellerWebUsers();
  }

  openModal(view, user = null) {
    const option: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.type = user ? 'edit' : 'add';
    this.setUser(user);
    this.modalService.open(view, option).result.then((result) => {}, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissUser();
        this.dismissFileSelection();
      }
    });
  }

  dismissUser() {
    this.setUser();
    this.removeError();
    this.modalService.dismissAll();
  }

  dismissFileSelection () {
    this.fileToUpload = null;
    this.modalService.dismissAll();
  }

  removeError() {
    this.errorMsg = {};
  }

  saveUser() {
    const data = {};
    this.user['name'] ? data['name'] = this.user['name'] : this.errorMsg['name'] = true;
    this.user['user'] ? data['username'] = this.user['user'] : this.errorMsg['user'] = true;
    this.user['email'] && this.checkEmail(this.user['email']) ?
      data['email'] = this.user['email'] : this.errorMsg['email'] = true;
    this.user['password'] && this.user['password'] !== 'unchangedPassword' ?
      data['password'] = this.user['password'] : {};
    !this.user['password'] ? this.errorMsg['password'] = true : {};
    if (_.values(this.errorMsg).filter(x => x).length) {
      return;
    }
    this.user['id'] ? this.updateUser(this.user['id'], data) : this.createUser(data);
  }

  setUser(user = null) {
    user ? user['password'] = 'unchangedPassword' : {};
    this.user = user || {
      'name': '',
      'userName': '',
      'email': '',
      'password': '',
    }
  }

  updateUser(id, data) {
    this.sellerWebUserService.updateSellerWebUser(id, data).subscribe(
      (res) => {
        this.dismissUser();
        this.getAllSellers();
        this.getSellerWebUsers();
        this.notiService.success(this.translate.instant('Successfully Updated User'));
      },
      (error) => {
        this.notiService.error(this.translate.instant('username/email already exists in the system'));
      }
    );
  }

  createUser(data) {
    this.sellerWebUserService.createNewSellerWebUser(data).subscribe(
      (res) => {
        this.dismissUser();
        this.getAllSellers();
        this.getSellerWebUsers();
        this.notiService.success(this.translate.instant('Successfully Created New User'));
      },
      (error) => {
        this.notiService.error(this.translate.instant('username/email already exists in the system'));
      }
    );
  }

  checkEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile() {
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.sellerWebUserService.importSellerWebUser(data).subscribe(
      (res: any) => {
        this.modalService.dismissAll();
        this.notiService.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
      },
      (err) => {
        this.showErrorMsg(err);
      }
    )
  }

  resetSearch() {
    this.search = {
      userName: '',
      email: '',
    }
    this.getSellerWebUsers();
  }
}
