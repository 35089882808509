import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyerDealerWebUserComponent } from './buyer-dealer-web-user/buyer-dealer-web-user.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '../../components/core/core.module';
import {SharedModule} from '../../shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {DailyCheckHistoryRoutingModule} from '../daily-check-history/daily-check-history-routing.module';

@NgModule({
  declarations: [
    BuyerDealerWebUserComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    CoreModule,
    SharedModule,
    TranslateModule,
    NgbModule,
    DailyCheckHistoryRoutingModule,
  ]
})
export class BuyerDealerWebUserModule { }
