<app-header
  title="{{ (showingDetail ? 'monotaro.purchasedOrderDetail' : 'monotaro.purchasedOrder') | translate }}"
  [hasCustomAddButton]="false"
  [orderCustomBtn]="showingDetail"
  (goToList)='showList()'>
</app-header>

<div class="main-content">
  <div *ngIf="!showingDetail">
    <div class='row no-gutter p-3'>
      <div class='search-box' [ngClass]="{'disabled': loadingSearch}">
        <h5 class='row no-gutter'>
          {{ 'Search' | translate }}
        </h5>

        <!----------------- 1st Row ------------------->
        <div class='row no-gutter h-auto min-h-80'>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'monotaro.userID' | translate }}
            </label>
            <div class='col-8 p-0'>
              <p-autoComplete
                class='w-100percent'
                [(ngModel)]="searchFields.user_id"
                [multiple]="true"
                [suggestions]="filterUserID"
                [field]=""
                [emptyMessage]="'User not found' | translate"
                [forceSelection]="true"
                (completeMethod)="onUserIDFiltering($event)"
                (onUnselect)="onUserIDUnselectValue($event)">
                <ng-template let-value pTemplate="selectedItem">
                  <span class="ui-autocomplete-token-label">{{ value }}</span>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'monotaro.shop' | translate }}
            </label>
            <ng-select
              class="col-8 p-0 custom-selector"
              [clearable]="true"
              [searchable]="true"
              [items]="shopList"
              [(ngModel)]="searchFields.shop">
            </ng-select>
          </div>
        </div>

        <!----------------- 2nd Row ------------------->
        <div class='row no-gutter h-auto min-h-80'>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'monotaro.majorClassification' | translate }}
            </label>
            <ng-select
              class="col-8 p-0 custom-selector"
              [clearable]="true"
              [searchable]="true"
              [items]="majorClassificationList"
              [multiple]="true"
              bindLabel="name"
              bindValue="id"
              (change)="selectMajor()"
              [(ngModel)]="searchFields.major_classification">
            </ng-select>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'monotaro.mediumClassification' | translate }}
            </label>
            <ng-select
              class="col-8 p-0 custom-selector"
              [clearable]="true"
              [searchable]="true"
              [items]="mediumClassificationList"
              [multiple]="true"
              bindLabel="name"
              bindValue="id"
              (change)="selectMedium()"
              [(ngModel)]="searchFields.medium_classification">
            </ng-select>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'monotaro.minorClassification' | translate }}
            </label>
            <ng-select
              class="col-8 p-0 custom-selector"
              [clearable]="true"
              [searchable]="true"
              [items]="minorClassificationList"
              [multiple]="true"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="searchFields.minor_classification">
            </ng-select>
          </div>
        </div>

        <!----------------- 3rd Row ------------------->
        <div class='row no-gutter h-auto'>
          <div class="col-8 row no-gutter d-flex align-items-center">
            <div class="col-auto d-flex align-items-center p-0">
              <input type="checkbox"
                     class="h-20 w-20"
                     [checked]="searchFields.selected_only"
                     (click)="changeSelectedOnly()"
                     [(ngModel)]="searchFields.selected_only">
            </div>
            <label class="col-11 m-0">
              {{ 'monotaro.showOnlySearchingClassification' | translate }}
            </label>
          </div>
        </div>

        <!----------------- 4th Row ------------------->
        <div class='row no-gutter h-auto min-h-80'>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'Order Number' | translate }}
            </label>
            <div class='col-8 p-0'>
              <p-autoComplete
                class='w-100percent'
                [(ngModel)]="searchFields.order_number"
                [multiple]="true"
                [suggestions]="filterOrderNumber"
                [field]=""
                [emptyMessage]="'Order number not found' | translate"
                [forceSelection]="true"
                (completeMethod)="onOrderNumberFiltering($event)"
                (onUnselect)="onOrderNumberUnselectValue($event)">
                <ng-template let-value pTemplate="selectedItem">
                  <span class="ui-autocomplete-token-label">{{ value }}</span>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'monotaro.productName' | translate }}
            </label>
            <div class='col-8 p-0'>
              <p-autoComplete
                class='w-100percent'
                [(ngModel)]="searchFields.product_name"
                [multiple]="true"
                [suggestions]="filterProductName"
                [field]=""
                [emptyMessage]="'Product name not found' | translate"
                [forceSelection]="true"
                (completeMethod)="onProductNameFiltering($event)"
                (onUnselect)="onProductNameUnselectValue($event)">
                <ng-template let-value pTemplate="selectedItem">
                  <span class="ui-autocomplete-token-label">{{ value }}</span>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'monotaro.productCode' | translate }}
            </label>
            <div class='col-8 p-0'>
              <p-autoComplete
                class='w-100percent'
                [(ngModel)]="searchFields.product_code"
                [multiple]="true"
                [suggestions]="filterProductCode"
                [field]=""
                [emptyMessage]="'Product code not found' | translate"
                [forceSelection]="true"
                (completeMethod)="onProductCodeFiltering($event)"
                (onUnselect)="onProductCodeUnselectValue($event)">
                <ng-template let-value pTemplate="selectedItem">
                  <span class="ui-autocomplete-token-label">{{ value }}</span>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
        </div>

        <!----------------- 5th Row ------------------->
        <div class='row no-gutter h-auto min-h-80'>
          <div class='col-5 row no-gutter align-self-center pr-5'>
            <label class='col-3 pl-0 m-0 d-flex align-items-center'>
              {{ 'Order Date' | translate }}
            </label>
            <div class="col-8 d-flex">
              <input
                class="form-control h-35 align-self-center border rounded px-1"
                type='text'
                (click)="d.toggle()"
                name="dp"
                [(ngModel)]="searchFields.order_date_after"
                ngbDatepicker
                #d="ngbDatepicker">
              <div class='wiggle-class pl-2 pr-2'>
                ~
              </div>
              <input
                class="form-control h-35 align-self-center border rounded px-1"
                type='text'
                (click)="g.toggle()"
                name="dp"
                [minDate]="searchFields.order_date_after"
                [disabled]="!searchFields.order_date_after"
                [(ngModel)]="searchFields.order_date_before"
                ngbDatepicker
                #g="ngbDatepicker">
            </div>


          </div>
          <div class="col-7 row no-gutter justify-content-end align-self-center pr-5">
            <button class="btn btn-sm d-flex align-items-center btn-primary h-30" (click)="clickSearchBtn()">
              <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
              {{'product-image.search' | translate}}
            </button>
            <button class="btn btn-sm d-flex align-items-center btn-secondary ml-3 h-30" (click)="resetSearch()">
              <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
              {{'product-image.reset' | translate}}
            </button>
            <button class="btn btn-outline-primary btn-sm d-flex align-items-center ml-3" (click)="exportOrder()">
              <img src="assets/svgs/blue_file_export.svg" height="20" class="mr-1" width="auto">
              {{'Export Order' | translate}}
            </button>
            <button class="btn btn-sm d-flex align-items-center btn-primary h-30 ml-3" (click)="openModal(importData)">
              <img src="assets/svgs/white_file_upload.svg" height="20" class="mr-1" width="auto">
              {{'Import' | translate}}
            </button>
          </div>
        </div>
      </div>
      <!---------------------------Order Table--------------------------------->
      <div class='order-table' *ngIf="!loadingSearch">
        <table
          class="table table-hover text-center table-borderless table-striped mb-5"
          style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
          *ngIf="!loadingSearch && purchaseOrders.length">
          <thead>
          <tr>
            <th style="width: 5%"></th>
            <th style="width: 10%">{{ 'Order Date' | translate }}</th>
            <th style="width: 10%">{{ 'Order Number' | translate }}</th>
            <th style="width: 20%">{{ 'monotaro.shop' | translate }}</th>
            <th style="width: 10%">{{ 'Total Price' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of purchaseOrders; let i = index;">
            <td class="align-middle detail-icn" (click)="showPurchaseDetail(item)">
              <img src='assets/svgs/black_info_circle.svg' width='24' height='24'>
            </td>
            <td class="align-middle">{{ item.order_date | date: 'yyyy-MM-dd' || '' }}</td>
            <td class="align-middle">{{ item.order_number || '' }}</td>
            <td class="align-middle">{{ item.etr_shop.name || '' }}</td>
            <td class='align-middle'>{{ item.total_price | currency: 'JPY' || '' }}</td>
          </tr>
          </tbody>
        </table>
        <div class='row no-gutter' *ngIf="!loadingSearch && purchaseOrders.length">
          <ngb-pagination
            [collectionSize]="count"
            [(page)]="currentPage"
            [maxSize]="5"
            [pageSize]="40"
            (pageChange)='navigateTo($event)'
            [boundaryLinks]="true">
          </ngb-pagination>
        </div>


        <div class='row no-gutter d-flex justify-content-center'
             *ngIf='!loadingSearch && !purchaseOrders.length'>
          <h1 class='text-muted'>{{ 'noDataFound' | translate }}</h1>
        </div>
      </div>
      <app-loading class="w-100 my-3" *ngIf="loadingSearch"></app-loading>
    </div>
  </div>

  <div *ngIf="showingDetail">
    <app-purchase-order-detail
      [purchaseOrder]="purchaseOrder"
      [searchParams]="selectedOnlyParams">
    </app-purchase-order-detail>
  </div>
</div>

<ng-template #importData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Import' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
      style="border: none;"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="uploadFileError"
        >
          {{ 'Cannot upload file' | translate }}. {{ 'Error' | translate }}: {{ uploadFileError }}
        </div>
        <label class="form-group">{{ 'Select File' | translate }}</label>
        <input class="form-control-file"
               type="file"
               (change)="chooseFile($event)"
               accept=".xls, .xlsx">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="importMonotaroFile()"
      [disabled]="!importFile || uploading"
    >
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="uploading"></span>
      {{ 'Import' | translate }}
    </button>
  </div>
</ng-template>
