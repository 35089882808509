import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DailyCheckTabletUserComponent } from './daily-check-tablet-user.component';


export const DailyCheckTabletUser: Routes = [
  {
    path: 'daily-check-tablet-user',
    children: [
      { path: '', component: DailyCheckTabletUserComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(DailyCheckTabletUser)],
  exports: [RouterModule]
})
export class DailyCheckTabletUserRoutingModule {}
