import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AnnouncementComponent } from './announcement.component';


export const AnnouncementRoutes: Routes = [
  {
    path: 'announcements',
    children: [
      {path: '', component: AnnouncementComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(AnnouncementRoutes)],
  exports: [RouterModule]
})
export class AnnouncementRoutingModule {
}
