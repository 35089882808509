<div class="row no-gutter"
     *ngIf="!checkEmptyData()">

  <!-- Summary Table for Branch, Shop, Staff -->
  <table *ngIf="searchData.tableType >= 0 && searchData.tableType <= 2">
    <thead>
      <tr class="tr-top-left">
        <th *ngIf="([0, 1, 2].includes(searchData.tableType)) && checkDisplayColumn('branch')"
            class="w-16percent max-w-270 text-center border-right min-w-150"
            rowspan="3">
          {{ "Branch" | translate}}
        </th>
        <th *ngIf="[1, 2].includes(searchData.tableType)  && checkDisplayColumn('dealer')"
            class="w-16percent max-w-270 text-center top-left border-right min-w-150"
            rowspan="3">
          {{"daily-check.dealer" | translate}}
        </th>
        <th *ngIf="[1, 2].includes(searchData.tableType)"
            class="w-16percent max-w-270 text-center top-left border-right min-w-150"
            rowspan="3">
          {{ "Shop" | translate}}
        </th>
        <th *ngIf="searchData.tableType === 2"
            class="w-16percent max-w-270 text-center top-left border-right min-w-150"
            rowspan="3">
          {{"Role" | translate}}
        </th>
        <th *ngIf="searchData.tableType === 2"
            class="w-16percent max-w-270 text-center border-right min-w-150"
            rowspan="3">
          {{ "daily-check-data-management.staff" | translate}}
        </th>
        <th *ngIf="searchData.tableType === 0" class="w-16percent max-w-270 text-center border-right min-w-150" rowspan="3">
          {{"Number of Shops" | translate}}
        </th>
        <th *ngIf="searchData.tableType === 0 || searchData.tableType === 1" class="w-16percent max-w-270 text-center border-right min-w-150" rowspan="3">
          {{"Number of Visited Cars" | translate}}
        </th>
        <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150" rowspan="3">
          {{"Number of Cars Corresponded" | translate}}
        </th>
        <ng-container *ngFor="let typeItem of resultData.summary_data.types; let i = index">
          <th *ngIf="searchData.tableType !== 2" class="w-7percent text-center border-bottom"
              colspan="8"
              [ngClass]="
                i === resultData.summary_data.types.length - 1 ? 
                'top-right-round' :
                'border-right'">
            {{ typeItem.type | dailyCheckItemMap | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-7percent text-center border-bottom"
              colspan="15"
              [ngClass]="
                i === resultData.summary_data.types.length - 1 ? 
                'top-right-round' :
                'border-right'">
            {{ typeItem.type | dailyCheckItemMap | translate}}
          </th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let typeItem of resultData.summary_data.types">
          <th class="w-16percent max-w-270 text-center border-right border-bottom min-w-150" [attr.colspan]="searchData.tableType === 2 ? 3 : 2">
            {{"Greet" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right border-bottom min-w-150" [attr.colspan]="searchData.tableType === 2 ? 3 : 2">
            {{"Checkup" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right border-bottom min-w-150" [attr.colspan]="searchData.tableType === 2 ? 3 : 2">
            {{"Estimation" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right border-bottom min-w-150" [attr.colspan]="searchData.tableType === 2 ? 3 : 2">
            {{"Confirmed Order" | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right border-bottom min-w-150" [attr.colspan]="searchData.tableType === 2 ? 3 : 2">
            {{"Actual Work" | translate}}
          </th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let typeItem of resultData.summary_data.types">
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col1-rank" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col1-cars" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col1-ratio" | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col2-rank" | translate}}
          </th>
          <th  class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col2-cars" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col2-ratio" | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col3-rank" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col3-cars" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col3-ratio" | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col4-rank" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col4-cars" | translate}}
          </th>
          <th class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col4-ratio" | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col5-rank" | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col5-cars" | translate}}
          </th>
          <th *ngIf="searchData.tableType === 2" class="w-16percent max-w-270 text-center border-right min-w-150">
            {{"daily-check-data-management.headers.col5-ratio" | translate}}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <!-------------------------------------------Daily Check Item Layer-------------------------------------------->
      <ng-container *ngFor="let data of resultData.data; let row_index = index;">
        <tr class='first-layer-bg border-y'>
          <td *ngIf="([0, 1, 2].includes(searchData.tableType)) && checkDisplayColumn('branch')"
              class="text-center p-y-5 p-x-10">
            {{ data.branch_name }}
          </td>
          <td *ngIf="[1, 2].includes(searchData.tableType)  && checkDisplayColumn('dealer')"
              class="text-center p-y-5 p-x-10">
            {{ data.dealer_name }}
          </td>
          <td *ngIf="[1, 2].includes(searchData.tableType)" class="text-center p-y-5 p-x-10">
            {{ data.shop_name || data.staff_shop }}
          </td>
          <td *ngIf="searchData.tableType === 2"
              class="text-center p-y-5 p-x-10">
            {{ getRoleName(data.staff_role) | translate }}
          </td>
          <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10">
            {{ data.staff_name }}
          </td>
          <td *ngIf="searchData.tableType === 0" class="text-center p-y-5 p-x-10">
            {{ data.number_of_shops | number }}
          </td>
          <td class="text-center p-y-5 p-x-10">
            {{ data.number_of_visited_cars | number }}
          </td>
          <ng-container *ngFor="let type of data.types; let type_index = index">
            <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10">
              {{ type.tab_1_rank | number }}
            </td>
            <td class="text-center p-y-5 p-x-10">
              {{ type.tab_1_cars | number }}
            </td>
            <td class="text-center p-y-5 p-x-10" [ngClass]="{'highlight': checkMaxNumber(searchData.tableType === 2 ? data.staff_id : row_index, type_index, 'tab_1')}">
              {{ type.tab_1_ratio | number:'1.1-1' }}%
            </td>
            <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10">
              {{ type.tab_2_rank | number }}
            </td>
            <td class="text-center p-y-5 p-x-10">
              {{ type.tab_2_cars | number }}
            </td>
            <td class="text-center p-y-5 p-x-10" [ngClass]="{'highlight': checkMaxNumber(searchData.tableType === 2 ? data.staff_id : row_index, type_index, 'tab_2')}">
              {{ type.tab_2_ratio | number:'1.1-1' }}%
            </td>
            <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10">
              {{ type.tab_3_rank | number }}
            </td>
            <td class="text-center p-y-5 p-x-10">
              {{ type.tab_3_cars | number }}
            </td>
            <td class="text-center p-y-5 p-x-10" [ngClass]="{'highlight': checkMaxNumber(searchData.tableType === 2 ? data.staff_id : row_index, type_index, 'tab_3')}">
              {{ type.tab_3_ratio | number:'1.1-1' }}%
            </td>
            <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10">
              {{ type.tab_4_rank | number }}
            </td>
            <td class="text-center p-y-5 p-x-10">
              {{ type.tab_4_cars | number }}
            </td>
            <td class="text-center p-y-5 p-x-10" [ngClass]="{'highlight': checkMaxNumber(searchData.tableType === 2 ? data.staff_id : row_index, type_index, 'tab_4')}">
              {{ type.tab_4_ratio | number:'1.1-1' }}%
            </td>
            <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10">
              {{ type.tab_5_rank | number }}
            </td>
            <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10">
              {{ type.tab_5_cars | number }}
            </td>
            <td *ngIf="searchData.tableType === 2" class="text-center p-y-5 p-x-10" [ngClass]="{'highlight': checkMaxNumber(searchData.tableType === 2 ? data.staff_id : row_index, type_index, 'tab_5')}">
              {{ type.tab_5_ratio | number:'1.1-1' }}%
            </td>
          </ng-container>
        </tr>
      </ng-container>

      <!----------------------------------------------Total Row ------------------------------------------------->
      <tr class="darker-grey-bg border-y">
        <td class="v-middle text-center p-x-10" [attr.colspan]="getTotalColSpan()">
          {{ 'Total' | translate }}
        </td>
        <td *ngIf="searchData.tableType === 0" class="v-middle text-center p-x-10">
          {{ resultData.summary_data.total_number_of_shops | number }}
        </td>
        <td class="v-middle text-center p-x-10">
          {{ resultData.summary_data.total_number_of_visited_cars | number }}
        </td>
        <ng-container *ngFor="let type of resultData.summary_data.types">
          <td *ngIf="searchData.tableType === 2" class="v-middle text-center p-x-10">
            {{ "&nbsp;" }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_1_total_cars | number }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_1_total_ratio | number:'1.1-1' }}%
          </td>
          <td *ngIf="searchData.tableType === 2" class="v-middle text-center p-x-10">
            {{ "&nbsp;" }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_2_total_cars | number }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_2_total_ratio | number:'1.1-1' }}%
          </td>
          <td *ngIf="searchData.tableType === 2" class="v-middle text-center p-x-10">
            {{ "&nbsp;" }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_3_total_cars | number }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_3_total_ratio | number:'1.1-1' }}%
          </td>
          <td *ngIf="searchData.tableType === 2" class="v-middle text-center p-x-10">
            {{ "&nbsp;" }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_4_total_cars | number }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_4_total_ratio | number:'1.1-1' }}%
          </td>
          <td *ngIf="searchData.tableType === 2" class="v-middle text-center p-x-10">
            {{ "&nbsp;" }}
          </td>
          <td *ngIf="searchData.tableType == 2" class="v-middle text-center p-x-10">
            {{ type.tab_5_total_cars | number }}
          </td>
          <td *ngIf="searchData.tableType == 2" class="v-middle text-center p-x-10">
            {{ type.tab_5_total_ratio | number:'1.1-1' }}%
          </td>
        </ng-container>
      </tr>

      <!----------------------------------------------Average Row ------------------------------------------------->
      <tr *ngIf="searchData.tableType !== 2" class="darker-grey-bg">
        <td [attr.colspan]="searchData.tableType === 1 ? 3 : (searchData.tableType === 0 ? 2 : 1)" class="v-middle text-center p-x-10">
          {{ ([0,1].includes(searchData.tableType) ? 'Average Per Shop' : '') | translate}}
        </td>
        <td class="v-middle text-center p-x-10">
          {{ resultData.summary_data.average_number_of_visited_cars | number:'1.0-0' }}
        </td>
        <ng-container *ngFor="let type of resultData.summary_data.types">
          <td class="v-middle text-center p-x-10">
            {{ type.tab_1_average_cars | number:'1.0-0' }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_1_average_ratio | number:'1.1-1' }}%
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_2_average_cars | number:'1.0-0' }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_2_average_ratio | number:'1.1-1' }}%
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_3_average_cars | number:'1.0-0' }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_3_average_ratio | number:'1.1-1' }}%
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_4_average_cars | number:'1.0-0' }}
          </td>
          <td class="v-middle text-center p-x-10">
            {{ type.tab_4_average_ratio | number:'1.1-1' }}%
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <!-- Summary Table End -->

  <!-- Activity Ranking Table -->
  <div class="w-100percent" *ngIf="searchData.tableType == 3 || searchData.tableType == 4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 m-y-10" *ngFor="let table of this.activityRankingTables; let index = index">
          <table class="w-100percent text-nowrap">
            <thead>
              <tr>
                <th *ngIf="[3, 4].includes(searchData.tableType)"
                    rowspan="2"
                    class="w-16percent text-center top-left-round border-right">
                  {{ 'Rank' | translate }}
                </th>
                <th rowspan="2" class="w-16percent text-center border-right">
                  {{ 'daily-check.dealer' | translate }}
                </th>
                <th rowspan="2" class="w-16percent text-center border-right">
                  {{ 'daily-check-data-management.ranking-shopName' | translate }}
                </th>
                <th *ngIf="searchData.tableType == 4" rowspan="2" class="w-16percent text-center border-right">
                  {{ 'Staff Name' | translate }}
                </th>
                <th colspan="2"
                  class="w-7percent text-center border-bottom"
                  [ngClass]="'top-right-round'">
                {{ getTabName(index+1) + ' Top 5' | translate }}
                </th>
              </tr>
              <tr>
                <th class="w-16percent text-center border-right">
                  {{"daily-check-data-management.headers.col" + (index + 1) + "-cars" | translate}}
                </th>
                <th class="w-16percent text-center border-right">
                  {{"daily-check-data-management.headers.col" + (index + 1) + "-ratio" | translate}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="first-layer-bg border-y" *ngFor="let item of this.resultData.data[table]; let row_index = index;">
                <td *ngIf="[3, 4].includes(searchData.tableType)"
                    class="text-center p-y-5 p-x-10">{{ row_index+1 }}</td>
                <td class="text-center p-y-5 p-x-10">{{ item.dealer_name }}</td>
                <td class="text-center p-y-5 p-x-10">{{ item.shop_name }}</td>
                <td *ngIf="searchData.tableType == 4" class="text-center p-y-5 p-x-10">{{ item.staff_name }}</td>
                <ng-container>
                  <td class="text-center p-y-5 p-x-10">{{ item.cars | number:'1.0-0' }}</td>
                  <td class="text-center p-y-5 p-x-10">{{ item.ratio | number:'1.1-1' }}%</td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- Activity Ranking Table End -->
</div>
<div class="d-flex justify-content-center align-items-center"
    *ngIf="checkEmptyData()">
  <app-list-empty title="{{ 'noDataFound' | translate }}"></app-list-empty>
</div>