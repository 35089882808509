import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {

  public sidebarOpened = true;
  private isReady = false;

  constructor(private cdr: ChangeDetectorRef, private translate: TranslateService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
      this.isReady = true;
  }

  public detectChanges() {
    this.isReady && this.cdr.detectChanges();
  }

}
