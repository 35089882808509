import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProductImageRoutingModule} from './product-image-routing.module';
import {ProductImageComponent} from './product-image.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '../../components/core/core.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextModule} from 'primeng/inputtext';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ProductImageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    CoreModule,
    AutoCompleteModule,
    InputTextModule,
    TranslateModule,
  ]
})
export class ProductImageModule { }
