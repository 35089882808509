<ng-template #content let-modal>
  <div class="modal-header">
    <h4>{{ 'sidebar.logoutConfirm' | translate }}</h4>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link" (click)="modal.close('cancel')">
      {{ 'sidebar.cancel' | translate }}
    </button>
    <button class="btn btn-danger" (click)="modal.close('logout')">
      <i class="fas fa-sign-out-alt"></i>
      {{ 'sidebar.logout' | translate }}
    </button>
  </div>
</ng-template>

<nav class="sidebar">
  <div class="sidebar-header">
    <img src='assets/images/ETR.png' height="25" width="auto">
  </div>

  <ul class="list-unstyled sidebar-menu">
    <li *ngFor="let eachRoute of routes">
      <div class="hover-effect w-100percent d-flex justify-content-between"
          [ngClass]="{'active': isActived(eachRoute.path)}"
           (click)="goToPath(eachRoute)">
        <div class="d-flex">
          <i class="fas fa-barcode"></i>
          <span>{{ eachRoute.name | translate }}</span>
        </div>
        <div class="p-r-10"
             *ngIf="eachRoute.hasChild">
          <i class="fas"
             [ngClass]="eachRoute.showChild ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
        </div>
      </div>

      <div class="w-100percent"
           *ngIf="eachRoute.showChild">
        <div class="hover-effect p-y-5 p-l-25 pr-0"
            [ngClass]="{'active': isActived(eachChildPath.path)}"
            [routerLink]="eachChildPath.path"
            *ngFor="let eachChildPath of eachRoute.childrenPaths">
          <span>{{ eachChildPath.name | translate }}</span>
        </div>
      </div>
    </li>

    <li routerLinkActive="active" (click)="open(content)">
      <div class="h-100">
        <div class="h-100" [class.justify-content-center]="!sidebarOpened">
          <i class="fas fa-sign-out-alt"
            [class.fa-collapsed]="!sidebarOpened"
            [class.mr-0]="!sidebarOpened"></i>
          <span *ngIf="sidebarOpened">
            {{ 'sidebar.logout' | translate }}
          </span>
        </div>
      </div>
    </li>
  </ul>

  <div class="dealer" *ngIf="showDealer">{{ 'Dealer' | translate }} : {{dealer}}</div>
  <div class="username">ID : {{username}}</div>
  <div class="change-language">
    <button
      (click)="useLanguage('en')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'en' }"
    >
      English
    </button>
    |
    <button
      (click)="useLanguage('ja')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'ja' }"
    >
      日本語
    </button>
  </div>
</nav>
