import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderApiDisableDateComponent } from './order-api-disable-date.component';
import { OrderApiDisableDateService } from './order-api-disable-date.service';
import { OrderApiDisableDateRoutingModule } from './order-api-disable-date-routing.module';
import { SharedModule } from 'src/app/shared.module';
import { CoreModule } from 'src/app/components/core/core.module';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [OrderApiDisableDateComponent],
  imports: [
    CommonModule,
    FormsModule,
    OrderApiDisableDateRoutingModule,
    CoreModule,
    SharedModule,
    NgbDatepickerModule,
    NgbModule,
  ],
  providers: [OrderApiDisableDateService],
})
export class OrderApiDisableDateModule {}
