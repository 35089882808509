<div class="search-box">
  <div class="row no-gutters justify-content-between">
    <h4 class="mb-0">{{ 'Search' | translate }}</h4>

    <div class="collapse-btn pointer"
         (click)="toggleSearchBox()">
      <img src="assets/svgs/chevron_down.svg"
           class="rotate"
           [ngClass]="showSearchBox ? 'up': 'down'"
           width="30"
           height="30"
           alt="down arrow">
    </div>
  </div>

  <div class="row no-gutters"
       [ngClass]="showSearchBox ? 'show-content': 'hide-content'">
    <div [ngClass]="showContents ? 'd-block w-100percent' : 'd-none'">
      <!--------------------------First Row-------------------------------->
      <ng-template *ngIf="flyerDistribution"
          [ngTemplateOutlet]="durationRow"></ng-template>
      <ng-template *ngIf="!flyerDistribution"
          [ngTemplateOutlet]="dailyCheckDurationRow"></ng-template>

      <!--------------------------Second Row-------------------------------->
      <ng-template *ngIf="flyerDistribution"
          [ngTemplateOutlet]="dropDownRow"></ng-template>
      <ng-template *ngIf="!flyerDistribution"
          [ngTemplateOutlet]="dailyCheckDropDownRow"></ng-template>

      <!--------------------------Third Row-------------------------------->
      <ng-template [ngTemplateOutlet]="checkBoxesRow"></ng-template>

      <!--------------------------Last Row-------------------------------->
      <ng-template [ngTemplateOutlet]="buttonsRow"></ng-template>
    </div>
  </div>
</div>


<ng-template #durationRow>
  <div class="w-100percent mt-4">
    <div class="col-4 row no-gutter pr-5">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'Duration' | translate }}
      </label>
      <div class="col-8 px-0 d-flex">
        <input class="form-control h-35 align-self-center border rounded px-1"
            type='text'
            (click)="d.toggle()"
            name="dp"
            [(ngModel)]="searchFields.orderDateAfter"
            ngbDatepicker
            #d="ngbDatepicker"
            (ngModelChange)="dateAfterSelected()">
        <div class='wiggle-class px-2 align-self-center'>
          ~
        </div>
        <input class="form-control h-35 align-self-center border rounded px-1"
            type='text'
            (click)="g.toggle()"
            name="dp"
            [minDate]="searchFields.orderDateAfter"
            [disabled]="!searchFields.orderDateAfter"
            [(ngModel)]="searchFields.orderDateBefore"
            ngbDatepicker
            #g="ngbDatepicker">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dailyCheckDurationRow>
  <div class="w-100percent mt-4 row no-gutter">
    <div class="pr-5 row no-gutter"
        [ngClass]="isInternalUser ? 'w-20percent' : 'w-25percent'">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'Duration' | translate }}
      </label>
      <div class="col-8 px-0 d-flex">
        <input class="form-control h-35 align-self-center border rounded px-1"
            type='text'
            (click)="d.toggle()"
            name="dp"
            [(ngModel)]="searchFields.orderDateAfter"
            ngbDatepicker
            #d="ngbDatepicker"
            (ngModelChange)="dateAfterSelected()">
      </div>
    </div>
    <div class="pr-5 row no-gutter"
        [ngClass]="isInternalUser ? 'w-20percent' : 'w-25percent'">
      <div class='col-4 wiggle-class align-self-center d-flex justify-content-start'>
        ~
      </div>
      <div class="col-8 px-0 d-flex">
        <input class="form-control h-35 align-self-center border rounded px-1"
            type='text'
            (click)="g.toggle()"
            name="dp"
            [minDate]="searchFields.orderDateAfter"
            [disabled]="!searchFields.orderDateAfter"
            [(ngModel)]="searchFields.orderDateBefore"
            ngbDatepicker
            #g="ngbDatepicker">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dropDownRow>
  <div class="row no-gutter mt-5">
    <div class='col-4 row no-gutter align-self-center pr-5'>
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'Dealer' | translate }}
      </label>
      <ng-select
        class="col-8 p-0 custom-selector"
        [clearable]="true"
        [searchable]="true"
        [disabled]="disableDealer"
        [loading]="loadingDealers"
        [multiple]="true"
        [items]="dealers"
        bindLabel="full_name"
        bindValue="id"
        (change)="getShops()"
        [(ngModel)]="searchFields['dealer']">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center row no-gutters">
            <span aria-hidden="true"
                class="ng-value-icon left"
                (click)="removeSelectDealer(item.id)">×</span>
            {{ item.full_name | translate }}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.full_name | translate }}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <div class='col-4 row no-gutter align-self-center pr-5'>
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'Shop' | translate }}
      </label>
      <ng-select
        class="col-8 p-0 custom-selector"
        [clearable]="true"
        [searchable]="true"
        [loading]="loadingShops"
        [disabled]="!searchFields['dealer'].length"
        [multiple]="true"
        [items]="shops"
        bindLabel="name"
        bindValue="id"
        (change)="handleSelectShops()"
        [(ngModel)]="searchFields['shop']">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center row no-gutters">
            <span aria-hidden="true"
                class="ng-value-icon left"
                (click)="removeSelectShop(item.id)">×</span>
            {{ item.name | translate }}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.name | translate }}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <div class='col-4 row no-gutter align-self-center pr-5'>
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'Staff' | translate }}
      </label>
      <ng-select
        class="col-8 p-0 custom-selector"
        [clearable]="true"
        [searchable]="true"
        [loading]="loadingStaffs"
        [items]="staff"
        [disabled]="!searchFields['shop'].length || searchFields['shop'].length > 1 || searchFields['shop'].includes(0)"
        bindLabel="name"
        bindValue="id"
        (change)="checkIfStaffTable()"
        [(ngModel)]="searchFields['staff']">
      </ng-select>
    </div>
  </div>
</ng-template>

<ng-template #dailyCheckDropDownRow>
  <div class="row no-gutter mt-5">
    <div class='row no-gutter align-self-center pr-5 w-20percent'
        *ngIf="isInternalUser && !isShopManager()">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'daily-check.branch' | translate }}
      </label>
      <ng-select class="col-8 p-0 custom-selector"
          [clearable]="true"
          [searchable]="true"
          [disabled]="loadingBranches || !branches.length"
          [loading]="loadingBranches"
          [multiple]="true"
          [items]="branches"
          (change)="getDealers()"
          bindValue="id"
          bindLabel="branch_name"
          [(ngModel)]="searchFields['branches']">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center row no-gutters">
            <span aria-hidden="true"
                class="ng-value-icon left"
                (click)="removeSelectBranch(item.id)">×</span>
            {{ item.branch_name | translate }}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.branch_name | translate }}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <!-- Internal User Dealer -->
    <div class='row no-gutter align-self-center pr-5 w-20percent'
        *ngIf="isInternalUser">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'daily-check.dealer' | translate }}
      </label>
      <ng-select class="col-8 p-0 custom-selector"
          [clearable]="true"
          [searchable]="true"
          [disabled]="(!searchFields['branches'].length && !isShopManager()) || !dealers.length || loadingDealers"
          [loading]="loadingDealers"
          [items]="dealers"
          bindLabel="name"
          bindValue="id"
          [multiple]="true"
          (change)="getShops()"
          [(ngModel)]="searchFields['dealer']">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center row no-gutters">
            <span aria-hidden="true"
                class="ng-value-icon left"
                (click)="removeSelectDealer(item.id)">×</span>
            {{ item.name | translate }}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.name | translate }}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <!-- External User Dealer -->
    <div class='row no-gutter align-self-center pr-5 w-25percent'
        *ngIf="!isInternalUser">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'daily-check.dealer' | translate }}
      </label>
      <ng-select
        class="col-8 p-0 custom-selector"
        [clearable]="true"
        [searchable]="true"
        [disabled]="disableDealer || !dealers.length"
        [loading]="loadingDealers"
        [multiple]="true"
        [items]="dealers"
        bindLabel="full_name"
        bindValue="id"
        (change)="getShops()"
        [(ngModel)]="searchFields['dealer']">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center row no-gutters">
            <span aria-hidden="true"
                class="ng-value-icon left"
                (click)="removeSelectDealer(item.id)">×</span>
            {{ item.full_name | translate }}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.full_name | translate }}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <div class='row no-gutter align-self-center pr-5'
        [ngClass]="isInternalUser ? 'w-20percent': 'w-25percent'">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'daily-check.shop' | translate }}
      </label>
      <ng-select class="col-8 p-0 custom-selector"
          [clearable]="true"
          [searchable]="true"
          [loading]="loadingShops"
          [disabled]="!searchFields['dealer'].length || loadingShops"
          [multiple]="true"
          [items]="shops"
          bindLabel="name"
          bindValue="id"
          (change)="handleSelectShops()"
          [(ngModel)]="searchFields['shop']">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center row no-gutters">
            <span aria-hidden="true"
                class="ng-value-icon left"
                (click)="removeSelectShop(item.id)">×</span>
            {{ item.name | translate }}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.name | translate }}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <div class='row no-gutter align-self-center pr-5'
        [ngClass]="isInternalUser ? 'w-20percent': 'w-25percent'">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'daily-check.position' | translate }}
      </label>
      <ng-select class="col-8 p-0 custom-selector"
          [items]="positionList"
          [clearable]="true"
          [searchable]="true"
          [multiple]="true"
          (change)="handleSelectPosition()"
          [(ngModel)]="searchFields['roles']"
          bindLabel="name"
          bindValue="role">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center row no-gutters">
            <span aria-hidden="true"
                class="ng-value-icon left"
                (click)="removeSelectPosotion(item.role)">×</span>
            {{ item.name | translate }}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.name | translate }}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <div class='row no-gutter align-self-center pr-5'
        [ngClass]="isInternalUser ? 'w-20percent': 'w-25percent'">
      <label class='col-4 pl-0 m-0 d-flex align-items-center'>
        {{ 'daily-check.staff' | translate }}
      </label>
      <ng-select
        class="col-8 p-0 custom-selector"
        [clearable]="true"
        [searchable]="true"
        [loading]="loadingStaffs"
        [items]="staff"
        [disabled]="isDisabelStaffSelect() || loadingStaffs || !this.staff.length"
        bindLabel="name"
        bindValue="id"
        (change)="checkIfStaffTable()"
        [(ngModel)]="searchFields['staff']">
      </ng-select>
    </div>
  </div>
</ng-template>

<ng-template #checkBoxesRow>
  <div class="row no-gutter mt-5">
    <div class="col-4 px-0">
      <label class="row no-gutter">
        {{ 'What to be Exported' | translate }}
      </label>

      <div class="row no-gutter mt-3">
        <div class="col-2 pl-0 d-flex align-items-center">
          <div class="w-auto h-auto d-flex pointer"
               (click)="selectBetween()">
            <input type="checkbox"
                   class="w-15 h-15 custom-checkbox-color m-auto pointer"
                   readonly
                   [checked]="flyerDistribution">
          </div>
        </div>
        <label class="row no-gutter col-10">
          {{ 'Flyer Distribution' | translate }}
        </label>
      </div>

      <div class="row no-gutter mt-4">
        <div class="col-2 pl-0 d-flex align-items-center">
          <div class="w-auto h-auto d-flex pointer"
               (click)="selectBetween()">
            <input type="checkbox"
                   class="w-15 h-15 custom-checkbox-color m-auto pointer"
                   readonly
                   [checked]="dailyChecks.checked">
          </div>
        </div>
        <label class="row no-gutter col-10">
          {{ 'Daily Check Analysis' | translate }}
        </label>
      </div>

      <div class="row no-gutter mt-2 pl-4">
        <div class="col-2 pl-0 d-flex align-items-center">
          <div class="w-auto h-auto d-flex pointer"
               [ngClass]="{'disable-pointer': !dailyChecks.checked}"
               (click)="dailyChecks.childItems.checkUpRatio = !dailyChecks.childItems.checkUpRatio">
            <input type="checkbox"
                   class="w-15 h-15 custom-checkbox-color m-auto pointer"
                   readonly
                   [disabled]="!dailyChecks.childItems.dailyCheckAnalysis"
                   [checked]="dailyChecks.childItems.checkUpRatio">
          </div>
        </div>
        <label class="row no-gutter col-10">
          {{ 'Checkup Ratio' | translate }}
        </label>
      </div>

      <div class="row no-gutter mt-2 pl-4">
        <div class="col-2 pl-0 d-flex align-items-center">
          <div class="w-auto h-auto d-flex pointer"
               [ngClass]="{'disable-pointer': !dailyChecks.checked}"
               (click)="dailyChecks.childItems.quotationRatio = !dailyChecks.childItems.quotationRatio">
            <input type="checkbox"
                   class="w-15 h-15 custom-checkbox-color m-auto pointer"
                   readonly
                   [disabled]="!dailyChecks.childItems.dailyCheckAnalysis"
                   [checked]="dailyChecks.childItems.quotationRatio">
          </div>
        </div>
        <label class="row no-gutter col-10">
          {{ 'Quotation Ratio' | translate }}
        </label>
      </div>

      <div class="row no-gutter mt-2 pl-4">
        <div class="col-2 pl-0 d-flex align-items-center">
          <div class="w-auto h-auto d-flex pointer"
               [ngClass]="{'disable-pointer': !dailyChecks.checked}"
               (click)="dailyChecks.childItems.confirmedOrderRatio = !dailyChecks.childItems.confirmedOrderRatio">
            <input type="checkbox"
                   class="w-15 h-15 custom-checkbox-color m-auto pointer"
                   readonly
                   [disabled]="!dailyChecks.childItems.dailyCheckAnalysis"
                   [checked]="dailyChecks.childItems.confirmedOrderRatio">
          </div>
        </div>
        <label class="row no-gutter col-10">
          {{ 'Confirmed Order Ratio' | translate }}
        </label>
      </div>

      <div class="row no-gutter mt-2 pl-4">
        <div class="col-2 pl-0 d-flex align-items-center">
          <div class="w-auto h-auto d-flex pointer"
               [ngClass]="{'disable-pointer': !dailyChecks.checked}"
               (click)="dailyChecks.childItems.actualWorkRatio = !dailyChecks.childItems.actualWorkRatio">
            <input type="checkbox"
                   class="w-15 h-15 custom-checkbox-color m-auto pointer"
                   readonly
                   [disabled]="!dailyChecks.childItems.dailyCheckAnalysis"
                   [checked]="dailyChecks.childItems.actualWorkRatio">
          </div>
        </div>
        <label class="row no-gutter col-10">
          {{ 'Actual Work Ratio' | translate }}
        </label>
      </div>
      <div class="row no-gutter"
          *ngIf="!flyerDistribution"
          [style]="{'padding-top': '77px'}">
        <div class="col-2 pl-0 d-flex align-items-center">
          <div class="w-auto h-auto d-flex pointer"
              (click)="oneOrMoreAchievements = !oneOrMoreAchievements">
            <input type="checkbox"
                class="w-15 h-15 custom-checkbox-color m-auto pointer"
                readonly
                [checked]="oneOrMoreAchievements">
          </div>
        </div>
        <label class="row no-gutter col-10">
          {{ 'One Or More Achievements' | translate }}
        </label>
      </div>
    </div>
    <div class="col-8 px-0"
         [ngClass]="{'disable-pointer grey-out': disableItems()}">
      <label class="row no-gutter">
        {{ 'Daily Check Items' | translate }}
      </label>
      <div class="row no-gutter">
        <div class="col-6">
          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.basic8Items = !dailyCheckItems.basic8Items">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.basic8Items">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Basic 8 Items' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.brakeFluid = !dailyCheckItems.brakeFluid">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.brakeFluid">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Brake Fluid' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.coolingWater = !dailyCheckItems.coolingWater">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.coolingWater">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Cooling Water' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.battery = !dailyCheckItems.battery">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.battery">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Battery' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.engineOil = !dailyCheckItems.engineOil">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.engineOil">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Engine Oil' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.avCvtFluid = !dailyCheckItems.avCvtFluid">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.avCvtFluid">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'AT/CVT Fluid' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.washerFluid = !dailyCheckItems.washerFluid">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.washerFluid">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Washer Fluid' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.wiperBlade = !dailyCheckItems.wiperBlade">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.wiperBlade">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Wiper Blade' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.tire = !dailyCheckItems.tire">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.tire">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Tire' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.airCleanFilter = !dailyCheckItems.airCleanFilter">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.airCleanFilter">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Air-Clean Filter' | translate }}
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.carWash = !dailyCheckItems.carWash">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.carWash">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Car Wash' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.coating = !dailyCheckItems.coating">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.coating">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Coating' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.repair = !dailyCheckItems.repair">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.repair">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Repair' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.eneosElectric = !dailyCheckItems.eneosElectric">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.eneosElectric">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'ENEOS Electric' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.eneosCard = !dailyCheckItems.eneosCard">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.eneosCard">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'ENEOS Card' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.eneKey = !dailyCheckItems.eneKey">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.eneKey">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'EneKey' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.engineCleaner = !dailyCheckItems.engineCleaner">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.engineCleaner">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Engine Cleaner' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.carInspection = !dailyCheckItems.carInspection">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.carInspection">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Car Inspection' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.lineMember = !dailyCheckItems.lineMember">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.lineMember">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'LINE Member' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.others = !dailyCheckItems.others">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.others">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Others 1' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.others_2 = !dailyCheckItems.others_2">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.others_2">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Others 2' | translate }}
            </label>
          </div>

          <div class="row no-gutter mt-2 pl-4">
            <div class="col-2 pl-0 d-flex align-items-center">
              <div class="w-auto h-auto d-flex pointer"
                   (click)="dailyCheckItems.others_3 = !dailyCheckItems.others_3">
                <input type="checkbox"
                       class="w-15 h-15 custom-checkbox-color m-auto pointer"
                       readonly
                       [checked]="dailyCheckItems.others_3">
              </div>
            </div>
            <label class="row no-gutter col-10">
              {{ 'Others 3' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttonsRow>
  <div class='row no-gutter mt-4'>
    <div class='col-12 d-flex justify-content-end align-items-end pr-5'>
      <button class="btn btn-sm d-flex align-items-center btn-primary h-30"
              (click)="search()">
        <img src="assets/svgs/search_len.svg"
             height="20"
             alt="search lens icon"
             class="mr-1"
             width="auto">
        {{'product-image.search' | translate}}
      </button>
      <button class="btn btn-sm d-flex align-items-center btn-secondary ml-3 h-30"
              (click)="reset()">
        <img src="assets/svgs/reset_icon.svg" height="20" alt="reset icon" class="mr-1" width="auto">
        {{'product-image.reset' | translate}}
      </button>
      <button class="btn btn-sm btn-outline-primary d-flex align-items-center hover-custom ml-3 h-30"
              (click)="export()">
        <div class="custom-img mr-2"></div>
        {{'Export' | translate}}
        <img src='assets/images/loading.svg'
             *ngIf="exporting"
             width='20'
             height='20'
             alt="loading icon"
             class='ml-1'>
      </button>
    </div>
  </div>
</ng-template>
