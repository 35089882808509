import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {HttpClientService} from '../../services/http-client.service';
import {API_CONSTANT} from '../../services/api.constants';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslationService} from 'src/app/services/translate.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ShopActualStockService} from './shop-actual-stock.service';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import {HttpParams} from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shop-actual-stock',
  templateUrl: './shop-actual-stock.component.html',
  styleUrls: ['./shop-actual-stock.component.scss']
})
export class ShopActualStockComponent implements OnInit {
  public loading = true;
  page = 1;
  productList = [];
  productlistLength = 0;
  reason = [];
  currentPage: Number = 1;
  noOfPage: Number = 1;
  count = 3;
  categories = [];
  categoryID: number = null;
  
  //File Upload
  importFile: File | null = null;
  uploadFileError: string;
  uploading = false;

  constructor(
    private api: HttpClientService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private translationSerivce: TranslationService,
    private modalService: NgbModal,
    private shopActualStockService: ShopActualStockService,
    private notification: ToastrService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.loadShopActualStock();
    this.loadCategories();
  }

  loadShopActualStock() {
    let params = new HttpParams();
    params = params.append('page', this.currentPage.toString());
    this.shopActualStockService.getImportShopActualStock(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.productList = res.results;
        this.productlistLength = res.results.length;
        this.noOfPage = res.total_pages;
        this.loading = false;
      },
      (err) => {
        this.handleError(err);
      }
    );
  }
  loadCategories() {
    this.api.get(API_CONSTANT.BASE_URL + '/categories/?all=true').subscribe(
      (res: any) => {
        this.categories = res;
      },
      (err) => {
      }
    );
  }

  openActualStockPopup(view) {
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  dismissEditOrCreate() {
    this.modalService.dismissAll();
  }

  importAcutalStock(view) {
    this.openActualStockPopup(view);
  }

  uploadStockFile(){
    this.uploading = true;
    this.uploadFileError = null;
    this.shopActualStockService
    .importShopActualStock(this.importFile, this.categoryID)
    .subscribe(
      () => {
        this.loadShopActualStock();
        this.modalService.dismissAll();
        this.uploading = false;
        this.categoryID = null;
        this.router.navigateByUrl('/shop-actual-stock');
      },
      (error) => {
        this.notification.error(this.translate.instant('Upload Failed'));
        this.uploadFileError = error.error?.message?.toString();
        this.loadShopActualStock();
        this.uploading = false;
      }
    )
  }

  chooseFile(event) {
    this.importFile = event.target.files.item(0);
  }

  navigateTo(event) {
    this.currentPage = event;
    this.loadShopActualStock();
  }

  handleError(err) {
    this.loading = false;
    this.notification.error(_.values(err)[0].toString());
  }

  translateMessage(messages) {
    const messageList = [];
    for (const message of messages) {
      messageList.push(
        this.translationSerivce.transformMessage(message) || message
      );
    }
    return messageList;
  }

  openErrorPopup(view, reason) {
    this.reason = this.translateMessage(reason);
    this.modalService.open(view);
  }

  getDate() {
    const today = new Date();
    const month = today.getMonth() < 10 ? '0' + today.getMonth() : today.getMonth();
    const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    return today.getFullYear() + '-' + month + '-' + day;
  }
}
