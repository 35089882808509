import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import {API_CONSTANT} from '../../services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class ShopActualStockService {
  constructor(private api: HttpClientService) {
  }

  getImportShopActualStock(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/import-results/actual-stock/', params);
  }

  importShopActualStock(file: File, categoryId?: number){
    const formData = new FormData();
    formData.append('file', file);
    (categoryId) ? formData.append('category_id', categoryId.toString()) : null
    
    return this.api.post(API_CONSTANT.BASE_URL + '/import-results/import-actual-stock/', formData);
  }
}
