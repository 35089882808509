import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailyCheckTabletUserComponent } from './daily-check-tablet-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../components/core/core.module';
import { SharedModule } from '../../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DailyCheckTabletUserRoutingModule } from './daily-check-tablet-user-routing.module';



@NgModule({
  declarations: [DailyCheckTabletUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    CoreModule,
    NgbPaginationModule,
    SharedModule,
    TranslateModule,
    DailyCheckTabletUserRoutingModule,
  ]
})
export class DailyCheckTabletUserModule { }
