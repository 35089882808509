import { Component, OnInit } from '@angular/core';
import { SellerEmailService } from './seller-email.service';
import { Category } from 'src/app/models/category.model';
import { ShopService } from 'src/app/services/shop.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductSellerEmail, SellerEmail } from './seller-email.model';
import { SellerWebuserService } from '../seller-webuser/seller-webuser.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-seller-email',
  templateUrl: './seller-email.component.html',
  styleUrls: ['./seller-email.component.scss'],
})
export class SellerEmailComponent implements OnInit {
  loadingSellerEmail: boolean = false;
  loadingShop: boolean = false;
  loadingEmail: boolean = false;
  loadingCategory: boolean = false;
  search = {
    categories: [],
    shops: [],
    ctaShops: [],
    emails: [],
  };
  change = {
    email: null,
    cc_emails: [],
  };

  allCategories: Category[] = [];
  allShops: any[] = [];
  allEmails: SellerEmail[] = [];
  sellerEmailList: ProductSellerEmail[] = [];
  page: number = 1;
  dataLength: number = 0;

  constructor(
    private sellerEmailService: SellerEmailService,
    private shopService: ShopService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.getShops();
    this.getEmails();
    this.getProductSellerEmails();
  }

  getCategories(): void {
    this.loadingCategory = true;
    this.sellerEmailService.getAllCategories().subscribe(
      (res) => {
        this.allCategories = res;
        this.loadingCategory = false;
      },
      (err) => {
        this.toastr.error(err.error.message);
        this.loadingCategory = false;
      }
    );
  }

  getShops(): void {
    this.loadingShop = true;
    const param = {
      all: 'true',
    };
    this.shopService.getCTAETRShopList(param).subscribe(
      (res: any) => {
        this.allShops = res;
        this.loadingShop = false;
      },
      (err) => {
        this.toastr.error(err.error.message);
        this.loadingShop = false;
      }
    );
  }

  getEmails(): void {
    this.loadingEmail = true;
    this.sellerEmailService.getAllSellersEmail().subscribe(
      (res: any) => {
        this.allEmails = res;
        this.allEmails.forEach((seller) => {
          seller.showValue = seller.email + ' (' + seller.id + ')';
        });
        this.loadingEmail = false;
      },
      (err) => {
        this.toastr.error(err.error.message);
        this.loadingEmail = false;
      }
    );
  }

  getProductSellerEmails(): void {
    this.loadingSellerEmail = true;
    const params = {
      page: this.page,
      category_id: this.search.categories.toString(),
      shop_id: this.search.shops.toString(),
      cta_shop_id: this.search.ctaShops.toString(),
      email_id: this.search.emails.toString(),
    };
    this.sellerEmailService.getProductSellerEmails(params).subscribe(
      (res) => {
        this.dataLength = res.count;
        this.sellerEmailList = res.results.map((sellerEmail) => {
          return { ...sellerEmail, checked: false };
        });
        this.loadingSellerEmail = false;
      },
      (err) => {
        this.toastr.error(err.error.message);
        this.loadingSellerEmail = false;
      }
    );
  }

  resetSearch(): void {
    this.page = 1;
    this.search = {
      categories: [],
      shops: [],
      ctaShops: [],
      emails: [],
    };
    this.getProductSellerEmails();
  }

  changeEmail(view) {
    this.modalService.open(view).result.then(
      (result) => {},
      (reason) => {
        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.dismissEdit();
        }
      }
    );
  }

  dismissEdit(): void {
    this.change = {
      email: null,
      cc_emails: [],
    };
    this.modalService.dismissAll();
  }

  changePage(event: number): void {
    this.page = event;
    this.getProductSellerEmails();
  }

  toggleCheck(id: number): void {
    this.sellerEmailList = this.sellerEmailList.map((sellerEmail) => {
      if (sellerEmail.id === id) {
        return { ...sellerEmail, checked: !sellerEmail.checked };
      }
      return sellerEmail;
    });
  }

  saveEdit() {
    const selectedSellerEmails = this.sellerEmailList
      .filter((sellerEmail) => sellerEmail.checked)
      .map((sellerEmail) => sellerEmail.id);
    if (!this.change.email) {
      this.toastr.error(this.translate.instant('Please select an email'));
      return;
    }
    if (!selectedSellerEmails.length) {
      this.toastr.error(this.translate.instant('Please select at least one seller email'));
      return;
    }
    const data = {
      id: selectedSellerEmails.toString(),
      email_id: this.change.email,
      cc_emails_id: this.change.cc_emails.toString(),
    };
    this.sellerEmailService.bulkUpdateSellerEmail(data).subscribe(
      (res) => {
        this.toastr.success(this.translate.instant('Seller email updated successfully'));
        this.dismissEdit();
        this.getProductSellerEmails();
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  hadleShopFilterChange(event: any): void {
    const etrShop = [];
    const ctaShop = [];
    event.forEach((shop) => {
      if (shop.is_etr) {
        etrShop.push(shop.id);
      } else {
        ctaShop.push(shop.id);
      }
    });
    this.search.shops = etrShop;
    this.search.ctaShops = ctaShop;
  }
}
