import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DealerSettingComponent } from './dealer-setting.component';

export const DealerSettingRoutes: Routes = [
  {
    path: 'dealer-setting',
    children: [{ path: '', component: DealerSettingComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(DealerSettingRoutes)],
  exports: [RouterModule],
})
export class DealerSettingRoutesModule {}
