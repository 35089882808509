<div class="row no-gutters mb-4 align-items-center"
     [ngClass]="{'loading-effect': loadingTable}">
  <h4 class="col-3">
    {{"Selected Daily Check Items"| translate}}
  </h4>
  <div class="col-9 row no-gutters">
    <span class="col-auto p-r-2" *ngFor="let eachItem of selectedItems; let last = last;">
      {{ eachItem | translate}}{{ last ? '' : ', ' }}
    </span>
  </div>
</div>

<div class="row no-gutter"
     [ngClass]="{'loading-effect': loadingTable}">
  <table>
    <thead>
    <tr>
      <th class="w-16percent max-w-270 text-center top-left-round border-right"
          rowspan="2">
        {{"Shop / Staff"|translate}}
      </th>
      <th class="w-14percent text-center border-right border-bottom"
          (click)="setSortingField('flyer_distribution')"
          rowspan="2">
        {{"Flyer Number"| translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'flyer_distribution',
                          'fa-sort-down': currentSortingField == '-flyer_distribution',
                          'fa-sort-up': currentSortingField == 'flyer_distribution'}"
             aria-hidden="true"></i>
        </span>
      </th>
      <th class="w-14percent text-center border-right border-bottom"
          (click)="setSortingField('monthly_visit')"
          rowspan="2">
        {{"Number of Cars Visited Shop" | translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'monthly_visit',
                          'fa-sort-down': currentSortingField == '-monthly_visit',
                          'fa-sort-up': currentSortingField == 'monthly_visit'}"
             aria-hidden="true"></i>
        </span>
      </th>
      <th class="w-14percent text-center border-right border-bottom"
          (click)="setSortingField('distribution_ratio')"
          rowspan="2">
        {{"Distribution Ratio"| translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'distribution_ratio',
                          'fa-sort-down': currentSortingField == '-distribution_ratio',
                          'fa-sort-up': currentSortingField == 'distribution_ratio'}"
             aria-hidden="true"></i>
        </span>
      </th>
      <th class="w-14percent text-center border-right border-bottom"
          colspan="2">
        {{"Quotation" | translate}}
      </th>
      <th class="w-14percent text-center border-right border-bottom"
          colspan="2">
        {{"Confirmed Order"| translate}}
      </th>
      <th class="w-14percent text-center top-right-round border-bottom"
          (click)="setSortingField('work_hours')"
          rowspan="2">
        {{"Work Hours"| translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'work_hours',
                          'fa-sort-down': currentSortingField == '-work_hours',
                          'fa-sort-up': currentSortingField == 'work_hours'}"
             aria-hidden="true"></i>
        </span>
      </th>
    </tr>
    <tr>
      <th class="w-7percent border-right text-center"
          (click)="setSortingField('quotation_number')">
        {{"Number"| translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'quotation_number',
                          'fa-sort-down': currentSortingField == '-quotation_number',
                          'fa-sort-up': currentSortingField == 'quotation_number'}"
             aria-hidden="true"></i>
        </span>
      </th>
      <th class="w-7percent border-right text-center"
          (click)="setSortingField('quotation_ratio')">
        {{"Ratio"|translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'quotation_ratio',
                          'fa-sort-down': currentSortingField == '-quotation_ratio',
                          'fa-sort-up': currentSortingField == 'quotation_ratio'}"
             aria-hidden="true"></i>
        </span>
      </th>
      <th class="w-7percent border-right text-center"
          (click)="setSortingField('confirmed_order_number')">
        {{"Number"| translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'confirmed_order_number',
                          'fa-sort-down': currentSortingField == '-confirmed_order_number',
                          'fa-sort-up': currentSortingField == 'confirmed_order_number'}"
             aria-hidden="true"></i>
        </span>
      </th>
      <th class="w-7percent border-right text-center"
          (click)="setSortingField('confirmed_order_ratio')">
        {{"Ratio"| translate}}
        <span>
          <i class="fa"
             [ngClass]="{'fa-sort':  currentSortingField !== 'confirmed_order_ratio',
                          'fa-sort-down': currentSortingField == '-confirmed_order_ratio',
                          'fa-sort-up': currentSortingField == 'confirmed_order_ratio'}"
             aria-hidden="true"></i>
        </span>
      </th>
    </tr>
    </thead>
    <tbody>
    <!-------------------------------------------Flyer Distribution First Layer--------------------------------------->
    <tr *ngFor="let shop of firstLayerItems; let firstLayerIndex = index; let last = last;">
      <td colspan="13">
        <div class="w-100percent row no-gutters border-top align-items-center"
             [ngClass]=" last ? 'darker-grey-bg' : 'first-layer-bg'">
          <div class="w-16percent max-w-270 p-y-5 p-x-5 row no-gutters justify-content-between align-items-center"
               (click)="!last && toggleFirstLayer(firstLayerIndex)">
            <span>
              {{ shop.name | translate }}
            </span>
            <div class="collapse-btn pointer"
                 *ngIf="!last">
              <img src="assets/svgs/chevron_down.svg"
                   class="rotate"
                   [ngClass]="shop.showChild ? 'up': 'down'"
                   width="30"
                   height="30"
                   alt="down arrow">
            </div>
          </div>
          <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
            {{ shop.flyer_distribution }}
          </div>
          <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
            {{ shop.monthly_visit }}
          </div>
          <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
            {{ shop.distribution_ratio | number: '1.1-1' }} %
          </div>
          <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
            {{ shop.quotation_number }}
          </div>
          <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
            {{ shop.quotation_ratio | number: '1.1-1' }} %
          </div>
          <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
            {{ shop.confirmed_order_number || 0 }}
          </div>
          <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
            {{ (shop.confirmed_order_ratio || 0) | number: '1.1-1' }} %
          </div>
          <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
            {{ shop.work_hours || 0 }}
          </div>
        </div>

        <!-------------------------------------------Shop Layer------------------------------------------------>
        <div class="w-100percent row no-gutters second-layer-bg border-y align-items-center"
             *ngIf="shop.showChild">
          <div class="w-100percent row no-gutters"
               *ngFor="let staff of shop.staff;">
            <div class="w-100percent row no-gutters justify-content-around align-items-center h-40">
              <div class="w-16percent v-middle max-w-270 p-l-15 p-r-5 row no-gutters justify-content-between align-items-center">
                {{ staff.name }}
              </div>
              <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
                {{ staff.flyer_distribution }}
              </div>
              <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
                {{ staff.monthly_visit || '' }}
              </div>
              <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
                {{ staff.distribution_ratio | number: '1.1-1'  }} %
              </div>
              <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
                {{ staff.quotation_number }}
              </div>
              <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
                {{ (staff.quotation_ratio) | number: '1.1-1' }} %
              </div>
              <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
                {{ staff.confirmed_order_number || 0 }}
              </div>
              <div class="text-center w-7percent p-y-5 p-x-10 min-w-60">
                {{ (staff.confirmed_order_ratio || 0) | number: '1.1-1' }} %
              </div>
              <div class="text-center w-14percent p-y-5 p-x-10 min-w-60">
                {{ staff.work_hours || 0 }}
            </div>
          </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
