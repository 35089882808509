import {Injectable} from '@angular/core';
import {HttpClientService} from 'src/app/services/http-client.service';
import {API_CONSTANT} from 'src/app/services/api.constants';
import {forkJoin, Observable} from 'rxjs';
import {PaginatedResult} from 'src/app/components/pagination/pagination.interface';
import {Category} from 'src/app/models/category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private api: HttpClientService) {
  }

  getCategories(params: any = {all: true}) {
    return this.api.get<PaginatedResult<Category>>(
      API_CONSTANT.BASE_URL + `/categories/`,
      params
    );
  }

  getCategoryByID(id): Observable<any> {
    return this.api.get(API_CONSTANT.BASE_URL + '/categories/' + id + '/');
  }

  getAllCategories(params?: any) {
    return this.api.get<Category[]>(API_CONSTANT.BASE_URL + '/categories/', {
      ...params,
      all: true
    });
  }

  addImage(data, id): Observable<any> {
    return this.api.put(
      API_CONSTANT.BASE_URL + '/categories/' + id + '/',
      data
    );
  }

  deleteCategory(id): Observable<any> {
    const data = {
      category_id: id
    };
    return forkJoin([
      this.api.delete(API_CONSTANT.BASE_URL + `/categories/${id}/`)
    ]);
  }

  deleteCategoryFromProductSet(category_id, product_set_id, category_category_id): Observable<any> {
    const data = {
      category_id: category_id,
      product_set_id: product_set_id,
      category_category_id: category_category_id
    };
    return forkJoin([
      this.api.get(API_CONSTANT.BASE_URL + '/delete-from-product-set/', data)
    ]);
  }

  addCategory(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/categories/', data);
  }

  importProducts(category: any, file: File, is_scheduled: Boolean, type: number, time?: any) {
    const formData = new FormData();
    formData.append('category_id', category.toString());
    formData.append('file', file);
    formData.append('is_scheduled', is_scheduled.toString());
    formData.append('type', type.toString());
    formData.append('time', time);

    return this.api.post(API_CONSTANT.BASE_URL + '/import-product/', formData);
  }

  importMonotaroProducts(
    category: any,
    file: File,
    is_scheduled: Boolean,
    time?: any
  ) {
    const formData = new FormData();
    formData.append('category_id', category.toString());
    formData.append('file', file);
    formData.append('is_scheduled', is_scheduled.toString());
    formData.append('time', time);
    return this.api.post(API_CONSTANT.BASE_URL + '/import-monotaro-product/', formData);
  }

  importCategoryStockNum(category: any, file: File){
    const formData = new FormData();
    formData.append('category_id', category.toString());
    formData.append('file', file);
    
    return this.api.post(API_CONSTANT.BASE_URL + '/import-appropriate-stock/', formData);
  }

  importProductEmail(category_id: any, file: File, is_scheduled: Boolean, time?: any) {
    const formData = new FormData();
    formData.append('category_id', category_id.toString());
    formData.append('file', file);
    formData.append('is_scheduled', is_scheduled.toString());
    formData.append('time', time);

    return this.api.post(API_CONSTANT.BASE_URL + '/import-product-emails/', formData);
  }

  editSequence(id, fd) {
    return this.api.patch(API_CONSTANT.BASE_URL + '/categories/' + id + '/', fd);
  }

  updateCategory(id, fd) {
    return this.api.put(API_CONSTANT.BASE_URL + '/categories/' + id + '/', fd);
  }

  getALLSellersAndCategories() {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-all-sellers-categories/');
  }
}
