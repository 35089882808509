import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from 'src/app/models/category.model';
import {
  ProductSellerEmailPagination,
  SellerEmail,
} from '../seller-email/seller-email.model';
import { API_CONSTANT } from 'src/app/services/api.constants';
import { HttpClientService } from 'src/app/services/http-client.service';

@Injectable()
export class SellerEmailService {
  static SELLER_EMAIL_API_URL = API_CONSTANT.BASE_URL + '/seller-email/';
  static PRODUCT_SELLER_EMAIL_API_URL =
    API_CONSTANT.BASE_URL + '/product-seller-email/';

  constructor(private api: HttpClientService) {}

  getAllCategories(params?: any) {
    return this.api.get<Category[]>(API_CONSTANT.BASE_URL + '/categories/', {
      ...params,
      all: true,
    });
  }

  getAllSellersEmail(params?: any): Observable<SellerEmail[]> {
    return this.api.get(SellerEmailService.SELLER_EMAIL_API_URL, params);
  }

  getProductSellerEmails(
    params?: any
  ): Observable<ProductSellerEmailPagination> {
    return this.api.get(
      SellerEmailService.PRODUCT_SELLER_EMAIL_API_URL,
      params
    );
  }

  bulkUpdateSellerEmail(data: any): Observable<string> {
    return this.api.post(
      SellerEmailService.PRODUCT_SELLER_EMAIL_API_URL +
        'bulk-update-product-seller/',
      data
    );
  }
}
