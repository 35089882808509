import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealerComponent } from './dealer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDatepickerModule, NgbDropdownModule,
  NgbPaginationModule, NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from 'src/app/components/core/core.module';
import { DealerService } from './dealer.service';
import { SharedModule } from 'src/app/shared.module';

@NgModule({
    declarations: [
        DealerComponent,
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgSelectModule,
      NgbDropdownModule,
      NgbDatepickerModule,
      NgbPaginationModule,
      NgbModule,
      CoreModule,
      SharedModule
    ],
    providers: [DealerService]
})
export class NewDealerCodeModule {}
