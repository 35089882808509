import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, iif, of, from } from 'rxjs';
import { Dealer } from './dealer.model';
import { Shop } from '../../models/shop.model';
import { ProductSet } from 'src/app/models/productSet.model';
import { Seller } from 'src/app/models/seller.model';
import { SalePackage } from 'src/app/models/salePackage.model';
import { DealerService } from './dealer.service';
import { ShopService } from '../../services/shop.service';
import { SellerService } from 'src/app/services/seller.service';
import { ProductSetService } from 'src/app/services/product-set.service';
import { SalePackageService } from 'src/app/services/sale-package.service';
import {
  switchMap,
  share,
  catchError
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealerPageService {
  refresh$ = new BehaviorSubject(null);
  refreshByView$ = new BehaviorSubject(null);
  // Lists
  dealers$: Observable<Dealer[]>;
  dealersByView$:  Observable<Dealer[]>;
  shops$: Observable<Shop[]>;
  productSets$: Observable<ProductSet[]>;
  sellers$: Observable<Seller[]>;
  salePackages$: Observable<SalePackage[]>;
  isLoading = false;

  // Selected Subjects
  selectedDealer$ = new BehaviorSubject<Dealer>(undefined);
  selectedShops$ = new BehaviorSubject<Shop[]>([]);
  selectedSeller$ = new BehaviorSubject<Seller>(undefined);
  selectedProductSet$ = new BehaviorSubject<ProductSet>(undefined);
  selectedSalePackage$ = new BehaviorSubject<SalePackage>(undefined);

  constructor(
    private dealerService: DealerService,
    private shopService: ShopService,
    private sellerService: SellerService,
    private productSetService: ProductSetService,
    private salePackageService: SalePackageService
  ) {
    this.dealers$ = this.refresh$.pipe(
      switchMap(() =>
        this.dealerService.searchDealers().pipe(catchError(error => of([])))
      ),
      share()
    );

    this.dealersByView$ = this.refreshByView$.pipe(
      switchMap(() =>
        this.dealerService.searchDealers().pipe(catchError(error => of([])))
      ),
      share()
    );

    this.shops$ = this.selectedDealer$.pipe(
      switchMap(dealer =>
        iif(
          () => !!dealer,
          this.shopService
            .searchShops({
              dealer: dealer && dealer.id,
              is_grit: dealer && dealer.is_grit
            })
            .pipe(catchError(error => of([]))),
          of([])
        )
      ),
      share()
    );
    this.productSets$ = this.refresh$.pipe(
      switchMap(_ =>
        this.productSetService.getAll().pipe(catchError(error => of([])))
      ),
      share()
    );
    this.salePackages$ = this.selectedProductSet$.pipe(
      switchMap(productSet =>
        iif(
          () => !!productSet,
          this.salePackageService
            .getAll({
              product_set: productSet && productSet.id
            })
            .pipe(catchError(error => of([]))),
          of([])
        )
      ),
      share()
    );
  }

  selectDealer(dealer: Dealer) {
    this.selectedDealer$.next(dealer);
  }

  selectShop(shop: Shop) {
    const shops = Array.from(new Set(this.selectedShops$.value));
    if (!this.shopIsSelected(shop)) {
      this.selectedShops$.next([...shops, shop]);
    } else {
      const newShop = shops.filter(s => s.id !== shop.id);
      this.selectedShops$.next(newShop);
    }
  }

  selectSalePackage(salePackage: SalePackage) {
    if (this.selectedSalePackage$.value === salePackage) {
      this.selectedSalePackage$.next(undefined);
    } else {
      this.selectedSalePackage$.next(salePackage);
    }
  }

  shopIsSelected(shop: Shop): boolean {
    const shops = Array.from(new Set(this.selectedShops$.value));
    return shops.map(s => s.id).indexOf(shop.id) >= 0;
  }

  salePackageIsSelected(salePackage: SalePackage): boolean {
    return (
      this.selectedSalePackage$.value &&
      this.selectedSalePackage$.value.id === salePackage.id
    );
  }

  refresh() {
    this.refresh$.next(null);
  }
}
