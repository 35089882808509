import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MonotaroService } from '../monotaro.service';
import { PurchaseOrder, PurchaseOrderDetail } from '../monotaro.model';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';


@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
  styleUrls: ['./purchase-order-detail.component.scss']
})
export class PurchaseOrderDetailComponent implements OnInit {
  @Input() purchaseOrder: PurchaseOrder = null;
  @Input() searchParams = {
    purchase_order_id: '',
    major_classification: '',
    medium_classification: '',
    minor_classification: '',
    product_name: '',
    product_code: '',
  };

  purchaseOrderDetails: PurchaseOrderDetail[] = [];

  loadingOrderDetails: Boolean = false;
  changingRow = null;
  changingID = null;
  userID = null;
  modalName = '';
  datePicker;

  constructor(
    private monotaroService: MonotaroService,
    private notificationService: ToastrService,
  ) {
  }

  ngOnInit() {
    this.getPurchaseOrderDetail();
  }


  getPurchaseOrderDetail() {
    this.loadingOrderDetails = true;
    const params = new HttpParams().set('purchase_order_id', this.purchaseOrder.id.toString() || '');
    this.monotaroService.getPurchaseOrderDetails(params).subscribe(
      (res: any) => {
        this.purchaseOrderDetails = res.results;
        this.loadingOrderDetails = false;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  showError(error) {
    this.notificationService.error(_.values(error.error)[0].toString());
  }

}
