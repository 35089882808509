<div class="form-group mt-3 mx-4 px-4 py-2 rounded filter-bar">
  <div class="row pb-3 align-items-center justify-content-center">
    <div class="col-lg-1 text-center">
      <label>&nbsp;</label><br />

      <label><b>From</b></label>
    </div>

    <div class="col-lg-auto">
      <label>Date</label>

      <div class="input-group">
        <input
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dp"
          [(ngModel)]="dateTime.fromDate"
          ngbDatepicker
          #fd="ngbDatepicker"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-black"
            (click)="fd.toggle()"
            type="button"
          >
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-1 text-center">
      <label>&nbsp;</label><br />

      <label><b>To</b></label>
    </div>

    <div class="col-lg-auto">
      <label>Date</label>

      <div class="input-group">
        <input
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dp"
          [(ngModel)]="dateTime.toDate"
          ngbDatepicker
          #td="ngbDatepicker"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-black"
            (click)="td.toggle()"
            type="button"
          >
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-2 text-right">
      <label class="text-danger">{{
        dateTime.invalid ? 'Invalid date' : '&nbsp;'
      }}</label
      ><br />

      <button
        class="btn btn-black"
        (click)="searchClicked()"
        [disabled]="dateTime.invalid"
      >
        <i class="fas fa-search"></i>
        Search
      </button>
    </div>
  </div>
</div>
