import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() hasSaveButton = false;
  @Input() hasAddButton = false;
  @Input() hasEditButton = false;
  @Input() hasCustomAddButton = false;
  @Input() buttonDisabled;
  @Input() hasCancelButton = false;
  @Input() btnSaveTitle = 'บันทึก';
  @Input() btnAddTitle = 'เพิ่ม';
  @Input() btnEditTitle = 'แก้ไข';
  @Input() btnAddURL = '';
  @Input() btnEditURL = '';
  @Input() btnCancelTitle = 'ยกเลิก';
  @Input() btnCancelURL = null;
  @Input() btnCustomAddTitle = '';
  @Input() hasBackButton = '';
  @Input() orderCustomBtn = false;
  @Output() saveClicked = new EventEmitter();
  @Output() customClicked = new EventEmitter();
  @Output() goToList = new EventEmitter();

  constructor(private location: Location) {
  }

  ngOnInit() {
    if (!this.buttonDisabled) {
      this.buttonDisabled = false;
    }
  }

  onSaveButtonClicked() {
    this.saveClicked.emit();
  }

  onCustomButtonClicked() {
    this.customClicked.emit();
  }

  goBack() {
    this.location.back();
  }
}
