import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { DateTime } from '../../shared/model';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {
  @Input() default: DateTime = new DateTime();
  @Output() search = new EventEmitter();

  dateTime: DateTime;

  constructor() {}

  ngOnInit() {
    this.dateTime = this.default;
  }

  searchClicked() {
    this.search.emit(this.dateTime);
  }
}
