<div class="content-header">
  <h4 style="margin: 0">
    {{ 'ios_deploy.downloadApplicationETR' | translate }}
  </h4>
  <h6 style="margin: 0; color: red">
    **{{ 'ios_deploy.DescriptionLine1' | translate }}
  </h6>
  <h6 style="margin-left: 14px; color: red">
    {{ 'ios_deploy.DescriptionLine2' | translate }}
  </h6>
</div>
<!--<app-loading style="position: center;vertical-align: middle; text-align: center;" *ngIf="loading"></app-loading>-->
<!--<app-list-empty *ngIf="!loading && dataIPhonelength && dataIPadlength" title="No data found."></app-list-empty>-->
<div class="change-language">
  <button (click)="useLanguage('en')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'en' }">
    English
  </button>
  |
  <button (click)="useLanguage('ja')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'ja' }">
    日本語
  </button>
</div>
<div class="row mt-3 mb-4 ml-1 mr-1"
    style="padding-left: 30px; padding-right: 30px">
  <!--  <table class="table table-hover text-center table-striped" style="border-radius: 5px;" *ngIf="!loading && dataIPhonelength && dataIPadlength">-->

  <table class="table table-hover text-center table-striped"
      style="border-radius: 5px;">
    <thead class="thead-light">
      <tr>
        <!-- <th style="background-color: #485460; color: white;">
        <b>{{ 'ios_deploy.name' | translate }}</b>
      </th> -->
        <th style="background-color: #485460; color: white;">
          <b>{{ 'ios_deploy.title' | translate }}</b>
        </th>
        <th style="background-color: #485460; color: white;">
          <b>{{ 'ios_deploy.type' | translate }}</b>
        </th>
        <th style="background-color: #485460; color: white;">
          <b>{{ 'ios_deploy.version' | translate }}</b>
        </th>
        <th style="background-color: #485460; color: white;"><b></b></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataETRlist; let i = index">
        <!-- <td class="align-middle">{{ item.name }}</td> -->
        <td class="align-middle">{{ item.title }}</td>
        <td class="align-middle">iPad</td>
        <td class="align-middle">{{ item.version }}</td>
        <td class="align-middle">
          <button class="btn btn-primary"
              [disabled]="!ETRLink"
              (click)="open(ETRLink)">
            {{ 'ios_deploy.downloadApp' | translate }}
          </button>
        </td>
      </tr>
      <tr *ngFor="let item of dataETRMobilelist; let i = index">
        <!-- <td class="align-middle">{{ item.name }}</td> -->
        <td class="align-middle">{{ "ETR Mobile" | translate }}</td>
        <td class="align-middle">Mobile</td>
        <td class="align-middle">{{ item.version }}</td>
        <td class="align-middle">
          <button class="btn btn-primary"
              [disabled]="!ETRMobileLink"
              (click)="open(ETRMobileLink)">
            {{ 'ios_deploy.downloadApp' | translate }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>