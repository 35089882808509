import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONSTANT } from './api.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(
    private http: HttpClient,
  ) { }

  login(username: string, password: string) {
    const body = {username: username, password: password, user_type: 'WebUser'}
    return this.http.post(API_CONSTANT.tokenAuth, body)
  }

  vefifyUUID(uuid: string) {
    const body = {uuid: uuid}
    return this.http.post(API_CONSTANT.userVerification , body)
  }
}
