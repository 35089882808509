<app-header [title]="'Branches' | translate"> </app-header>
<!--background-color: #485460-->
<form
  class="form-group mt-3"
  style="height: 100px; border-radius: 5px; padding-left: 10px; padding-top: 5px; padding-right: 10px; margin-left: 30px; margin-right: 30px; background-color: ghostwhite"
  [formGroup]="branchForm"
  (submit)="importSeller()"
>
  <label>{{ 'branch.addBranch' | translate }}</label>
  <table>
    <tr>
      <td style="padding-right: 15px;">
        <input
          type="text"
          size="100"
          class="form-control"
          formControlName="name"
          placeholder="{{ 'branch.branchName' | translate }}"
          required
        />
      </td>
      <td style="padding-right: 15px;">
        <input
          type="text"
          size="100"
          class="form-control"
          formControlName="email_seller"
          placeholder="{{ 'branch.branchSellerEmail' | translate }}"
          required
        />
      </td>
      <td>
        <button
          style="width: 90px; background-color: #485460;"
          class="btn btn-primary"
          type="submit"
        >
          <i class="fas fa-save"></i>
          {{ 'branch.add' | translate }}
        </button>
      </td>
    </tr>
  </table>

  <!-- <div
    class="alert alert-danger"
    role="alert"
    *ngIf="branchForm.dirty && !branchForm.valid"
  >
    {{ branchForm.error }}
  </div> -->
</form>
<app-loading
  style="position: center;vertical-align: middle; text-align: center;"
  *ngIf="loading"
></app-loading>
<app-list-empty
  *ngIf="!loading && !dataLength"
  title="{{ 'noDataFound' | translate }}"
></app-list-empty>
<div
  class="row mt-3 mb-4 ml-1 mr-1"
  style="padding-left: 30px; padding-right: 30px"
>
  <table
    class="table table-hover text-center table-striped"
    style="border-radius: 5px;"
    *ngIf="!loading && dataLength"
  >
    <thead class="thead-light">
      <tr>
        <th style="background-color: #485460; color: white;">
          <b>{{ 'branch.number' | translate }}</b>
        </th>
        <th style="background-color: #485460; color: white;">
          <b>{{ 'branch.branchName' | translate }}</b>
        </th>
        <th style="background-color: #485460; color: white;">
          <b>{{ 'branch.sellerEmail' | translate }}</b>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataList; let i = index">
        <td class="align-middle">{{ i + 1 }}</td>
        <td class="align-middle">{{ item.name }}</td>
        <td class="align-middle">{{ item.email_seller }}</td>
      </tr>
    </tbody>
  </table>
</div>
