import { Injectable } from '@angular/core';
import { HttpClientService } from '../../services/http-client.service';
import { API_CONSTANT } from '../../services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class MonotaroService {

  constructor(private api: HttpClientService) {
  }

  getPurchaseOrders(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/monotaro-purchase-orders', param);
  }

  getPurchaseOrderDetails(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/monotaro-purchase-order-details', param);
  }

  getShops(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-monotaro-shops', param);
  }

  getMonotaroUsers(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/monotaro-users', param);
  }

  getMajorClassifications(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/major-classifications', param);
  }

  getMediumClassifications(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/medium-classifications', param);
  }

  getMinorClassifications(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/minor-classifications', param);
  }

  getOrderNumbers(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-order-numbers', param);
  }

  getProductNames(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/monotaro-products', param);
  }

  getProductCodes(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-product-codes', param);
  }

  importMonotaroFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.post(API_CONSTANT.BASE_URL + '/import-monotaro/', formData);
  }

  exportOrer(param?) {
    return this.api.postFile(API_CONSTANT.BASE_URL + '/export-monotaro', param);
  }

  exportMajorClassification(param?) {
    return this.api.postFile(API_CONSTANT.BASE_URL + '/export-major-classification', param);
  }

  exportMediumClassification(param?) {
    return this.api.postFile(API_CONSTANT.BASE_URL + '/export-medium-classification', param);
  }

  exportMinorClassification(param?) {
    return this.api.postFile(API_CONSTANT.BASE_URL + '/export-minor-classification', param);
  }

  exportShopSummary(param?) {
    return this.api.postFile(API_CONSTANT.BASE_URL + '/export-shop-summary', param);
  }

}
