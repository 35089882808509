import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  constructor() { }

  saveFile(res: ArrayBuffer, fileName: string = 'excel.xlsx') {
    const blob = new Blob([res], {type: 'application/octet-stream'});
    this.downloadSave(blob, fileName);
  }

  downloadSave(blob: Blob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
      return;
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}
