import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { API_CONSTANT } from 'src/app/services/api.constants';
import { Branch } from './branch.model';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  constructor(private api: HttpClientService) {}

  getBranches() {
    return this.api.get(API_CONSTANT.BASE_URL + '/branches/');
  }

  addBranch(data: Branch) {
    return this.api.post(API_CONSTANT.BASE_URL + '/branches/', data);
  }
}
