<app-header title="{{id}} {{ categoryName }}"></app-header>
<div class="w-100percent row no-gutter p-3 px-4">
  <div class="col-2 row no-gutter pr-4">
    <label class="row w-100percent no-gutter pl-1">
      {{ 'Shops' | translate }}
    </label>
    <div class="w-100percent">
      <ng-select [clearable]="true"
          [loading]="loadingShop"
          [items]="shopList"
          placeholder="{{ 'Select Shop' | translate }}"
          bindLabel="name"
          bindValue="id"
          [multiple]="true"
          (change)="hadleShopFilterChange($event)"
          [notFoundText]="'notFound'|translate">
        <ng-template ng-label-tmp
            let-item="item">
          <span class="d-flex align-items-center">
            {{ item.is_etr ? "(ETR)" : "(CTA)"}} {{item.name}}
          </span>
        </ng-template>
        <ng-template ng-option-tmp
            let-item="item"
            let-search="searchTerm"
            let-index="index">
          <span class="d-flex align-items-center">
            {{ item.is_etr ? "(ETR)" : "(CTA)"}} {{item.name}}
          </span>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-2 row no-gutter">
    <label class="row w-100percent no-gutter pl-1">
      {{ 'Email' | translate }}
    </label>
    <div class="w-100percent">
      <ng-select [clearable]="true"
          [loading]="loadingEmail"
          [disabled]="loadingEmail"
          [items]="emailList"
          placeholder="{{ 'Select Email' | translate }}"
          bindLabel="showValue"
          bindValue="id"
          [(ngModel)]="search['emails']"
          [multiple]="true"
          [notFoundText]="'notFound'|translate">
      </ng-select>
    </div>
  </div>
  <div class="col-8 d-flex align-items-end">
    <div class="w-100percent d-flex justify-content-between">
      <div class="row no-gutter">
        <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center"
            (click)="resetSearch()">
          <img src="assets/svgs/reset_icon.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'reset' | translate}}
        </button>
        <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center"
            (click)="getProductSellerEmail()">
          <img src="assets/svgs/search_len.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'search' | translate}}
        </button>
      </div>
      <div class="row no-gutter">
        <button class="btn mt-4 mr-2 btn-sm btn-primary d-flex align-items-center"
            (click)="addOrEditSellerEmail(editOrCreate, true)">
          <img src="assets/svgs/plus_icon.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'Add Seller Email' | translate}}
        </button>
        <button class="btn mt-4 mr-2 btn-sm btn-outline-primary d-flex align-items-center"
            (click)="editingCategory(editCategory)">
          <i class="fas fa-cube mr-2"
              style="font-size: 16px;"></i>
          {{'category.editCategory' | translate}}
        </button>
        <button class="btn mt-4 mr-2 btn-sm btn-primary d-flex align-items-center"
            (click)="openModal(content)">
          <img src="assets/svgs/plus_icon.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'seller.importSellerEmail' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<app-loading style="position: center;vertical-align: middle; text-align: center;"
    *ngIf="loadingProductSellerEmail"></app-loading>
<app-list-empty *ngIf="!loadingProductSellerEmail && !productSellerEmailList.length"
    title="{{ 'noDataFound' | translate }}"></app-list-empty>
<div class="row mb-4 mx-3">
  <table class="table table-hover text-center table-striped"
      style="border-radius: 5px;"
      *ngIf="!loadingProductSellerEmail && productSellerEmailList.length">
    <thead class="thead-light">
      <tr>
        <th>
          {{ 'No' | translate }}
        </th>
        <th>
          {{ 'Shop Name' | translate }}
        </th>
        <th>
          {{ 'Seller Email' | translate }}
        </th>
        <th>
          {{ 'CC Email' | translate }}
        </th>
        <th>
          {{ 'category.edit' | translate }}
        </th>
        <th>
          {{ 'Delete' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of productSellerEmailList; let i = index;">
        <td class="align-middle">
          {{ (i + ((page -1) * 40)) + 1}}
        </td>
        <td class="align-middle">
          {{ item.shop_name }} ({{item.is_etr ? 'ETR' : 'CTA'}})
        </td>
        <td class="align-middle">{{ item.email_name }} ({{ item.email }})</td>
        <td class="align-middle">
          <ul class="m-0 p-0"
              *ngFor="let email of item.cc_email">
            {{ email.email }} ({{ email.id }})
          </ul>
        </td>
        <td>
          <img (click)="addOrEditSellerEmail(editOrCreate, false, item)"
              src="assets/svgs/edit_pen.svg"
              height="28"
              width="auto"
              class='clickable'>
        </td>
        <td>
          <img (click)="openDeleteModel(confirmDelete, item.id)"
              src="assets/svgs/trash_bin.svg"
              height="28"
              width="auto"
              class='clickable'>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
    <ngb-pagination [collectionSize]="dataLength"
        (pageChange)="changePage($event)"
        [(page)]="page"
        [pageSize]="40"
        [maxSize]="3"
        [rotate]="true"
        [boundaryLinks]="true"
        [ellipses]="false">
    </ngb-pagination>
  </div>
</div>

<ng-template #editOrCreate>
  <div class="model-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0"> {{ (type === 'add' ?
        'seller.addSellerEmail' : 'category.editCategory') | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-2">
      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{'shop.Shops' | translate}}
        </label>
        <div class="col-8 p-0">
          <ng-select *ngIf="type === 'add'"
              class="w-100percent"
              [clearable]="false"
              [loading]="loadingShopSelect"
              [items]="shopSelectList"
              bindLabel="name"
              bindValue="id"
              (change)="handleSelectShop($event)"
              [placeholder]="'shop.selectShop' | translate"
              [notFoundText]="'notFound' | translate">
            <ng-template ng-label-tmp
                let-item="item">
              <span class="d-flex align-items-center">
                {{ item.is_etr ? "(ETR)" : "(CTA)"}} {{item.name}}
              </span>
            </ng-template>
            <ng-template ng-option-tmp
                let-item="item"
                let-search="searchTerm"
                let-index="index">
              <span class="d-flex align-items-center">
                {{ item.is_etr ? "(ETR)" : "(CTA)"}} {{item.name}}
              </span>
            </ng-template>
          </ng-select>
          <div class="w-100percent"
              *ngIf="type==='edit'">
            {{ editProductSellerEmailShop }}
          </div>
        </div>
      </div>
      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{'branch.sellerEmail' | translate}}
        </label>
        <div class="col-8 p-0">
          <ng-select class="w-100percent"
              [clearable]="true"
              [loading]="loadingEmail"
              [items]="emailList"
              bindLabel="showValue"
              bindValue="id"
              [(ngModel)]="selectedSellerEmail"
              placeholder="{{ 'Select Email' | translate }}"
              [notFoundText]="'notFound' | translate"></ng-select>
        </div>
      </div>
      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{ 'CC Email' | translate }}
        </label>
        <div class="col-8 p-0">
          <ng-select class="w-100percent"
              [clearable]="true"
              [loading]="loadingEmail"
              [items]="emailList"
              bindLabel="showValue"
              bindValue="id"
              [(ngModel)]="selectedCCEmails"
              [multiple]="true"
              placeholder="{{ 'Select Email' | translate }}"
              [notFoundText]="'notFound' | translate"></ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary"
          (click)="dismissModal()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary"
          (click)="createOrEditProductSellerEmail()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #editCategory>
  <div class="model-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0"> {{ 'category.editCategory' | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-2">
      <div class="row p-0 m-0 mt-3">
        <div class="center w-100">
          <ngx-file-drop dropZoneLabel="Drop files here"
              (onFileDrop)="droppedImageFile($event)"
              [multiple]="false"
              accept=".png,.jpg">
            <ng-template ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector">
              <div class="w-100 h-100 clickable"
                  (click)="openFileSelector()"
                  *ngIf="!editCategoryImageUrl">
                <div class="row my-4 d-flex justify-content-center">
                  <img src="assets/svgs/file_upload.svg"
                      height="60px"
                      width="auto">
                </div>
                <h4 class="row d-flex justify-content-center text-secondary">
                  {{'browseOrDrop' | translate}}
                </h4>
              </div>

              <div class="w-100 h-100 row d-flex justify-content-center align-items-center clickable"
                  *ngIf="editCategoryImageUrl">
                <div class="col-12 d-flex justify-content-center mt-1"
                    (click)="openFileSelector()">
                  <img [src]="editCategoryImageUrl"
                      alt=""
                      class="preview-img">
                </div>
                <div class="rmv-img"
                    (click)="removeImage()">
                  <i class="fa fa-times"
                      aria-hidden="true"></i>
                </div>
              </div>
            </ng-template>
          </ngx-file-drop>
        </div>
      </div>
      <div class="row p-0 m-0 mt-4">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{'category.categoryName' | translate}}</label>
        <input type="text"
            class="col-8 form-control"
            [(ngModel)]="editCategoryName"
            [placeholder]="'inputCategory' | translate">
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary"
          (click)="dismissModal()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary"
          (click)="saveCategory()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #confirmDelete
    let-modal>
  <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">
      {{ 'category.deleteConfirmation' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ 'category.areYouSure' | translate }}</p>
    <div class="modal-footer">
      <button type="button"
          class="btn btn-outline-secondary"
          (click)="dismissModal()">
        {{ 'category.cancel' | translate }}
      </button>
      <button type="button"
          class="btn btn-danger"
          (click)="deleteProductSellerEmail()">
        {{ 'category.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #content
    let-modal>
  <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">
      {{ 'seller.importSellerEmail' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label class="form-group">{{ 'Select File' | translate }}</label>
        <input class="form-control-file"
            type="file"
            (change)="chooseFile($event)"
            accept=".xls, .xlsx">
      </div>
    </form>
    <div class="row no-gutters mt-3">
      <div class="col-5 align-self-center">
        <div class="btn-group"
            role="group"
            aria-label="First group">
          <button type="button"
              class="btn"
              (click)="checked('Normal')"
              [ngClass]="selectedCheckBox === 'Normal' ? 'btn-secondary' : 'btn-outline-secondary' ">
            {{ 'Normal' | translate }}
          </button>
          <button type="button"
              class="btn"
              (click)="checked('Scheduled')"
              [ngClass]="selectedCheckBox === 'Scheduled' ? 'btn-primary' : 'btn-outline-secondary' ">
            {{ 'Scheduled' | translate }}
          </button>
        </div>
      </div>
      <div class="col-7 row no-gutters">
        <input class="col-6 form-control h-35 align-self-center border rounded px-1"
            type='text'
            (click)="d.toggle()"
            name="dp"
            ngbDatepicker
            [disabled]="selectedCheckBox == 'Normal'"
            [(ngModel)]="startDate"
            [minDate]="minDate"
            #d="ngbDatepicker">

        <div class='col-6 px-2'>
          <ngb-timepicker [(ngModel)]="startTime"
              [disabled]="selectedCheckBox == 'Normal'">
          </ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
        class="btn btn-outline-secondary"
        (click)="dismissModal()">
      {{ 'category.cancel' | translate }}
    </button>
    <button type="button"
        class="btn btn-outline-dark"
        (click)="uploadFile()"
        translate
        [disabled]="!importFile">
      {{ 'Import' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #uploading>
  <app-loading style="position: center;vertical-align: middle; text-align: center;"></app-loading>
</ng-template>