import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrderHistoryService } from '../order-history.service';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalService } from 'src/app/components/confirm-modal/confirm-modal.service';
import { OrderItems } from '../order-history.model';
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  @Input() orderDetail = null;
  @Input() searchParams = {};

  loadingOrderItems: Boolean = false;
  orderItems = [];
  changingRow = null;
  changingStatus = null;
  selectedDate = null;
  changingID = null;
  userID = null;
  modalName = '';
  datePicker;
  shipmentStatus = [
    'Confirming Accepted',
    'Arranging Shipment',
    'Delivery Date Assigned',
    'Delivered',
    'Cancelled',
    'Akaden'
  ]

  shipmentColors = [
    '#B7DEFF',
    '#FFECAD',
    '#DDCCFA',
    '#E3FFBC',
    '#FA6E3A',
    '#FE2926'
  ]

  shipmentFontColors = [
    '#137AD3',
    '#FEC400',
    '#9553FF',
    '#82D313',
    '#FADDD2',
    '#FADDD2'
  ]

  constructor(
    private orderHistoryService: OrderHistoryService,
    private notiService: ToastrService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private confirmModalService: ConfirmModalService,
    
  ) {
  }

  ngOnInit() {
    this.getOrderDetail();
    this.userID = localStorage.getItem('userId');
  }

  getOrderDetail() {
    this.loadingOrderItems = true;
    let param = new HttpParams().set('order', this.orderDetail.id);
    this.searchParams['category']
      ? (param = param.set('category_id', this.searchParams['category'] || ''))
      : {};
    this.searchParams['productName']
      ? (param = param.set(
          'product_name',
          this.searchParams['productName'] || ''
        ))
      : {};
    this.searchParams['productCode']
      ? (param = param.set(
          'product_code',
          this.searchParams['productCode'] || ''
        ))
      : {};
    this.orderHistoryService.getOrderItems(param).subscribe(
      (res: any) => {
        this.orderItems = res.results;
        this.orderDetail['price_total'] = this.orderItems.reduce((acc, cur) => {
          return acc + cur.price_total;
        }, 0);
        this.orderDetail['totalAmount'] =
          this.orderDetail.price_total + this.orderDetail.transportation_fee;
        this.loadingOrderItems = false;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  showError(error) {
    this.notiService.error(_.values(error.error)[0].toString());
  }

  updateDeliveryDate(id, rowIndex, date?) {
    const data = {
      delivery_date: _.values(date).join('-'),
      status: 3,
    }
    this.updateOrderItem(id, data);
    this.orderItems[rowIndex].delivery_date = null
  }

  checkDelivered() {
    const data = {
      is_delivered: true,
      status: 4,
      is_web: true
    }
    this.updateOrderItem(this.changingID, data);
  }

  openConfirmation(id, view, rowIndex, status, datePicker?, date?) {
    this.changingID = id;
    this.changingRow = rowIndex;
    this.datePicker = datePicker;
    this.selectedDate = date;
    this.changingStatus = _.cloneDeep(status);
    if (status === 2) {
      this.modalName = 'not decided';
    } else if (status === 3 && !this.orderItems[rowIndex].delivery_date) {
      this.modalName = 'date selected';
    } else if (status === 3 && this.orderItems[rowIndex].delivery_date) {
      this.modalName = 'date reselected';
    } else {
      this.modalName = 'other';
    }
    const modalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalService.open(view, modalOptions);
  }

  selectNotDecided() {
    const data = {
      status: 2,
    }
    this.updateOrderItem(this.changingID, data);
  }

  openDatePicker() {
    this.orderItems[this.changingRow].status = 3;
    this.datePicker.open();
    this.modalService.dismissAll();
  }

  dismissDecision() {
    this.changingID = null;
    this.changingRow = null;
    this.changingStatus = null;
    this.selectedDate = null;
    this.datePicker ? this.datePicker.close() : {};
    this.datePicker = null;
    this.modalName = '';
    this.modalService.dismissAll();
  }

  updateOrderItem (id, data) {
    data['updated_user_id'] = this.userID;
    this.orderHistoryService.updateOrderItem(id, data).subscribe(
      (res: any) => {
        this.getOrderDetail();
        this.dismissDecision();
        this.translateService.get('Successfully Updated').subscribe(updatedWord => {
          this.notiService.success(updatedWord);
        });
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  save() {
    switch (this.changingStatus) {
      case 2:
        this.selectNotDecided()
        break;
      case 3:
        this.openDatePicker();
        break;
      case 4:
        this.checkDelivered();
        break;
    }
  }

  isItemCancellable(item: OrderItems) {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    return ['1', '2', '3'].includes(item.status.toString()) && ['Admin', 'SellerWebUser'].includes(group[0])
  }

  async onCancelOrder(id: string) {
    const title = await this.translateService.get('Confirm Cancellation').toPromise();
    const content = await this.translateService.get('Are you sure you want to cancel this product?').toPromise();

    this.confirmModalService.open(title, content, () => this.onCancelOrderConfirmed(id));
  }

  async onCancelOrderConfirmed(id: string) {
    this.loadingOrderItems = true;
    const params = {
      status: 5
    };

    this.orderHistoryService.updateOrderItem(id, params).subscribe((res) => {
      this.getOrderDetail();
    }, (error) => {
      this.showError(error);
    }, () => {
      this.loadingOrderItems = false;
    });
  }

  getLangNWidth() {
    return this.translateService.currentLang.toString() === 'en' ? 'en-width' : 'jp-width';
  }
}
