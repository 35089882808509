<div class="container">
  <img class="mx-auto" src="../../assets/images/logo.png" alt="logo" />
  <!-- Form -->
  <form [formGroup]="registerForm" (ngSubmit)="register()">
    <div class="row h-100">
      <div class="col-xs-12 col-md-8 offset-md-2 my-auto">
        <div class="container form-signin">
          <!-- Name field -->
          <div class="form-group">
            <div class="inner-addon">
              <i class="fa fa-user-circle"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="name"
                placeholder="*{{ 'register.name' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.errors &&
                    registerForm.errors.shopAndShopNameField
                }"
              />
            </div>
          </div>
          <!-- Shop name field -->
          <div class="form-group">
            <div class="inner-addon">
              <i class="fa fa-store"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="shop_name"
                placeholder="*{{ 'register.shopName' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.errors &&
                    registerForm.errors.shopAndShopNameField
                }"
              />
            </div>
          </div>
          <div
            class="form-group text-danger text-center"
            *ngIf="
              registerForm.errors && registerForm.errors['shopAndShopNameField']
            "
          >
            <small translate>Please enter name or shop name</small>
          </div>
          <!-- Username field -->
          <div class="form-group">
            <div class="inner-addon">
              <i class="fa fa-user"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="username"
                placeholder="*{{ 'register.createUserID' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.controls.username.dirty &&
                    !registerForm.controls.username.valid
                }"
                required
              />
              <div class="small">
                <small>
                  {{ 'register.createUserID2' | translate }}
                </small>
              </div>
            </div>
          </div>
          <!-- Password field -->
          <div class="form-group">
            <div class="inner-addon">
              <i class="fa fa-lock"></i>
              <input
                type="password"
                class="form-control rounded-pill"
                formControlName="password"
                placeholder="*{{ 'register.createPassword' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.controls.password.dirty &&
                    !registerForm.controls.password.valid
                }"
                required
              />
            </div>
          </div>
          <!-- Email field -->
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-envelope"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="email"
                placeholder="*{{ 'register.enterEmail' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.controls.email.dirty &&
                    !registerForm.controls.email.valid
                }"
                required
              />
            </div>
          </div>
          <!-- Registered Address -->
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-city"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="default_address_postal_code"
                placeholder="*{{
                  'register.defaultAddressPostalCode' | translate
                }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.controls.default_address_postal_code.dirty &&
                    !registerForm.controls.default_address_postal_code.valid
                }"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-mail-bulk"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="default_address_prefecture"
                placeholder="*{{
                  'register.defaultAddressPrefecture' | translate
                }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.controls.default_address_prefecture.dirty &&
                    !registerForm.controls.default_address_prefecture.valid
                }"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-home"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="default_address"
                placeholder="*{{ 'register.defaultAddress' | translate }}"
                [ngClass]="{
                  'is-invalid':
                    registerForm.controls.default_address.dirty &&
                    !registerForm.controls.default_address.valid
                }"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-truck"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="delivery_address_name"
                placeholder="{{ 'user.deliveryPlaceName' | translate }}"
              />
            </div>
          </div>
          <!-- Delivery Address -->
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-envelope-square"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="delivery_address_postal_code"
                placeholder="{{
                  'register.deliveryAddressPostalCode' | translate
                }}"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-archway"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="delivery_address_prefecture"
                placeholder="{{
                  'register.deliveryAddressPrefecture' | translate
                }}"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="inner-addon">
              <i class="fas fa-truck"></i>
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="delivery_address"
                placeholder="{{ 'register.deliveryAddress' | translate }}"
              />
            </div>
          </div>
          <!-- Phone Numbers -->
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <div class="inner-addon">
                  <i class="fas fa-phone"></i>
                  <input
                    type="text"
                    class="form-control rounded-pill"
                    formControlName="phone_number_1"
                    placeholder="*{{ 'register.phoneNumber' | translate }} 1"
                    [ngClass]="{
                      'is-invalid':
                        registerForm.controls.phone_number_1.dirty &&
                        !registerForm.controls.phone_number_1.valid
                    }"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="form-group">
                <div class="inner-addon">
                  <i class="fas fa-phone"></i>
                  <input
                    type="text"
                    class="form-control rounded-pill"
                    formControlName="phone_number_2"
                    placeholder="{{ 'register.phoneNumber' | translate }} 2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group text-danger text-center" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
          <div class="form-group">
            <button class="btn btn-primary rounded-pill w-100" type="submit">
              {{ 'register.register' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="change-language">
    <button
      (click)="useLanguage('en')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'en' }"
    >
      English
    </button>
    |
    <button
      (click)="useLanguage('ja')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'ja' }"
    >
      日本語
    </button>
  </div>
</div>
