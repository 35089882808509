import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalculationHistoryComponent } from './calculation-history.component';

export const CalculationHistoryRoutes: Routes = [
  {
    path: 'calculation-history',
    children: [
      {
        path: '',
        component: CalculationHistoryComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(CalculationHistoryRoutes)],
  exports: [RouterModule],
})
export class CalculationHistoryRoutesModule {}
