<app-header title="{{ 'product-set.EditProductSet' | translate }}">
</app-header>

<div class="row w-100 p-3 px-4 m-0">
  <div class="col-6 row p-0 m-0">
    <label class="col-auto m-0 p-0 pr-3 align-self-center">
      {{ "product-master.productSetName" | translate }}
    </label>
    <div class="col-6 row p-0 m-0 d-flex align-items-center" *ngIf="productset">
      <input class="col-6 form-control form-control-sm p-3" type="text"
        [(ngModel)]="productset.name" [readOnly]="!editPSName">
      <img src="assets/svgs/blue_pen.svg" height="22" class="ml-2 clickable" width="auto"
        (click)="editProductSetName()" *ngIf="!editPSName" >
      <img src="assets/svgs/blue_check.svg" height="22" class="ml-2 clickable" width="auto"
        (click)="save()" *ngIf="editPSName">
      <img src="assets/svgs/red_cross.svg" height="22" class="ml-2 clickable" width="auto"
        (click)="resetName()" *ngIf="editPSName">
    </div>
  </div>
  <div class="col-6 p-0 d-flex justify-content-end align-items-end">
    <button class="btn btn-sm btn-primary d-flex align-items-center"
      (click)="addCategory(editOrCreate)">
      <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
      {{'product-set.AddCategory' | translate}}
    </button>
  </div>
</div>

<div class="row mb-4 mx-4">
  <table
    class="table table-hover mb-0 text-center table-borderless table-striped"
    style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;">
    <thead>
      <tr class="text-center">
        <th width="10%">
          {{ "product-set.sequence" | translate }}
        </th>
        <th width="20%">
          {{ "product-set.CategoryNumber" | translate }}
        </th>
        <th width="20%">
          {{ "product-set.categoryName" | translate }}
        </th>
        <th width="35%">
          {{ "seller.Seller" | translate }}
        </th>
        <th width="15%">
          {{ "remove" | translate }}
        </th>
      </tr>
    </thead>
  </table>
  <div cdkDropList class="example-list w-100" *ngIf="category_data.length"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box text-center w-100" *ngFor="let item of category_data"
      [cdkDragData]="item" cdkDrag>
      <div style="width: 10%;">
        <i class="fa fa-bars"></i>
      </div>
      <div style="width: 20%;">
        {{ item.id }}
      </div>
      <div style="width: 20%;">
        {{ item.category_name }}
      </div>
      <div style="width: 35%;">
        {{ item.seller_name}}
      </div>
      <div style="width: 15%;" class="clickable">
        <img src="assets/svgs/trash_bin.svg"
          height="22" width="auto"
          (click)="deleteCategory(confirmDelete, item)">
      </div>
    </div>
  </div>
</div>

<div class="row w-100 p-3 px-4 m-0 d-flex justify-content-between">
  <div class="w-15percent row p-0 m-0 align-self-start">
    <label class="row mt-2 w-100 m-0 p-0 pl-1">
      {{ "product-set.pricePackage" | translate }}
    </label>
  </div>
  <button class="btn btn-sm mt-1 mr-2 btn-primary d-flex align-items-center" (click)="addCategory(addPackage)"
  >
    <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
    {{'product-set.createPackageSet' | translate}}
  </button>
</div>

<div class="row mb-4 mx-4">
  <table
    class="table table-hover text-center table-borderless table-striped"
    style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
  >
    <thead>
    <tr>
      <th width="10%">
        {{ "product-image.no" | translate }}
      </th>
      <th width="25%">
        {{ "product-set.pricePackageName" | translate }}
      </th>
      <th width="25%">
        {{ "product-set.updatedDate" | translate }}
      </th>
      <th width="25%">
        {{ "Import" | translate }}
      </th>
      <th width="15%">
        {{ "Delete" | translate }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of priceset_data; let i = index">
      <td class="align-middle">{{ i + 1 }}</td>
      <td class="d-flex align-items-center justify-content-center">

        <input class="form-control form-control-sm p-3" type="text"
        [(ngModel)]="item.name" *ngIf="!editingPricePackage || editingPPRow != i" readonly>
        <input class="form-control form-control-sm p-3" type="text"
        [(ngModel)]="editingPPName" *ngIf="editingPricePackage  && editingPPRow == i">

        <div class="w-50percent text-left">
          <img src="assets/svgs/blue_pen.svg" height="22" class="ml-2 clickable" width="auto"
          (click)="editPricePackageName(i)" *ngIf="!editingPricePackage || editingPPRow != i">
          <img src="assets/svgs/blue_check.svg" height="22" class="ml-2 clickable" width="auto"
            (click)="savePricePackage()" *ngIf="editingPricePackage  && editingPPRow == i">
          <img src="assets/svgs/red_cross.svg" height="22" class="ml-2 clickable" width="auto"
            (click)="resetPricePackage()" *ngIf="editingPricePackage  && editingPPRow == i">
        </div>
      </td>
      <td class="align-middle">{{ item.updated_at | date: 'yyyy-MM-dd h:mm a': '+0900' }}</td>
      <td class="align-middle">
        <button class="btn btn-sm btn-outline-primary d-flex align-items-center mx-auto"
        (click)="openDialog(content, item)">
        <img src="assets/svgs/download.svg" height="22" width="auto" class="mr-2">
          {{'product-set.importPriceFile' | translate}}
        </button>
      </td>
      <td class="align-middle">
        <img src="assets/svgs/trash_bin.svg" height="22" width="auto" (click)="deletePrice(confirmDelete, item)"
        >
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'price-master.uploadFile' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="uploadFileError"
        >
          {{ 'Cannot upload file' | translate }}. {{ 'Error' | translate }}: {{ uploadFileError }}
        </div>
        <label class="form-group">{{ 'Select File' | translate }}</label>
        <input class="form-control-file"
               type="file"
               (change)="chooseFile($event)"
               accept=".xls, .xlsx">
      </div>
    </form>
    <div class="row no-gutters mt-3">
      <div class="col-5 align-self-center">
        <div class="btn-group" role="group" aria-label="First group">
          <button type="button"
                  class="btn"
                  (click)="checked('Normal')"
                  [ngClass]="selectedCheckBox === 'Normal' ? 'btn-secondary' : 'btn-outline-secondary' ">
            {{ 'Normal' | translate}}
          </button>
          <button type="button"
                  class="btn"
                  (click)="checked('Scheduled')"
                  [ngClass]="selectedCheckBox === 'Scheduled' ? 'btn-primary' : 'btn-outline-secondary' ">
            {{ 'Scheduled' | translate }}
          </button>
        </div>
      </div>
      <div class="col-7 row no-gutters">
        <input
          class="col-6 from-control h-35 align-self-center border rounded px-1"
          type='text'
          (click)="d.toggle()"
          name="dp"
          ngbDatepicker
          [disabled]="selectedCheckBox == 'Normal'"
          [(ngModel)]="startDate"
          [minDate]="minDate"
          #d="ngbDatepicker">

        <div class='col-6 px-2'>
          <ngb-timepicker
            [(ngModel)]="startTime"
            [disabled]="selectedCheckBox == 'Normal'">
          </ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="uploadFile()"
      [disabled]="!importFile"
    >
      {{ 'Import' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #loading>
  <app-loading
    style="position: center;vertical-align: middle; text-align: center;"
  ></app-loading>
</ng-template>

<ng-template #addPackage>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0" *ngIf="type=='add'"> {{ "Create Package Set to" | translate}} {{productset.name}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-2">
      <div class="row p-0 m-0 mt-4">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{"Package Set Name" | translate}}</label>
        <input type="text" class="col-8 form-control" [(ngModel)]="packagesetName"
               [placeholder]="'Enter Package Set Name' | translate">
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="savePackage()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>


<ng-template #editOrCreate>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0" *ngIf="type=='add'"> {{ "product-set.addCategoryTo" | translate}} {{productset.name}}</h4>
      <h4 class="m-0" *ngIf="type=='edit'"> {{ 'category.editCategory' | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-2">
      <div class="row p-0 m-0 mt-4">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{"product-master.productSetName" | translate}}</label>
        <input type="text" class="col-8 form-control" [(ngModel)]="productset.name"
               [placeholder]="'Input Product Set' | translate" readonly>
      </div>

      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{"product-import.category" | translate}}</label>
        <input type="text" class="col-8 form-control" *ngIf="type=='edit'" readonly>
        <ng-select
          *ngIf="type=='add'"
          class="col-8 w-100 p-0"
          [clearable]="false"
          bindLabel="number_and_name"
          bindValue="id"
          [multiple]="true"
          [items]="categories"
          [(ngModel)]="categoryID"
          [placeholder]="'product-set.selectCategory' | translate"
          [notFoundText]="'notFound' | translate"
        ></ng-select>
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="submit()">{{'Save' | translate}} </button>
    </div>
  </div>
</ng-template>


<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'category.deleteConfirmation' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'category.areYouSure' | translate }}</p>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ 'category.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.close('Ok click')"
      >
        {{ 'category.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>
