import { Injectable } from '@angular/core';
import { HttpClientService } from '../services/http-client.service';
import { RegisterInput } from './register.input';
import { Observable } from 'rxjs';
import { API_CONSTANT } from '../services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private api: HttpClientService) {}

  register(data: RegisterInput): Observable<any> {
    return this.api.post(API_CONSTANT.BASE_URL + '/register/', data);
  }
}
