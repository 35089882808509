import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { API_CONSTANT } from '../../services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class ExportResultService {
  constructor(private api: HttpClientService) {
  }

  getExportResults(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/export-results/', params);
  }
}
