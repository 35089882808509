import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '../../components/core/core.module';
import {ImportProductComponent} from './import-product.component';
import {ImportProductRoutingModule} from './import-product-routing.module';
import { SharedModule } from 'src/app/shared.module';


@NgModule({
  declarations: [
    ImportProductComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbModule,
        NgbDatepickerModule,
        NgbPaginationModule,
        ImportProductRoutingModule,
        CoreModule,
        SharedModule,
    ],
  providers: [
  ]
})
export class NewProductCodeModule { }
