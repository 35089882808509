import {Component, OnInit} from '@angular/core';
import {Shop} from '../../models/shop.model';
import {Dealer} from '../dealer/dealer.model';
import {ToastrService} from 'ngx-toastr';
import { OrderItems, Orders, OrderStatusType } from './order-history.model';
import {OrderHistoryService} from './order-history.service';
import {
    NgbDatepickerI18n,
    NgbModal,
    NgbModalOptions,
    ModalDismissReasons,
  } from '@ng-bootstrap/ng-bootstrap';
import {CustomDatepickerI18n} from '../../shared/datepicker-i18n';
import {HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {HttpClientService} from '../../services/http-client.service';
import {API_CONSTANT} from '../../services/api.constants';
import {CategoryService} from '../category/category.service';
import {UserService} from '../user/user.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

type TabTypes = 'By Order' | 'By Product';
@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss'],
    providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
})
export class OrderHistoryComponent implements OnInit {

    loadingOrderItems: Boolean = false;
    loadingOrders: Boolean = false;
    loadingShop: Boolean = false;
    loadingDealer: Boolean = false;
    loadingImport: Boolean = false;
    showingDetail: Boolean = false;
    exportingSB: Boolean = false;
    exportingDelivered: Boolean = false;
    exportingWebUser: Boolean = false;
    exportingMSI: Boolean = false;
    exportingMissingEmail: Boolean = false;

    shops: Shop[] = [];
    orders: Orders[] = [];
    orderItems: OrderItems[] = [];
    dealers: Dealer[] = [];
    categories = [];

    filterUserName = [];
    originalUsernames = [];

    search = {}
    orderDetail = null;
    currentPage = 1;
    count = 3;
    request;

    orderItemCurrentPage = 1;
    orderItemCount = 0;

    admin = false;
    CCUser = false;
    sellerWebUser = false;
    etrBuyAdmin = false;
    searchParams = {};

    selectedTab: TabTypes = 'By Order';
    showChangeProductType = false;
    selectedOrderItem: number[] = [];
    selectedAllCheck: boolean = false;
    fileToUpload: File = null;

    productTypesChoice = {
        'Other': {
            value: 0,
        },
        'Purchased': {
            value: 1,
        },
        'Self Used': {
            value: 2,
        }
    }

    selectedProductType: number | null = null;

    statusChoice = [
        { name: 'Confirmed', value: 1},
        { name: 'Not Decided', value: 2},
        { name: 'Date Asssigned', value: 3},
        { name: 'Delivered', value: 4},
        { name: 'Discarded', value: 5},
        { name: 'Akaden', value: 6}
    ];

    shipmentStatus = {
        'Confirmed': {
            background: '#B7DEFF',
            font: '#137AD3',
            word: 'Confirming Accepted'
        },
        'Not Decided': {
            background: '#FFECAD',
            font: '#FEC400',
            word: 'Arranging Shipment'
        },
        'Date Asssigned': {
            background: '#DDCCFA',
            font: '#9553FF',
            word: 'Delivery Date Assigned'
        },
        'Delivered': {
            background: '#E3FFBC',
            font: '#82D313',
            word: 'Delivered'
        },
        'Discarded': {
            background: '#FADDD2',
            font: '#FA6E3A',
            word: 'Cancelled'
        },
        'Akaden': {
            background: '#FE2926',
            font: '#FADDD2',
            word: 'Akaden'
        }
    }

    productType = {
        'Self Used': {
            background: '#e26941',
            font: '#fff',
        },
        'Purchased': {
            background: '#1e2f61',
            font: '#fff',
        },
        'Other': {
            background: '#fff',
            font: '#212529',
        }
    }

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        private orderHistoryService: OrderHistoryService,
        private translateService: TranslateService,
        private api: HttpClientService,
        private userService: UserService,
        private categoryService: CategoryService,
        private router: Router,
    ) {
        this.request = {
            user: {loading: false, success: null, error: null},
            file: {loading: false, success: null, error: null}
        };
    }

    ngOnInit() {
        this.resetSearch();
        this.getUserList();
        this.getDealers();
        this.getCategories();
        this.admin = this.isAdmin();
        this.CCUser = this.isCCUser();
        this.sellerWebUser = this.isSellerWebUser();
        this.etrBuyAdmin = this.isEtrBuyAdmin();
    }

    getStatusString(item: Orders): string {
      if (item.status === OrderStatusType.Pending) {
        return 'Pending';
      } else if (item.status === OrderStatusType.Rejected) {
        return 'Rejected';
      }
      const statusDict = {
        1: 'Completed',
        2: 'Not Complete',
        3: 'Cancelled',
        6: 'Akaden'
      };
      return statusDict[item.complete_or_not];
    }

    changeTab(tab: TabTypes): void {
        this.selectedTab = tab;
        this.showChangeProductType = false;
        this.selectedOrderItem = [];
        this.orderItems.forEach((item) => {
          item.is_selected = false;
        });
        this.selectedAllCheck = false;
    }

    getOrderItemHistoryList(): void {
        this.loadingOrderItems = true;
        this.orderHistoryService.getOrderItems(this.getOrderItemParams()).subscribe(
            (res: any) => {
                res.results.forEach((item) => {
                  item.is_selected = false;
                });
                this.orderItems = res.results;
                this.orderItemCount = res.count;
                this.loadingOrderItems = false;
            },
            (error) => {
                this.showError(error);
                this.loadingOrderItems = false;
            }
        )
    }

    getOrderHistoryList(): void {
        this.loadingOrders = true;
        this.orderHistoryService.getOrders(this.getOrderParams()).subscribe(
            (res: any) => {
                this.count = res.count;
                this.orders = res.results;
                this.loadingOrders = false;
            },
            (error) => {
                this.showError(error);
            }
        )
    }

    toggleChangeProductType(): void {
        this.showChangeProductType = !this.showChangeProductType;
        if (!this.showChangeProductType) {
          this.selectedOrderItem = [];
          this.orderItems.forEach((item) => {
            item.is_selected = false;
          });
          this.selectedAllCheck = false;
        }
    }

    getCategories() {
        this.categoryService.getAllCategories().subscribe(
            (res: any) => {
                this.categories = res;
            },
            (err) => {
                this.showError(err);
            }
        )
    }

    showError(error) {
        this.notification.error(_.values(error.error)[0].toString());
    }

    showList() {
        this.orderDetail = null;
        this.searchParams = {};
        this.getOrderHistoryList();
        this.getOrderItemHistoryList();
        this.showingDetail = false;
    }

    resetSearch() {
        this.search = {
            'username': [],
            'dealer': null,
            'selected_only': true,
            'shop': [],
            'orderID': [],
            'productName': [],
            'category': [],
            'productCode': [],
            'startDate': null,
            'endDate': null,
            'startTime': {'hour': 0, 'minute': 0},
            'endTime': {'hour': 23, 'minute': 59},
            'deliveryStatus': [],
        }
        this.shops = [];
        this.getShops();
        this.getOrderHistoryList();
        this.getOrderItemHistoryList();
    }

    navigateTo(event) {
        this.currentPage = event;
        this.getOrderHistoryList();
    }

    navigateToOrderItem(event):void {
        this.orderItemCurrentPage = event;
        this.getOrderItemHistoryList();
    }

    showOrderDetail(order) {
        this.showingDetail = true;
        this.orderDetail = order;
        this.search['selected_only'] ?
            this.searchParams = {
                category: this.search['category'] || '',
                productName: this.search['productName'] || '',
                productCode: this.search['productCode'] || '',
            }
            :
            {};
    }

    downloadMSI() {
        this.exportingMSI = true;
        this.request.file.loading = true;
        this.request.file.success = null;
        this.api.postFile(`${API_CONSTANT.BASE_URL}/export-order-background/`, this.getParams()).subscribe(
            (res: any) => {
                // this.saveFile(res, true);
                this.router.navigate(['/export-result'])
                this.exportingMSI = false;
            },
            (err: any) => {
                this.exportingMSI = false;
                this.showExportError(err);
            }
        );
    }

    downloadSB() {
        this.exportingSB = true;
        const api = (this.isAdmin()) ? '/export-sb-order-excel-background/' : '/export-order-excel-background/';
        this.request.file.loading = true;
        this.request.file.success = null;
        this.api.postFile(`${API_CONSTANT.BASE_URL}${api}`, this.getParams()).subscribe(
            (res: any) => {
                // this.saveFile(res, true);
                this.router.navigate(['/export-result'])
                this.exportingSB = false;
            },
            (err: any) => {
                this.exportingSB = false;
                this.showExportError(err);
            }
        );
    }

    downloadDelivered() {
        this.exportingDelivered = true;
        const api = '/export-delivered-item-background/';
        this.request.file.loading = true;
        this.request.file.success = null;
        let params = this.getParams();
        params = params
          .set('is_delivered', this.selectedTab !== 'By Product' ? 'true' : '')
          .set(
            'delivery_status',
            this.selectedTab === 'By Product'
              ? this.search['deliveryStatus'].toString() || ''
              : ''
          );
        this.api.postFile(`${API_CONSTANT.BASE_URL}${api}`, params).subscribe(
            (res: any) => {
                this.router.navigate(['/export-result'])
                this.exportingSB = false;
            },
            (err: any) => {
                this.exportingSB = false;
                this.showExportError(err);
            }
        );
    }

    downloadMissingEmail() {
        this.exportingMissingEmail = true;
        const api = '/export-missing-order/';
        this.request.file.loading = true;
        this.request.file.success = null;
        this.api.postFile(`${API_CONSTANT.BASE_URL}${api}`, this.getParams()).subscribe(
            (res: any) => {
                this.saveFile(res, false);
                this.exportingMissingEmail = false;
            },
            (err: any) => {
                this.exportingMissingEmail = false;
                this.showExportError(err);
            }
        );
    }

    downloadWebUser(): void {
        this.exportingWebUser = true;
        const api = this.CCUser
          ? '/export-cc-user-order-background/'
          : this.etrBuyAdmin
            ? '/export-order-background/'
            : '/export-web-user-order-background/';
        this.request.file.loading = true;
        this.request.file.success = null;
        const params = this.CCUser ? this.getOrderParams() : this.getParams();
        this.api.postFile(`${API_CONSTANT.BASE_URL}${api}`, params).subscribe(
          (res: any) => {
            this.router.navigate(['/export-result']);
            this.exportingWebUser = false;
          },
          (err: any) => {
            this.exportingWebUser = false;
            this.showExportError(err);
          }
        );
      }

    isAdmin() {
        return JSON.parse(localStorage.getItem('isSuperuser'));
    }

    isCCUser() {
      const group = JSON.parse(localStorage.getItem('groups')) as string[];
      return group[0] === 'CCUser';
    }

    isSellerWebUser() {
      const group = JSON.parse(localStorage.getItem('groups')) as string[];
      return group[0] === 'SellerWebUser';
    }

    isEtrBuyAdmin() {
        const username = localStorage.getItem('username');
        return username === 'etr-buy-admin';
    }

    showExportError(err) {
        this.request.file.loading = false;
        this.request.file.success = false;
        if (err.status === 400) {
            this.request.file.error = 'Export error: No data';
        } else {
            this.request.file.error = 'Failed to download';
        }
        this.translateService.get(this.request.file.error).subscribe((res) => {
            this.notification.error(res);
        })
    }

    saveFile(res, csvFile?) {
        this.request.file.loading = false;
        this.request.file.success = true;
        this.request.file.error = null;

        const tz_offset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
        const today = new Date(Date.now() - tz_offset).toISOString();
        const index = today.indexOf('T');
        const blob = new Blob([res], {type: 'application/octet-stream'});
        const fileName = csvFile ?
            `Order-Export_${today.substring(0, index)}.csv` :
            `Order-Export_${today.substring(0, index)}.xlsx`;
        this.downloadSave(blob, fileName);
    }

    downloadSave(blob, fileName) {
        if (window.navigator.msSaveOrOpenBlob) { // For IE:
            navigator.msSaveBlob(blob, fileName);
        } else { // For other browsers:
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
        }
    }

    getDateNTime(date, time, type) {
        if (!date) {
            return '';
        }
        !time ? type === 'end' ? (time = {hour: 23, minute: 59}) : (time = {hour: 0, minute: 0}) : {};
        time.second = 0;
        const dateStr = _.values(date).map(x => +x < 10 ? '0' + x : x).join('-');
        const timeStr = _.values(time).join(':');
        return dateStr + 'T' + timeStr;
    }

    onUserNameFiltering(event) {
        this.filterUserName = []
        const searchingName = event.query.toLowerCase();
        this.filterUserName = this.originalUsernames.filter(x => x.toLowerCase().indexOf(searchingName) >= 0);
        this.filterUserName.sort();
    }

    onUserNameUnselectValue(event) {
        const index = this.search['username'].indexOf(event);
        index !== -1 ? this.search['username'].splice(index, 1) : {};
    }

    getUserList() {
        this.userService.getAllUsers({'getAllUsersAndShops': 'true'}).subscribe(
            (res: any) => {
                res.all_users.forEach(element => {
                    this.originalUsernames.indexOf(element) === -1 && element != null ? this.originalUsernames.push(element) : {};
                });
            }, (err) => {
                this.showError(err);
            }
        );
    }

    sameStartDateAndEndDate(): boolean {
        return (
          this.search['startDate'] &&
          this.search['endDate'] &&
          this.search['endTime'].minute !== 59 &&
          this.search['startDate'].year === this.search['endDate'].year &&
          this.search['startDate'].month === this.search['endDate'].month &&
          this.search['startDate'].day === this.search['endDate'].day &&
          this.search['startTime'].hour === this.search['endTime'].hour &&
          this.search['startTime'].minute === this.search['endTime'].minute
        );
      }

    getOrderItemParams(): HttpParams {
        let params = new HttpParams();
        params = params
          .set('delivery_status', this.search['deliveryStatus'].toString() || '')
          .set('is_mobile', 'true')
          .set('pagination', 'true')
          .set('page', this.orderItemCurrentPage.toString())
          .set('username', this.search['username'] || '')
          .set('dealer', this.search['dealer'] || '')
          .set('product_name', this.search['productName'] || '')
          .set('product_code', this.search['productCode'] || '')
          .set('order_number', this.search['orderID'] || '')
          .set('category_id', this.search['category'] || '')
          .set('shop', this.search['shop'] || '')
          .set(
            'date_range',
            this.getDateNTime(
              this.search['startDate'],
              this.search['startTime'],
              'start'
            ) +
              ',' +
              this.getDateNTime(
                this.search['endDate'],
                this.search['endTime'],
                'end'
              )
          );
        return params;
    }

    getOrderParams(): HttpParams {
        if (this.sameStartDateAndEndDate()) {
          this.search['endTime'].minute += 1;
        }
        let params = new HttpParams();
        params = params
          .set('web', 'true')
          .set('page', this.currentPage.toString())
          .set('username', this.search['username'] || '')
          .set('dealer', this.search['dealer'] || '')
          .set('shop', this.search['shop'] || '')
          .set('order_number', this.search['orderID'] || '')
          .set('category_id', this.search['category'] || '')
          .set('product_name', this.search['productName'] || '')
          .set('product_code', this.search['productCode'] || '')
          .set(
            'date_range',
            this.getDateNTime(
              this.search['startDate'],
              this.search['startTime'],
              'start'
            ) +
              ',' +
              this.getDateNTime(
                this.search['endDate'],
                this.search['endTime'],
                'end'
              )
          );
        this.search['selected_only']
          ? (params = params.set('selected_only', this.search['selected_only']))
          : {};
        return params;
      }

    getParams() {
        let params = new HttpParams();
        params = params
            .set('web', 'true')
            .set('page', this.currentPage.toString())
            .set('username', this.search['username'] || '')
            .set('dealer', this.search['dealer'] || '')
            .set('shop', this.search['shop'] || '')
            .set('order_number', this.search['orderID'] || '')
            .set('category_id', this.search['category'] || '')
            .set('product_name', this.search['productName'] || '')
            .set('product_code', this.search['productCode'] || '')
            .set('start_date', this.getDateNTime(this.search['startDate'], this.search['startTime'], 'start'))
            .set('end_date', this.getDateNTime(this.search['endDate'], this.search['endTime'], 'end'));
        this.search['selected_only'] ? params = params.set('selected_only', this.search['selected_only']) : {};
        return params;
    }

    getDealers() {
        this.loadingDealer = true;
        this.orderHistoryService.getDealersForOrderHistory().subscribe(
          (res: any) => {
            this.dealers = res.results;
            this.loadingDealer = false;
          },
          (err) => {
            this.showError(err);
            this.loadingDealer = false;
          }
        );
      }

    getShops() {
        this.search['shop'] = []
        this.loadingShop = true;
        this.orderHistoryService
          .getShopsForOrderHistory({ dealer_name: this.search['dealer'] || '' })
          .subscribe(
            (res: any) => {
              this.shops = res.results;
              this.loadingShop = false;
            },
            (err) => {
              this.showError(err);
              this.loadingShop = false;
            }
          );
      }

    changeSelectedOnly() {
        this.search['selected_only'] = !this.search['selected_only']
    }

    openPopup(view, productType: number): void {
        this.selectedProductType = productType;
        this.modalService.open(view);
    }

    dismiss(): void {
        this.modalService.dismissAll();
    }

    selectedAll(): void {
        this.orderItems.forEach((item) => {
            item.is_selected = !this.selectedAllCheck;
        })
        this.selectedAllCheck = !this.selectedAllCheck;
    }

    searchOrder(): void {
        this.getOrderHistoryList();
        this.getOrderItemHistoryList();
    }

    checkSelect(): void {
       this.selectedAllCheck = this.orderItems.every((item) => {
            return item.is_selected;
        }
        );
    }

    changeProductType(): void {
        const selectItem = this.orderItems.filter((item) => {
          return item.is_selected;
        });
        if (!selectItem.length) {
          this.dismiss();
          this.notification.error('Please select at least one item');
          return;
        }
        const data = {
          order_items: selectItem.map((item) => {
            return item.id;
          }),
          product_type: this.selectedProductType,
        };
        this.orderHistoryService.updateProductType(data).subscribe(
          (res: any) => {
            this.dismiss();
            this.getOrderItemHistoryList();
            this.showChangeProductType = false;
          },
          (err) => {
            this.dismiss();
            this.showError(err);
          }
        );
    }

    showOrderItemDetail(orderItem): void {
        this.showingDetail = true;
        this.orderDetail = {
          puchese_id: orderItem.order_number,
          created_at: orderItem.created_at,
          id: orderItem.order_id,
          order_snapshot: orderItem.order_snapshot,
          transportation_fee: orderItem.transportation_fee,
        };
        this.search['selected_only']
          ? (this.searchParams = {
              category: this.search['category'] || '',
              productName: this.search['productName'] || '',
              productCode: this.search['productCode'] || '',
            })
          : {};
    }

    getLang() {
        return this.translateService.currentLang.toString();
    }

    openModal(view) {
        const option: NgbModalOptions = {
          backdrop : 'static',
          keyboard : false
        };
        this.modalService.open(view, option).result.then((result) => {}, (reason) => {
          if (reason === ModalDismissReasons.ESC ||
            reason === ModalDismissReasons.BACKDROP_CLICK) {
            this.dismissFileSelection();
          }
        });
    }

    dismissFileSelection () {
        this.fileToUpload = null;
        this.modalService.dismissAll();
    }

    uploadFile(): void {
        const data = new FormData();
        data.append('file', this.fileToUpload);
        this.loadingImport = true;
        this.orderHistoryService.importOrderDeliveryDate(data).subscribe(
            (res: any) => {
                this.modalService.dismissAll();
                this.notification.success(this.translateService.instant('Successfully Imported'));
                this.router.navigate(['/import-product']);
                this.loadingImport = false;
            },
            (err) => {
                this.showErrorMsg(err);
                this.router.navigate(['/import-product']);
                this.loadingImport = false;
            }
        );
    }

    showErrorMsg(msg): void {
        this.notification.error(_.values(msg.error)[0].toString());
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }
}
