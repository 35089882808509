import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {AuthApiService} from '../services/auth-api.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  errorMessage: string = null;

  constructor(
    private router: Router,
    private authService: AuthApiService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
  }

  userTypes = [
    'Admin',
    'SellerWebUser',
    'MONOTAROWebUser',
    'ENEOSTradingWebUser',
    'ENEOSTradingSubWebUser',
    'BuyerDealerWebUser',
    'BuyerDealerExportWebUser',
    'DailyCheckAdmin',
    'ShopManager',
    'DealerAdmin',
    'CCUser',
    'ShopManager',
    'ShopAdminSingleDealer',
    'ShopAdminMultipleDealer',
    'SellerWebUserByDealer',
    'SupervisorDealerManager',
  ]

  ngOnInit() {
    localStorage.clear();
    this.activatedRoute.params.subscribe(params => {
      this.errorMessage = params.msg || null;
    });
  }

  login() {
    this.authService.login(this.username, this.password).subscribe(
      (res: any) => {
        localStorage.jwtToken = res.token;
        if (this.userTypes.includes(res.groups[0])) {
          localStorage.userId = res.login_as_user_id;
          res.dealer_id ? localStorage.dealer_id = res.dealer_id : {};
          localStorage.first_name = res.first_name;
          localStorage.last_name = res.last_name;
          localStorage.groups = JSON.stringify(res.groups);
          localStorage.isSuperuser = res.is_superuser;
          localStorage.username = res.login_as_username;
          localStorage.is_grit = res.is_grit;
          localStorage.dealer = res.dealer;
          localStorage.email = res.email;
          localStorage.is_internal_user = res.is_internal_user;
        } else {
          // if user cannot login to website if it is grit user
          this.errorMessage = this.translate.instant(
            'tabletUserCanLoginFromMobileOnly'
          );
          localStorage.clear();
        }

        this.router.navigate(['/']);
      },
      (res: any) => {
        let responseError = res.error.non_field_errors[0]
        if(responseError == 'accountLocked'){
          this.errorMessage = 'accountLocked'; 
        } else{
          this.errorMessage = 'incorrectUsernameOrPassword'; 
        }
        localStorage.clear();
      }
    );
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }
}
