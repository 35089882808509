import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SupervisorComponent} from './supervisor.component';

export const SupervisorRoutes: Routes = [
    {
    path: 'supervisor',
    children: [
      {path: '', component: SupervisorComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(SupervisorRoutes)],
  exports: [RouterModule]
})
export class SupervisorRoutingModule { }
