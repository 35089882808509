import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderApiDisableDateService } from './order-api-disable-date.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalService } from 'src/app/components/confirm-modal/confirm-modal.service';
import { TranslateService } from '@ngx-translate/core';

interface DisabledDate {
  id: string;
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-order-api-disable-date',
  templateUrl: './order-api-disable-date.component.html',
  styleUrls: ['./order-api-disable-date.component.scss'],
})
export class OrderApiDisableDateComponent implements OnInit {
  loadingDisabledDates: boolean = false;
  addNewLoading: boolean = false;
  deleteLoading: boolean = false;
  disabledDates: DisabledDate[] = [];

  currentDate: NgbDateStruct = null;

  addNewProps = {
    startDate: null,
    endDate: null,
    startTime: { hour: 0, minute: 0, second: 0 },
    endTime: { hour: 0, minute: 0, second: 0 },
  };

  constructor(
    private modalService: NgbModal,
    private confirmModalService: ConfirmModalService,
    private orderApiDisabledDataService: OrderApiDisableDateService,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.getCurrentDateTime();
    this.getDisabledDates();
  }

  getDisabledDates() {
    this.loadingDisabledDates = true;
    this.orderApiDisabledDataService.getOrderApiDisableDate().subscribe(
      (res: any) => {
        this.loadingDisabledDates = false;
        this.disabledDates = res.map((item) => ({
          id: item.id,
          startDate: item.start_date,
          endDate: item.end_date,
        }));
      },
      (err) => {
        this.loadingDisabledDates = false;
        this.toastrService.error('error');
      }
    );
  }

  addNewDates(modal) {
    this.modalService.open(modal);
  }

  dismiss(): void {
    this.modalService.dismissAll();
  }

  addNewDatesConfirmed() {
    if (!this.addNewProps.startDate || !this.addNewProps.endDate) {
      this.toastrService.error(this.translate.instant('Please select start date and end date'));
      return;
    }

    const data = {
      start_date: this.convertDate(
        this.addNewProps.startDate,
        this.addNewProps.startTime
      ),
      end_date: this.convertDate(
        this.addNewProps.endDate,
        this.addNewProps.endTime
      ),
    };

    this.addNewLoading = true;
    this.orderApiDisabledDataService.createOrderApiDisableDate(data).subscribe(
      (res) => {
        this.addNewLoading = false;
        this.dismiss();
        this.getDisabledDates();
        this.resetAddNewProps();
      },
      (err) => {
        this.addNewLoading = false;
        this.toastrService.error(err.error[0]);
      }
    );
  }

  resetAddNewProps() {
    this.addNewProps = {
      startDate: null,
      endDate: null,
      startTime: { hour: 0, minute: 0, second: 0 },
      endTime: { hour: 0, minute: 0, second: 0 },
    };
  }

  convertDate({ year, month, day }, { hour, minute, second }): string {
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  getCurrentDateTime(): void {
    const now = new Date();
    const japanDatetimeOptions = { timeZone: 'Asia/Tokyo' };

    const currentDate: NgbDateStruct = {
      year: Number(
        now.toLocaleString('en-US', {
          ...japanDatetimeOptions,
          year: 'numeric',
        })
      ),
      month: Number(
        now.toLocaleString('en-US', {
          ...japanDatetimeOptions,
          month: 'numeric',
        })
      ),
      day: Number(
        now.toLocaleString('en-US', {
          ...japanDatetimeOptions,
          day: 'numeric',
        })
      ),
    };
    this.currentDate = currentDate;
  }

  confirmDelete(id) {
    this.confirmModalService.open(
      'Delete Disabled Date',
      'Are you sure you want to delete this ?',
      () => {
        this.deleteDisabledDate(id)
      }
    );
  }

  deleteDisabledDate(id) {
    this.deleteLoading = true;
    this.orderApiDisabledDataService.deleteOrderApiDisableDate(id).subscribe(
      (res) => {
        this.deleteLoading = false;
        this.disabledDates = this.disabledDates.filter(disabledDate => disabledDate.id !== id);
      },
      (err) => {
        this.deleteLoading = false;
        this.toastrService.error(err.error[0]);
      }
    )
    this.modalService.dismissAll();
  }

  isInvalidToDelete(disabledDate: DisabledDate) {
    const today = new Date((new Date()).toLocaleString('en-US', {timeZone: 'Asia/Tokyo'}));
    const startDate = new Date(disabledDate.startDate);
    const endDate = new Date(disabledDate.endDate);
    return today < endDate && today > startDate;
  }
}
