import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceSimulationComponent } from './maintenance-simulation.component';

export const MaintenanceSimulationRoutes: Routes = [
  {
    path: 'maintenance-simulation',
    children: [{ path: '', component: MaintenanceSimulationComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(MaintenanceSimulationRoutes)],
  exports: [RouterModule],
})
export class MaintenanceSimulationRoutesModule {}
