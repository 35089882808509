import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { SellerWebuserComponent } from './seller-webuser.component';


export const sellerWebUserRoutes: Routes = [
  {
    path: 'seller-webuser',
    children: [
      {path: '', component: SellerWebuserComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(sellerWebUserRoutes)],
  exports: [RouterModule]
})
export class SellerWebUserRoutingModule {
}
