import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANT } from '../../services/api.constants';
import { HttpClientService } from '../../services/http-client.service';
import { Dealer } from '../dealer/dealer.model';
import {
  BuyerDealerWebUser,
  ExportUserDealersAndShop,
} from './buyer-dealer-web-user.model';

@Injectable({
  providedIn: 'root',
})
export class BuyerDealerWebUserService {
  private readonly getExportUserDealersAndShopUrl =
    '/get-export-user-dealers-and-shops/';
  private readonly exportWebUserUrl = '/export-web-users/';
  private readonly buyerDealerWebUsersUrl = '/buyer-dealer-web-users/';
  private readonly importBuyerDealerWebUserUrl =
    '/import-buyer-dealer-web-user/';
  private readonly importExportWebUserUrl = '/import-export-web-user/';
  private readonly impotShopDealerWebUserUrl =
    '/import-shopmanager-and-dealeradmin/';
  private readonly getDealerListExportWebUserUrl =
    '/get-dealer-list-export-web-user/';
  private readonly editShopExportWebUserUrl = '/edit-shop-export-web-user/';
  private readonly shopAndDealerWebUserUrl =
    '/shop-manager-dealer-admin-web-users/';
  private readonly getImportTemplateUrl = '/import-templates/';

  constructor(private api: HttpClientService) {}

  getBuyerDealerWebUsers(params?) {
    return this.api.get(
      API_CONSTANT.BASE_URL + this.buyerDealerWebUsersUrl,
      params
    );
  }

  getExportWebUsers(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + this.exportWebUserUrl, params);
  }

  getShopAndDealerWebUsers(params?) {
    return this.api.get(
      API_CONSTANT.BASE_URL + this.shopAndDealerWebUserUrl,
      params
    );
  }

  getExportWebUser(id: number): Observable<BuyerDealerWebUser> {
    return this.api.get<BuyerDealerWebUser>(
      API_CONSTANT.BASE_URL + this.exportWebUserUrl + `${id}/`
    );
  }

  patchBuyerDealerWebUsers(id: number, data: Object) {
    return this.api.patch(
      API_CONSTANT.BASE_URL + this.buyerDealerWebUsersUrl + `${id}/`,
      data
    );
  }

  patchExportWebUser(id: number, data: Object) {
    return this.api.patch(
      API_CONSTANT.BASE_URL + this.exportWebUserUrl + `${id}/`,
      data
    );
  }

  patchShopAndDealerWebUser(id: number, data: Object) {
    return this.api.patch(
      API_CONSTANT.BASE_URL + this.shopAndDealerWebUserUrl + `${id}/`,
      data
    );
  }

  deleteBuyerDealerWebUsers(id: number) {
    return this.api.delete(
      API_CONSTANT.BASE_URL + this.buyerDealerWebUsersUrl + `${id}/`
    );
  }

  deleteExportWebUser(id: number) {
    return this.api.delete(
      API_CONSTANT.BASE_URL + this.exportWebUserUrl + `${id}/`
    );
  }

  deleteShopAndDealerWebUser(id: number) {
    return this.api.delete(
      API_CONSTANT.BASE_URL + this.shopAndDealerWebUserUrl + `${id}/`
    );
  }

  importBuyerDealerWebUser(data) {
    return this.api.post(
      API_CONSTANT.BASE_URL + this.importBuyerDealerWebUserUrl,
      data
    );
  }

  importExportWebUser(data) {
    return this.api.post(
      API_CONSTANT.BASE_URL + this.importExportWebUserUrl,
      data
    );
  }

  importShopDealerWebUser(data) {
    return this.api.post(
      API_CONSTANT.BASE_URL + this.impotShopDealerWebUserUrl,
      data
    );
  }

  getExportUserDealersAndShops(
    params?
  ): Observable<ExportUserDealersAndShop[]> {
    return this.api.get<ExportUserDealersAndShop[]>(
      API_CONSTANT.BASE_URL + this.getExportUserDealersAndShopUrl,
      params
    );
  }

  getDealerListExportWebUser(params?): Observable<Dealer[]> {
    return this.api.get<Dealer[]>(
      API_CONSTANT.BASE_URL + this.getDealerListExportWebUserUrl,
      params
    );
  }

  editShopExportWebUser(data) {
    return this.api.post(
      API_CONSTANT.BASE_URL + this.editShopExportWebUserUrl,
      data
    );
  }

  getImportTemplate(params: Object): Observable<ArrayBuffer> {
    return this.api.postFile(
      API_CONSTANT.BASE_URL + this.getImportTemplateUrl,
      params
    );
  }
}
