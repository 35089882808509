import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { importProductRoutes } from '../features/import-product/import-product-routing.module';
import { ShopActualStockRoutes } from '../features/shop-actual-stock/shop-actual-stock-routing.module';
import { CategoryRoutes } from '../features/category/category-routing.module';
import { SellerRoutes } from '../features/seller/seller-routing.module';
import { UserRoutes } from '../features/user/user-routing.module';
import { DealerRoutes } from '../features/dealer/dealer-routing.module';
import { orderHistoryRoutes} from '../features/order-history/order-history-routing.module';
import { ProductMasterRoutes } from '../features/product-master/product-master-routing.module';
import {SupervisorRoutes} from '../features/supervisor/supervisor-routing.module';
import {ProductImageRoutes} from '../features/product-image/product-image-routing.module';
import { sellerWebUserRoutes } from '../features/seller-webuser/seller-webuser-routing.module';
import { AnnouncementRoutes } from '../features/announcement/announcement-routing.module';
import { CarSpecificationRoutes } from '../features/car-specification/car-specification-routing.module';
import { monotaroRoutes } from '../features/monotaro/monotaro-routing.module';
import { exportResultRoutes } from '../features/export-result/export-result-routing.module';
import { ImportUpdateHistoryRoutes } from '../features/import-update-history/import-update-history-routing.module';
import {settingRoutes} from '../features/settings/settings-routing.module';
import { dailyCheckRoutes } from '../features/daily-check-history/daily-check-history-routing.module';
import {BuyerDealerWebUserRoutes} from '../features/buyer-dealer-web-user/buyer-dealer-web-user-routing.module';
import { DailyCheckTabletUser } from '../features/daily-check-tablet-user/daily-check-tablet-user-routing.module';
import { MaintenanceSimulationRoutes } from '../features/maintenance-simulation/maintenance-simulation-routing.module';
import { CalculationHistoryRoutes } from '../features/calculation-history/calculation-history-routing.module';
import { DealerSettingRoutes } from '../features/dealer-setting/dealer-setting-routing.module';
import { orderApiDisableDateRoutes } from '../features/order-api-disable-date/order-api-disable-date-routing.module';
import { SellerEmailRoutes } from '../features/seller-email/seller-email-routing.module';
import { DailyCheckDataManagementRoutes } from '../features/daily-check-data-management/daily-check-data-management-routing.module';

export const mainRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'planning'
  },
  {
    path: '',
    component: MainComponent,
    children: [
      ...importProductRoutes,
      ...ShopActualStockRoutes,
      ...CategoryRoutes,
      ...SellerRoutes,
      ...UserRoutes,
      ...SupervisorRoutes,
      ...ProductMasterRoutes,
      ...ProductImageRoutes,
      ...DealerRoutes,
      ...orderHistoryRoutes,
      ...sellerWebUserRoutes,
      ...AnnouncementRoutes,
      ...CarSpecificationRoutes,
      ...monotaroRoutes,
      ...exportResultRoutes,
      ...ImportUpdateHistoryRoutes,
      ...settingRoutes,
      ...dailyCheckRoutes,
      ...BuyerDealerWebUserRoutes,
      ...DailyCheckTabletUser,
      ...MaintenanceSimulationRoutes,
      ...CalculationHistoryRoutes,
      ...DealerSettingRoutes,
      ...orderApiDisableDateRoutes,
      ...SellerEmailRoutes,
      ...DailyCheckDataManagementRoutes
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(mainRoutes)],
  exports: [RouterModule]
})
export class MainRoutingModule {}
