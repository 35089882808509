import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { API_CONSTANT } from 'src/app/services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  constructor(private api: HttpClientService) {}

  getAnnouncements(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/announcements/', params);
  }

  createAnnouncement(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/announcements/', data);
  }

  updateAnnouncement(id, data) {
    return this.api.patch(API_CONSTANT.BASE_URL + '/announcements/' + id + '/', data);
  }

  deleteAnnouncement(id) {
    return this.api.delete(API_CONSTANT.BASE_URL + '/announcements/' + id + '/');
  }
}
