import {Component, OnDestroy, OnInit} from '@angular/core';
import {FlyerFirstLayerItems, SearchData} from '../daily-check.models';
import {DailyCheckHistoryService} from '../daily-check-history.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {DailyCheckItemsConstants} from '../daily-check-items.constants';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-flyer-distribution',
  templateUrl: './flyer-distribution.component.html',
  styleUrls: ['./flyer-distribution.component.scss']
})
export class FlyerDistributionComponent implements OnInit, OnDestroy {

  parentSearchData: SearchData = null;

  firstLayerItems: FlyerFirstLayerItems[] = [];

  selectedItems: string[] = [];

  currentSortingField: string = '';

  loadingFirstLayerIndex: number | null = null;

  loadingTable: boolean = false;

  param = new Subscription(null);

  constructor(
    private notiService: ToastrService,
    private dailyCheckService: DailyCheckHistoryService,
  ) {
  }

  ngOnInit() {
    this.param = this.dailyCheckService.parentParams.subscribe((event) => {
      this.parentSearchData = event;
      this.getFirstLayer();
      this.changeSelectedItems(event.dailyCheckItems);
    });
  }

  ngOnDestroy(): void {
    this.param.unsubscribe();
  }

  getFirstLayer(): void {
    this.loadingTable = true;
    const param = {
      dealer: this.parentSearchData?.dealer || '',
      shop: this.parentSearchData?.shop || '',
      staff: this.parentSearchData?.staff || '',
      start_date: this.parentSearchData?.start_date || '',
      end_date: this.parentSearchData?.end_date || '',
      type: this.parentSearchData.dailyCheckItems || '',
      sort: this.currentSortingField || '',
    }
    this.dailyCheckService.getFlyerDistributionFirstLayer(param).subscribe(
      (res) => {
        this.firstLayerItems = res;
        this.loadingTable = false;
      },
      (error) => {
        this.loadingTable = false;
        this.showErrorMsg(error);
      }
    )
  }

  getSecondLayer(): void {
    const params = {
      type: this.parentSearchData.dailyCheckItems || '',
      shop: this.firstLayerItems[this.loadingFirstLayerIndex]?.id || '',
      start_date: this.parentSearchData?.start_date || '',
      end_date: this.parentSearchData?.end_date || '',
    }
    this.dailyCheckService.getFlyerDistributionSecondLayer(params).subscribe(
      (res) => {
        this.firstLayerItems[this.loadingFirstLayerIndex].staff = res;
        this.loadingFirstLayerIndex = null;
      },
      (error) => {
        this.loadingFirstLayerIndex = null;
        this.showErrorMsg(error);
      }
    )
  }

  showErrorMsg(error: HttpErrorResponse): void {
    this.notiService.error(error?.error?.message || error?.message  || error);
  }

  toggleFirstLayer(index: number): void {
    this.firstLayerItems[index].showChild = !this.firstLayerItems[index].showChild;
    this.loadingFirstLayerIndex = index;
    this.getSecondLayer();
  }

  changeSelectedItems(checkItemsInString: string): void {
    this.selectedItems = [];
    checkItemsInString.split(',').forEach(itemIndex => {
      this.selectedItems.push(DailyCheckItemsConstants[+itemIndex - 1]);
    });
  }

  setSortingField(sortingField: string): void {
    if (this.currentSortingField.replace('-', '') === sortingField) {
      this.currentSortingField = this.currentSortingField.includes('-') ? sortingField : ('-' + sortingField);
    } else {
      this.currentSortingField = sortingField;
    }
    this.getFirstLayer();
  }
}
