import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreModule } from '../../../app/components/core/core.module';
import { SharedModule } from '../../../app/shared.module';
import { DealerSettingRoutesModule } from './dealer-setting-routing.module';
import { DealerSettingComponent } from './dealer-setting.component';

@NgModule({
    declarations: [DealerSettingComponent],
    imports: [
        CommonModule,
        DealerSettingRoutesModule,
        CoreModule,
        SharedModule,
        NgSelectModule,
        FormsModule
    ]
})
export class DealerSettingModule {}