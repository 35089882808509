<app-header title="{{ 'product-master.ProductMaster' | translate }}">
</app-header>
<div class="row w-100 p-3 px-4 m-0 d-flex justify-content-start">
  <div class="w-15percent row p-0 m-0 align-self-start">
    <label class="row w-100 m-0 p-0 pl-1">
      {{ "product-set.ProductSet:" | translate }}
    </label>
    <p-autoComplete class="row w-100 m-0 mr-3"
                    [(ngModel)]="productset_names"
                    [multiple]="true"
                    [suggestions]="productsetNameList"
                    [field]=""
                    [emptyMessage]="auto_emptymsg"
                    [placeholder]="'product-set.SearchProductSet' | translate"
                    [forceSelection]="true"
                    (completeMethod)="onProductsetFiltering($event)"
                    (onUnselect)="onProductsetUnselectValue($event)"

    >
      <ng-template let-value pTemplate="selectedItem">
        <span class="ui-autocomplete-token-label">{{ value }}</span>
      </ng-template>
    </p-autoComplete>
  </div>

  <div class="w-85percent d-flex align-items-start justify-content-between">
    <div class="d-flex align-items-start">
      <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center"
              (click)="loadProductSets()"
      >
        <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
        {{'search' | translate}}
      </button>
      <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center" (click)="reset()"
      >
        <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
        {{'reset' | translate}}
      </button>
    </div>
    <div class="d-flex align-items-start">
      <button class="btn mt-4 mr-2 btn-sm btn-outline-primary d-flex align-items-center" (click)="openStockModal(updateStockModal)"
      >
        <i class="fas fa-cube mr-2" style="font-size: 16px;"></i>
        {{'product-import.updateStock' | translate}}
      </button>
      <button class="btn mt-4 mr-2 btn-sm btn-primary d-flex align-items-center" (click)="addProductSet(editOrCreate)"
      >
        <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
        {{'product-set.CreateProductSet' | translate}}
      </button>
    </div>
  </div>
</div>


<div class="row mb-4 mx-4">
  <table
    class="table table-hover text-center table-borderless table-striped"
    style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
  >
    <thead>
    <tr>
      <th width="10%">
        {{ "product-set.ProductSet" | translate }}
      </th>
      <th width="18%">
        {{ "product-set.CategoryNumber" | translate }}
      </th>
      <th width="18%">
        {{ "category.seller" | translate }}
      </th>
      <th width="10%">
        {{ "Price set" | translate }}
      </th>
      <th width="5%">
        {{ "user.edit" | translate }}
      </th>
      <th width="5%">
        {{ "Delete" | translate }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of dataList; let i = index">
      <td class="align-middle">{{ item.name }}</td>
      <td class="align-middle" style="text-align: left">
        <span *ngFor="let category of item.category_data">
          {{category.id}}
        </span>
      </td>
      <td class="align-middle">
        <li *ngFor="let seller of item.seller_name">
          {{seller}}
        </li>
      </td>
      <td class="align-middle" style="text-align: left">
        <li *ngFor="let packageset of item.packageset_data">
          {{packageset.name}}
        </li>
      </td>
      <td class="align-middle">
        <img src="assets/svgs/edit_pen.svg" height="28" width="auto" (click)="edit(item, i)"
        >
      </td>

      <td class="align-middle">
        <img src="assets/svgs/trash_bin.svg" height="28" width="auto" (click)="openDelete(confirmDelete, item)"
        >
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'category.deleteConfirmation' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'category.areYouSure' | translate }}</p>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ 'category.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.close('Ok click')"
      >
        {{ 'category.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editOrCreate>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0" *ngIf="type=='add'"> {{ "product-set.CreateProductSet" | translate}}</h4>
      <h4 class="m-0" *ngIf="type=='edit'"> {{ 'category.editCategory' | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-2">
      <div class="row p-0 m-0 mt-4">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{"product-master.productSetName" | translate}}</label>
        <input type="text" class="col-8 form-control" [(ngModel)]="productsetName"
               [placeholder]="'product-set.EnterProductSet' | translate">
      </div>
      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{"product-import.category" | translate}}</label>
        <input type="text" class="col-8 form-control" *ngIf="type=='edit'" readonly>
        <ng-select
          *ngIf="type=='add'"
          class="col-8 w-100 p-0"
          [clearable]="false"
          bindLabel="number_and_name"
          bindValue="id"
          [multiple]="true"
          [items]="categories"
          [(ngModel)]="categoryID"
          [placeholder]="'product-set.selectCategory' | translate"
          [notFoundText]="'notFound' | translate"
        ></ng-select>
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="submit()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #updateStockModal let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>{{ 'product-import.selectExcel' | translate }}</label>
      <input
        style="margin-right: 30px;"
        class="file-input"
        type="file"
        name="the_file"
        (change)="onStockFileInputChange($event)"
      />
    </div>

    <div *ngIf="stockErrorMessage" class="text-danger">
      {{ stockErrorMessage }}
    </div>

    <div *ngIf="stockErrorList">
      {{ 'product-import.importError' | translate }}: <br/>
      <div *ngFor="let trans_stockErrorList of translateMessage(stockErrorList)">
        {{ trans_stockErrorList }}
      </div>
    </div>

    <div *ngIf="stockImportSuccessful" class="text-success">
      {{ 'product-import.importSuccessful' | translate }}.
    </div>
  </div>

  <div class="modal-footer">
    <button
      style="margin-left:5px"
      class="btn btn-outline-primary"
      (click)="importStock()"
    >
      <i *ngIf="stockImporting === false" class="fa fa-download"></i>
      <i *ngIf="stockImporting === true" class="fa fa-spinner fa-spin"></i>
      {{ 'Import' | translate }}
    </button>
  </div>
</ng-template>
