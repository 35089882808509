import { ActivatedRoute } from '@angular/router';
import { Pagination } from './pagination.interface';

/** Set Current page using Activated route to the component
 *  Becareful that Callback has to be arrow function too
 *
 * Get Current page update component.page value
 */
const getCurrentPage = (
  component: Pagination,
  callback?: (page?: number) => any
) => {
  component.activatedRoute.params.subscribe(params => {
    if (params.page) {
      try {
        component.page = parseInt(params.page, 10);
      } catch (e) {
        component.page = 1;
      }
    }
    if (callback) {
      callback(params.page);
    }
  });
};

export { getCurrentPage };
