import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditOrCreateComponent } from './edit-or-create/edit-or-create.component';
import { UserComponent } from './user.component';

export const UserRoutes: Routes = [
  {
    path: 'user',
    children: [
      { path: ':page', component: UserComponent },
      { path: '', component: UserComponent },
      { path: 'edit/:id/', component: EditOrCreateComponent},
      { path: 'add/', component: EditOrCreateComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(UserRoutes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}
