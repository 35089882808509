<div class="container">
  <img class="mx-auto" src="../../assets/images/logo.png" alt="logo" />
  <div class="center">
    <span>{{ 'register.success' | translate }}</span>
    <button class="btn btn-primary rounded-pill " [routerLink]="['/login']">
      {{ 'login.login' | translate }}
    </button>
  </div>
  <div class="change-language">
    <button
      (click)="useLanguage('en')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'en' }"
    >
      English
    </button>
    |
    <button
      (click)="useLanguage('ja')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'ja' }"
    >
      日本語
    </button>
  </div>
</div>
