<app-header [title]=" 'Dealer Setting' | translate"></app-header>
<div class="content">
  <div class="w-100percent p-3 px-4">
    <div class="d-flex justify-content-between m-t-20 m-b-15">
      <div class="row no-gutter w-40percent">
        <label class="col-4 m-0 p-l-0 align-self-center">{{ 'Company' | translate }}</label>
        <ng-select class="p-0 col-8"
            [notFoundText]="'notFound' | translate"
            [placeholder]=" 'Company' | translate "
            [loading]="loadingCompany"
            [(ngModel)]="selectCompanyName"
            [items]="companyList"
            bindLabel="name"
            bindValue="name"></ng-select>
      </div>
      <div class="row no-gutter d-flex align-items-center">
        <button class="btn btn-sm d-flex align-items-center btn-primary h-30"
            (click)="getDealerSettingData()">
          <img src="assets/svgs/search_len.svg"
              height="20"
              alt="search lens icon"
              class="mr-1"
              width="auto">
          {{'Search' | translate}}
        </button>
        <button class="btn btn-sm d-flex align-items-center btn-secondary h-30 ml-3"
            (click)="resetSearch()">
          <img src="assets/svgs/reset_icon.svg"
              height="20"
              alt="reset icon"
              class="mr-1"
              width="auto">
          {{'Reset' | translate}}
        </button>
        <button class="btn btn-sm d-flex align-items-center btn-outline-primary h-30 ml-3 hover-custom"
            (click)='openImportModal(importModal)'>
          <div class="custom-img mr-2"></div>
          {{'Import' | translate}}
        </button>
      </div>
    </div>
    <app-loading *ngIf="loading"></app-loading>
    <div class="overflow-x-auto"
        *ngIf="!loading && dealerSettingData.length">
      <table class="table table-striped w-100percent">
        <thead>
          <tr>
            <th class="col-4">{{ 'Dealer (Company)' | translate }}</th>
            <th class="col-2 text-center">{{ 'Purchasing Function' | translate }}</th>
            <th class="col-2 text-center">{{ 'MONOTARO Button' | translate }}</th>
            <th class="col-2 text-center">{{ 'ETR Sugkik Button' | translate }}</th>
            <th class="col-2 text-center">{{ 'Daily Check Function' | translate }}</th>
            <th class="col-2 text-center">{{ 'edit' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dealers of dealerSettingData">
            <td>
              <span *ngFor="let item of dealers.dealers">
                {{item.full_name}} <br>
              </span>
            </td>
            <td class="text-center">
              <img src="assets/svgs/blue_circle_tick.svg"
                  *ngIf="dealers.dealers[0].is_purchasing_function"
                  height="25"
                  alt="reset icon"
                  class="mr-1"
                  width="auto">
            </td>
            <td class="text-center">
              <img src="assets/svgs/blue_circle_tick.svg"
                  *ngIf="dealers.dealers[0].is_monotaro_enabled"
                  height="25"
                  alt="reset icon"
                  class="mr-1"
                  width="auto">
            </td>
            <td class="text-center">
              <img src="assets/svgs/blue_circle_tick.svg"
                  *ngIf="dealers.dealers[0].is_etr_sugkik"
                  height="25"
                  alt="reset icon"
                  class="mr-1"
                  width="auto">
            </td>
            <td class="text-center">
              <img src="assets/svgs/blue_circle_tick.svg"
                  *ngIf="dealers.dealers[0].is_daily_check_dealer"
                  height="25"
                  alt="reset icon"
                  class="mr-1"
                  width="auto">
            </td>
            <td class="text-center">
              <img src='assets/svgs/edit_pen.svg'
                  width='30'
                  height='30'
                  class="mr-1"
                  (click)="openEditModal(editModal, dealers)">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #importModal>
  <div class="modal-header">
    <h4 class="m-0">{{'Import' | translate }}</h4>
  </div>
  <div class="modal-body">
    <input type="file"
        id="file"
        class="row no-gutter"
        (change)="handleFileInput($event.target.files)">
    <ng-select [clearable]="false"
        class="row no-gutter mt-2"
        [items]="dealerCustomerType"
        bindLabel="name"
        bindValue="value"
        [(ngModel)]="dealerType"
        [placeholder]="'shop.customerType' | translate"
        [notFoundText]="'notFound' | translate">
      <ng-template ng-label-tmp
          let-item="item">
        <span class="d-flex align-items-center">
          <span class="square"
              [ngClass]="item.name"></span>
          {{ getCurrentLanguage() === 'en' ? item.name : item.jp_name }}
        </span>
      </ng-template>
      <ng-template ng-option-tmp
          let-item="item"
          let-search="searchTerm"
          let-index="index">
        <span class="d-flex align-items-center">
          <span class="square"
              [ngClass]="item.name"></span>
          {{ getCurrentLanguage() === 'en' ? item.name : item.jp_name }}
        </span>
      </ng-template>
    </ng-select>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary"
        (click)="close()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button"
        class="btn btn-outline-primary"
        [disabled]="importing || !this.file"
        (click)="uploadFile()">
      {{ 'Upload' | translate }}
      <i class="fa fa-spinner fa-spin"
          *ngIf="importing"></i>
    </button>
  </div>
</ng-template>

<ng-template #editModal>
  <div class="modal-header">
    <h4 class="m-0">{{'edit' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="row no-gutter p-y-10">
      <div class="col-4 d-flex align-items-center">{{ 'Dealers' | translate }}</div>
      <div class="col-8">
        <span *ngFor="let item of editingData.name_list">
          {{ item }} <br>
        </span>
      </div>
    </div>
    <div class="row no-gutter p-y-10">
      <div class="col-12">
        <span class="font-bold">{{'Tablet Functions Setting' | translate}}</span>
      </div>
    </div>
    <div class="row no-gutter p-y-10">
      <div class="p-l-15 d-flex align-items-center">
        <input type="checkbox"
            class="h-20 w-20"
            [(ngModel)]="editingData.is_purchasing_function"
            [checked]="editingData.is_purchasing_function">
      </div>
      <div class="p-x-15">{{'Purchasing Function' | translate}}</div>
    </div>
    <div class="row no-gutter p-y-10">
      <div class="p-l-15 d-flex align-items-center">
        <input type="checkbox"
            class="h-20 w-20"
            [(ngModel)]="editingData.is_monotaro_enabled"
            [checked]="editingData.is_monotaro_enabled">
      </div>
      <div class="p-x-15">{{'MONOTARO Button' | translate}}</div>
    </div>
    <div class="row no-gutter p-y-10">
      <div class="p-l-15 d-flex align-items-center">
        <input type="checkbox"
            class="h-20 w-20"
            [(ngModel)]="editingData.is_etr_sugkik"
            [checked]="editingData.is_etr_sugkik">
      </div>
      <div class="p-x-15">{{'ETR Sugkik Button' | translate}}</div>
    </div>
    <div class="row no-gutter p-y-10">
      <div class="p-l-15 d-flex align-items-center">
        <input type="checkbox"
            class="h-20 w-20"
            [(ngModel)]="editingData.is_daily_check_dealer"
            [checked]="editingData.is_daily_check_dealer">
      </div>
      <div class="p-x-15">{{'Daily Check Function' | translate}}</div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary"
        (click)="close()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button"
        class="btn btn-outline-primary"
        (click)="saveEditData()"
        [disabled]="saving">
      {{ 'Save' | translate }}
      <i class="fa fa-spinner fa-spin"
          *ngIf="saving"></i>
    </button>
  </div>
</ng-template>
