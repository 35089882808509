import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONSTANT } from 'src/app/services/api.constants';
import { HttpClientService } from 'src/app/services/http-client.service';
import { DailyCheckDataManagementResponse, RequestParams } from './daily-check-data-management.models';

@Injectable({
  providedIn: 'root'
})
export class DailyCheckDataManagementService {

  constructor(private api: HttpClientService) { }

  getSummaryByBranch(params?: RequestParams): Observable<DailyCheckDataManagementResponse> {
    return this.api.get(API_CONSTANT.BASE_URL + '/daily-check-data-management/', params);
  }

  getSummaryByShop(params?: RequestParams): Observable<DailyCheckDataManagementResponse> {
    return this.api.get(API_CONSTANT.BASE_URL + '/daily-check-data-management/shop/', params);
  }

  getSummaryByStaff(params?: RequestParams): Observable<DailyCheckDataManagementResponse> {
    return this.api.get(API_CONSTANT.BASE_URL + '/daily-check-data-management/staff/', params);
  }

  getActivityRankingByShop(params?: RequestParams): Observable<DailyCheckDataManagementResponse> {
    return this.api.get(API_CONSTANT.BASE_URL + '/daily-check-data-management/shop_ranking/', params);
  }

  getActivityRankingByStaff(params?: RequestParams): Observable<DailyCheckDataManagementResponse> {
    return this.api.get(API_CONSTANT.BASE_URL + '/daily-check-data-management/staff_ranking/', params);
  }
  exportTable(params: Object, tableType: number): Observable<ArrayBuffer> {
    const apiStrMap = {
      0: '/export-daily-check-data-management/',
      1: '/export-daily-check-data-management/shop/',
      2: '/export-daily-check-data-management/staff/',
      3: '/export-daily-check-data-management/shop_ranking/',
      4: '/export-daily-check-data-management/staff_ranking'
    }
    return this.api.postFile(API_CONSTANT.BASE_URL + apiStrMap[tableType], params);
  }
}
