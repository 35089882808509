import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import {ImportProductComponent} from "./import-product.component";


export const importProductRoutes: Routes = [
  {
    path: 'import-product',
    children: [
      {path: '', component: ImportProductComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(importProductRoutes)],
  exports: [RouterModule]
})
export class ImportProductRoutingModule {
}
