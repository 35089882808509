import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchComponent } from './branch.component';
import { CoreModule } from 'src/app/components/core/core.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDatepickerModule,
  NgbPaginationModule
} from '@ng-bootstrap/ng-bootstrap';
import { BranchService } from './branch.service';
import { SharedModule } from 'src/app/shared.module';

@NgModule({
  declarations: [BranchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    CoreModule,
    SharedModule
  ],
  providers: [BranchService]
})
export class NewBranchCodeModule {}
