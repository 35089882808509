import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/http-client.service';
import { API_CONSTANT } from '../../services/api.constants';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { PaginatedResult } from 'src/app/components/pagination/pagination.interface';

@Component({
  selector: 'ios_deploy',
  templateUrl: './ios_deploy.component.html',
  styleUrls: ['./ios_deploy.component.scss'],
})
export class Ios_deployComponent implements OnInit {
  public loading = false;
  dataETRlength = 0;
  dataETRMobilelength = 0;
  dataETRlist = [];
  dataETRMobilelist = [];
  error_message: string = '';
  error_list: Array<string> = null;
  ETRLink: string;
  ETRMobileLink: string;

  constructor(
    private api: HttpClientService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loadDownloadLinks();
    this.loadETRData();
    this.loadETRMobileData();
  }

  open(link: string) {
    window.open(link, '_blank');
  }

  loadDownloadLinks() {
    forkJoin([
      this.api.get<any>(API_CONSTANT.BASE_URL + '/download-etr-app/'),
      this.api.get<any>(API_CONSTANT.BASE_URL + '/download-iphone-app/'),
    ]).subscribe(([etrLink, etrMobileLink]) => {
      this.ETRLink = etrLink.app_url;
      this.ETRMobileLink = etrMobileLink.app_url;
    });
  }

  loadETRData() {
    let data = {
      app_type: 2,
    };
    this.loading = true;
    this.api.get(API_CONSTANT.BASE_URL + '/ios_deploy/', data).subscribe(
      (res: any) => {
        this.dataETRlist = res.results;
        this.dataETRlength = res.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  loadETRMobileData() {
    let data = {
      app_type: 0,
    };
    this.loading = true;
    this.api.get(API_CONSTANT.BASE_URL + '/ios_deploy/', data).subscribe(
      (res: any) => {
        this.dataETRMobilelist = res.results;
        this.dataETRMobilelength = res.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  useLanguage(language: string) {
    this.translate.use(language);
    // console.log(this.translate.currentLang);
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }
}
