import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '../../components/core/core.module';
import {ShopActualStockComponent} from './shop-actual-stock.component';
import {ShopActualStockRoutingModule} from './shop-actual-stock-routing.module';
import { SharedModule } from 'src/app/shared.module';


@NgModule({
  declarations: [
    ShopActualStockComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbModule,
        NgbDatepickerModule,
        NgbPaginationModule,
        ShopActualStockRoutingModule,
        CoreModule,
        SharedModule,
    ],
  providers: [
  ]
})
export class ShopActualStockModule { }
