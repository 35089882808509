<app-header [title]="'Import Update History' | translate">
</app-header>
<div class="row p-0 m-3 d-flex justify-content-end">
  <button class="btn btn-primary d-flex align-items-center mr-2" *ngIf="admin" (click)='openModal(importModal)'
    >
    <img src="assets/svgs/plus_icon.svg" height="28" class="mr-1" width="auto">
    {{ 'Import' | translate }}
  </button>
  <button class="btn btn-secondary d-flex align-items-center" (click)='export()'
    >
    <img src="assets/svgs/white_export.svg" height="22" class="mr-1" width="auto">
    {{ 'Export' | translate }}
  </button>
</div>
<app-loading style="position: center;vertical-align: middle; text-align: center;" *ngIf="loading"></app-loading>
<app-list-empty *ngIf="!loading && !dataList.length" title="{{ 'noDataFound' | translate }}"></app-list-empty>
<div class="row mb-4 mx-3">
  <table class="table table-hover text-center table-striped" style="border-radius: 5px;" *ngIf="!loading && dataList.length">
    <thead class="thead-light">
      <tr>
        <th>{{ 'Imported Date' | translate }}</th>
        <th>{{ 'Category' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of dataList; let rowIndex = index">
        <td class="align-middle">{{ data.created_at | date: 'yyyy-MM-dd' }}</td>
        <td class='align-middle'>{{ data.category }}</td>
      </tr>
    </tbody>
  </table>
</div>


<ng-template #importModal>
  <div class="modal-header">
    {{'Import Update History' | translate }}
  </div>
  <div class="modal-body">
    <input type="file"
           id="file"
           (change)="handleFileInput($event.target.files)"
           accept=".xls, .xlsx">
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissFileSelection()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="uploadFile()">
      {{ 'Upload' | translate }}
    </button>
  </div>
</ng-template>
