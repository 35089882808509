<div class='main-content'>
<app-header [title]="'Car Specification' | translate"></app-header>

<div class="row w-100 p-3 px-4 m-0 d-flex justify-content-end">
  <div class="w-25percent row p-0 m-0 align-self-start">
    <label class="row w-100 m-0 p-0 pl-1">
      {{ 'Car Maker' | translate }}:
    </label>
    <div class="row w-100 p-0 m-0 pr-3">
      <ng-select
        class="w-100 p-0"
        [clearable]="false"
        [items]="originalMakerNames"
        bindLabel="brand__name"
        bindValue="brand__name"
        [(ngModel)]="search['maker']"
        [placeholder]="'Select Maker' | translate"
        [notFoundText]="'notFound' | translate"
      ></ng-select>
    </div>
  </div>

  <div class="w-25percent row p-0 m-0 align-self-start">
    <label class="row w-100 m-0 p-0 pl-1">
      {{ 'Car Model' |translate}}:
    </label>
    <div class="row w-100 p-0 m-0 pr-3">
      <ng-select
        class="w-100 p-0"
        [clearable]="false"
        [items]="originalModelNames"
        bindLabel="name"
        bindValue="name"
        [(ngModel)]="search['model']"
        [placeholder]="'Select Model' | translate"
        [notFoundText]="'notFound' | translate"
      ></ng-select>
    </div>
  </div>

  <div class="w-15percent row p-0 m-0 align-self-start">
    <label class="row w-100 m-0 p-0 pl-1">
      {{ 'Katashiki' |translate}}:
    </label>
    <input type="text" class="row w-100 m-0 mr-3 form-control" [(ngModel)]="search['katashiki']"
      [placeholder]="'Enter Katashiki' | translate">
  </div>

  <div class="w-35percent d-flex align-items-start justify-content-between">
    <div class="d-flex align-items-start">
      <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center"
        (click)="loadCarData()">
      <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
        {{'search' | translate}}
      </button>
      <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center"
        (click)="reset()">
      <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
        {{'reset' | translate}}
      </button>
    </div>
    <button class="btn mt-4 mr-2 btn-sm btn-primary d-flex align-items-center"
      (click)="openModal(importModal)">
      <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
      {{'Import Car Specification' | translate}} 
    </button>
  </div>
</div>
<div class='px-3' *ngIf="!loading">
<table class="table table-hover text-center table-borderless table-striped mb-5"
      style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
      *ngIf="!loading && CarSpecifications.length">
      <thead class="thead-light">
        <tr>
          <th>{{'Number' | translate }}</th>
          <th>{{'Maker' | translate }}</th>
          <th>{{'Model' | translate}}</th>
          <th>{{'Katashiki' | translate }}</th>
          <th>{{ 'Delete' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let car of CarSpecifications; let i = index;">
          <td class="align-middle">{{ (i+1) + ((currentPage-1)*40)  }}</td>
          <td class='align-middle'>{{ car.brand_name }}</td>
          <td class='align-middle'>{{ car.name }}</td>
          <td class='align-middle'>{{ car.katashiki }}</td>
          <td class="v-middle" (click)="openDelete(confirmDelete, car.id)">
            <img src="assets/svgs/trash_bin.svg" height="28" width="auto">
          </td>
        </tr>
      </tbody>
    </table>
  <div class='row no-gutter' *ngIf="!loading && this.CarSpecifications.length">
          <ngb-pagination
            [collectionSize]="count"
            [(page)]="currentPage"
            [maxSize]="5"
            [pageSize]="40"
            (pageChange)='navigateTo($event)'
            [boundaryLinks]="true">
          </ngb-pagination>
        </div>
</div>
<div class='row no-gutter d-flex justify-content-center'
       *ngIf="!loading && !this.CarSpecifications.length">
    <h1>{{ 'Cannot Load Data' | translate }}</h1>
  </div>
  <app-loading class="w-100 my-3" *ngIf="loading"></app-loading>
</div>

<ng-template #importModal>
  <div class="modal-header">
    {{'Import Car Specifications' | translate }}
  </div>
  <div class="modal-body">
    <input type="file"
           id="file"
           (change)="handleFileInput($event.target.files)">
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissFileSelection()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="uploadFile()">
      <i *ngIf="loading === true" class="fa fa-spinner fa-spin"></i>
      {{ 'Upload' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'category.deleteConfirmation' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'category.areYouSure' | translate }}</p>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ 'category.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.close('Ok click')"
      >
        {{ 'category.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>
