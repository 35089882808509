import { Component, OnInit, TemplateRef } from '@angular/core';
import { BuyerDealerWebUser } from '../buyer-dealer-web-user/buyer-dealer-web-user.model';
import { ToastrService } from 'ngx-toastr';
import { DealerService } from '../dealer/dealer.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BuyerDealerWebUserService } from '../buyer-dealer-web-user/buyer-dealer-web-user.service';
import { Router } from '@angular/router';
import { DealerNames } from '../dealer/dealer.model';
import {DailyCheckTabletUserService} from "./daily-check-tablet-user.service";
import {DailyCheckTabletUser} from "./daily-check-tablet-user.model";
import {HttpParams} from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-daily-check-tablet-user',
  templateUrl: './daily-check-tablet-user.component.html',
  styleUrls: ['./daily-check-tablet-user.component.scss']
})
export class DailyCheckTabletUserComponent implements OnInit {

  loading: boolean = false;
  dailyCheckTabletUsers: DailyCheckTabletUser[];
  fileToUpload: File = null;

  editingUser: DailyCheckTabletUser | null = null;
  currentPage: Number = 1;
  noOfPage: Number = 1;
  count = 3;
  defaultPassword: string = 'PASSWORD';

  constructor(
    private notification: ToastrService,
    private dealerService: DealerService,
    private modalService: NgbModal,
    private buyerDealerWebUserService: BuyerDealerWebUserService,
    private dailyCheckTabletUserService: DailyCheckTabletUserService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getDealerNames();
    this.getDailyCheckTabletUser();
  }

  getDailyCheckTabletUser() {
    let params = new HttpParams();
    params = params.append('page', this.currentPage.toString());
    this.dailyCheckTabletUserService.getDailyCheckTabletUser(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.dailyCheckTabletUsers = res.results;
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  getDealerNames(): void {
  }

  navigateTo(event) {
    this.currentPage = event;
    this.getDailyCheckTabletUser();
  }

  showErrorMsg(msg) {
    this.notification.error(Object.values(msg.error)[0].toString());
  }

  reset(): void {
    this.getDailyCheckTabletUser();
  }

  saveEdit(): void {
    if (!this.editingUser?.id) {
      this.notification.error(this.translate.instant('Error Saving Dealer'));
      return;
    }
    const data = {
      family_name: this.editingUser.family_name,
      username: this.editingUser.username,
      password: this.editingUser.password
    }
    this.editingUser.password === this.defaultPassword &&
    delete data.password;
    this.dailyCheckTabletUserService
      .patchDailyCheckTabletUser(this.editingUser.id, data)
      .subscribe(
        () => {
          this.cancelEdit();
          this.reset();
        },
        (error) => {
          this.showErrorMsg(error);
        }
      );
  }

  cancelEdit(): void {
    this.editingUser = null;
    this.modalService.dismissAll();
  }

  import(importTemplate: TemplateRef<NgbModalRef>): void {
    this.modalService.open(importTemplate);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  dismissFileSelection () {
    this.fileToUpload = null;
    this.modalService.dismissAll();
  }

  uploadFile() {
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.dailyCheckTabletUserService.importDailyCheckTabletUser(data).subscribe(
      () => {
        this.modalService.dismissAll();
        this.notification.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
      },
      (err) => {
        this.showErrorMsg(err);
      }
    )
  }

  edit(editTemplate: TemplateRef<NgbModalRef>, user: DailyCheckTabletUser): void {
    this.editingUser = JSON.parse(JSON.stringify(user));
    this.editingUser.password = this.defaultPassword;
    this.modalService.open(editTemplate);
  }
}
