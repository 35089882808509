import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchData, StaffFlyer} from '../daily-check.models';
import {DailyCheckHistoryService} from '../daily-check-history.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-staff-table',
  templateUrl: './staff-table.component.html',
  styleUrls: ['./staff-table.component.scss']
})
export class StaffTableComponent implements OnInit, OnDestroy {

  staff: StaffFlyer[] = [];
  parentSearchData: SearchData = null;
  param = new Subscription(null);

  constructor(
    private notiService: ToastrService,
    private dailyCheckService: DailyCheckHistoryService,
  ) {
  }

  getStaffFlyerDistribution(): void {
    if (!this.parentSearchData.staff) {
      this.getStaffFlyerDistribution();
    }
    const params = {
      staff: this.parentSearchData.staff || '',
      start_date: this.parentSearchData.start_date || '',
      end_date: this.parentSearchData.end_date || '',
    }
    this.dailyCheckService.getStaffFlyerDistribution(params).subscribe(
      (res) => {
        this.staff = res;
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  showErrorMsg(error: HttpErrorResponse): void {
    this.notiService.error(error?.error?.message || error?.message  || error);
  }

  ngOnInit(): void {
    this.param = this.dailyCheckService.parentParams.subscribe((event) => {
      this.parentSearchData = event;
      if (!event) {
        return;
      }
      this.getStaffFlyerDistribution();
    });
  }

  ngOnDestroy(): void {
    this.param.unsubscribe();
  }

}
