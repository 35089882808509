import {Component, OnInit} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {CategoryService} from './category.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Pagination} from 'src/app/components/pagination/pagination.interface';
import {getCurrentPage} from 'src/app/components/pagination/pagination.functions';
import {NgxFileDropEntry, FileSystemFileEntry} from 'ngx-file-drop';
import {API_CONSTANT} from '../../services/api.constants';
import {HttpClientService} from '../../services/http-client.service';
import {ToastrService} from 'ngx-toastr';
import {HttpParams} from '@angular/common/http';
import {ProductImportType} from 'src/app/models/category.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, Pagination {
  public loading = false;
  dataLength = 0;
  currentItem: any;
  dataList = [];
  fileString: string = null;
  // Pagination
  page = 1;
  pageCount = 1;

  error: string = '';

  //File Upload
  importFile: File | null = null;
  uploadFileError: string;
  uploadStockFileError: string;
  importingFile: boolean = false;
  selectedCategoryID: number;
  startTime = {'hour': 0, 'minute': 0};
  startDate = null;

  categoryName: string = '';
  sellerID: number = null;
  selerID: number = null;
  type: string = 'Edit';
  editingID: number = null;
  sellers = [];
  file: File;
  imageUrl: any;
  fileChanged = false;
  category_names = []
  categoryNameList = []
  originalCategoryNames = []
  selectedCheckBox: string = 'Normal';
  seller_ids = []
  originalSellerNames = []
  searchID = '';
  productImportType = [
    { value: ProductImportType.normal, name: 'Normal' },
    { value: ProductImportType.monotaro, name: 'MONOTARO' },
    { value:ProductImportType.other, name: 'Other' },
  ];
  selectedProductImportType: number = ProductImportType.normal;

  auto_emptymsg = 'Result not found';

  minDate: NgbDateStruct = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };


  constructor(
    private category: CategoryService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private tostrService: ToastrService,
    private modalService: NgbModal,
    private api: HttpClientService,
    private translate: TranslateService,
  ) {
  }

  checked(selected) {
    this.selectedCheckBox = selected;
  }

  ngOnInit() {
    getCurrentPage(this, this.loadCategories);
    this.loadSellers();
    this.getAllSellersAndCategories();
  }

  loadCategories = async () => {
    this.loading = true;
    let param = new HttpParams()
    this.searchID ?
      param = param.append('id', this.searchID.toString()) : {};
    this.category_names.length ?
      param = param.append('category_name', this.category_names.toString()) : {};
    this.seller_ids.length ?
      param = param.append('seller_id', this.seller_ids.toString()) : {};
    let pageNo = this.category_names.length || this.seller_ids.length || this.searchID ?
      '1' : this.page.toString()
    param = param.append('page', pageNo);
    try {
      const res = await this.category.getCategories(param).toPromise();
      this.dataList = res.results; 
      this.dataLength = res.count;
      this.pageCount = res.total_pages;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      if (err.error.detail === 'Invalid page.') {
        this.router.navigateByUrl('category');
      }
    }
  };

  edit(category) {
    this.router.navigateByUrl('category/edit/' + category.id + '/');
  }

  getAllSellersAndCategories() {
    this.category.getALLSellersAndCategories().subscribe((res: any) => {
      this.originalSellerNames = res.sellers;
      this.originalCategoryNames = res.categories;
    })
  }

  loadSellers() {
    this.api.get(API_CONSTANT.BASE_URL + '/sellers/?all=true').subscribe(
      (res: any) => {
        this.sellers = res;
        this.dataLength = res.length;
      },
      (err) => {
      }
    );
  }

  open(view, item: any) {
    this.type = 'edit';
    this.modalService.open(view)
  }

  async openDelete(content, item) {
    try {
      const result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title'
      }).result;
      this.deleteCategory(item.id);
    } catch (e) {
    }
  }

  navigateTo(page: number) {
    this.page = page;
    this.router.navigateByUrl(`/category/${page}`);
  }

  deleteCategory(id) {
    this.category.deleteCategory(id).subscribe(res => {
      this.loadCategories();
    });
  }

  trackByFn(index, item) {
    return index;
  }

  chooseFile(event) {
    this.importFile = event.target.files.item(0);
  }

  uploadFile() {
    const is_scheduled = this.selectedCheckBox !== 'Normal';
    const time = this.getDateNTime(this.startDate, this.startTime, 'start');
    this.importingFile = true;
    this.uploadFileError = null;
    this.selectedProductImportType === ProductImportType.monotaro
      ? this.monotaroProductUpload(is_scheduled, time)
      : this.normalProductUpload(is_scheduled, this.selectedProductImportType, time);
  }

  normalProductUpload(is_scheduled: Boolean, type: number, time?: any) {
    this.category
      .importProducts(
        this.selectedCategoryID,
        this.importFile,
        is_scheduled,
        type,
        time
      )
      .subscribe(
        () => {
          this.modalService.dismissAll();
          this.importingFile = false;
          this.router.navigateByUrl('/import-product');
        },
        (error) => {
          this.tostrService.error(this.translate.instant('Import Failed'));
          this.uploadFileError = error.error?.message?.toString();
          this.importingFile = false;
        }
      );
  }

  monotaroProductUpload(is_scheduled: Boolean, time?: any) {
    this.category
      .importMonotaroProducts(
        this.selectedCategoryID,
        this.importFile,
        is_scheduled,
        time
      )
      .subscribe(
        () => {
          this.modalService.dismissAll();
          this.importingFile = false;
          this.router.navigateByUrl('/import-product');
        },
        (error) => {
          this.tostrService.error(this.translate.instant('Import Failed'));
          this.uploadFileError = error.error?.message?.toString();
          this.importingFile = false;
        }
      );
  }

  uploadStockFile(){
    this.importingFile = true;
    this.uploadStockFileError = null;
    this.category.importCategoryStockNum(this.selectedCategoryID, this.importFile).subscribe(
      () => {
        this.modalService.dismissAll();
        this.importingFile = false;
        this.router.navigateByUrl('/import-product');
      },
      (error) => {
        this.tostrService.error(this.translate.instant('Upload Failed'));
        this.uploadStockFileError = error.error?.message?.toString();
        this.importingFile = false;
      }
    )
  }

  getDateNTime(date, time, type) {
    if (!date) {
        return '';
    }
    !time ? type === 'end' ? (time = {hour: 23, minute: 59}) : (time = {hour: 0, minute: 0}) : {};
    time.second = 0;
    const dateStr = _.values(date).map(x => +x < 10 ? '0' + x : x).join('-');
    const timeStr = _.values(time).join(':');
    return dateStr + 'T' + timeStr;
  }

  openDialog(content, item: any) {
    this.selectedCategoryID = item.id;
    this.selectedProductImportType = item.type;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  openStockDialog(stock, item: any){
    this.selectedCategoryID = item.id;
    this.modalService.open(stock, {ariaLabelledBy: 'modal-stock-title'});
  }

  addCategory(view) {
    this.type = 'add';
    this.categoryName = '';
    this.selectedProductImportType = ProductImportType.normal;
    this.sellerID = null;
    this.openCategoryPopup(view);
  }

  editCategory(view, item) {
    this.type = 'edit';
    this.editingID = item.id;
    this.categoryName = item.category_name;
    this.sellerID = item.seller_name;
    this.selerID = item.seller;
    this.imageUrl = item.category_image;
    this.selectedProductImportType = item.type;
    this.openCategoryPopup(view);
  }

  openCategoryPopup(view) {
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  dismissEditOrCreate() {
    this.categoryName = '';
    this.sellerID = null;
    this.editingID = null;
    this.removeImage();
    this.modalService.dismissAll();
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;
          this.fileChanged = this.type == 'add' ? false : true;
          const reader = new FileReader();
          fileEntry.file(file => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              let image_result = reader.result as string;
              this.file['base64'] = image_result.split(',')[1];
              this.imageUrl = reader.result;
            };
          });
        });
      }
    }
  }

  removeImage() {
    this.imageUrl = '';
    this.file = null;
  }

  submit() {
    const fd = new FormData();
    this.categoryName == '' ? this.error = 'Input Category Name' :
      this.categoryName != '' ? fd.append('category_name', this.categoryName) : {};

    if (this.type === 'add') {
      this.sellerID == null
        ? (this.error = 'Select Seller')
        : (this.error = '');
      this.sellerID ? fd.append('seller_id', this.sellerID.toString()) : {};
    } else {
      this.sellerID == null
        ? (this.error = 'Select Seller')
        : (this.error = '');
      this.sellerID ? fd.append('seller_id', this.selerID.toString()) : {};
    }

    if ((this.type == 'add' && this.file) || (this.type == 'edit' && this.fileChanged)) {
      fd.append('category_image', this.file['base64']);
      fd.append('category_image_type', 'data:image/png;base64');
    }

    //add category type
    fd.append('type', this.selectedProductImportType.toString());

    if (this.type == 'add' && this.error == '') {
      this.category.addCategory(fd).subscribe((res) => {
          this.loadCategories();
          this.tostrService.success(this.translate.instant("Adding Successful"));
          this.dismissEditOrCreate();
        },
        (error) => {
          this.tostrService.error(this.translate.instant('Adding Failed'));
        })
    } else if (this.type == 'edit' && this.error == '') {
      this.category.updateCategory(this.editingID, fd).subscribe((res) => {
          this.loadCategories();
          this.tostrService.success(this.translate.instant("Successfully Updated"));
          this.dismissEditOrCreate();
        },
        (error) => {
          this.tostrService.error(this.translate.instant('Update Failed'));
        })
    } else {
      this.tostrService.error(this.error);
    }
  }

  // for category name search suggestions
  onCategoryUnselectValue(event) {
    this.category_names = this.category_names.filter(
      (x: string) => x != event
    );
  }

  onCategoryFiltering(e) {
    this.categoryNameList = []
    let searchingName = e.query.toLowerCase();
    this.categoryNameList = this.originalCategoryNames.filter(x =>
      x.toLowerCase().indexOf(searchingName) >= 0)
  }

  reset() {
    this.category_names = [];
    this.seller_ids = [];
    this.searchID = '';
    this.loadCategories();
  }
}
