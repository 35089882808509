import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchData} from './daily-check.models';
import {DailyCheckHistoryService} from './daily-check-history.service';
import {AvailableTables} from './daily-check-history.model';
import {Subscription} from 'rxjs';
import {NgbDatepickerI18n} from "@ng-bootstrap/ng-bootstrap";
import {CustomDatepickerI18n} from "../../shared/datepicker-i18n";

@Component({
  selector: 'app-daily-check-history',
  templateUrl: './daily-check-history.component.html',
  styleUrls: ['./daily-check-history.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
})
export class DailyCheckHistoryComponent implements OnInit, OnDestroy {

  searchData: SearchData;
  showingTable: AvailableTables;
  param = new Subscription(null);

  constructor(
    private dailyCheckService: DailyCheckHistoryService,
  ) {
  }

  ngOnInit(): void {
    this.param = this.dailyCheckService.parentParams.subscribe((event) => {
      if (!event) {
        return;
      }
      this.searchData = event;
      if (event.dailyCheck) {
        this.showingTable = AvailableTables.dailyCheck;
      }
      if (event.flyerDistribution) {
        this.showingTable = AvailableTables.flyerDistribution;
      }
      if (event.flyerDistribution && event.staff) {
        this.showingTable = AvailableTables.staff;
      }
    });
  }

  ngOnDestroy(): void {
    this.dailyCheckService.parentParams.next(null);
    this.param.unsubscribe();
  }
}
