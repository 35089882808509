import { Injectable } from '@angular/core';
import { API_CONSTANT } from '../../../app/services/api.constants';
import { HttpClientService } from '../../../app/services/http-client.service';
import { EditDealerSettingBody } from './dealer-setting.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealerSettingService {
  constructor(private api: HttpClientService) {}

  getDealerSettingList(params?) {
    return this.api.get(
      API_CONSTANT.BASE_URL + '/dealer-settings-list/',
      params
    );
  }

  editDealerSetting(data: EditDealerSettingBody) {
    return this.api.post(API_CONSTANT.BASE_URL + '/edit-dealer-setting/', data);
  }

  getAllCompanyName(params?: Object) {
    return this.api.get(API_CONSTANT.BASE_URL + '/all-dealer-setting-name/', params);
  }

  getAllBranchName(params?: Object): Observable<{ dealers: string[] }> {
    return this.api.get(
      API_CONSTANT.BASE_URL + '/get-all-branch-name/',
      params
    );
  }
}
