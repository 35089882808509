import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { Observable } from 'rxjs';
import { API_CONSTANT } from 'src/app/services/api.constants';
import { Branch, Dealer } from './dealer.model';
import { PaginatedResult } from 'src/app/components/pagination/pagination.interface';
import { DealerCustomerType } from 'src/app/models/customerTypes.model';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  constructor(private api: HttpClientService) {}

  get(params?: any): Observable<PaginatedResult<Dealer>> {
    return this.api.get(API_CONSTANT.BASE_URL + '/dealers/', params);
  }

  getAll(params?: any): Observable<Dealer[]> {
    return this.api.get(API_CONSTANT.BASE_URL + '/dealers/', {
      all: true,
      ...params
    });
  }

  getAllDealersName() {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-all-dealers/');
  }

  getBranches(params?: any): Observable<Branch[]> {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-branches/', {
      all: true,
      ...params
    });
  }

  searchDealers(params?: any): Observable<Dealer[]> {
    return this.api
      .get<{ results: Dealer[] }>(API_CONSTANT.BASE_URL + '/search-dealer/', {
        all: true,
        ...params
      })
      .pipe(pluck('results'));
  }

  create(data: Partial<Dealer>) {
    return this.api.post(API_CONSTANT.BASE_URL + '/dealers/', data);
  }

  update(id: number, data: Partial<Dealer>, params?: any): Observable<Dealer> {
    return this.api.patch(API_CONSTANT.BASE_URL + '/dealers/' + `${id}/`, data);
  }

  updateSalePackage(data: {
    id: number;
    package_set: number;
    is_grit: boolean;
  }): Observable<Dealer> {
    return this.api.patch(
      API_CONSTANT.BASE_URL + '/update-dealer-package/',
      data
    );
  }

  delete(dealer: Dealer): Observable<Dealer> {
    return this.api.delete<Dealer>(
      API_CONSTANT.BASE_URL + '/dealers/' + `${dealer.id}`
    );
  }

  patchDealer(id: number, data: Object) {
    return this.api.patch(
      API_CONSTANT.BASE_URL + '/dealers/' + `${id}/`,
      data
    );
  }

  import(file: File, type: DealerCustomerType) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_type', type.toString());
    return this.api.post(
      API_CONSTANT.BASE_URL + '/import-big-dealer/',
      formData
    );
  }

  getCTAETRDealerList() {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-cta-etr-dealers/');
  }
}
