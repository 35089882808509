import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DealerNames } from '../../dealer/dealer.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BuyerDealerWebUserService } from '../buyer-dealer-web-user.service';
import { BuyerDealerWebUser } from '../buyer-dealer-web-user.model';
import { Router } from '@angular/router';
import { DealerSettingService } from '../../dealer-setting/dealer-setting.service';
import { ConfirmModalService } from 'src/app/components/confirm-modal/confirm-modal.service';
import { ExportFileService } from 'src/app/services/export-file.service';
import { TranslateService } from '@ngx-translate/core';

type TabTypes = 'setting' | 'export' | 'shopAndDealer';
type ColumnTypes = 'dealer' | 'shop';
@Component({
  selector: 'app-buyer-dealer-web-user',
  templateUrl: './buyer-dealer-web-user.component.html',
  styleUrls: ['./buyer-dealer-web-user.component.scss'],
})
export class BuyerDealerWebUserComponent implements OnInit {
  loading: boolean = false;
  loadingDealer: boolean = false;
  loadingBranch: boolean = false;
  importing: boolean = false;

  selectedTab: TabTypes = 'setting';
  dealersName: string[] = [];
  branchNames: string[] = [];
  selectedDealer: string = null;
  selectedBranch: string = null;
  searchUserName: string = '';
  buyerDealerWebUsers: BuyerDealerWebUser[];
  fileToUpload: File = null;
  currentPage: Number = 1;
  count = 3;

  editingUser: BuyerDealerWebUser | null = null;

  defaultPassword: string = 'PASSWORD';

  constructor(
    private notification: ToastrService,
    private modalService: NgbModal,
    private buyerDealerWebUserService: BuyerDealerWebUserService,
    private router: Router,
    private dealerSettingService: DealerSettingService,
    private confirmModal: ConfirmModalService,
    private exportExcelFileService: ExportFileService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const tab = this.router.parseUrl(this.router.url).queryParams.tab;
    if (tab) {
      this.selectedTab = tab;
    }
    this.getDealerNames();
    this.getUserData();
  }

  changeTab(tab: TabTypes): void {
    this.router.navigate([], {
      queryParams: { tab },
      queryParamsHandling: 'merge',
    });
    this.selectedTab = tab;
    this.reset();
  }

  getBuyerDealerWebUsers(): void {
    this.loading = true;
    const params = {
      username: this.searchUserName || '',
      dealer_name: this.selectedDealer || '',
      page: this.currentPage.toString(),
      branch_name: this.selectedBranch || '',
    };
    this.buyerDealerWebUserService.getBuyerDealerWebUsers(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.buyerDealerWebUsers = res.results;
        this.loading = false;
      },
      (error) => {
        this.showErrorMsg(error);
        this.loading = false;
      }
    );
  }

  getBuyerDealerExportWebUsers(): void {
    this.loading = true;
    const params = {
      username: this.searchUserName || '',
      dealer_name: this.selectedDealer || '',
      branch_name: this.selectedBranch || '',
      page: this.currentPage.toString(),
    };
    this.buyerDealerWebUserService.getExportWebUsers(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.buyerDealerWebUsers = res.results;
        this.loading = false;
      },
      (error) => {
        this.showErrorMsg(error);
        this.loading = false;
      }
    );
  }

  getShopAndDealerWebUsers(): void {
    this.loading = true;
    const params = {
      username: this.searchUserName || '',
      dealer_name: this.selectedDealer || '',
      branch_name: this.selectedBranch || '',
      page: this.currentPage.toString(),
    };
    this.buyerDealerWebUserService.getShopAndDealerWebUsers(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.buyerDealerWebUsers = res.results.map((item) => {
          return {
            ...item,
            show_dealer: false,
            show_shop: false,
          };
        });
        this.loading = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.loading = false;
      }
    );
  }

  navigateTo(event): void {
    this.currentPage = event;
    this.getUserData();
  }

  getBranchName(): void {
    this.selectedBranch = null;
    this.loadingBranch = true;
    const params = {
      dealer_name: this.selectedDealer || '',
    };
    this.dealerSettingService.getAllBranchName(params).subscribe(
      (res: any) => {
        this.branchNames = res.dealers.length > 0 ? res.dealers : [];
        this.loadingBranch = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.loadingBranch = false;
      }
    );
  }

  getDealerNames(): void {
    this.loadingDealer = true;
    const params = {
      daily_check: true,
    };
    this.dealerSettingService.getAllCompanyName(params).subscribe(
      (res: DealerNames) => {
        this.dealersName = res.dealers;
        this.loadingDealer = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.loadingDealer = false;
      }
    );
  }

  showErrorMsg(msg): void {
    this.notification.error(Object.values(msg.error)[0].toString());
  }

  reset(): void {
    this.selectedDealer = null;
    this.selectedBranch = null;
    this.searchUserName = '';
    this.currentPage = 1;
    this.getUserData();
  }

  getUserData(): void {
    switch (this.selectedTab) {
      case 'setting':
        this.getBuyerDealerWebUsers();
        break;
      case 'export':
        this.getBuyerDealerExportWebUsers();
        break;
      case 'shopAndDealer':
        this.getShopAndDealerWebUsers();
        break;
    }
  }

  saveEdit(): void {
    if (!this.editingUser?.id) {
      this.notification.error(this.translate.instant('Error Saving Dealer'));
      return;
    }
    this.editingUser.password === this.defaultPassword &&
      delete this.editingUser.password;
    this.buyerDealerWebUserService
      .patchBuyerDealerWebUsers(this.editingUser.id, this.editingUser)
      .subscribe(
        () => {
          this.cancelEdit();
          this.reset();
        },
        (error) => {
          this.showErrorMsg(error);
        }
      );
  }

  saveShopAndDealerEdit(): void {
    if (!this.editingUser?.id) {
      this.notification.error(this.translate.instant('Error Saving Dealer'));
      return;
    }
    this.editingUser.password === this.defaultPassword &&
      delete this.editingUser.password;
    this.buyerDealerWebUserService
      .patchShopAndDealerWebUser(this.editingUser.id, this.editingUser)
      .subscribe(
        () => {
          this.cancelEdit();
          this.reset();
        },
        (error) => {
          this.showErrorMsg(error);
        }
      );
  }

  cancelEdit(): void {
    this.editingUser = null;
    this.modalService.dismissAll();
  }

  import(importTemplate: TemplateRef<NgbModalRef>): void {
    this.modalService.open(importTemplate);
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  dismissFileSelection(): void {
    this.fileToUpload = null;
    this.modalService.dismissAll();
  }

  uploadFile(): void {
    switch (this.selectedTab) {
      case 'setting':
        this.uploadSettingUserFile();
        break;
      case 'export':
        this.uploadExportUserFile();
        break;
      case 'shopAndDealer':
        this.uploadShopAndDealerUserFile();
        break;
    }
  }

  uploadSettingUserFile(): void {
    this.importing = true;
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.buyerDealerWebUserService.importBuyerDealerWebUser(data).subscribe(
      () => {
        this.modalService.dismissAll();
        this.importing = false;
        this.notification.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
      },
      (err) => {
        this.showErrorMsg(err);
        this.importing = false;
      }
    );
  }

  showCellChild(type: ColumnTypes, index: number): void {
    if (type === 'dealer') {
      this.buyerDealerWebUsers[index].show_dealer =
        !this.buyerDealerWebUsers[index].show_dealer;
    } else if (type === 'shop') {
      this.buyerDealerWebUsers[index].show_shop =
        !this.buyerDealerWebUsers[index].show_shop;
    }
  }

  uploadExportUserFile(): void {
    this.importing = true;
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.buyerDealerWebUserService.importExportWebUser(data).subscribe(
      () => {
        this.modalService.dismissAll();
        this.notification.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
        this.importing = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.importing = false;
      }
    );
  }

  uploadShopAndDealerUserFile(): void {
    this.importing = true;
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.buyerDealerWebUserService.importShopDealerWebUser(data).subscribe(
      () => {
        this.modalService.dismissAll();
        this.notification.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
        this.importing = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.importing = false;
      }
    );
  }

  edit(editTemplate: TemplateRef<NgbModalRef>, user: BuyerDealerWebUser): void {
    this.editingUser = JSON.parse(JSON.stringify(user));
    this.editingUser.password = this.defaultPassword;
    this.modalService.open(editTemplate);
  }

  exportUserEdit(id: number): void {
    this.router.navigateByUrl('buyer-dealer-web-user/edit/' + id + '/');
  }

  delete(id: number): void {
    switch (this.selectedTab) {
      case 'setting':
        this.settingUserdelete(id);
        break;
      case 'export':
        this.exportUserDelete(id);
        break;
      case 'shopAndDealer':
        this.shopAndDealerUserDelete(id);
        break;
    }
  }

  settingUserdelete(id: number): void {
    this.confirmModal.open(
      'Delete Confirmation',
      'Are u sure you want to delete?',
      () => {
        this.buyerDealerWebUserService.deleteBuyerDealerWebUsers(id).subscribe(
          () => {
            this.getBuyerDealerWebUsers();
          },
          (err) => {
            this.notification.error(err);
          }
        );
      }
    );
  }

  exportUserDelete(id: number): void {
    this.confirmModal.open(
      'Delete Confirmation',
      'Are u sure you want to delete?',
      () => {
        this.buyerDealerWebUserService.deleteExportWebUser(id).subscribe(
          () => {
            this.getBuyerDealerExportWebUsers();
          },
          (err) => {
            this.notification.error(err);
          }
        );
      }
    );
  }

  shopAndDealerUserDelete(id: number): void {
    this.confirmModal.open(
      'Delete Confirmation',
      'Are u sure you want to delete?',
      () => {
        this.buyerDealerWebUserService.deleteShopAndDealerWebUser(id).subscribe(
          () => {
            this.getShopAndDealerWebUsers();
          },
          (err) => {
            this.notification.error(err);
          }
        );
      }
    );
  }

  exportImportTemplate(): void {
    const params: Object = {
      type: this.selectedTab,
    };
    const fileName = `(${this.selectedTab})_import_template.xlsx`;
    this.buyerDealerWebUserService.getImportTemplate(params).subscribe(
      (res) => {
        this.exportExcelFileService.saveFile(res, fileName);
      },
      (err) => {
        this.notification.error(err);
      }
    );
  }
}
