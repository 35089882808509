<div class='detail-box p-3 col-12'>
  <!---------------------------Table Row------------------------------------------>
  <div class='row no-gutter' *ngIf="!loading && dealerManagers.length">
    <table class="table table-hover text-center table-striped">
      <thead class="thead-light">
        <tr>
          <th>ID</th>
          <th>{{'Name' | translate }}</th>
          <th>{{'Email'| translate}}</th>
          <th>{{'Company Name' | translate }}</th>
          <th>{{ 'Delete' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let manager of dealerManagers; let rowIndex = index">
          <td class="align-middle">{{ manager.id }}</td>
          <td class="align-middle">{{ manager.family_name }} {{ manager.given_name }}</td>
          <td class='align-middle'>{{ manager.email }}</td>
          <td class='align-middle'>{{ manager.dealer_name }}</td>
          <td class="v-middle" (click)="openDeleteModal(deleteUserModal, manager.id)">
            <img src="assets/svgs/trash_bin.svg" height="28" width="auto">
          </td>

        </tr>
      </tbody>
    </table>

  </div>
  <div class='row no-gutter d-flex justify-content-center mt-5'
       *ngIf='!loading && !dealerManagers.length'>
    <h1 class='text-muted'>{{ 'noDataFound' | translate }}</h1>
  </div>

  <app-loading class="w-100" *ngIf='loading'></app-loading>
</div>

<ng-template #editModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ ('Edit Supervisor Dealer Manager') | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissUser()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">


       <!------------1st Row------------->
    <div class='row no-gutter mb-3'>
      <label class='col-4 m-0 align-self-center'>{{'Given Name' | translate}}</label>
      <input
        type='text'
        class='col-8 form-control'
        [(ngModel)]="user['given_name']"
        [placeholder]="'Input Given Name' | translate "
        [ngClass]="{'is-invalid': errorMsg['name']}"
        (ngModelChange)="errorMsg['name'] = false">
    </div>

    <!------------2st Row------------->
    <div class='row no-gutter mb-3'>
      <label class='col-4 m-0 align-self-center'>{{'Family Name' | translate}}</label>
      <input
        type='text'
        class='col-8 form-control'
        [(ngModel)]="user['family_name']"
        [placeholder]="'Input Family Name' | translate "
        [ngClass]="{'is-invalid': errorMsg['name']}"
        (ngModelChange)="errorMsg['name'] = false">
    </div>

    <!------------3nd Row------------->
    <div class='row no-gutter mb-3'>
      <label class='col-4 m-0 align-self-center'>{{'Email' | translate}}</label>
      <input
        autocomplete="false"
        type='text'
        class='col-8 form-control'
        [placeholder]="'Input Email' | translate "
        [(ngModel)]="user['email']"
        [ngClass]="{'is-invalid': errorMsg['email']}"
        (ngModelChange)="errorMsg['email'] = false">
    </div>




  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissUser()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="saveUser()">
      {{ 'Save' | translate }}
    </button>
  </div>
</ng-template>


<ng-template #deleteUserModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
          {{ 'Delete Confirmation' | translate }}
      </h4>
    </div>
    <div class="modal-body">

      <div class="form-group">
        <div class="text-center mt-3">
          <span class="font-weight-bold" *ngIf='!deleting'>{{"Are you sure you want to delete?" | translate}} </span>
          <app-loading class="w-100" *ngIf='deleting'></app-loading>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="mr-4 w-75"> <div class="mb-1 alert alert-success" role="alert" *ngIf="successMessage"> {{ successMessage }} </div> <div class="mb-1 alert alert-danger" role="alert" *ngIf="errorMessage"> {{ errorMessage }} </div> </div> <button type="button" class="btn btn-danger" (click)="cancel()"> {{ 'Cancel' | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="deleteUser()">
        {{ 'Confirm' | translate }}
      </button>
    </div>
</ng-template>
