import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../../app/components/core/core.module';
import { SharedModule } from '../../../app/shared.module';
import { MaintenanceSimulationRoutesModule } from './maintenance-simulation-routing.module';
import { MaintenanceSimulationComponent } from './maintenance-simulation.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [MaintenanceSimulationComponent],
  imports: [
    CommonModule,
    MaintenanceSimulationRoutesModule,
    NgbDatepickerModule,
    CoreModule,
    SharedModule,
    FormsModule
  ],
})
export class MaintenanceSimulationModule {}
