import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import {CarSpecificationComponent} from './car-specification.component';


export const CarSpecificationRoutes: Routes = [
  {
    path: 'car-specifications',
    children: [
      {path: '', component: CarSpecificationComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(CarSpecificationRoutes)],
  exports: [RouterModule]
})
export class CarSpecificationRoutingModule {
}
