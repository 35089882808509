import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuyerDealerWebUserComponent } from './buyer-dealer-web-user/buyer-dealer-web-user.component';
import { EditExportUserComponent } from './edit-export-user/edit-export-user.component';

export const BuyerDealerWebUserRoutes: Routes = [
  {
    path: 'buyer-dealer-web-user',
    children: [
      { path: '', component: BuyerDealerWebUserComponent },
      { path: 'edit/:id/', component: EditExportUserComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(BuyerDealerWebUserRoutes)],
  exports: [RouterModule],
})
export class BuyerDealerWebUserRoutingModule {}
