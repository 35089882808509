import { Component, OnInit } from '@angular/core';
import { SupervisorService } from './supervisor.service';
import { CategoryService } from '../category/category.service';
import { Dealer } from '../dealer/dealer.model';
import { Category } from '../../models/category.model';
import { HttpParams } from '@angular/common/http';
import { TranslationService } from '../../services/translate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { ApprovalModel, BulkDeleteParams, ShopSupervisor, Supervisor } from './supervisor.model';
import { ApprovalService } from './approval.service';
import { ShopService } from 'src/app/services/shop.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss'],
})
export class SupervisorComponent implements OnInit {
  public supervisorList: Supervisor[] = [];
  dealers: Dealer[];
  categories: Category[];
  filteredCategoryList: Category[] = [];
  filterargs = {
    name: '',
    category: '',
    limit_amount: 0,
  };
  selectedDealerID: number;
  selectedDealerOption: boolean;
  model = {};
  view = 'normal';
  isLoading = false;
  selectedCategory: Category;
  approvals: ApprovalModel[] = [
    { amount_limit: 0, supervisors: [] } as ApprovalModel,
  ];
  dropdowns: number[][] = [[0]];
  approvalList: ApprovalModel[];
  isEditingName: boolean[] = [];
  isEditingAmount: boolean[] = [];
  loadingDealers = false;
  loadingSupervisors: boolean = false;
  buttonLoadingState: boolean[] = [];
  shops: ShopSupervisor[] = [];
  filteredShops: ShopSupervisor[] = [];
  selectedShop: ShopSupervisor;
  fileToUpload: File = null;
  showManagerList: boolean = false;
  showManagerDealerList: boolean = false;
  isAdmin: boolean = false;
  isSupervisorDealerManager: boolean = false;
  affectedShops: ShopSupervisor[] = [];
  selectedShops: ShopSupervisor[] = [];
  selectedCategories: Category[] = [];
  latestSelectedShopId: number;
  latestSelectedCategoryId: number;

  constructor(
    private translationSerice: TranslationService,
    private modalService: NgbModal,
    private supervisorService: SupervisorService,
    private toastr: ToastrService,
    private categoryService: CategoryService,
    private approvalService: ApprovalService,
    private shopService: ShopService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getShops();
    this.checkAdminUser();
  }

  onSelectShop(shop: ShopSupervisor) {
    shop.selected = !shop.selected;
    if (shop.selected) {
      // fetch the category based on the latest selected shop
      this.getCategories(shop);
      // also assigned the fetched categories to the selected shop
      shop.categories = this.categories;
      this.selectedShops.push(shop);
      this.latestSelectedShopId = shop.id;
    } else {
      this.selectedShops = this.selectedShops.filter((s) => s.id !== shop.id);
      // if the shop is deselected, fetch the category based on the previous selected shop
      if (this.selectedShops.length > 0) {
        this.getCategories(this.selectedShops[this.selectedShops.length - 1]);
        this.latestSelectedShopId =
          this.selectedShops[this.selectedShops.length - 1].id;
      } else {
        this.categories = [];
        this.filteredCategoryList = [];
        this.latestSelectedShopId = null;
      }
    }

    if (this.latestSelectedShopId) {
      this.reloadSupervisors();
    }

    // clear selected categories
    this.selectedCategories = [];
  }

  onSelectCategory(category: Category) {
    category.selected = !category.selected;
    if (category.selected) {
      this.selectedCategories.push(category);
      this.latestSelectedCategoryId = category.id;
    } else {
      this.selectedCategories = this.selectedCategories.filter(
        (c) => c.id !== category.id
      );
      if (this.selectedCategories.length > 0) {
        this.latestSelectedCategoryId =
          this.selectedCategories[this.selectedCategories.length - 1].id;
      } else {
        this.latestSelectedCategoryId = null;
      }
    }
    this.refreshAffectedShops();
    if (this.latestSelectedShopId) {
      this.getLimitAmount(
        this.latestSelectedShopId,
        this.latestSelectedCategoryId
      );
    }
  }

  refreshAffectedShops() {
    this.affectedShops = [];
    if (!this.latestSelectedCategoryId) {
      return;
    }

    this.selectedShops.forEach((shop) => {
      const matchingCategories = shop.categories.filter(
        (cat) => cat.id === this.latestSelectedCategoryId
      );
      if (matchingCategories.length > 0) {
        this.affectedShops.push(shop);
      }
    });
  }

  onSelectAllCategories() {
    if (this.filteredCategoryList.length === 0) {
      return;
    }

    const isSelectAllAction = !this.filteredCategoryList.every(
      (category) => category.selected
    );

    if (isSelectAllAction) {
      this.filteredCategoryList.forEach((category) => {
        category.selected = true;
      });
      this.selectedCategories = this.filteredCategoryList;
      // get the first item as the latest selected category
      this.latestSelectedCategoryId = this.selectedCategories[0].id;
    } else {
      this.filteredCategoryList.forEach((category) => {
        category.selected = false;
      });
      this.selectedCategories = [];
      this.latestSelectedCategoryId = null;
    }

    this.refreshAffectedShops();
    if (this.latestSelectedShopId) {
      this.getLimitAmount(
        this.latestSelectedShopId,
        this.latestSelectedCategoryId
      );
    }
  }

  getCategorySelectAllTitle(): boolean {
    return (
      this.filteredCategoryList.every((category) => category.selected) &&
      this.filteredCategoryList.length > 0
    );
  }

  checkAdminUser() {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    this.isAdmin = group[0] == 'Admin';
    this.isSupervisorDealerManager = group[0] == 'SupervisorDealerManager';
  }

  getShops() {
    this.shopService.getCTAETRShopList({ is_etr: true }).subscribe(
      (res: any) => {
        this.shops = res;
        this.filteredShops = this.shops;
      },
      (error: any) => {
        this.showErrorMsg(error);
      }
    );
  }

  uploadPrice(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  showErrorMsg(errorMsg) {
    this.toastr.error(_.values(errorMsg)[0].toString());
  }

  reloadSupervisors() {
    if (!this.latestSelectedShopId) {
      return;
    }
    let params = new HttpParams();
    params = params.append('shop', this.latestSelectedShopId.toString());
    this.supervisorService.get(params).subscribe((res: any) => {
      this.supervisorList = res;
    });
  }

  getLimitAmount(shop: number, category_id?: number) {
    if (!category_id) {
      return;
    }
    this.loadingSupervisors = true;
    this.filterargs.limit_amount = 0;
    const params = {
      shop: shop,
      category: category_id,
    };
    this.approvalService.get(params).subscribe((res: any) => {
      this.approvalList = res.results;

      if (this.approvalList && this.approvalList.length > 0) {
        this.approvals = this.approvalList;

        // only keep three dropdowns from selected approvals
        this.dropdowns = this.approvals
          .map((approval) => approval.supervisors)
          .slice(0, 3);
      } else {
        this.approvals = [
          { amount_limit: 0, supervisors: [] } as ApprovalModel,
        ];
        this.dropdowns = [[0]];
      }
      this.loadingSupervisors = false;
    });
  }

  filterShop() {
    this.filteredShops = this.filterList(
      this.shops,
      'name',
      this.filterargs.name
    );
  }

  filterCategory() {
    this.filteredCategoryList = this.filterList(
      this.categories,
      'category_name',
      this.filterargs.category
    );
  }

  filterList<T>(list: T[], filterProperty: keyof T, filterValue: string): T[] {
    const searchTxt = filterValue.toLocaleLowerCase();
    return list.filter((item) =>
      item[filterProperty].toString().toLocaleLowerCase().includes(searchTxt)
    );
  }

  getApprovalRouteList(approvalRoute: ApprovalModel): ApprovalModel[] {
    const approvalRouteList: ApprovalModel[] = [];
    for (const shop of this.affectedShops) {
      for (const cat of this.selectedCategories) {
        approvalRouteList.push({
          name: approvalRoute.name,
          shop: shop.id,
          category: cat.id,
          amount_limit: approvalRoute.amount_limit,
          supervisors: approvalRoute.supervisors,
        });
      }
    }

    return approvalRouteList;
  }

  save(index: number) {
    let params = this.approvals[index];
    params.category = this.latestSelectedCategoryId;

    if (!this.validateParam(params)) {
      return false;
    }

    params.cta_shop_id = null;
    params.shop = this.latestSelectedShopId;

    const paramList = this.getApprovalRouteList(params);

    this.buttonLoadingState[index] = true;

    if (params.id) {
      this.approvalService.patch(params).subscribe(
        (res: ApprovalModel) => {
          this.approvals[index] = res;
          this.toastr.success(this.translate.instant('Saved successfully'));
          this.clearEditing();
          this.buttonLoadingState[index] = false;
          this.addHasRouteByCategoryId(this.latestSelectedCategoryId);
        },
        (error: any) => {
          this.showError(error);
          this.clearEditing();
          this.buttonLoadingState[index] = false;
        }
      );
    } else {
      this.approvalService.bulkCreate(paramList).subscribe(
        (res: ApprovalModel[]) => {
          this.toastr.success(this.translate.instant('Saved successfully'));
          this.approvalList = this.approvalList.filter(approval => approval.id).concat(res);
          this.clearEditing();
          this.buttonLoadingState[index] = false;
          res.forEach(({ category }) => this.addHasRouteByCategoryId(category));
        },
        (error: any) => {
          this.showError(error);
          this.clearEditing();
          this.buttonLoadingState[index] = false;
        }
      );
    }
  }

  validateParam(approval: ApprovalModel): boolean {
    if (approval.category == null) {
      this.toastr.error(this.translate.instant('Please select a category'));
      return false;
    }

    if (approval.supervisors.length < 1) {
      this.toastr.error(this.translate.instant('Please select an email'));
      return false;
    }

    if (approval.amount_limit == null || approval.amount_limit < 0) {
      this.toastr.error(this.translate.instant('Please set a valid amount'));
      return false;
    }

    if (
      this.approvals.some(
        (appr) =>
          appr.amount_limit === approval.amount_limit && appr.id !== approval.id
      )
    ) {
      this.toastr.error(this.translate.instant('Amount limit already exists'));
      return false;
    }

    return true;
  }

  toggleEditingAmount(index: number) {
    this.isEditingAmount[index] = !this.isEditingAmount[index];
  }

  clearEditing() {
    this.isEditingAmount = [];
  }

  addDropdown(index: number) {
    if (this.dropdowns[index].length < 3) {
      this.dropdowns[index].push(0);
    }
  }

  removeDropdown(approvals: number, index: number) {
    this.dropdowns[approvals].splice(index, 1);
    this.approvals[approvals].supervisors.splice(index, 1);
  }

  addSection() {
    this.approvals.push({ amount_limit: 0, supervisors: [] } as ApprovalModel);
    this.dropdowns.push([0]);
  }

  removeSection(index: number) {
    this.loadingSupervisors = true;
    const removedItem = this.approvals[index];
    if (removedItem.id !== null && removedItem.id !== undefined) {
      this.approvalService.delete(removedItem.id).subscribe(
        () => {
          this.approvals.splice(index, 1);
          this.loadingSupervisors = false;
        },
        (error: any) => {
          this.showError(error);
          this.loadingSupervisors = false;
        }
      );
    } else {
      this.approvals.splice(index, 1);
      this.loadingSupervisors = false;
    }
  }

  removeAllApprovalRoutes() {
    this.loadingSupervisors = true;
    const selectedShopIds = this.selectedShops.map((shop) => shop.id);
    const selectedCategoryIds = this.selectedCategories.map((cat) => cat.id);
    const ids: BulkDeleteParams = {shop_ids: selectedShopIds, category_ids: selectedCategoryIds};
    this.approvalService.bulkDelete(ids).subscribe(
      () => {
        this.loadingSupervisors = false;
        this.approvals = [{ amount_limit: 0, supervisors: [] } as ApprovalModel];
        this.filteredCategoryList.filter((cat) => selectedCategoryIds.includes(cat.id)).map((cat) => cat.has_route = false);
        this.toastr.success(this.translate.instant('Deleted'));
      },
      (error: any) => {
        this.showError(error);
        this.loadingSupervisors = false;
      }
    );
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  showSupervisorDealerManagerList() {
    this.showManagerDealerList = true;
    this.showManagerList = false;
  }

  showSupervisorManagerList() {
    this.showManagerList = true;
  }

  showingSupervisorOrDealerManager() {
    this.showManagerList = false;
    this.showManagerDealerList = false;
  }

    async openDeleteAll(content) {
    try {
      const result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
      }).result;
      this.removeAllApprovalRoutes();
      this.approvals.forEach((approval) => {
        this.removeHasRouteByApproval(approval);
      });
    } catch (e) {}
  }

  async openDelete(content, sectionIndex, approval) {
    try {
      const result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
      }).result;
      this.removeSection(sectionIndex);
      this.removeHasRouteByApproval(approval);
    } catch (e) {}
  }

  removeHasRouteByApproval(approval) {
    let foundCategory = this.filteredCategoryList.find(
      (cat) => cat.id == approval.category
    );
    if (foundCategory) {
      foundCategory.has_route = false;
    }
  }

  addHasRouteByCategoryId(categoryId: number) {
    let foundCategory = this.filteredCategoryList.find(
      (cat) => cat.id == categoryId
    );
    if (foundCategory) {
      foundCategory.has_route = true;
    }
  }

  hasRouteAndSelected(): boolean {
    return this.filteredCategoryList.some((cat) => cat.has_route && cat.selected);
  }

  uploadFile() {
    this.isLoading = true;
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.supervisorService.import(this.fileToUpload).subscribe(
      () => {
        this.modalService.dismissAll();
        this.isLoading = false;
        this.toastr.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
      },
      (err) => {
        this.showErrorMsg(err);
        this.isLoading = false;
      }
    );
  }

  translateMessage(messages) {
    // messages is string
    // debugger
    var message_split = messages.split('\n');
    const messageList = [];
    for (const message of message_split) {
      messageList.push(
        this.translationSerice.transformMessage(message) || message
      );
    }
    return messageList;
  }

  getCategories(shop: ShopSupervisor) {
    const params = {
      shop_id: shop.id,
    };

    this.categoryService.getAllCategories(params).subscribe(
      (res: any) => {
        this.categories = res;
        this.filteredCategoryList = this.categories;
        shop.categories = this.categories;
      },
      (error: any) => {
        this.showErrorMsg(error);
      }
    );
  }

  showError(error) {
    this.toastr.error(_.values(error.error)[0].toString());
  }
}
