import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import {ImportUpdateHistoryComponent} from './import-update-history.component';


export const ImportUpdateHistoryRoutes: Routes = [
  {
    path: 'import-update-history',
    children: [
      {path: '', component: ImportUpdateHistoryComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ImportUpdateHistoryRoutes)],
  exports: [RouterModule]
})
export class ImportUpdateHistoryRoutingModule {
}
