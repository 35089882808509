import { environment } from 'src/environments/environment'

const BASE_URL = environment.hostURL + '/api';

export const API_CONSTANT = {
  'BASE_URL':BASE_URL,
  'STATIC_URL': environment.hostURL + '/static',
  tokenAuth: BASE_URL + '/token-auth/',
  userVerification: BASE_URL + '/user-verification/',
}
