import { Component, OnInit } from '@angular/core';
import { MonotaroUser, Product, PurchaseOrder, PurchaseOrderSearch } from './monotaro.model';
import { NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n } from '../../shared/datepicker-i18n';
import { MonotaroService } from './monotaro.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {ExportFileService} from '../../services/export-file.service';


@Component({
  selector: 'app-monotaro',
  templateUrl: './monotaro.component.html',
  styleUrls: ['./monotaro.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
})
export class MonotaroComponent implements OnInit {
  showingDetail = false;
  purchaseOrder: PurchaseOrder;
  loadingSearch = false;
  searchFields = {selected_only: true} as PurchaseOrderSearch;
  shopList = [];
  monotaroUserIDList = []
  majorClassificationList = [];
  mediumClassificationList = [];
  minorClassificationList = [];
  orderNumberList = [];
  productNameList = [];
  productCodeList = [];
  purchaseOrders: PurchaseOrder[] = [];
  selectedOnlyParams;

  filterUserID = [];
  filterOrderNumber = [];
  filterProductName = [];
  filterProductCode = [];

  importFile: File | null = null;
  uploadFileError: string;
  uploading = false;

  currentPage = 1;
  count = 0;

  exporting = {
    exportingMajorClassification: false,
    exportingMediumClassification: false,
    exportingMinorClassification: false,
    exportingShopSummary: false,
    exportingOrder: false
  }

  constructor(
    private monotaroService: MonotaroService,
    private notification: ToastrService,
    private modalService: NgbModal,
    private translationService: TranslateService,
    private exportExcelService: ExportFileService,
  ) {
  }

  ngOnInit(): void {
    this.searchPurchaseOrder();
    this.getMonotaroUserList();
    this.getShopList();
    this.getMajorClassification();
    this.getMediumClassification();
    this.getMinorClassification();
    this.getOrderNumberList();
    this.getProductName();
    this.getProductCode();
  }

  showList() {
    this.showingDetail = false;
    this.selectedOnlyParams = {};
  }

  getMonotaroUserList() {
    this.monotaroUserIDList = [];
    this.monotaroService.getMonotaroUsers().subscribe(
      (res: any) => {
        // this.count = res.count;
        res.results.forEach((user: MonotaroUser) => {
          this.monotaroUserIDList.push(user.user_id);
        })
      },
      (error) => {
        this.showError(error);
      }
    )
  }

  getShopList() {
    this.monotaroService.getShops().subscribe(
      (res: any) => {
        // this.count = res.count;
        this.shopList = res.results;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  getMajorClassification() {
    this.monotaroService.getMajorClassifications().subscribe(
      (res: any) => {
        // this.count = res.count;
        this.majorClassificationList = res.results;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  getMediumClassification(params?) {
    this.monotaroService.getMediumClassifications(params).subscribe(
      (res: any) => {
        // this.count = res.count;
        this.mediumClassificationList = res.results;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  getMinorClassification(params?) {
    this.monotaroService.getMinorClassifications(params).subscribe(
      (res: any) => {
        // this.count = res.count;
        this.minorClassificationList = res.results;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  getOrderNumberList(params?) {
    this.monotaroService.getOrderNumbers(params).subscribe(
      (res: any) => {
        // this.count = res.count;
        this.orderNumberList = res.results;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  getProductName(params?) {
    this.monotaroService.getProductNames(params).subscribe(
      (res: any) => {
        // this.count = res.count;
        res.results.forEach((product: Product) => {
          this.productNameList.push(product.name);
        })
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  getProductCode(params?) {
    this.monotaroService.getProductCodes(params).subscribe(
      (res: any) => {
        // this.count = res.count;
        this.productCodeList = res.results;
      },
      (error) => {
        this.showError(error);
      }
    );
  }

  selectMajor() {
    let params = new HttpParams();
    params = params.set(
      'major_classification', this.searchFields.major_classification?.toString() || ''
    )
    this.getMediumClassification(params);
    this.getMinorClassification(params);
  }

  selectMedium() {
    let params = new HttpParams();
    params = params.set(
      'medium_classification', this.searchFields.medium_classification?.toString() || ''
    )
    this.getMinorClassification(params);
  }

  onFiltering(event, searchList) {
    const searchingName = event.query.toLowerCase();
    const searchResult = searchList.filter(x => x?.toLowerCase().indexOf(searchingName) >= 0);
    return searchResult.sort();
  }

  onUnselectedValue(event, fields) {
    const index = this.searchFields[fields].indexOf(event);
    if (index !== -1) {
      this.searchFields[fields].splice(index, 1);
    }
  }

  onUserIDFiltering(event) {
    this.filterUserID = this.onFiltering(event, this.monotaroUserIDList);
  }

  onUserIDUnselectValue(event) {
    this.onUnselectedValue(event, 'user_id');
  }

  onOrderNumberFiltering(event) {
    this.filterOrderNumber = this.onFiltering(event, this.orderNumberList);
  }

  onOrderNumberUnselectValue(event) {
    this.onUnselectedValue(event, 'order_number');
  }

  onProductNameFiltering(event) {
    this.filterProductName = this.onFiltering(event, this.productNameList);
  }

  onProductNameUnselectValue(event) {
    this.onUnselectedValue(event, 'product_name');
  }

  onProductCodeFiltering(event) {
    this.filterProductCode = this.onFiltering(event, this.productCodeList);
  }

  onProductCodeUnselectValue(event) {
    this.onUnselectedValue(event, 'product_code');
  }

  clickSearchBtn() {
    this.currentPage = 1;
    this.searchPurchaseOrder();
  }

  getSearchParams() {
    return new HttpParams()
      .set('user_id', this.searchFields.user_id?.toString() || '')
      .set('shop', this.searchFields.shop?.toString() || '')
      .set('product_name', this.searchFields.product_name?.toString() || '')
      .set('product_code', this.searchFields.product_code?.toString() || '')
      .set('major_classification', this.searchFields.major_classification?.toString() || '')
      .set('medium_classification', this.searchFields.medium_classification?.toString() || '')
      .set('minor_classification', this.searchFields.minor_classification?.toString() || '')
      .set('order_number', this.searchFields.order_number?.toString() || '')
      .set('selected_only', this.searchFields.selected_only.toString() || '')
      .set('order_date_before', this.getDateStr(this.searchFields.order_date_before) || '')
      .set('order_date_after', this.getDateStr(this.searchFields.order_date_after) || '')
      .set('page', this.currentPage.toString() || '')
      .set('language', this.translationService.currentLang || '');
  }

  searchPurchaseOrder() {
    this.loadingSearch = true;
    this.monotaroService.getPurchaseOrders(this.getSearchParams()).subscribe(
      (res: any) => {
        this.purchaseOrders = res.results;
        this.count = res.count;
        this.loadingSearch = false;
      },
      (error) => {
        this.showError(error);
        this.loadingSearch = false;
      }
    )
  }

  resetSearch() {
    this.searchFields = {selected_only: true} as PurchaseOrderSearch;
    this.getMediumClassification();
    this.getMinorClassification();
  }

  showError(error) {
    console.log(error);
    this.notification.error(error.message?.toString());
  }

  changeSelectedOnly() {
    this.searchFields.selected_only = !this.searchFields.selected_only
  }

  showPurchaseDetail(purchaseDetail) {
    this.showingDetail = true;
    this.purchaseOrder = purchaseDetail;
    if (this.searchFields.selected_only) {
      this.selectedOnlyParams = {
        major_classification: this.searchFields.major_classification || '',
        medium_classification: this.searchFields.medium_classification || '',
        minor_classification: this.searchFields.minor_classification || '',
        product_name: this.searchFields.product_name || '',
        product_code: this.searchFields.product_code || '',
      }
    }
  }

  navigateTo(event) {
    this.currentPage = event;
    this.searchPurchaseOrder();
  }

  openModal(content) {
    this.modalService.open(content);
  }

  chooseFile(event) {
    this.importFile = event.target.files.item(0);
  }

  importMonotaroFile() {
    this.uploading = true;
    this.uploadFileError = null;
    this.monotaroService.importMonotaroFile(this.importFile).subscribe(
      () => {
        this.modalService.dismissAll();
        this.notification.success(this.translationService.instant('monotaro.importFileSuccessfully'))
        this.resetSearch();
        this.searchPurchaseOrder();
        this.uploading = false;
      },
      (error) => {
        console.log(error);
        this.uploadFileError = error.error?.message?.toString();
        this.uploading = false;
      }
    );
  }

  exportOrder() {
    this.exporting.exportingOrder = true;
    this.monotaroService.exportOrer(this.getSearchParams()).subscribe(
      (res) => {
        this.exportExcelService.saveFile(res, 'monotaro_order.xlsx');
        this.exporting.exportingOrder = false;
      },
      (error) => {
        this.exporting.exportingOrder = false;
        this.showError(error);
      }
    )
  }

  exportMajorClassification() {
    this.exporting.exportingMajorClassification = true;
    this.monotaroService.exportMajorClassification(this.getSearchParams()).subscribe(
      (res) => {
        this.exportExcelService.saveFile(res, 'major_classification.xlsx');
        this.exporting.exportingMajorClassification = false;
      },
      (error) => {
        this.exporting.exportingMajorClassification = false;
        this.showError(error);
      }
    )
  }

  exportMediumClassification() {
    this.exporting.exportingMediumClassification = true;
    this.monotaroService.exportMediumClassification(this.getSearchParams()).subscribe(
      (res) => {
        this.exportExcelService.saveFile(res, 'medium_classification.xlsx');
        this.exporting.exportingMediumClassification = false;
      },
      (error) => {
        this.exporting.exportingMediumClassification = false;
        this.showError(error);
      }
    )
  }

  exportMinorClassification() {
    this.exporting.exportingMinorClassification = true;
    this.monotaroService.exportMinorClassification(this.getSearchParams()).subscribe(
      (res) => {
        this.exportExcelService.saveFile(res, 'minor_classification.xlsx');
        this.exporting.exportingMinorClassification = false;
      },
      (error) => {
        this.exporting.exportingMinorClassification = false;
        this.showError(error);
      }
    )
  }

  exportShopSummary() {
    this.exporting.exportingShopSummary = true;
    this.monotaroService.exportShopSummary(this.getSearchParams()).subscribe(
      (res) => {
        this.exportExcelService.saveFile(res, 'shop_summary.xlsx');
        this.exporting.exportingShopSummary = false;
      },
      (error) => {
        this.exporting.exportingShopSummary = false;
        this.showError(error);
      }
    )
  }

  getDateStr(date) {
    if (!date) {
      return '';
    }
    return _.values(date).map(x => +x < 10 ? '0' + x : x).join('-');
  }

}
