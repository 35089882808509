import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Branch, Dealer} from '../../dealer/dealer.model';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {DealerService} from '../../dealer/dealer.service';
import {ShopService} from '../../../services/shop.service';
import {Staff} from '../../settings/settings.model';
import {SettingsService} from '../../settings/settings.service';
import {DailyCheck, DailyCheckItemChecks, DailyCheckSearchFields, SearchData} from '../daily-check.models';
import {DailyCheckHistoryService} from '../daily-check-history.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {ExportFileService} from '../../../services/export-file.service';
import { DealerSettingService } from '../../dealer-setting/dealer-setting.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Output() searchEvent = new EventEmitter<SearchData>();

  exporting: boolean = false;
  showContents: boolean = true;
  showSearchBox: boolean = true;
  loadingShops: boolean = false;
  loadingBranches: boolean = false;
  loadingStaffs: boolean = false;
  disableDealer: boolean = false;
  loadingDealers: boolean = false;
  flyerDistribution: boolean = false;
  oneOrMoreAchievements: boolean = true;
  isInternalUser: boolean = false;

  searchFields: DailyCheckSearchFields = {
    dealer: [],
    shop: [],
    staff: null,
    orderDateAfter: null,
    orderDateBefore: null,
    branches: [],
    roles: [],
  }

  dailyChecks: DailyCheck = {
    checked: true,
    childItems: {
      dailyCheckAnalysis: true,
      checkUpRatio: true,
      quotationRatio: true,
      confirmedOrderRatio: true,
      actualWorkRatio: true,
    }
  }

  dailyCheckItems: DailyCheckItemChecks;

  shops = [];
  staff: Staff[] = [];
  dealers: Dealer[] = [];
  branches: Branch[] = [];
  positionList: { name:string, role: number}[] = [];

  constructor(
    private shopService: ShopService,
    private settingsService: SettingsService,
    private notiService: ToastrService,
    private dealerService: DealerService,
    private dailyCheckService: DailyCheckHistoryService,
    private exportExcelFileService: ExportFileService,
    private translate: TranslateService,
  ) {
    this.setItems(true);
  }

  ngOnInit(): void {
    const is_internal_user = localStorage.getItem('is_internal_user');
    this.isInternalUser =
      is_internal_user && is_internal_user === 'true' ? true : false;
    const id = localStorage.getItem('dealer_id');
    if (id) {
      this.searchFields.dealer = [+id];
      this.disableDealer = true;
      this.getShops();
    }
    this.getDealers(this.isInternalUser ? true : false);
    this.getBranches();
    this.handleExternalUser();
  }

  handleExternalUser(): void {
    if (!this.isExcludeRoles()) {
      this.positionList = [
        { name: 'All Positions', role: 0 },
        { name: 'Manager 1', role: 1 },
        { name: 'Sub-Manager 1', role: 2 },
        { name: 'Shop Manager', role: 3 },
        { name: 'Mate', role: 4 },
        { name: 'Part-Time Worker', role: 5 },
        { name: 'Employee', role: 6 },
        { name: 'Staff', role: 7 },
        { name: 'Manager 2', role: 8 },
        { name: 'Sub-Manager 2', role: 9 },
      ];
    } else {
      this.positionList = [
        { name: 'Manager 1', role: 1 },
        { name: 'Sub-Manager 1', role: 2 },
        { name: 'Shop Manager', role: 3 },
        { name: 'Mate', role: 4 },
        { name: 'Part-Time Worker', role: 5 },
        { name: 'Employee', role: 6 },
        { name: 'Staff', role: 7 },
        { name: 'Manager 2', role: 8 },
        { name: 'Sub-Manager 2', role: 9 },
      ];
    }
  }

  getBranches(): void {
    this.loadingBranches = true;
    const params = {
      daily_check: true
    }
    this.dealerService.getBranches(params).subscribe(
      (res: Branch[]) =>{
        this.branches = res;
        // not show all branches for 
        // 1. excluded roles (e.g., etr-admin)
        // 2. etradmin2-10
        // 3. shop managers and shop admins
        // 4. dealer accounts
        (!this.isExcludeRoles() 
          && !this.isAccountBranch() 
          && !this.isShopAccount()
          && !this.isDealerAccount()) &&
          this.branches.unshift({
            id: 0,
            branch_name: 'All Branches',
          });
        this.loadingBranches = false;
      },
      (error) => {
        this.loadingBranches = false;
        this.showErrorMsg(error);
      }
    )
  }

  getDealers(checkBranch: boolean = true): void {
    if (checkBranch) {
      this.searchFields.dealer = [];
      this.searchFields.shop = [];
      this.searchFields.staff = null;
      if (!this.searchFields.branches.length && !this.isShopManager()) {
        this.dealers = [];
        return;
      }
      if (this.searchFields['branches'].includes(0)) {
        this.searchFields['branches'] = [0];
      }
    }
    this.loadingDealers = true;
    const params = {
      daily_check: true,
      branches: this.getBranchQuery()
    };
    this.dealerService.getAll(params).subscribe(
      (res: Dealer[]) => {
        this.dealers = checkBranch ? this.processUniqueDealers(res) : res;
        !(this.isExcludeRoles()
        || this.isExcludeShopManager()
        || this.isShopAdminAccount()
        || this.isDealerAccount()) &&
          this.dealers.unshift({
            id: 0,
            name: 'All Dealers',
            full_name: 'All Dealers',
          });
        this.loadingDealers = false;
      },
      (err) => {
        this.loadingDealers = false;
        this.showErrorMsg(err);
      }
    );
  }

  processUniqueDealers(dealers: Dealer[]): Dealer[] {
    const nameMap = new Map<string, Dealer[]>();

    // Create a map of names to an array of dealers with the same name
    dealers.forEach((dealer) => {
      if (!nameMap.has(dealer.name)) {
        nameMap.set(dealer.name, [dealer]);
      } else {
        nameMap.get(dealer.name)!.push(dealer);
      }
    });

    const uniqueDealers: Dealer[] = [];

    // Create the final array with unique dealers and correct unique_name_id arrays
    nameMap.forEach((dealersWithSameName) => {
      const uniqueIDs = dealersWithSameName.map((dealer) => dealer.id);
      const firstDealer = dealersWithSameName[0];
      firstDealer.unique_name_id = uniqueIDs.filter(
        (id) => id !== firstDealer.id
      );
      uniqueDealers.push(firstDealer);
    });

    return uniqueDealers;
  }
  
  isExcludeRoles(): boolean {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    return (
      group[0] === 'Admin' ||
      group[0] === 'DailyCheckAdmin' ||
      group[0] === 'ENEOSTradingWebUser'
    );
  }

  isAccountBranch(): boolean {
    // check if it is etradmin2-10
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    const username = localStorage.getItem('username');
    return group[0] === 'BuyerDealerExportWebUser' && username !== 'etradmin1';
  }

  isShopAccount(): boolean {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    return group[0] === 'ShopAdminSingleDealer' || group[0] === 'ShopAdminMultipleDealer' || group[0] === 'ShopManager';
  }

  isExcludeShopManager(): boolean {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    return group[0] === 'ShopManager';
  }

  isShopAdminAccount(): boolean {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    return group[0] === 'ShopAdminSingleDealer' || group[0] === 'ShopAdminMultipleDealer';
  }

  isDealerAccount(): boolean {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    return group[0] === 'DealerAdmin';
  }

  isDisabelStaffSelect(): boolean {
    return (
      !this.searchFields['shop'].length ||
      this.searchFields['shop'].length > 1 ||
      this.searchFields['shop'].includes(0) ||
      (!this.searchFields['roles'].length && !this.flyerDistribution)
    );
  }

  removeSelectShop(id: number): void {
    this.searchFields['roles'] = [];
    this.searchFields['staff'] = null;
    this.searchFields.shop = this.searchFields.shop.filter(
      (shop) => shop !== id
    );
    this.getStaffs();
  }

  removeSelectDealer(id: number): void {
    this.searchFields.dealer = this.searchFields.dealer.filter(
      (dealer) => dealer !== id
    );
    this.getShops();
  }

  removeSelectPosotion(role: number): void {
    this.searchFields.roles = this.searchFields.roles.filter(
      (position) => position !== role
    );
  }

  removeSelectBranch(id: number): void {
    this.searchFields.branches = this.searchFields.branches.filter(
      (branch) => branch !== id
    );
    this.getDealers();
  }

  handleSelectShops(): void {
    !this.flyerDistribution && this.handleMultipleSelectedShop();
    this.searchFields['roles'] = [];
    this.getStaffs();
  }

  handleSelectPosition(): void {
    this.searchFields['staff'] = null;
    if (this.searchFields['roles'].includes(0)) {
      this.searchFields['roles'] = [0];
    }
    this.getStaffs();
  }

  handleMultipleSelectedShop(): void {
    if (this.searchFields['shop'].length > 1) {
      this.dailyCheckItems.carWash = false;
      this.dailyCheckItems.coating = false;
      this.dailyCheckItems.repair = false;
      this.dailyCheckItems.eneosElectric = false;
      this.dailyCheckItems.eneosCard = false;
      this.dailyCheckItems.eneKey = false;
      this.dailyCheckItems.engineCleaner = false;
      this.dailyCheckItems.carInspection = false;
      this.dailyCheckItems.lineMember = false;
      this.dailyCheckItems.others = false;
      this.dailyCheckItems.others_2 = false;
      this.dailyCheckItems.others_3 = false;
    }
  }

  getShops(): void {
    this.searchFields['shop'] = [];
    this.searchFields['roles'] = [];
    this.searchFields['staff'] = null;
    this.loadingShops = true;
    if (!this.searchFields['dealer'].length) {
      this.shops = [];
      this.loadingShops = false;
      return;
    }
    if (this.searchFields['dealer'].includes(0)) {
      this.searchFields['dealer'] = [0];
    }
    this.shopService
      .getAll({
        dealer_id: this.getDealerQuery(),
      })
      .subscribe(
        (res: any) => {
          this.shops = res;
          this.shops.forEach((shop) => {
            shop.name_jp = shop.name;
          });
          (!this.isExcludeRoles() && !this.isShopAccount()) &&
            this.shops.unshift({
              id: 0,
              name: 'All Shops',
              name_jp: 'All Shops',
            });
          this.loadingShops = false;
        },
        (err) => {
          this.loadingShops = false;
          this.showErrorMsg(err);
        }
      );
  }

  getStaffs(): void {
    this.searchFields['staff'] = null;
    this.loadingStaffs = true;
    if (this.searchFields['shop'].includes(0)) {
      this.searchFields['shop'] = [0];
      this.staff = [];
      this.loadingStaffs = false;
      return;
    }
    if (!this.searchFields['shop'] || this.searchFields['shop'].length > 1) {
      this.staff = [];
      this.loadingStaffs = false;
      return;
    }
    const params = {
      shop: this.searchFields['shop'],
      roles: this.getRolesQuery(),
    };
    this.settingsService.getStaffs(params).subscribe(
      (res) => {
        this.staff = res;
        this.loadingStaffs = false;
      },
      (error) => {
        this.loadingStaffs = false;
        this.showErrorMsg(error);
      }
    );
  }

  toggleSearchBox(): void {
    this.showSearchBox = !this.showSearchBox;
    setTimeout(() => {
      this.showContents = !this.showContents;
    }, this.showSearchBox ? 0 : 250);
  }

  showErrorMsg(error: HttpErrorResponse): void {
    this.notiService.error(error?.error?.message || error?.message  || error);
  }

  getShopQuery(): string {
    if (this.searchFields.shop.includes(0) || !this.searchFields.shop.length) {
      return this.shops
        .filter((shop) => shop.id !== 0)
        .map((shop) => shop.id)
        .join(',');
    }
    return this.searchFields.shop.join(',');
  }

  getBranchQuery(): string {
    if (!this.searchFields.branches.length) {
      return '';
    }
    if (this.searchFields.branches.includes(0)) {
      return this.branches
        .filter((branch) => branch.id !== 0)
        .map((branch) => branch.id)
        .join(',');
    }
    return this.searchFields.branches.join(',');
  }

  getRolesQuery(): string {
    if (!this.searchFields.roles.length || this.searchFields.roles.includes(0)) {
      return '';
    }
    return this.searchFields.roles.join(',');
  }

  getDealerQuery(): string {
    if (this.flyerDistribution) {
      if (this.searchFields.dealer.includes(0) || !this.searchFields.dealer.length) {
        return this.dealers
          .filter((dealer) => dealer.id !== 0)
          .map((dealer) => dealer.id)
          .join(',');
      }
      return this.searchFields.dealer.join(',');
    } else {
      const uniqueDealers = new Set<number>();
      if (this.searchFields.dealer.includes(0) || !this.searchFields.dealer.length) {
        this.dealers.forEach((dealer) => {
          if (dealer.id !== 0) {
            uniqueDealers.add(dealer.id);
            dealer.unique_name_id?.forEach((id) => uniqueDealers.add(id));
          }
        });
        return Array.from(uniqueDealers).join(',');
      }
      this.searchFields.dealer.forEach((id) => {
        const dealer = this.dealers.find((dealer) => dealer.id === id);
        if (dealer) {
          uniqueDealers.add(id);
          dealer.unique_name_id?.forEach((id) => uniqueDealers.add(id));
        }
      });
      return Array.from(uniqueDealers).join(',');
    }
  }

  search(): void {
    if (!this.searchFields.orderDateAfter) {
      this.notiService.error(this.translate.instant('Please Select Start Date'));
      return;
    }
    if (!this.searchFields.orderDateBefore) {
      this.notiService.error(this.translate.instant('Please Select End Date'));
      return;
    }
    if (this.flyerDistribution || !this.isInternalUser) {
      if (!this.searchFields?.dealer.length) {
        this.notiService.error(this.translate.instant('Please Select Dealer'));
        return;
      }
    } else {
      if (!this.searchFields?.branches.length) {
        this.notiService.error(this.translate.instant('Please Select Branches'));
        return;
      }
    }
    if (!this.getTypes(this.dailyCheckItems)) {
      this.notiService.error(this.translate.instant('Please Select Daily Check Item Type'));
      return;
    }
    const shopNameList = this.searchFields.shop.length
      ? this.searchFields.shop.map((id) => {
          return this.shops.find((x) => x.id === id).name;
        })
      : '';
    const searchData: SearchData = {
      branches: this.getBranchQuery(),
      shop: this.getShopQuery(),
      staff: this.searchFields.staff,
      dealer: this.getDealerQuery(),
      start_date: this.getDate(this.searchFields.orderDateAfter),
      end_date: this.getDate(this.searchFields.orderDateBefore),
      dailyCheckItems: this.getTypes(this.dailyCheckItems),
      dailyCheck: this.dailyChecks,
      flyerDistribution: this.flyerDistribution,
      shopName: shopNameList.toString(),
      one_or_more_achievement: this.oneOrMoreAchievements,
      roles: this.getRolesQuery(),
      staffName: this.searchFields.staff ? this.staff.find(x => x.id === this.searchFields.staff).name : ''
    }
    const cloneDeepData = JSON.parse(JSON.stringify(searchData));
    this.dailyCheckService.parentParams.next(cloneDeepData);
  }

  getTypes(dailyChecks: DailyCheckItemChecks): string {
    return Object.values(dailyChecks)
      .map((eachItem, index) => eachItem && (index + 1))
      .filter(notNull => notNull)
      .join(',');
  }

  reset(): void {
    this.setItems(true);
    this.setDailyChecks(true);
    this.flyerDistribution = false;
    this.resetDropDownSelect();
  }

  resetDropDownSelect(): void {
    this.searchFields = {
      dealer: [],
      shop: [],
      staff: null,
      orderDateAfter: null,
      orderDateBefore: null,
      branches: [],
      roles: []
    };
  }

  export(): void {
    if (this.flyerDistribution && this.searchFields.staff) {
      this.exportStaff();
      return;
    }
    if (this.dailyChecks.checked) {
      this.exportDailyChecks();
      return;
    }
    if (this.flyerDistribution) {
      this.exportFlyerDistributions();
      return;
    }
  }

  exportStaff(): void {
    if (!this.checkRequiredFields()) {
      return;
    }
    if (!this.searchFields.shop || !this.searchFields.staff) {
      !this.searchFields.shop && this.notiService.error(this.translate.instant('Please Select Shop'));
      !this.searchFields.staff && this.notiService.error(this.translate.instant('Please Select Staff'));
      return;
    }
    const params = {
      dealer: this.getDealerQuery(),
      shop: this.getShopQuery(),
      staff: this.searchFields.staff,
      start_date: this.getDate(this.searchFields.orderDateAfter),
      end_date: this.getDate(this.searchFields.orderDateBefore),
    };
    const fileName = `チラシ配布•個人${this.getTodayDate()}.xlsx`;
    this.dailyCheckService.exportStaff(params).subscribe(
      (res) => this.exportExcelFileService.saveFile(res, fileName),
      (error) => this.showErrorMsg(error)
    );
  }

  exportDailyChecks(): void {
    if (!this.checkRequiredFields()) {
      return;
    }
    const params = {
      branch: this.getBranchQuery(),
      dealer: this.getDealerQuery(),
      start_date: this.getDate(this.searchFields.orderDateAfter),
      end_date: this.getDate(this.searchFields.orderDateBefore),
      shop: this.getShopQuery(),
      staff: this.searchFields?.staff || '',
      type: this.getTypes(this.dailyCheckItems),
      roles: this.getRolesQuery(),
      one_or_more_achievement: this.oneOrMoreAchievements,
    }
    const fileName = `デイリーチェック分析${this.getTodayDate()}.xlsx`
    this.dailyCheckService.exportDailyChecks(params).subscribe(
      (res) => this.exportExcelFileService.saveFile(res, fileName),
      (error) => this.showErrorMsg(error),
    );
  }

  exportFlyerDistributions(): void {
    if (!this.checkRequiredFields()) {
      return;
    }
    const params = {
      dealer: this.getDealerQuery(),
      shop: this.getShopQuery(),
      start_date: this.getDate(this.searchFields.orderDateAfter),
      end_date: this.getDate(this.searchFields.orderDateBefore),
      type: this.getTypes(this.dailyCheckItems),
    }
    const fileName = `チラシ配布${this.getTodayDate()}.xlsx`
    this.dailyCheckService.exportFlyerDistribution(params).subscribe(
      (res) => this.exportExcelFileService.saveFile(res, fileName),
      (error) => this.showErrorMsg(error),
    );
  }

  getTodayDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return yyyy + mm + dd;
  }

  checkRequiredFields(): boolean {
    if (
      !this.searchFields.orderDateBefore ||
      !this.searchFields.orderDateAfter
    ) {
      !this.searchFields.orderDateBefore &&
        this.notiService.error(this.translate.instant('Please Select End Date'));
      !this.searchFields.orderDateAfter &&
        this.notiService.error(this.translate.instant('Please Select Start Date'));
      return false;
    }
    if (this.flyerDistribution || !this.isInternalUser) {
      if (!this.searchFields?.dealer.length) {
        this.notiService.error(this.translate.instant('Please Select Dealer'));
        return;
      }
    } else {
      if (!this.searchFields?.branches.length) {
        this.notiService.error(this.translate.instant('Please Select Branche'));
        return;
      }
    }
    return true;
  }

  selectBetween(): void {
    const chooseFlyer = this.flyerDistribution;
    this.flyerDistribution = !chooseFlyer;
    this.setDailyChecks(chooseFlyer);
    this.resetDropDownSelect();
    this.getDealers(
      this.flyerDistribution || !this.isInternalUser ? false : true
    );
  }

  getDate(date: NgbDate | string | undefined): string {
    if (!date || typeof date === 'string') {
      return '';
    }
    return `${date.year}-${date.month < 10 ? '0' + date.month : date.month}-${date.day < 10 ? '0' + date.day : date.day}`;
  }

  setDailyChecks(yesOrNo: boolean): void {
    this.dailyChecks = {
      checked: yesOrNo,
      childItems: {
        dailyCheckAnalysis: yesOrNo,
        checkUpRatio: yesOrNo,
        quotationRatio: yesOrNo,
        confirmedOrderRatio: yesOrNo,
        actualWorkRatio: yesOrNo,
      }
    }
  }

  setItems(yesOrNo: boolean): void {
    this.dailyCheckItems = {
      basic8Items: yesOrNo,
      brakeFluid: yesOrNo,
      coolingWater: yesOrNo,
      battery: yesOrNo,
      engineOil: yesOrNo,
      avCvtFluid: yesOrNo,
      washerFluid: yesOrNo,
      wiperBlade: yesOrNo,
      tire: yesOrNo,
      airCleanFilter: yesOrNo,
      carWash: yesOrNo,
      coating: yesOrNo,
      repair: yesOrNo,
      eneosElectric: yesOrNo,
      eneosCard: yesOrNo,
      eneKey: yesOrNo,
      engineCleaner: yesOrNo,
      carInspection: yesOrNo,
      lineMember: yesOrNo,
      others: yesOrNo,
      others_2: yesOrNo,
      others_3: yesOrNo
    }
  }

  checkIfStaffTable(): void {
    if (this.flyerDistribution && this.searchFields.staff) {
      this.setItems(false);
    }
  }

  disableItems(): boolean {
    return Boolean(this.flyerDistribution && this.searchFields.staff);
  }

  dateAfterSelected(): void {
    if (!this.searchFields.orderDateBefore) {
      this.searchFields.orderDateBefore= this.searchFields.orderDateAfter;
    }
  }

  isShopManager(): boolean {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    return group[0] === 'ShopManager';
  }
}
