import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';
import { MaintenanceSimulationService } from './maintenance-simulation.service';
import { ToastrService } from 'ngx-toastr';
import { AnalysisItem } from './maintenance-simulation.model';

type TabTypes =  'general' | 'shop';

@Component({
  selector: 'app-maintenence-simulation',
  templateUrl: './maintenance-simulation.component.html',
  styleUrls: ['./maintenance-simulation.component.scss'],
})
export class MaintenanceSimulationComponent implements OnInit {
  loading: boolean = false;
  selectedTab: TabTypes = 'general';
  selecedOperation: string = 'self';
  gasoline: number = 0;
  lightOil: number = 0;
  itemList: AnalysisItem[] = [];
  item: string | null;
  unit: string | null;

  constructor(
    private modalService: NgbModal,
    private maintenanceService: MaintenanceSimulationService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchItem();
  }

  addItem(): void {
    const data = {
      item: this.item,
      unit: this.unit,
    };
    this.maintenanceService.post(data).subscribe(
      (res: any) => {
        this.dismissPopup();
        this.searchItem();
      },
      (err) => {
        this.toastrService.error('error');
      }
    );
  }

  reset(): void {
    this.gasoline = 0;
    this.searchItem();
  }

  searchItem(): void {
    this.loading = true;
    let param = new HttpParams();
    param = param.append('gasoline', this.gasoline.toString());
    this.maintenanceService.get(param).subscribe(
      (res: any) => {
        this.itemList = res.results;
        this.loading = false;
      },
      (err) => {
        this.toastrService.error('error');
        this.loading = false;
      }
    );
  }

  changeTab(tab: TabTypes): void {
    this.selectedTab = tab;
  }

  changeOperation(operation: string): void {
    this.selecedOperation = operation;
  }

  openPopup(template): void {
    this.modalService.open(template);
  }

  dismissPopup(): void {
    this.item = null;
    this.unit = null;
    this.modalService.dismissAll();
  }
}
