<app-header [title]="'Daily Check Analysis Export' | translate"></app-header>

<div class="main-content p-3">
  <app-search-box></app-search-box>

  <div class="w-100percent" [ngSwitch]="showingTable">
    <app-daily-check-table *ngSwitchCase="'dailyCheck'"></app-daily-check-table>

    <app-flyer-distribution *ngSwitchCase="'flyerDistribution'"></app-flyer-distribution>

    <app-staff-table *ngSwitchCase="'staff'"></app-staff-table>
  </div>

</div>

