import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DealerComponent } from './dealer.component';

export const DealerRoutes: Routes = [
  {
    path: 'dealer',
    component: DealerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(DealerRoutes)],
  exports: [RouterModule]
})
export class DealerRoutingModule {}
