import {Component, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CarSpecificationService} from './car-specification.service';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-car-specification',
  templateUrl: './car-specification.component.html',
  styleUrls: ['./car-specification.component.scss']
})
export class CarSpecificationComponent implements OnInit {
  originalMakerNames = []
  originalModelNames = []
  fileToUpload: File = null;
  CarSpecifications = [];
  loading: Boolean = false;
  currentPage: Number = 1;
  noOfPage: Number = 1;
  count = 3;
  search = {
    maker: '',
    model: '',
    katashiki: ''
  }

  constructor(
    private modalService: NgbModal,
    private carSpecificationService: CarSpecificationService,
    private notiService: ToastrService,
    private router: Router,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.loadCarData();
    this.getCarDataName();
  }

  getCarDataName() {
    this.carSpecificationService.getCarDataNames().subscribe((res: any) => {
      this.originalMakerNames = res.makers;
      this.originalModelNames = res.models;
    })
  }

  async openDelete(content, item) {
    try {
      const result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title'
      }).result;
      this.deleteCarData(item);
    } catch (e) {
    }
  }

  deleteCarData(id) {
    this.carSpecificationService.delete(id).subscribe(res => {
      this.loadCarData()
    });
  }

  loadCarData() {
    this.loading = true;
    let params = new HttpParams();
    params = params.append('page', this.currentPage.toString());
    params = params.append('maker', this.search['maker']);
    params = params.append('name', this.search['model']);
    params = params.append('katashiki', this.search['katashiki']);
    this.carSpecificationService.getCarData(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.CarSpecifications = res.results;
        this.noOfPage = res.total_pages;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.showErrorMsg(err);
      }
    )
  }

  navigateTo(event) {
    this.currentPage = event;
    this.loadCarData();
  }

  showErrorMsg(msg) {
    this.notiService.error(_.values(msg.error)[0].toString());
  }

  reset() {
    this.search = {
      maker: '',
      model: '',
      katashiki: ''
    }
    this.loadCarData();
  }

  dismissFileSelection() {
    this.fileToUpload = null;
    this.modalService.dismissAll();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile() {
    this.loading = true;
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.carSpecificationService.importCarData(data).subscribe(
      (res: any) => {
        this.modalService.dismissAll();
        this.notiService.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
        this.loading = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.modalService.dismissAll();
        this.loading = false;
        this.router.navigate(['/import-product']);
      }
    )
  }

  openModal(view, user = null) {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalService.open(view, option).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissFileSelection();
      }
    });
  }
}
