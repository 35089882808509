import { Injectable } from '@angular/core';
import {HttpClientService} from '../../services/http-client.service';
import {API_CONSTANT} from '../../services/api.constants';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Staff} from './settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClientService
  ) { }

  getStaffs(param?: Object | HttpParams): Observable<Staff[]> {
    return this.http.get(API_CONSTANT.BASE_URL + '/staffs/', param);
  }
}
