import { Component, OnInit } from '@angular/core';
import { AnnouncementService } from './announcement.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { DealerService } from '../dealer/dealer.service';
import { ShopService } from '../../services/shop.service';
import { CustomDatepickerI18n } from '../../shared/datepicker-i18n';
import * as _ from 'lodash';
import { ConfirmModalService } from '../../components/confirm-modal/confirm-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
})
export class AnnouncementComponent implements OnInit {

  announcements = [];
  currentPage: Number = 1;
  noOfPage: Number = 1;
  loading: Boolean = false;
  announcement;
  type: String = '';
  savingAnnouncement = false;

  file: File;
  imageUrl: any;
  fileChanged = false;

  dealers = [];
  shops = [];
  allObj = {id: 0, name: 'All', name_jp: '全て'};
  loadingDealers = false;
  loadingShops = false;

  constructor(
    private announcementService: AnnouncementService,
    private notiService: ToastrService,
    private modalService: NgbModal,
    private dealerService: DealerService,
    private shopService: ShopService,
    private confirmModal: ConfirmModalService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.loadAnnouncements();
    this.getDealears();
  }

  loadAnnouncements() {
    this.loading = true;
    const params = {
      page: this.currentPage,
    }
    this.announcementService.getAnnouncements(params).subscribe(
      (res: any) => {
        this.announcements = res.results;
        this.noOfPage = res.total_pages;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.showErrorMsg(err);
      }
    )
  }

  getDealears () {
    this.loadingDealers = true;
    this.dealerService.getAll().subscribe(
      (res: any) => {
        this.dealers = res;
        this.dealers.forEach(dealer => {
          dealer.name_jp = dealer.name;
        });
        this.dealers.unshift(this.allObj);
        this.loadingDealers = false;
      },
      (err) => {
        this.loadingDealers = false;
        this.showErrorMsg(err);
      }
    )
  }

  getShops(reset?) {
    reset ? this.announcement['shop_id'] = 0 : {};
    if (!this.announcement['dealer_id'] || this.announcement['dealer_id'][0] === 0) {
      return
    }

    this.loadingShops = true;
    this.shopService.getAll({'dealer_id': this.announcement['dealer_id']}).subscribe(
      (res: any) => {
        this.shops = res;
        this.shops.forEach(shop => {
          shop.name_jp = shop.name;
        });
        this.shops.unshift(this.allObj);
        this.loadingShops = false;
      },
      (err) => {
        this.loadingShops = false;
        this.showErrorMsg(err);
      }
    )
  }

  showErrorMsg(msg) {
    this.notiService.error(_.values(msg.error)[0].toString());
  }

  openModal(view, announcement = null) {
    this.type = announcement ? 'edit' : 'add';
    this.announcement = announcement ?
        {
          id: announcement.id,
          title: announcement.title,
          message: announcement.message,
          startDate: this.getDate(announcement.start_date),
          endDate: this.getDate(announcement.end_date),
          shop_id: announcement.all_shop ? 0 : +announcement.shop_id,
          dealer_id: announcement.all_dealer ? 0 : +announcement.dealer_id,
        }
      :
        {
          title: '',
          message: '',
          startDate: null,
          endDate: null,
          shop_id: 0,
          dealer_id: 0,
        };
    this.imageUrl = this.type === 'add' ? '' : announcement.picture ? _.cloneDeep(announcement.picture) : '';
    this.announcement['dealer_id'] ? this.getShops() : {};
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  dismissEditOrCreate() {
    this.announcement = {};
    this.removeImage();
    this.modalService.dismissAll();
  }

  updateHideInHistory(announcement) {
    const data = new FormData();
    const hideInHistory = !announcement.hide_in_history;
    data.append('hide_in_history', hideInHistory.toString());
    data.append('shop', announcement.shop_id);
    data.append('dealer', announcement.dealer_id);

    this.updateAnnouncement(announcement.id, data);
  }

  save() {
    const data = new FormData();
    data.append('title', this.announcement['title']);
    data.append('message', this.announcement['message']);
    this.fileChanged ? data.append('picture', this.file) : {};
    data.append('start_date', this.getDate(this.announcement.startDate));
    data.append('end_date', this.getDate(this.announcement.endDate));
    data.append('shop', this.announcement['shop_id']);
    data.append('dealer', this.announcement['dealer_id']);
    this.savingAnnouncement = true;
    this.type === 'add' ?
      this.createAnnouncement(data)
    :
      this.updateAnnouncement(this.announcement.id, data);
  }

  createAnnouncement(data) {
    this.announcementService.createAnnouncement(data).subscribe(
      (res: any) => {
        this.loadAnnouncements();
        this.dismissEditOrCreate();
        this.savingAnnouncement = false;
        this.notiService.success(this.translateService.instant('Successfully Created'));
      },
      (err) => {
        this.savingAnnouncement = false;
        this.showErrorMsg(err);
      }
    );
  }

  updateAnnouncement(id, data) {
    this.announcementService.updateAnnouncement(id, data).subscribe(
      (res: any) => {
        this.loadAnnouncements();
        this.dismissEditOrCreate();
        this.savingAnnouncement = false;
        this.notiService.success(this.translateService.instant('Successfully Updated'));
      },
    (err) => {
      this.savingAnnouncement = false;
      this.showErrorMsg(err);
      }
    );
  }

  openConfirm(id) {
    this.confirmModal.open(
      'Delete Announcement',
      'Are you sure you want to delete this ?',
      () => {
        this.deleteAnnouncement(id)
      }
    );
  }

  deleteAnnouncement(id) {
    this.announcementService.deleteAnnouncement(id).subscribe(
      (res: any) => {
        this.loadAnnouncements();
        this.notiService.success(this.translateService.instant('Deleted'));
      },
      (err) => {
        this.showErrorMsg(err);
      }
    );
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;
          this.fileChanged = true;
          const reader = new FileReader();
          fileEntry.file(file => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const image_result = reader.result as string;
              this.file['base64'] = image_result.split(',')[1];
              this.imageUrl = reader.result;
            };
          });
        });
      }
    }
  }

  removeImage() {
    this.imageUrl = '';
    this.file = null;
    this.fileChanged =  false;
  }

  getDate(date) {
    let processedDate;
    if (!date) {
      return null;
    }
    if (typeof(date) === 'string') {
      processedDate = date.split('-');
      processedDate = {
        year: +processedDate[0],
        month: +processedDate[1],
        day: +processedDate[2],
      }
    } else {
      processedDate = _.values(date).map(x => x < 10 ? '0' + x : x).join('-');
    }
    return processedDate;
  }

  navigateTo(event) {
    this.currentPage = event;
    this.loadAnnouncements();
  }

  getLang() {
    return this.translateService.currentLang.toString();
  }
}
