<app-header
  title="{{ (showingDetail ? 'Order Detail' : 'Order History') | translate }}"
  [hasCustomAddButton]="false"
  [orderCustomBtn]="showingDetail"
  (goToList)='showList()'>
</app-header>

<div class='main-content'>
  <div *ngIf="!showingDetail">
    <!-------------------------------Search Box--------------------------------->
    <div class='row no-gutter p-3'>
      <div class='search-box' [ngClass]="{'disabled': loadingOrders || loadingOrderItems}">
        <h5 class='row no-gutter'>
          {{ 'Search' | translate }}
        </h5>

        <!----------------- 1st Row ------------------->
        <div class='row no-gutter h-auto min-h-80'
            *ngIf="!CCUser">
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'Buyer Username' | translate }}
            </label>
            <div class='col-8 p-0'>
              <p-autoComplete
                class='w-100percent'
                [(ngModel)]="search['username']"
                [multiple]="true"
                [suggestions]="filterUserName"
                [field]=""
                [emptyMessage]="'User not found' | translate"
                [forceSelection]="true"
                (completeMethod)="onUserNameFiltering($event)"
                (onUnselect)="onUserNameUnselectValue($event)"
              >
                <ng-template let-value pTemplate="selectedItem">
                  <span class="ui-autocomplete-token-label">{{ value }}</span>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'orderHistory.Dealer' | translate }}
            </label>
            <ng-select
              class="col-8 p-0 custom-selector"
              [clearable]="true"
              [searchable]="true"
              [items]="dealers"
              bindLabel="name"
              bindValue="name"
              [disabled]="loadingDealer"
              [loading]="loadingDealer"
              (change)="getShops()"
              [(ngModel)]="search['dealer']">
            </ng-select>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'orderHistory.Shop' | translate }}
            </label>
            <ng-select
              class="col-8 p-0 custom-selector"
              [clearable]="true"
              [searchable]="true"
              [multiple]="true"
              [items]="shops"
              [disabled]="loadingShop"
              [loading]="loadingShop"
              bindLabel="name"
              bindValue="name"
              [(ngModel)]="search['shop']">
            </ng-select>
          </div>
        </div>

        <!----------------- 2nd Row ------------------->
        <div class='row no-gutter h-auto min-h-80'
            *ngIf="!CCUser">
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'Product Group1' | translate }}
            </label>
            <ng-select
              class="col-8 p-0 custom-selector"
              [clearable]="true"
              [searchable]="true"
              [items]="categories"
              [multiple]='true'
              bindLabel="category_name"
              bindValue="id"
              [(ngModel)]="search['category']">
            </ng-select>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'Product Name' | translate }}
            </label>
            <p-chips class='col-8 p-0'
                [(ngModel)]="search['productName']"></p-chips>
          </div>
          <div class='col-4 row no-gutter align-self-center pr-5'>
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'Product Code' | translate }}
            </label>
            <p-chips class='col-8 p-0'
                [(ngModel)]="search['productCode']"></p-chips>
          </div>
        </div>

        <!----------------- 3rd Row ------------------->
        <div class='row no-gutter h-auto' *ngIf="!CCUser">
          <div class="col-8 row no-gutter d-flex align-items-center">
            <div class="col-auto d-flex align-items-center p-0">
              <input type="checkbox"
                     class="h-20 w-20"
                     [checked]="search['selected_only']"
                     (click)="changeSelectedOnly()"
                     [(ngModel)]="search['selected_only']">
            </div>
            <label class="col-11 m-0">
              {{ 'Show Only the Searching Category' | translate }}
            </label>
          </div>
        </div>

        <!----------------- 4th Row ------------------->
        <div class='row no-gutter h-auto'>
          <div class='col-4 row no-gutter align-self-center pr-5'
              *ngIf="!CCUser">
            <label class='col-4 pl-0 m-0 d-flex align-items-center'>
              {{ 'Order Number' | translate }}
            </label>
            <p-chips class='col-8 p-0'
                [(ngModel)]="search['orderID']"></p-chips>
          </div>

          <div class='col-8 row no-gutter align-self-center pr-5'>
            <label class='w-16percent pl-0 m-0 d-flex align-items-center'>
              {{ 'Order Date' | translate }}
            </label>

            <div class='w-84percent row no-gutters'>
              <div class='w-48percent row no-gutter'>
                <input
                  class="col-7 from-control h-35 align-self-center border rounded px-1"
                  type='text'
                  (click)="d.toggle()"
                  name="dp"
                  [(ngModel)]="search['startDate']"
                  ngbDatepicker
                  #d="ngbDatepicker">

                <div class='col-5 px-2'>
                  <ngb-timepicker
                    [(ngModel)]="search['startTime']">
                  </ngb-timepicker>
                </div>
              </div>
              <div class='w-4percent wiggle-class'>
                ~
              </div>
              <div class='w-48percent row no-gutter pl-2'
                  >
                <input
                  class="col-7 from-control h-35 align-self-center border rounded px-1"
                  type='text'
                  (click)="g.toggle()"
                  name="dp"
                  [minDate]="search['startDate']"
                  [disabled]="!search['startDate']"
                  [(ngModel)]="search['endDate']"
                  ngbDatepicker
                  #g="ngbDatepicker">

                <div class='col-5 pl-2 pr-0'>
                  <ngb-timepicker
                    [(ngModel)]="search['endTime']">
                  </ngb-timepicker>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!----------------- 5th Row ------------------->

        <!------------------------------6th Row-------------------------------->
        <div class='row no-gutter h-50'>
          <div class='col-12 d-flex justify-content-end align-items-end pr-5'>
            <button class="btn btn-sm d-flex align-items-center btn-primary h-30"
                    (click)="searchOrder()">
              <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
              {{'product-image.search' | translate}}
            </button>
            <button class="btn btn-sm d-flex align-items-center btn-secondary ml-3 h-30"
                    (click)="resetSearch()">
              <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
              {{'product-image.reset' | translate}}
            </button>
            <button class="btn btn-sm d-flex align-items-center ml-3 h-30 btn-outline-primary hover-detect"
                (click)="openModal(importModal)">
              <div class="plus-icon"></div>
              {{ 'Import Delivery Date' | translate }}
            </button>
            <button class="btn btn-sm d-flex align-items-center btn-light ml-3 h-30" *ngIf='!admin'
                    (click)='!exportingWebUser ? downloadWebUser() : {}'>
              <div class="custom-img mr-2"></div>
              {{'Export Order' | translate}}
              <img src='assets/images/loading.svg' width='20' height='20' class='ml-1' *ngIf="exportingWebUser">
            </button>
            <div ngbDropdown class="d-inline-block" [autoClose]="'outside'" *ngIf='admin'>
              <button class="btn btn-outline-primary btn-sm d-flex align-items-center hover-custom ml-3"
                      id="dropdownBasic1" ngbDropdownToggle >
                <div class="custom-img mr-2"></div>
                {{'Export Order' | translate}}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)='!exportingMSI ? downloadMSI() : {}'>
                  {{'Export MSI' | translate }}
                  <img src='assets/images/loading.svg' width='20' height='20' class='ml-1' *ngIf="exportingMSI">
                </button>
                <button ngbDropdownItem (click)='!exportingSB ? downloadSB() : {}'>
                  {{'Export SB' | translate }}
                  <img src='assets/images/loading.svg' width='20' height='20' class='ml-1' *ngIf="exportingSB">
                </button>
                <button ngbDropdownItem (click)='!exportingDelivered ? downloadDelivered() : {}'>
                  {{'Export Delivered Item' | translate }}
                  <img src='assets/images/loading.svg' width='20' height='20' class='ml-1' *ngIf="exportingDelivered">
                </button>
                <button *ngIf='admin' ngbDropdownItem (click)='downloadMissingEmail()'>
                  {{'Missing Email' | translate }}
                  <img src='assets/images/loading.svg' width='20' height='20' class='ml-1' *ngIf="exportingMissingEmail">
                </button>
              </div>
            </div>

            <button class="btn btn-sm d-flex align-items-center ml-3 h-30 btn-outline-primary hover-detect hover-custom" *ngIf="sellerWebUser" (click)="!exportingDelivered ? downloadDelivered() : {}">
              <div class="custom-img mr-2"></div>
              {{ 'Export Delivered Item' | translate }}
              <img src="assets/images/loading.svg" width="20" height="20" class="ml-1" *ngIf="exportingDelivered">
            </button>

          </div>
        </div>
      </div>
      <!-- Tab Selector  -->
      <div class="content">
        <div class="bg-white custom-radius p-y-15">
          <div class="tabs-row">
            <div class="tab"
                [ngClass]="{'selected': selectedTab === 'By Order'}"
                (click)="changeTab('By Order')">
              {{ "Search Result by Order" | translate }}
            </div>
            <div class="tab"
                [ngClass]="{'selected': selectedTab === 'By Product'}"
                (click)="changeTab('By Product')">
              {{ 'Search Result by Product' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-100percent p-b-15 row no-gutter justify-content-between"
          *ngIf="selectedTab === 'By Product'">
        <div class="w-100percent d-flex justify-content-start row no-gutter"
            *ngIf="showChangeProductType">
          <div class="row no-gutter col-auto d-flex align-items-center m-l-15">
            <div class="col-auto d-flex align-items-center p-0">
              <input type="checkbox"
                  class="h-20 w-20" (click)="selectedAll()"
                  [checked]="selectedAllCheck">
            </div>
            <span class="p-l-10">
              {{ 'Select All' | translate }}
            </span>
          </div>
          <div ngbDropdown
              class="d-inline-block"
              [autoClose]="'outside'">
            <button class="btn btn-primary btn-sm d-flex align-items-center hover-custom ml-3"
                id="dropdownBasic1"
                ngbDropdownToggle>
              {{'Change Product Type' | translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem
                  (click)="openPopup(confirmModal, productTypesChoice['Self Used'].value)">
                {{ 'To Self Used' | translate}}
              </button>
              <button ngbDropdownItem
                  (click)="openPopup(confirmModal, productTypesChoice['Purchased'].value )">
                {{ 'To Purchased' | translate}}
              </button>
              <button ngbDropdownItem
                  (click)="openPopup(confirmModal, productTypesChoice['Other'].value)">
                {{ 'To Other' | translate}}
              </button>
            </div>
          </div>
          <button class="btn btn-sm d-flex align-items-center btn-secondary ml-3 h-30"
              (click)="toggleChangeProductType()">
            {{ 'Cancel' | translate }}
          </button>
        </div>
        <div class=" w-100percent d-flex justify-content-end"
            *ngIf="!showChangeProductType">
          <button class="btn btn-sm d-flex align-items-center btn-primary h-30 m-r-15"
              (click)="toggleChangeProductType()">
            {{ 'Change Product Type' | translate}}
          </button>
        </div>
      </div>
      <!---------------------------Order Table--------------------------------->
      <div class='order-table'
          *ngIf="!loadingOrders && selectedTab=== 'By Order'">
        <table class="table table-hover text-center table-borderless table-striped mb-5"
            style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
            *ngIf="!loadingOrders && orders.length">
          <thead>
            <tr>
              <th width="5%"></th>
              <th width='12%'>{{ 'Status' | translate }}</th>
              <th width="20%">{{'Order Number' | translate }}</th>
              <th width="10%">{{'Dealer' | translate }}</th>
              <th width="10%">{{'Shop' | translate }}</th>
              <th width="20%">{{'Delivery Name' | translate }}</th>
              <th width="10%">{{'Order Date' | translate }}</th>
              <th width="12%">{{'Total Price (without tax)' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orders; let i = index;">
              <td class="align-middle detail-icn"
                  (click)="showOrderDetail(item)">
                <img src='assets/svgs/black_info_circle.svg'
                    width='24'
                    height='24'>
              </td>
              <td class='align-middle complete-or-not'>
                <div class="status-wrapper">
                  <div class='status' [ngClass]="{'complete': item.complete_or_not === 1,
                    'not-complete': item.complete_or_not === 2,
                    'cancelled': item.complete_or_not === 3 || item.status === 1,
                    'akaden': item.complete_or_not === 6 || item.status === 2}">
                    {{ getStatusString(item) | translate }}
                  </div>
                  <div class="partial-cancel"
                      *ngIf="item.partial_cancel && item.complete_or_not !== 3">
                    一部<br>取消
                  </div>
                  <div class="partial-akaden"
                      *ngIf="item.partial_akaden && item.complete_or_not !== 6">
                    一部<br>赤伝
                  </div>
                </div>
              </td>
              <td class="align-middle">{{ item.puchese_id || '' }}</td>
              <td class="align-middle">{{ item.order_snapshot?.dealer_name || '' }}</td>
              <td class="align-middle">{{ item.order_snapshot?.buyer_name || '' }}</td>
              <td class='align-middle'>{{ item.order_snapshot?.delivery_name || '' }}</td>
              <td class="align-middle">{{ item.created_at | date: 'yyyy-MM-dd' || '' }}</td>
              <td class="align-middle"> ¥ {{ item.price_total | number: '1.0-0' }}</td>
            </tr>
          </tbody>
        </table>
        <div class='row no-gutter'
            *ngIf="!loadingOrders && orders.length">
          <ngb-pagination [collectionSize]="count"
              [(page)]="currentPage"
              [maxSize]="5"
              [pageSize]="40"
              (pageChange)='navigateTo($event)'
              [boundaryLinks]="true">
          </ngb-pagination>
        </div>
        <div class='row no-gutter d-flex justify-content-center'
            *ngIf='!loadingOrders && !orders.length'>
          <h1 class='text-muted'>{{ 'noDataFound' | translate }}</h1>
        </div>
      </div>
      <!---------------------------Product Table--------------------------------->
      <div class='order-table'
          *ngIf="!loadingOrderItems && selectedTab=== 'By Product'">
        <table class="table table-hover text-center table-borderless table-striped mb-5"
            style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
            *ngIf="!loadingOrderItems && orderItems.length">
          <thead>
            <tr>
              <th width="5%"></th>
              <th width="10%">{{ 'Status' | translate }}</th>
              <th width="20%">{{'Product Name' | translate }}</th>
              <th width="9%">{{'Product Code' | translate }}</th>
              <th width="9%">{{'Unit' | translate }}</th>
              <th width="9%">{{'Unit Price' | translate }}</th>
              <th width="9%">{{'Total Price' | translate }}</th>
              <th width="20%">{{'Order Number' | translate }}</th>
              <th width="9%">{{'Order Date' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderItems; let i = index;">
              <td class="align-middle detail-icn">
                <img *ngIf="!showChangeProductType"
                    src='assets/svgs/black_info_circle.svg'
                    width='24'
                    height='24'
                    (click)="showOrderItemDetail(item)">
                <input *ngIf="showChangeProductType"
                    type="checkbox"
                    (change)="checkSelect()"
                    [checked]="item.is_selected"
                    [(ngModel)]="item.is_selected"
                    class="h-20 w-20">
              </td>
              <td class="align-middle">
                <div class='shipment-status'
                    [ngStyle]="{'background-color': shipmentStatus[item.status].background,
                                          'color': shipmentStatus[item.status].font }">
                  {{ shipmentStatus[item.status].word | translate }}
                </div>
              </td>
              <td class="align-middle">
                <div class="w-100percent row no-gutter">
                  <div class="w-100percent d-flex justify-content-start">
                    <div class="product-type"
                        [ngStyle]="{'background-color': productType[item.type].background,
                                    'color': productType[item.type].font }"
                        [ngClass]="{'border': item.type === 'Other'}">
                      {{ item.type | translate }}
                    </div>
                  </div>
                  <div class="w-100percent d-flex justify-content-start m-t-5"
                      *ngIf="item.product_name">
                    {{ item.product_name }}
                  </div>
                </div>
              </td>
              <td class="align-middle">
                {{ item.product_code }}
              </td>
              <td class="align-middle">
                {{ item.quantity }}
              </td>
              <td class="align-middle">
                ¥ {{ item.price | number: '1.0-0' }}
              </td>
              <td class="align-middle">
                ¥ {{ item.price_total | number: '1.0-0' }}
              </td>
              <td class="align-middle"> {{ item.order_number}}</td>
              <td class="align-middle">{{ item.created_at | date: 'yyyy-MM-dd' || '' }}</td>
            </tr>
          </tbody>
        </table>
        <div class='row no-gutter'
            *ngIf="!loadingOrders && orders.length">
          <ngb-pagination [collectionSize]="orderItemCount"
              [(page)]="orderItemCurrentPage"
              [maxSize]="5"
              [pageSize]="40"
              (pageChange)='navigateToOrderItem($event)'
              [boundaryLinks]="true">
            </ngb-pagination>
    </div>
        <div class='row no-gutter d-flex justify-content-center'
            *ngIf='!loadingOrderItems && !orderItems.length'>
          <h1 class='text-muted'>{{ 'noDataFound' | translate }}</h1>
        </div>
      </div>
      <app-loading class="w-100 my-3"
          *ngIf="(loadingOrders && selectedTab === 'By Order') || (loadingOrderItems && selectedTab=== 'By Product')">
      </app-loading>
    </div>
  </div>

  <div *ngIf="showingDetail">
    <app-order-detail
      [orderDetail]="orderDetail"
      [searchParams]="searchParams">
    </app-order-detail>
  </div>
</div>

<ng-template #confirmModal>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="d-flex text-center m-0">
        {{ 'Product Type Change Confirmation' | translate }}
      </h4>
    </div>
  </div>
  <div class="modal-body mx-3 p-t-15 mb-3">
    <span>
      <span *ngIf="getLang() === 'en'">
        {{ 'Are you sure you want to change type of the selected products to be' | translate }}
      </span>
      <span class="font-weight-bold"
          *ngIf="selectedProductType === productTypesChoice['Self Used'].value ">
        {{ 'Self Used' | translate }}
      </span>
      <span class="font-weight-bold"
          *ngIf="selectedProductType === productTypesChoice['Purchased'].value ">
        {{ 'Purchased' | translate }}
      </span>
      <span class="font-weight-bold"
          *ngIf="selectedProductType === productTypesChoice['Other'].value ">
        {{ 'Other' | translate }}
      </span>
      <span *ngIf="getLang() === 'ja'">
        {{ 'Are you sure you want to change type of the selected products to be' | translate }}
      </span>
      ?
    </span>
  </div>
  <div class="modal-footer border-top-0">
    <button class="btn btn-secondary"
        (click)="dismiss()">{{'Cancel' | translate}}</button>
    <button class="btn btn-danger"
        (click)="changeProductType()">{{ 'Confirm' | translate}}</button>
  </div>
</ng-template>

<ng-template #importModal>
  <div class="modal-header">
    {{'Import Order Delivery Date' | translate }}
  </div>
  <div class="modal-body">
    <input type="file"
        id="file"
        (change)="handleFileInput($event.target.files)">
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary"
        (click)="dismissFileSelection()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button"
        class="btn btn-outline-primary"
        (click)="uploadFile()"
        [disabled]="loadingImport || !fileToUpload"
        [loading]="loadingImport">
      {{ 'Upload' | translate }}
    </button>
  </div>
</ng-template>

