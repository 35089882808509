<app-header [title]="'Daily Check Only User' | translate"></app-header>

<div class="w-100percent">
  <div class="w-100percent mb-3 d-flex align-items-start justify-content-end">
<!--    <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center"-->
<!--            (click)="getBuyerDealerWebUsers()">-->
<!--      <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">-->
<!--      {{'search' | translate}}-->
<!--    </button>-->
<!--    <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center"-->
<!--            (click)="reset()">-->
<!--      <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">-->
<!--      {{'reset' | translate}}-->
<!--    </button>-->

    <button class="btn btn-sm mt-4 btn-outline-primary mr-3 d-flex align-items-center"
            (click)='import(importTemplate)'>
      <img src="assets/svgs/blue_plus_icon.svg" height="20" class="mr-1" width="auto">
      {{'Import' | translate}}
    </button>
  </div>

  <div class="w-100percent px-3">
    <table class="w-100percent">
      <thead>
      <tr>
        <th class="text-center w-15percent top-left-round">
          {{'Username' | translate }}
        </th>
        <th class="text-center w-15percent">
          {{'Name' | translate}}
        </th>
        <th class="text-center w-35percent">
          {{'Dealer' | translate}}
        </th>
        <th class="text-center w-35percent">
          {{'Shop' | translate}}
        </th>
        <th class="w-5percent top-right-round"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of dailyCheckTabletUsers">
        <td class="text-center">
          {{ user.username }}
        </td>
        <td class="text-center">
          {{ user.family_name }}
        </td>
        <td class="text-center">
          {{ user.shops[0].dealerName }}
        </td>
        <td class="text-center">
          {{ user.shops[0].name }}
        </td>
        <td class="text-center pointer">
          <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
               (click)="edit(editTemplate, user)">
        </td>
      </tr>
      </tbody>
    </table>
    <div class='row no-gutter' *ngIf="!loading && this.dailyCheckTabletUsers.length">
      <ngb-pagination
        [collectionSize]="count"
        [(page)]="currentPage"
        [maxSize]="5"
        [pageSize]="40"
        (pageChange)='navigateTo($event)'
        [boundaryLinks]="true">
      </ngb-pagination>
    </div>
  </div>
</div>

<ng-template #editTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-center">
      {{'Daily Check Only User' | translate}}
    </h4>
  </div>
  <div class="modal-body">
    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 align-self-center">
        {{'Dealer' | translate}}
      </label>
      <div class="col-8 p-l-0">
        {{ editingUser?.shops[0].dealerName }}
      </div>
    </div>
    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 align-self-center">
        {{'Name' | translate}}
      </label>
      <input
        type='text'
        class="col-8 form-control"
        [(ngModel)]="editingUser.family_name"
        [placeholder]="'Input name' | translate">
    </div>

    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 align-self-center">
        {{'Username' | translate}}
      </label>
      <input
        type='text'
        class="col-8 form-control"
        [(ngModel)]="editingUser.username"
        [placeholder]="'Input Username' | translate">
    </div>

    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 align-self-center">
        {{'Password' | translate}}
      </label>
      <input
        autocomplete="new-password"
        type='password'
        class="col-8 form-control"
        [(ngModel)]="editingUser.password"
        [placeholder]="'Input Password' | translate">
    </div>

  </div>
  <div class="modal-footer border-top-0 mr-3">
    <button class="btn btn-secondary"
            (click)="cancelEdit()">
      {{'Cancel' | translate}}
    </button>
    <button class="btn btn-primary"
            (click)="saveEdit()">
      {{'Save' | translate}}
    </button>
  </div>
</ng-template>


<ng-template #importTemplate>
  <div class="modal-header">
    {{'Daily Check Only User' | translate }}
  </div>
  <div class="modal-body">
    <input type="file"
           id="file"
           (change)="handleFileInput($event.target.files)" accept=".xls, .xlsx">
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissFileSelection()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="uploadFile()">
      {{ 'Upload' | translate }}
    </button>
  </div>
</ng-template>
