<app-header [title]=" 'Calculation History Search/Export' | translate"></app-header>
<div class="content">
  <div class="w-100percent p-3 px-4">
    <h5 class="row no-gutter p-b-10 p-t-50">
      {{ 'Search' | translate}}
    </h5>
    <div class="row no-gutter p-y-5 col-md-5 d-flex align-items-center">
      <div class="w-40percent">
        {{'Shop Code'| translate}}
      </div>
      <div class="w-50percent">
        <input type="text"
            class="w-100 form-control">
      </div>
    </div>
    <div class="row no-gutter p-y-5 col-md-5 d-flex align-items-center">
      <div class="w-40percent">
        {{'Operation Type' | translate}}
      </div>
      <div class="w-50percent d-flex justify-content-between">
        <button class="btn w-48percent button-color"
            [ngClass]="{'selected': selecedOperation === 'self'}">
          {{'Self'|translate}}
        </button>
        <button class="btn w-48percent button-color"
            [ngClass]="{'selected': selecedOperation === 'full'}">
          {{'Full'|translate}}
        </button>
      </div>
    </div>
    <div class="row no-gutter p-y-10">
      <div class="w-40percent">
        {{'Monthly fuel oil sales volume' | translate}}
      </div>
    </div>
    <div class="row no-gutter p-y-5 col-md-5 d-flex align-items-center">
      <div class="w-40percent">
        {{'Gasoline' | translate}}
      </div>
      <div class="w-50percent">
        <input type="text"
            [(ngModel)]="gasoline"
            class="w-100 form-control"
            [readOnly]="true">
      </div>
      <div class="w-10percent text-center">Kl</div>
    </div>
    <div class="row no-gutter p-y-5 col-md-5">
      <div class="w-40percent">
        {{'Light Oil' | translate}}
      </div>
      <div class="w-50percent">
        <input type="text"
            class="w-100 form-control"
            [(ngModel)]="lightOil"
            [readOnly]="true">
      </div>
      <div class="w-10percent text-center">Kl</div>
    </div>
    <div class="row no-gutter p-y-5 col-md-5 d-flex align-items-center">
      <div class="w-40percent">
        {{'Year/Month' | translate}}
      </div>
      <div class="w-50percent form-control d-flex align-items-center justify-content-between"
          (click)="d.toggle()">
        <input type="text"
            class="no-border w-80percent"
            placeholder="yyyy-mm-dd"
            ngbDatepicker
            [readOnly]="true"
            #d="ngbDatepicker" />
        <i class="fa fa-calendar"></i>
      </div>
    </div>
    <div class="d-flex justify-content-end row no-gutter">
      <button class="btn btn-sm d-flex align-items-center btn-secondary h-30">
        <img src="assets/svgs/reset_icon.svg"
            height="20"
            alt="reset icon"
            class="mr-1"
            width="auto">
        {{'Reset' | translate}}
      </button>
      <button class="btn btn-sm d-flex align-items-center btn-primary h-30 ml-3"
          (click)="searchItem()">
        <img src="assets/svgs/search_len.svg"
            height="20"
            alt="search lens icon"
            class="mr-1"
            width="auto">
        {{'Search' | translate}}
      </button>
      <button class="btn btn-sm d-flex align-items-center btn-outline-primary h-30 ml-3 hover-custom"
          (click)="exportFile()">
        <div class="custom-img mr-2"></div>
        {{'Export' | translate}}
      </button>
    </div>
    <div class="row no-gutter d-flex justify-content-center align-items-center p-t-10">
      <span class="p-r-15">{{'Calculation record' | translate}}</span>
      <ngb-pagination [collectionSize]="count"
          [(page)]="currentPage"
          [maxSize]="5"
          [pageSize]="40">
      </ngb-pagination>
    </div>
    <div class="row no-gutter p-t-5">
      <h5>{{'Potential Data' | translate}}</h5>
    </div>
    <app-loading *ngIf="loading"></app-loading>
    <div class="overflow-x-auto"
        *ngIf="!loading && itemList.length">
      <table class="table table-striped min-w-800px">
        <thead>
          <tr>
            <th class="w-5percent"></th>
            <th class="w-22percent">{{'Analysis Item' | translate}}</th>
            <th class="w-7percent text-center">{{'Unit' | translate}}</th>
            <th class="w-7percent text-center">{{'Potential Sales Volume' | translate}}</th>
            <th class="w-10percent text-center">{{'Sales Performance' | translate}}</th>
            <th class="w-7percent text-center">{{'Achievement Ratio' | translate}}</th>
            <th class="w-7percent text-center">{{'Gap with Potentials' | translate}}</th>
            <th class="w-10percent text-center">{{'Greeting Ratio' | translate}}</th>
            <th class="w-10percent text-center">{{'Number of Checkup' | translate}}</th>
            <th class="w-10percent text-center">{{'Number of QT' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of itemList let i = index;">
            <td class="text-center">{{ i+1 }}</td>
            <td>{{ item.item | translate }}</td>
            <td class="text-center">{{ item.unit | translate }}</td>
            <td class="text-center">{{ item.potential_sales }}</td>
            <td><input type="text" [(ngModel)]="item.sales_performance"
                  class="form-control w-100percent text-center"></td>
            <td class="text-center">{{ item.achievement_ratio |  number: '1.0-0' }}</td>
            <td class="text-center">{{ item.gap_with_potential |  number: '1.0-0' }}</td>
            <td><input type="text" [(ngModel)]="item.greeting_ratio"
                  class="form-control w-100percent text-center"></td>
            <td><input type="text" [(ngModel)]="item.number_of_checkup"
                  class="form-control w-100percent text-center"></td>
            <td><input type="text" [(ngModel)]="item.number_of_qt"
                  class="form-control w-100percent text-center"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
