<app-header [title]="'Seller Web Users' | translate"></app-header>
<div class='main-content px-3'>
  <!----------------------------Button Row---------------------------------------->
  <div class='row no-gutter d-flex justify-content-between my-3'>
    <div class='col-7 row no-gutter'>
      <div class='col-4 pl-0'>
        <label class='pl-1'>{{'Username' | translate }}</label>
        <input type='text'
          class='form-control'
          [placeholder]="'Search Username' | translate"
          [(ngModel)]="search['userName']">
      </div>
      <div class='col-4 pl-0'>
        <label class='pl-1'>{{'Email' | translate }}</label>
        <input type='text'
          class='form-control'
          [placeholder]="'Search Email' | translate "
          [(ngModel)]="search['email']">
      </div>
      <div class='col-4 p-0 d-flex align-self-end'>
        <button class="btn btn-sm d-flex align-items-center btn-primary mr-3 h-30"
          (click)="getSellerWebUsers()">
          <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
          {{'product-image.search' | translate}}
        </button>
        <button class="btn btn-sm d-flex align-items-center btn-secondary mr-3 h-30"
          (click)="resetSearch()">
          <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
          {{'product-image.reset' | translate}}
        </button>
      </div>
    </div>


    <div class='col-5 row no-gutter align-self-end justify-content-end'>
      <button class="btn btn-sm btn-outline-primary hover-detect"
              (click)='openModal(importModal)'>
        <div class="plus-icon"></div>
        {{'Import Seller Web Users' | translate}}
      </button>

      <button class="btn btn-sm btn-primary d-flex ml-3 align-items-center"
              (click)="openModal(editOrCreateModal)">
        <img src="assets/svgs/plus_icon.svg" height="20" width="auto" class='m-r-10'>
        {{'Create Seller Web User' | translate}}
      </button>
    </div>
  </div>

  <!---------------------------Table Row------------------------------------------>
  <div class='row no-gutter' *ngIf="!loading && sellerWebUsers.length">
    <table class="table table-hover text-center table-striped">
      <thead class="thead-light">
        <tr>
          <th>ID</th>
          <th>{{'Name' | translate }}</th>
          <th>{{'Username' | translate}}</th>
          <th>{{'Email' | translate }}</th>
          <th>{{ 'edit' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of sellerWebUsers; let rowIndex = index">
          <td class="align-middle">{{ user.id }}</td>
          <td class='align-middle'>{{ user.name }}</td>
          <td class='align-middle'>{{ user.user }}</td>
          <td class='align-middle'>{{ user.email }}</td>
          <td class="v-middle" (click)="openModal(editOrCreateModal, user)">
            <img src="assets/svgs/edit_pen.svg" height="28" width="auto">
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination
      [current]="currentpage"
      [pages]="pageCount"
      (navigate)="navigateTo($event)">
    </app-pagination>
  </div>
  <div class='row no-gutter d-flex justify-content-center mt-5'
       *ngIf='!loading && !sellerWebUsers.length'>
    <h1 class='text-muted'>{{ 'noDataFound' | translate }}</h1>
  </div>

  <app-loading class="w-100" *ngIf='loading'></app-loading>
</div>

<ng-template #editOrCreateModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ (type === 'add' ? 'Create Seller Web User' : 'Edit Seller Web User') | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissUser()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!------------1st Row------------->
    <div class='row no-gutter mb-3'>
      <label class='col-4 m-0 align-self-center'>{{'Name' | translate}}</label>
      <input
        type='text'
        class='col-8 form-control'
        [(ngModel)]="user['name']"
        [placeholder]="'Input Name' | translate "
        [ngClass]="{'is-invalid': errorMsg['name']}"
        (ngModelChange)="errorMsg['name'] = false">
    </div>

    <!------------2nd Row------------->
    <div class='row no-gutter mb-3'>
      <label class='col-4 m-0 align-self-center'>{{'Email' | translate}}</label>
      <input
        autocomplete="false"
        type='text'
        class='col-8 form-control'
        [placeholder]="'Input Email' | translate "
        [(ngModel)]="user['email']"
        [ngClass]="{'is-invalid': errorMsg['email']}"
        (ngModelChange)="errorMsg['email'] = false"
        [disabled]="type === 'edit'">
    </div>

    <!------------3rd Row------------->
    <div class='row no-gutter mb-3'>
      <label class='col-4 m-0 align-self-center'>{{'Username' | translate}}</label>
      <input
        type='text'
        class='col-8 form-control'
        [(ngModel)]="user['user']"
        [placeholder]="'Input Username' | translate "
        [ngClass]="{'is-invalid': errorMsg['user']}"
        (ngModelChange)="errorMsg['user'] = false"
        [disabled]="type === 'edit'">
    </div>

    <!------------4thrd Row------------->
    <div class='row no-gutter mb-3'>
      <label class='col-4 m-0 align-self-center'>{{'Password' | translate}}</label>
      <input
        autocomplete="new-password"
        type='password'
        class='col-8 form-control'
        [placeholder]="'Input Password' | translate "
        [(ngModel)]="user['password']"
        [ngClass]="{'is-invalid': errorMsg['password']}"
        (ngModelChange)="errorMsg['password'] = false">
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissUser()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="saveUser()">
      {{ 'Save' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #importModal>
  <div class="modal-header">
    {{'Import Seller Web Users' | translate }}
  </div>
  <div class="modal-body">
    <input type="file"
           id="file"
           (change)="handleFileInput($event.target.files)">
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissFileSelection()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="uploadFile()">
      {{ 'Upload' | translate }}
    </button>
  </div>
</ng-template>
