<app-header title="{{ 'import-result.ImportResult' | translate }}">
</app-header>

<div class="row mt-3 mb-4 ml-1 mr-1">
  <div class="col-md-12">
    <div>
      <app-loading
        style="position: center;vertical-align: middle; text-align: center;"
        *ngIf="loading"
      ></app-loading>
      <app-list-empty
        *ngIf="!loading && !productlistLength"
        title="{{ 'noDataFound' | translate }}"
      ></app-list-empty>
      <div class="mt-3 mb-4">
        <table
          class="table table-hover text-center table-striped table-borderless"
          *ngIf="!loading && productlistLength">
          <thead class="thead-light">
            <tr>
              <th>
                {{ 'product-import.type' | translate }}
              </th>
              <th>
                {{ 'product-import.seller' | translate }}
              </th>
              <th>
                {{ 'product-import.category' | translate }}
              </th>
              <th>
                {{ 'product-import.setName' | translate }}
              </th>
              <th>
                {{ 'product-import.date' | translate }}
              </th>
              <th>
                {{ 'product-import.status' | translate }}
              </th>
              <th class="min-w-100">
                {{'Percentage' | translate }}
              </th>
              <th>
                {{ 'File Name' | translate }}
              </th>
              <th>
                {{ 'Scheduled Date' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of productList
                  | slice: (page - 1) * 40:(page - 1) * 40 + 40
              "
            >
              <td class="align-middle">
                {{ item.type === 'supervisor' ? ('supervisor.Supervisor' | translate) : (item.type || '-') | translate}}
              </td>
              <td class="align-middle">
                {{ item.display?.seller_name || '-' }}
              </td>
              <td class="align-middle">
                {{item.inputs?.category_id || '-'}} {{ item.display?.category_name || '-' }}
              </td>
              <td class="align-middle">
                {{ item.display?.product_set_name || '-' }}
              </td>

              <td class="align-middle">
                {{item.created_at | date: 'yyyy-MM-dd h:mm a' : '+0900' || ''}}
              </td>

              <td ngClass="align-middle {{ item.processing }}"
                  (click)="item.processing === 'Failed' ? openErrorPopup(errPopup, item.message) : {}">
                {{ item.processing }}
              </td>

              <td ngClass="align-middle">
                  {{item.progress}}%
              </td>

              <td class="align-middle">
                {{item.file_name}}
              </td>

              <td class="align-middle">
                {{item.scheduled_date | date: 'yyyy-MM-dd h:mm a' : '+0900' || ''}}
              </td>
            </tr>
          </tbody>
        </table>
        <div class='d-flex'>
          <ngb-pagination
            [collectionSize]="count"
            [(page)]="currentPage"
            [maxSize]="5"
            [pageSize]="40"
            (pageChange)='navigateTo($event)'
            [boundaryLinks]="true"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #errPopup let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{'Message' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body reason-box mb-3" *ngIf="reason.length">
      <div class="row no-gutter mb-1" *ngFor="let message of reason">
        {{ message }}
      </div>
    </div>
    <div class="modal-body mb-3" *ngIf="!reason.length">
      {{'noErrorMessage' | translate}}
    </div>
  </div>
</ng-template>
