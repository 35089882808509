import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { OrderApiDisableDateComponent } from './order-api-disable-date.component';


export const orderApiDisableDateRoutes: Routes = [
  {
    path: 'order-api-disable-date',
    children: [
      {path: '', component: OrderApiDisableDateComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(orderApiDisableDateRoutes)],
  exports: [RouterModule]
})
export class OrderApiDisableDateRoutingModule {
}
