import { Component, OnInit } from '@angular/core';
import { DailyCheckDataManagementService } from './daily-check-data-management.service';
import { DailyCheckDataManagementResponse, RequestParams, SearchData } from './daily-check-data-management.models';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-daily-check-data-management',
  templateUrl: './daily-check-data-management.component.html',
  styleUrls: ['./daily-check-data-management.component.scss']
})
export class DailyCheckDataManagementComponent implements OnInit {
  searchData: SearchData;
  resultData: DailyCheckDataManagementResponse;
  ob: Observable<DailyCheckDataManagementResponse>

  isLoadingData: boolean = false;

  constructor(
    private dailyCheckDataManagementService: DailyCheckDataManagementService,
    private notiService: ToastrService
  ) { }

  ngOnInit(): void {
  }

  onSearch(searchData: SearchData) {
    this.isLoadingData = true;
    this.searchData = searchData;
    this.resultData = null;

    const params: RequestParams = {
      start_date: searchData.start_date,
      end_date: searchData.end_date,
      table_type: searchData.tableType,
      type: searchData.dailyCheckItems,
      roles: searchData.roles,
    }

    switch (searchData.tableType) {
      case 0: {
        params.dealer = searchData.dealer;
        this.ob = this.dailyCheckDataManagementService.getSummaryByBranch(params);
        break;
      }
      case 1: {
        params.dealer = searchData.dealer;
        params.shop = searchData.shop;
        this.ob = this.dailyCheckDataManagementService.getSummaryByShop(params); 
        break;
      }
      case 2: {
        params.dealer = searchData.dealer;
        params.shop = searchData.shop;
        params.staff = searchData.staff;
        params.roles = searchData.roles;
        this.ob = this.dailyCheckDataManagementService.getSummaryByStaff(params); 
        break;
      }
      case 3: {
        params.dealer = searchData.dealer;
        params.shop = searchData.shop;
        this.ob = this.dailyCheckDataManagementService.getActivityRankingByShop(params); 
        break;
      }
      case 4: {
        params.dealer = searchData.dealer;
        params.shop = searchData.shop;
        params.staff = searchData.staff;
        this.ob = this.dailyCheckDataManagementService.getActivityRankingByStaff(params); 
        break;
      }
      default: return;
    }

    this.ob.subscribe(
      (res: DailyCheckDataManagementResponse) => {
        this.resultData = res;
      },
      (err) => {
        this.showErrorMsg(err);
      },
      () => {
        this.isLoadingData = false;
      }
    )
  }

  showErrorMsg(error: HttpErrorResponse): void {
    this.notiService.error(error?.error?.message || error?.message  || error);
  }

  isNotEmpty(data: any) {
    return (Array.isArray(data) && data.length !== 0) || data
  }

}
