import {Component, OnInit, TemplateRef} from '@angular/core';
import {ModalDismissReasons, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { ShopService } from '../../services/shop.service';
import { ToastrService } from 'ngx-toastr';
import { DealerService } from '../dealer/dealer.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Dealer } from '../dealer/dealer.model';
import { Shop } from '../../models/shop.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private shopService: ShopService,
    private notification: ToastrService,
    private dealerService: DealerService,
    public router: Router,
    private translate: TranslateService
  ) {}

  dataLength = 0;
  dealers = [];
  dealersName = [];
  shops: Shop[] = [];
  shopsName = [];
  loadingDealers = false;
  loadingShops = false;
  loadingShopName = false;
  loadingDealerName = false;
  mode = '';
  selectedTab: string | null = '0';
  editingDealer: Dealer;
  selectedDealer: string = '';
  selectedShop: string = '';
  currentPage = 1;
  count = 1;
  webUserDealerID: string = '';
  fileToUpload: File = null;
  disableDealer: boolean = false;
  importing: boolean = false;
  group = JSON.parse(localStorage.getItem('groups'));

  ngOnInit() {
    const id = localStorage.getItem('dealer_id');
    if (id) {
      this.webUserDealerID = id;
      this.disableDealer = true;
    }
    this.getDealers();
    this.getShops();
    this.getDealersName();
  }

  isShopManager(): boolean {
    return this.group[0] === 'ShopManager'
  }

  changeTab(tab: string | null): void {
    this.selectedTab = tab;
  }

  editShop(shop) {
    this.router.navigateByUrl('settings/edit/' + shop.id + '/');
  }

  getShopsAndDealers() {
    this.getDealers();
    this.getShops();
  }

  getShops() {
    this.loadingShops = true;
    const params = {
      name: this.selectedShop || '',
      page: this.currentPage || 1,
      dealer_id: this.selectedDealer || '',
      daily_check: true
    };
    this.shopService.get(params).subscribe(
      (res: any) => {
        this.shops = res.results;
        this.count = res.count;
        this.loadingShops = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.loadingShops = false;
      }
    );
  }

  import(importTemplate: TemplateRef<NgbModalRef>): void {
    this.modalService.open(importTemplate);
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  dismissFileSelection(): void {
    this.fileToUpload = null;
    this.modalService.dismissAll();
  }

  uploadFile(): void {
    this.importing = true;
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.shopService.importStaffs(data).subscribe(
      () => {
        this.modalService.dismissAll();
        this.importing = false;
        this.notification.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
      },
      (err) => {
        this.showErrorMsg(err);
        this.importing = false;
      }
    );
  }

  uploadDealerSettings(): void {
    this.importing = true;
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.shopService.importDealerSettings(data).subscribe(
      () => {
        this.modalService.dismissAll();
        this.importing = false;
        this.notification.success(this.translate.instant('Successfully Imported'));
        this.router.navigate(['/import-product']);
      },
      (err) => {
        this.showErrorMsg(err);
        this.importing = false;
      }
    );
  }

  getShopsName() {
    this.loadingShopName = true;
    this.selectedShop = null;
    const params = {
      dealer_id: this.selectedDealer || '',
    };
    this.shopService.getAllShopsName(params).subscribe(
      (res: any) => {
        this.shopsName = res.shops;
        this.loadingShopName = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.loadingShopName = false;
      }
    );
  }

  editDealer(view, item) {
    this.editingDealer = JSON.parse(JSON.stringify(item));
    this.modalService.open(view).result.then(
      (result) => {},
      (reason) => {
        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.dismissEditOrCreate();
        }
      }
    );
  }

  dismissEditOrCreate() {
    this.modalService.dismissAll();
  }

  updateDealer() {
    if (this.isTabNameIsNull()) {
      this.notification.error(
        'Tab Name cannot be blank. Please input the name or click Default Name button to use the default name.'
      );
      return;
    }
    const data = {
      tab_1: this.editingDealer['tab_1'],
      tab_2: this.editingDealer['tab_2'],
      tab_3: this.editingDealer['tab_3'],
      tab_4: this.editingDealer['tab_4'],
      tab_5: this.editingDealer['tab_5'],
    };
    this.dealerService.update(this.editingDealer['id'], data).subscribe(
      (res) => {
        this.getDealers();
        this.dismissEditOrCreate();
        this.notification.success(
          this.translate.instant('Successfully Updated')
        );
      },
      (err) => {
        this.showErrorMsg(err);
      }
    );
  }

  isTabNameIsNull(): boolean {
    return (
      !this.editingDealer['tab_1'] ||
      !this.editingDealer['tab_2'] ||
      !this.editingDealer['tab_3'] ||
      !this.editingDealer['tab_4'] ||
      !this.editingDealer['tab_5']
    );
  }

  showErrorMsg(msg) {
    this.notification.error(_.values(msg.error)[0].toString());
  }

  getDealers() {
    const params = {
      id: this.selectedDealer || '',
      daily_check: true,
    };
    this.loadingDealers = true;
    this.dealerService.getAll(params).subscribe(
      (res: any) => {
        this.dataLength = res.length;
        this.dealers = res;
        this.loadingDealers = false;
        this.setDealer();
      },
      (err) => {
        this.loadingDealers = false;
        this.showErrorMsg(err);
      }
    );
  }

  getDealersName() {
    this.loadingDealerName = true;
    this.dealerService.getAllDealersName().subscribe(
      (res: any) => {
        this.dealersName = res.dealers;
        this.loadingDealerName = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.loadingDealerName = false;
      }
    );
  }

  setDealer() {
    if (this.webUserDealerID && this.dealers.length === 1) {
      this.selectedDealer = this.dealers[0].name;
    }
  }

  resetDealer() {
    this.selectedDealer = null;
    this.getDealers();
    this.setDealer();
  }

  resetShop() {
    this.selectedDealer = null;
    this.selectedShop = null;
    this.getShops();
    this.setDealer();
  }
}
