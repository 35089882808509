import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Shop } from '../../../models/shop.model';
import { ShopService } from '../../../services/shop.service';
import { User } from '../user.model';
import { UserService } from '../user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryAddressInfo, UserDeliveryAddressService } from '../user-delivery-address.service';
import { ConfirmModalService } from 'src/app/components/confirm-modal/confirm-modal.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-or-create',
  templateUrl: './edit-or-create.component.html',
  styleUrls: ['./edit-or-create.component.scss']
})
export class EditOrCreateComponent implements OnInit {
  id: number;
  user: any;
  selectedShop = null;
  shopList: Shop[];
  newPassword = '';
  editingIndex = null;
  deliveryAddresses: DeliveryAddressInfo[];
  deletingRow = null;
  renderingTable = false;
  errorMsg = {};
  deliveryErrorMsg = {};
  loading = false;
  type = '';
  gritUser = false;

  editingEmails = [];
  editingEmailRow = null;
  editingEmail = '';
  emailError = false;
  userEmailsLoading = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    private shopService: ShopService,
    private tostrService: ToastrService,
    private confirmModalService: ConfirmModalService,
    private modalService: NgbModal,
    public router: Router,
    private deliveryAddressService: UserDeliveryAddressService,
    private confirmModal: ConfirmModalService,
    private traslate: TranslateService,
  ) { }

  ngOnInit() {
    if(this.userService.is_grit) {
      this.gritUser = true;
      this.user = this.userService.gritUserData;
      this.user.password = 'unchangedPassword';
      this.user.id = localStorage.getItem('userId')
      this.deliveryAddresses = [];
      this.getDeliveryAddreessesForGirtUser();
    }
    else {
      this.activatedRoute.params.subscribe(
        (params: Params) => {
          this.id = +params['id'];
        }
      );
      this.getUser();
      this.getShopList();
    }
  }

  getUser() {
    if(this.id) {
      this.type = 'editing';
      this.userService.getUserById(this.id).subscribe((res : User)=> {
        this.user = res;
        this.user.password = 'unchangedPassword';
        this.deliveryAddresses = this.user.delivery_addresses;
        !this.user.email ? this.loadUserEmails() : {};
      },
      (error) => {
        this.tostrService.error(error)
      })
    }
    else{
      this.type = 'adding';
      this.user = {
        username: '',
        password: '',
        shops: [],
        shop_name: '',

        family_name: '',
        given_name: '',
        email: '',

        is_default_address: true,
        default_address: '',
        default_address_prefecture: '',
        default_address_postal_code: '',

        phone_number_1: '',
        customer_code: '',
      }
      this.deliveryAddresses = [];
    }
  }

  saveUser() {
    this.loading = true;
    let errorMsg = this.chechError();
    if(Object.keys(errorMsg).length === 0){
      this.user.id ? this.updateUser(this.user) : this.addUser(this.user);
    }
    this.errorMsg = errorMsg;
    this.loading = false;
  }

  updateUser(user: User, back= true) {
    user.password == 'unchangedPassword' ? delete user.password : {};
    this.userService.editUser(user, user.shop_name, this.id).then((res : User)=> {
      this.tostrService.success(this.traslate.instant("Updated User Information"))
      user = res;
      user.password = 'unchangedPassword';
      back ? this.goBack() : {};
    },
    (error) => {
      this.tostrService.error(this.traslate.instant("Error"))
    })
  }

  addUser(user: User) {
    user.shops = [this.selectedShop]
    if(this.deliveryAddresses.length){
      user.delivery_addresses = this.deliveryAddresses;
      user.is_default_address = false;
    }
    this.userService.addUser(user, '').then((res : User)=> {
      this.tostrService.success(this.traslate.instant("Updated User Information"))
      user = res;
      user.password = 'unchangedPassword';
      this.goBack();
    },
    (error) => {
      error.error['username'] ? this.tostrService.error(this.traslate.instant("Username was Already Taken")) : this.tostrService.error(this.traslate.instant("Error"));
    })
  }

  getShopList(){
    this.shopService.getAll().subscribe((res: any) => {
      this.shopList = res;
    },
    (error) => {
      this.tostrService.error(error)
    });
  }

  openChangePassword(view) {
    this.modalService.open(view).result.then((result) => {}, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK) {
          this.dismiss();
        }
    });
  }

  dismiss() {
    this.newPassword = '';
    this.modalService.dismissAll();
  }

  saveNewPassword() {
    this.user.password = this.newPassword;
    this.dismiss();
  }

  editRow(index: number) {
    this.editingIndex = this.editingIndex ? this.editingIndex : index;
    this.deliveryErrorMsg = {};
  }

  saveRow(index: number) {
    let data = this.deliveryAddresses[index];
    let errorMsg = this.checkDeliveryValues(data);
    if(Object.keys(errorMsg).length === 0){
      if(this.user.id && !data.id && !this.gritUser) {
        this.deliveryAddressService.add(data, this.user).subscribe(
          (res: any) => {
            this.deliveryAddresses[index] = res;
            this.editingIndex = null;
            this.tostrService.success(this.traslate.instant("Adding Successful"));
            this.deliveryErrorMsg = {};
          },
          (error) => {
            this.tostrService.error(error.error.message)
        });
      }
      else if(this.user.id && data.id && !this.gritUser) {
        this.deliveryAddressService.update(data).subscribe(
          (res: any) => {
            this.editingIndex = null;
            this.tostrService.success(this.traslate.instant("Updated Successfully"));
            this.deliveryErrorMsg = {};
          },
          (error) => {
            this.tostrService.error(error)
        });
      }
      else if (this.gritUser && !data.id) {
        this.deliveryAddressService.add(data, this.user).subscribe(
          (res: any) => {
            this.deliveryAddresses[index] = res;
            this.editingIndex = null;
            this.tostrService.success(this.traslate.instant("Adding Success"));
            this.deliveryErrorMsg = {};
          },
          (error) => {
            this.tostrService.error(error.error.message)
        });
      }
      else if (this.gritUser && data.id) {
        this.deliveryAddressService.update(data).subscribe(
          (res: any) => {
            this.editingIndex = null;
            this.tostrService.success(this.traslate.instant("Updated Successfully"));
            this.deliveryErrorMsg = {};
          },
          (error) => {
            this.tostrService.error(error)
        });
      }
      else{
        this.editingIndex = null;
      }
    }
    else{
      this.deliveryErrorMsg = errorMsg;
    }
  }

  deleteRow(){
    const id = (this.deliveryAddresses[this.deletingRow].id).toString();
    this.deliveryAddressService.delete(id).subscribe(
    (res: any) => {
      this.deliveryAddresses.splice(this.deletingRow, 1);
      this.deletingRow = null;
      this.tostrService.success(this.traslate.instant("Deleted"));
    },
    (error) => {
      this.tostrService.error(error)
    });
  }

  openDeleteConfirmModal(index: number) {
    this.deletingRow = index;
    this.confirmModal.open(
      'Delete Confirmation',
      'Are you sure you want to delete?',
      () => {
        this.deleteRow();
      }
    );
  }

  addNewDeliveryAddress() {
    this.renderingTable = true;
    let newAddress: DeliveryAddressInfo = {
      id: '',
      name: '',
      delivery_address: '',
      delivery_address_prefecture: '',
      delivery_address_postal_code: '',
      phone_number: '',
      delivery_address_remarks: '',
      user: '',
      cta_user_id: this.gritUser ? this.user.grit_user_id : null,
    }
    this.editingIndex = this.deliveryAddresses.length;
    this.deliveryAddresses.push(newAddress);
    this.renderingTable = false;
  }

  goBack() {
    this.router.navigateByUrl('user/');
  }

  chechError() {
    let errorMsg = {};
    this.user.username == '' ? errorMsg['username'] = true: {};
    this.user.password == '' ? errorMsg['password'] = true: {};
    this.type== 'adding' && this.selectedShop == null ? errorMsg['shop_name'] = true: {};
    this.user.family_name == '' ? errorMsg['family_name'] = true: {};
    this.user.given_name == '' ? errorMsg['given_name'] = true: {};
    this.user.email !== '' && !this.validateEmail(this.user.email) ? errorMsg['email'] = true: {};
    this.user.default_address == '' ? errorMsg['default_address'] = true: {};
    this.user.default_address_prefecture == '' ? errorMsg['default_address_prefecture'] = true: {};
    this.user.default_address_postal_code == '' ?  errorMsg['default_address_postal_code'] = true: {};
    this.user.phone_number_1 == '' ? errorMsg['phone_number_1'] = true: {};
    return errorMsg;
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validatePostalCode(code) {
    const re = /^(\d+-?)+\d+$/;
    return re.test(code);
  }

  checkDeliveryValues(data) {
    let errorMsg = {};
    data.name == "" ? errorMsg['name'] = true : {};
    data.delivery_address_postal_code !== "" && !this.validatePostalCode(data.delivery_address_postal_code) ? errorMsg['delivery_address_postal_code'] = true : {};
    data.phone_number !== "" && !this.validatePostalCode(data.phone_number) ? errorMsg['phone_number'] = true : {};
    data.delivery_address_prefecture == "" ? errorMsg['delivery_address_prefecture'] = true : {};
    data.delivery_address == "" ? errorMsg['delivery_address'] = true : {};
    return errorMsg;
  }


  removeUnsavedRow(index: number){
    this.deliveryAddresses.splice(index, 1);
  }

  removeWarning(type) {
    delete this.errorMsg[type];
  }

  removeError(type){
    type ? delete this.deliveryErrorMsg[type] : this.emailError = false;
  }

  setShopData(shop) {
    this.user.shop_name = shop.name;
  }

  getDeliveryAddreessesForGirtUser() {
    this.deliveryAddressService.getAddressesForGritUser(this.user.grit_user_id).subscribe((res:any)=> {
      this.deliveryAddresses = res.results;
    },
    (error)=> {
      this.tostrService.error(this.traslate.instant("Error Getting Grit User's Delivery Addresses"))
    })
  }

  openMoreEmails(view) {
    this.editingEmails = [];
    this.loadUserEmails();
    this.modalService.open(view).result.then((result) => {}, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK) {
            this.dismissEditEmails();
        }
    });
  }

  addNewEmail() {
    if(this.editingEmailRow == null) {
      this.editingEmails.push({id:'', email:''})
      this.editingEmail = '';
      this.editingEmailRow = this.editingEmails.length - 1;
    }
  }

  saveEmailEdit() {
    let valid = this.validateEmail(this.editingEmail);
    if(valid) {
      this.editingEmails[this.editingEmailRow].email = this.editingEmail;
      this.resetEmailEdit();
    }else {
      this.emailError = true;
    }
  }

  resetEmailEdit(rowIndex?){
    if(rowIndex >= 0){
      this.editingEmails[rowIndex].id == '' && this.editingEmails[rowIndex].email == '' ?
        this.editingEmails.splice(rowIndex, 1) : {};
    }
    this.editingEmail = '';
    this.editingEmailRow = null;
  }

  editEmailRow(rowIndex: number) {
    if(this.editingEmailRow == null) {
      this.emailError = false;
      this.editingEmailRow = rowIndex;
      this.editingEmail = this.editingEmails[rowIndex].email;
    }
  }

  dismissEditEmails () {
    this.editingEmails = [];
    this.resetEmailEdit();
    this.removeError(false);
    this.modalService.dismissAll();
  }

  saveEmails() {
    if(this.editingEmails.length) {
      this.editingEmails.forEach((eachEmail, index) => {
        let fd = new FormData();
        fd.append('email', eachEmail.email);
        const condition = eachEmail.id && eachEmail.id !== 'primary' ? '1':
                            eachEmail.id === 'primary' || eachEmail.id === '' ? '2' : {};
        if(index == 0) {
          this.user.email = eachEmail.email;
          this.updateUser(this.user, false)
        }
        switch(condition) {
          case '1':
            this.userService.editUserEmails(eachEmail.id, fd, this.user.id).subscribe((res) => {},
            (error) => {
              this.tostrService.error(this.traslate.instant("Error"));
            })
            break;
          case '2':
            fd.append('user_id', this.user.id);
            fd.append('from_web', 'true');
            this.userService.addUserEmails(fd).subscribe((res) => {},
            (error) => {
              this.tostrService.error(this.traslate.instant("Error"));
            })
            break;
        }
        if(index + 1 == this.editingEmails.length) {
          this.dismissEditEmails();
        }
      })
    }else {
      this.user.email = '';
      this.updateUser(this.user, false);
      this.dismissEditEmails();
    }
  }

  openConfirm(id) {
    this.confirmModalService.open(
      'Delete Confirmation',
      'Are you sure you want to delete?',
      () => {
        if(id == 'primary') {
          this.editingEmails.splice(0, 1);
          this.user.email = this.editingEmails[0] ? this.editingEmails[0].email : '';
          this.updateUser(this.user, false);
        }
        else {
          this.userService.deleteUserEmails(id, this.user.id).subscribe((res)=> {
            this.editingEmails = this.editingEmails.filter(x => x.id !== id);
          })
        }
      }
    );
  }

  loadUserEmails() {
    this.userEmailsLoading = true;
    let param = new HttpParams()
      .append('user_id', this.user.id);
    this.userService.getUserEmails(param).subscribe(
      (res: any) => {
        this.editingEmails = res.results;
        if(this.user.email &&
            this.editingEmails.length &&
            this.user.email !== this.editingEmails[0].email) {
          const data = {id: "primary", "email" : this.user.email}
          this.editingEmails.unshift(data);
        }
        if(!this.editingEmails.length &&
                  this.user.email) {
          const data = {id: "primary", "email" : this.user.email}
          this.editingEmails.unshift(data);
        }
        if(this.user.email == '') {
          this.user.email = this.editingEmails.length ? this.editingEmails[0].email : '';
        }
        this.userEmailsLoading = false;
      },
      (error) => {
        this.userEmailsLoading = false;
        this.tostrService.error(this.traslate.instant("Error"));
      }
    )
  }

  removeEmail(idx) {
    this.editingEmails.splice(idx, 1);
  }
}
