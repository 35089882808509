import { FormControl, FormGroup } from '@angular/forms';

export const parseGivenFamilyShopName = (str: string): GivenFamilyShopName => {
  const [name, ...shops] = str.trim().split(/(,|、)/);
  const [family_name, given_name] = name.trim().split(/\s+/);
  return { given_name, family_name, shop: str.slice(str.search(/(,|、)/) + 1) };
};
export const validateGivenFamilyShopName = (fc: FormControl) => {
  const names = fc.value.trim().split(/(,|、)/);
  if (names.length > 1) {
    const [name, ...shops] = names;
    if (name.trim().split(/\s+/).length !== 2) {
      return {
        invalid: true
      };
    } else {
      return;
    }
  } else {
    return {
      invalid: true
    };
  }
};

interface GivenFamilyShopName {
  given_name: string;
  family_name: string;
  shop: string;
}

export const nameAndShopNameFilled = (fg: FormGroup) => {
  const { shop_name, name } = fg.value;
  if (!shop_name && !name) {
    return { shopAndShopNameField: true };
  }
};
