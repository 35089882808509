import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgSelectModule} from "@ng-select/ng-select";
import { ProductMasterRoutingModule } from './product-master-routing.module';
import { ProductMasterComponent } from './product-master.component';
import { CoreModule } from 'src/app/components/core/core.module';
import { SharedModule } from 'src/app/shared.module';
import { ProductSetComponent } from './product-set/product-set.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {DragDropModule} from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [ProductMasterComponent, ProductSetComponent],
  imports: [
    CommonModule,
    ProductMasterRoutingModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    CoreModule,
    FormsModule,
    SharedModule,
    AutoCompleteModule,
    NgSelectModule,
    DragDropModule,
  ]
})
export class ProductMasterModule {}
