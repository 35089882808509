import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductImageComponent } from './product-image.component';

export const ProductImageRoutes: Routes = [
  {
    path: 'product-image',
    children: [{ path: '', component: ProductImageComponent }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ProductImageRoutes)],
  exports: [RouterModule]
})
export class ProductImageRoutingModule {}
