import { Injectable } from '@angular/core';
import {API_CONSTANT} from "../../services/api.constants";
import {HttpClientService} from "../../services/http-client.service";

@Injectable({
  providedIn: 'root'
})
export class DailyCheckTabletUserService {

  constructor(
    private api: HttpClientService
  ) { }

  importDailyCheckTabletUser(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-daily-check-tablet-user/', data)
  }

  patchDailyCheckTabletUser(id: number, data: Object) {
    return this.api.patch(
      API_CONSTANT.BASE_URL + '/daily-check-tablet-users/' + `${id}/`,
      data
    );
  }

  getDailyCheckTabletUser(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/daily-check-tablet-users/', params);
  }
}
