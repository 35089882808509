import {Component, OnInit } from '@angular/core';
import {Dealer, SelectedDealer} from './dealer.model';
import {Shop} from '../../models/shop.model';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShopService} from '../../services/shop.service';
import {DealerPageService} from './dealer-page.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {DealerService} from './dealer.service';
import {ProductSetService} from '../../services/product-set.service';
import {SalePackageService} from '../../services/sale-package.service';
import {DealerCustomerType, EtrDealerType, ShopCustomerType} from '../../models/customerTypes.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.scss']
})
export class DealerComponent implements OnInit {

  file: File = null;

  shops = [];
  dealers = [];
  productSets = [];
  salePackages = [];
  selectedShops = [];
  originalDealers = [];

  searchDealer = '';
  editingShopName = '';
  editOrCreateDealer: string = 'create';

  dealerInput: Partial<Dealer> = {
    name: '',
    user_type: 0,
    dealer_type: 0
  };

  shopInput: Partial<Shop> = {
    name: '',
    dealer: null,
  };

  etrDealerType;
  shopCustomerType;
  dealerCustomerType;

  selectedDealerID: SelectedDealer = new SelectedDealer();
  selectedProductSetID = null;
  selectedSalePackageID = null;
  editingDealerRow = null;
  editingDealerID = null;
  editingShopRow = null;
  editingShopID = null;
  dealerType = null;

  selectedAll = false;
  loadingDealers = false;
  loadingShops = false;
  loadingProductSets = false;
  creatingDealer = false;
  creatingShop = false;
  importing = false;
  savingSelections = false;
  is_cta = false;

  constructor(
    private modalService: NgbModal,
    private shopService: ShopService,
    private service: DealerPageService,
    private translate: TranslateService,
    private notification: ToastrService,
    private dealerService: DealerService,
    private productSetService: ProductSetService,
    private packageSetService: SalePackageService,
  ) {
  }

  ngOnInit() {
    this.getDealers();
    this.getPackageSets();
    this.getListForDealerCreation();
  }

  getDealers() {
    this.loadingDealers = true;
    this.dealerService.getCTAETRDealerList().subscribe(
      (res: any) => {
        this.dealers = _.cloneDeep(res);
        this.originalDealers = _.cloneDeep(res);
        this.loadingDealers = false;
      },
      (err) => {
        this.loadingDealers = false;
        this.showErrorMsg(err);
      }
    )
  }

  getPackageSets() {
    this.loadingProductSets = true;
    this.productSetService.getAll().subscribe(
      (res: any) => {
        this.productSets = res;
        this.loadingProductSets = false;
      },
      (err) => {
        this.loadingProductSets = false;
        this.showErrorMsg(err);
      }
    );
  }

  selectDealer(dealer) {
    this.selectedDealerID = { id: dealer.id, is_cta: !dealer.is_etr };
    this.resetShopSelection();
    this.resetProductSetSelection();
    this.resetSalePackage();
    this.is_cta = !dealer.is_etr;
    this.getShops(dealer.id, dealer.is_etr);
  }

  selectShop(id) {
    const index = this.selectedShops.indexOf(id);
    index === -1 ? this.selectedShops.push(id) : this.selectedShops.splice(index, 1);
    if (!this.selectedShops.length) {
      this.resetProductSetSelection();
      this.resetSalePackage();
    }
  }

  checkInSelectedShop(id) {
    return this.selectedShops.indexOf(id) !== -1;
  }

  checkInSelectDealer(dealer): boolean {
    if (
      this.selectedDealerID.id === dealer.id &&
      this.selectedDealerID.is_cta === !dealer.is_etr
    ) {
      return true;
    }
    return false;
  }

  getShops(dealer_id: number, is_etr: boolean) {
    this.loadingShops = true;
    const params = {
      dealer_id: dealer_id,
      is_etr: is_etr,
    };
    this.shopService.getCTAETRShopList(params).subscribe(
      (res: any) => {
        this.shops = res;
        this.loadingShops = false;
      },
      (err) => {
        this.loadingShops = false;
        this.showErrorMsg(err);
      }
    );
  }

  selectProductSet(event) {
    this.packageSetService.getAll({product_set: event.id}).subscribe(
      (res: any) => {
        this.salePackages = res;
      },
      (err) => {
        this.showErrorMsg(err);
      }
    )
  }

  selectSalePackage(salePackage) {
    this.selectedSalePackageID = this.selectedSalePackageID === salePackage.id ? null : salePackage.id;
  }

  openDealer(view, dealer?) {
    this.editOrCreateDealer = dealer ? 'edit' : 'create';
    this.dealerInput = dealer ? dealer : this.dealerInput;
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissDealer();
      }
    });
  }

  openImportModal(view) {
    this.file = null;
    this.dealerType = 0;
    this.modalService.open(view);
  }

  handleFileInput(files: FileList) {
    this.file = files.item(0);
  }

  openShop(view, shop?) {
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissShop();
      }
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  uploadFile() {
    this.importing = true;
    this.dealerService.import(this.file, this.dealerType).subscribe(
      res => {
        this.importing = false;
        this.notification.success(this.translate.instant('Successfully Imported'));
      },
      err => {
        this.importing = false;
        this.notification.error(this.translate.instant('Upload Failed'));
      }
    );
  }

  dismissDealer() {
    this.dealerInput = {
      name: '',
      user_type: 0,
      dealer_type: 0
    };
    this.modalService.dismissAll();
  }

  dismissShop() {
    this.shopInput = {
      name: '',
      dealer: null,
    };
    this.modalService.dismissAll();
  }

  search() {
    this.dealers = this.searchDealer ?
      this.originalDealers.filter(x => x.name.toLowerCase().indexOf(this.searchDealer.toLowerCase()) >= 0)
      :
      this.originalDealers;
  }

  selectAllShops() {
    this.selectedShops = [];
    !this.selectedAll ?
      this.shops.forEach(shop => {
        this.selectedShops.push(shop.id);
      })
    :
      {};
    this.selectedAll = !this.selectedAll;
  }

  resetShopSelection() {
    this.selectedAll = false;
    this.selectedShops = [];
  }

  resetProductSetSelection() {
    this.selectedProductSetID = null;
  }

  resetSalePackage() {
    this.salePackages = [];
    this.selectedSalePackageID = null;
  }

  showErrorMsg(errorMsg) {
    this.notification.error(_.values(errorMsg)[0].toString());
  }

  async saveSelections() {
    this.savingSelections = true;
    for (const id of this.selectedShops) {
      const data = {
        id: id,
        package_set: this.selectedSalePackageID,
        is_cta: this.is_cta ? true : undefined
      }

      await this.shopService.updateSalePackage(data).toPromise();
    }
    this.savingSelections = false;
    this.notification.success(this.translate.instant('Successfully Updated'));
    this.getShops(this.selectedDealerID.id, !this.selectedDealerID.is_cta);
  }

  isMonotaroEnabled() {
    this.dealerInput.is_monotaro_enabled = !this.dealerInput.is_monotaro_enabled;
  }

  addOrEditDealer() {
    this.editOrCreateDealer == 'edit' ? this.editDealer() : this.addDealer();

  }

  editDealer() {
    const data: Partial<Dealer> = {
      name: this.dealerInput.name,
      is_monotaro_enabled: this.dealerInput.is_monotaro_enabled
    }
    this.dealerService.update(this.dealerInput.id, data).subscribe(
      (res) => {
        this.notification.success(this.translate.instant('Successfully Created'));
        this.getDealers();
        this.dismissDealer();
        this.creatingDealer = false;
      },
      (err) => {
        this.creatingDealer = false;
        this.showErrorMsg(err);
      }
    );
  }

  addDealer() {
    this.creatingDealer = true;
    this.dealerService.create(this.dealerInput).subscribe(
      (res) => {
        this.notification.success(this.translate.instant('Successfully Created'));
        this.getDealers();
        this.dismissDealer();
        this.creatingDealer = false;
      },
      (err) => {
        this.creatingDealer = false;
        this.showErrorMsg(err);
      }
    );
  }

  addShop() {
    this.creatingShop = true;
    this.shopService.create(this.shopInput).subscribe(
      res => {
        this.notification.success(this.translate.instant('Successfully Created'));
        this.dismissShop();
        this.creatingShop = false;
      },
      (err) => {
        this.creatingShop = false;
        this.showErrorMsg(err);
      }
    );
  }

  getListForDealerCreation() {
    this.dealerCustomerType = [
      { name: 'Big Dealer', value: DealerCustomerType['Big Dealer'] },
      { name: 'General User', value: DealerCustomerType['General User'] }
    ].map(e => ({ ...e, name: this.translate.instant(e.name) }));

    this.etrDealerType = [
      { name: 'MSI', value: EtrDealerType['MSI'] },
      { name: 'SB', value: EtrDealerType['SB'] }
    ].map(e => ({ ...e, name: this.translate.instant(e.name) }));

    this.shopCustomerType = [
      { name: 'Big Dealer', value: ShopCustomerType['Big Dealer'] },
      { name: 'General User', value: ShopCustomerType['General User'] },
      { name: 'None', value: ShopCustomerType.None },
      { name: 'Grit User', value: ShopCustomerType['Grit User'] }
    ].map(e => ({ ...e, name: this.translate.instant(e.name) }));
  }

  editShop(editingIndex) {
    this.editingShopID = this.shops[editingIndex].id;
    this.editingShopName = this.shops[editingIndex].name;
    this.editingShopRow = editingIndex;
  }

  saveEditingShop() {
    const data = {
      name: this.editingShopName,
    }
    this.shopService.update(this.editingShopID, data).subscribe(
      (res: any) => {
        this.shops[this.editingShopRow].name = this.editingShopName;
        this.notification.success(this.translate.instant('Successfully Updated'));
        this.dismissShopEdit();
      },
      (err) => {
        this.showErrorMsg(err);
      }
    )
  }

  dismissShopEdit() {
    this.editingShopRow = null;
    this.editingShopID = null;
    this.editingShopName = '';
  }
}
