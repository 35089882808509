<app-header [title]="'Settings' | translate"> </app-header>
<div class="row w-100 p-3 px-4 m-0 d-flex justify-content-start">
  <div class="w-50percent row p-0 m-0 align-self-start">
    <div class="row w-100 p-0 m-0 pr-3">
      {{ "Shop:" | translate}} {{ shop?.name }}
    </div>
  </div>

  <div class="w-50percent d-flex align-items-start justify-content-end">
    <button class="btn btn-sm mr-2 btn-secondary d-flex align-items-center"
        (click)="backClicked()">
      {{'Back' | translate}}
    </button>
  </div>
</div>

<div class='main-content'>
  <div class='row no-gutter p-4'>
    <div class='search-box' *ngIf="shop" >
      <div class='row no-gutter w-100percent d-flex justify-content-between align-items-center'>
        <h5>
          {{ 'Monthly Visit Amount' | translate }}
        </h5>
        <button class="btn btn-sm mr-2 btn-primary"
            [disabled]="savingMonthlyVisit"
            (click)="saveMonthlyVisit()">
          <span *ngIf="!savingMonthlyVisit">{{'Save' | translate}}</span>
          <img *ngIf="savingMonthlyVisit"
              src="assets/images/loading.svg"
              alt="loading"
              width="auto"
              height="20">
        </button>
      </div>
      <div class='row no-gutter h-50'>
        <div class='col-4 row no-gutter align-self-center pr-5'>
          <label class='col-4 pl-0 m-0 d-flex align-items-center'>
            {{ 'Date' | translate }}
          </label>
          <div class="col-8 row no-gutter form-control d-flex justify-content-center align-items-center">
            <input type="text"
                class="col-10 hidden"
                [placeholder]="'Select Date' | translate"
                [(ngModel)]="monthlyVisitDate"
                ngbDatepicker
                (click)="d.toggle()"
                #d="ngbDatepicker"
                [readOnly]="true"
                (ngModelChange)="getMonthlyVisit()">
            <i class="fa fa-calendar col-2 d-flex justify-content-end"
                (click)="d.toggle()"></i>
          </div>
        </div>
      </div>
      <div class='row no-gutter h-50'>
        <div class='col-4 row no-gutter align-self-center pr-5'>
          <label class='col-4 pl-0 m-0 d-flex align-items-center'>
            {{ 'Daily Visit Number' | translate }}
          </label>
          <input type="number"
              class="col-8 form-control"
              [(ngModel)]="dailyVisitNumber"
              [placeholder]="'Visit' | translate"
              (ngModelChange)="updateTotalMonthlyVisit()">
        </div>
      </div>
      <div class='row no-gutter h-50'>
        <div class='col-4 row no-gutter align-self-center pr-5'>
          <label class='col-4 pl-0 m-0 d-flex align-items-center'>
            {{ 'Total Monthly Visit' | translate }}
          </label>
          <input type="number"
              class="col-8 form-control"
              [(ngModel)]="totalMonthlyVisit"
              [readOnly]="true">
        </div>
      </div>
    </div>


    <div class='search-box' *ngIf="shop" >
      <div class='row no-gutter w-100percent d-flex justify-content-between align-items-center'>
        <h5>
          {{ 'Monthly Target Number' | translate }}
        </h5>
        <button class="btn btn-sm mr-2 btn-primary"
            [disabled]="savingMonthlyTarget"
            (click)="saveMonthlyTarget()">
          <span *ngIf="!savingMonthlyTarget">{{'Save' | translate}}</span>
          <img *ngIf="savingMonthlyTarget"
              src="assets/images/loading.svg"
              alt="loading"
              width="auto"
              height="20">
        </button>
      </div>
      <div class='row no-gutter h-50'>
        <div class='col-4 row no-gutter align-self-center pr-5'>
          <label class='col-4 pl-0 m-0 d-flex align-items-center'>
            {{ 'Flyer' | translate }}
          </label>
          <input type="text" class="col-8 form-control" [(ngModel)]="shop.flyer"
                 [placeholder]="'Flyer' | translate">
        </div>
      </div>
      <div class='row no-gutter h-50'>
        <div class='col-4 row no-gutter align-self-center pr-5'>
          <label class='col-4 pl-0 m-0 d-flex align-items-center'>
            {{ 'Tab 3 (Quotation)' | translate }}
          </label>
          <input type="text" class="col-8 form-control" [(ngModel)]="shop.tab_3"
                 [placeholder]="'Visit' | translate">
        </div>
      </div>
    </div>
  </div>


  <div class='row no-gutter p-3'>
    <div class='search-box'>
      <div class='row no-gutter w-100percent d-flex justify-content-between align-items-center'>
        <h5>
          {{ 'Staff Edition' | translate }}
        </h5>
        <button class="btn btn-sm mr-2 btn-primary"
            [disabled]="savingStaff"
            (click)="saveStaff()">
          <span *ngIf="!savingStaff">{{'Save' | translate}}</span>
          <img *ngIf="savingStaff"
              src="assets/images/loading.svg"
              alt="loading"
              width="auto"
              height="20">
        </button>
      </div>
      <div class="row no-gutter p-y-5">
        <div class="col-1"></div>
        <div class="col-4 p-0imp">
          {{ 'Position' | translate }}
        </div>
        <div class="col-7 row no-gutter align-items-center pr-5">
          <div class="col-3 ml-3 m-r-3re p-0imp"> {{ 'Staff Name' | translate }}</div>
          <div class="col-3 p-0imp ml-45"></div>
        </div>
      </div>
      <div cdkDropList class="example-list"
           *ngIf="shop?.staffs?.length"
           (cdkDropListDropped)="changeSequence($event)">
        <div class="example-box text-center row no-gutters p-y-5"
             *ngFor="let staff of shop.staffs; let staffIndex = index;"
             [cdkDragData]="staff"
             cdkDrag>
          <div class="col-1 align-self-center pointer">
            <i class="fa fa-bars"></i>
          </div>

          <div class='col-4 row no-gutter align-self-center pr-5' (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()" (touchmove)="$event.stopPropagation()" (touchend)="$event.stopPropagation()">
            <ng-select
              [items]="positionList"
              bindValue="role"
              bindLabel="name"
              [(ngModel)]="staff.role"
              class="w-100percent"
              [notFoundText]=" 'Result Not Found' | translate "
              [placeholder]="'Status' | translate ">
              <ng-template ng-label-tmp let-item="item">
              <span class="d-flex align-items-center">
                <span class="square"
                      [ngClass]="item.name"></span>
                {{ getCurrentLanguage() === 'en' ? item.name : item.jp_name }}
              </span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm"
                           let-index="index">
              <span class="d-flex align-items-center">
                <span class="square"
                      [ngClass]="item.name"></span>
                {{ getCurrentLanguage() === 'en' ? item.name : item.jp_name }}
              </span>
              </ng-template>
            </ng-select>
          </div>

          <div class='col-7 row no-gutter align-items-center pr-5'
              (mousedown)="$event.stopPropagation()"
              (touchstart)="$event.stopPropagation()"
              (touchmove)="$event.stopPropagation()"
              (touchend)="$event.stopPropagation()">
            <input type="text"
                class="col-3 form-control"
                [(ngModel)]="staff.name"
                [placeholder]="'Name' | translate">
            <img src="assets/svgs/red_cross.svg"
                width="30"
                height="30"
                alt="cross-icon"
                class="clickable ml-3 m-r-3re"
                (click)="removeStaff(staffIndex)">
          </div>
        </div>
      </div>

      <div class="row no-gutters">
            <div class="col-8">
                <img src="assets/svgs/blue_plus_icon.svg"
                     alt="add-mechanics"
                     class="pointer"
                     (click)="addNewStaff()"
                     width="25"
                     height="25">
            </div>
        </div>
    </div>
  </div>

  <div class="row mb-4 mx-4">
    <div class='row no-gutter w-100percent d-flex justify-content-between align-items-center p-b-10'>
      <h5>
        {{ 'Daily Check Items' | translate }}
      </h5>
      <button class="btn btn-sm mr-2 btn-primary"
          [disabled]="savingDailyCheckItems"
          (click)="saveDailyCheckItems()">
        <span *ngIf="!savingDailyCheckItems">{{'Save' | translate}}</span>
        <img *ngIf="savingDailyCheckItems"
            src="assets/images/loading.svg"
            alt="loading"
            width="auto"
            height="20">
      </button>
    </div>

    <table
      class="table table-hover mb-0 text-center table-borderless table-striped"
      style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;">
      <thead>
        <tr class="text-center">
          <th width="15%">
            {{ "product-set.sequence" | translate }}
          </th>
          <th width="35%">
            {{ "Daily Check Items" | translate }}
          </th>
          <th width="25%">
            {{ "Display in Tab 1" | translate }}
          </th>
          <th width="25%">
            {{ "Display in Tab 2,3,4,5" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="p-0" colspan="4">
            <div cdkDropList class="example-list w-100percent"
                 *ngIf="shop?.daily_checks?.length"
                 (cdkDropListDropped)="changeTableSequence($event)">
              <div class="example-box text-center w-100percent row no-gutters"
                   *ngFor="let item of shop?.daily_checks; let dailyCheckIndex = index"
                   [ngClass]="{'bg-white': dailyCheckIndex % 2 === 0}"
                   [cdkDragData]="item" cdkDrag>
                <div *ngIf="!checkChangeable(item.type)" class="w-15percent align-middle p-y-6">
                  <div>
                    <i class="fa fa-bars"></i>
                  </div>
                </div>
                <div *ngIf="checkChangeable(item.type)" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()" (touchmove)="$event.stopPropagation()" (touchend)="$event.stopPropagation()"
                     class="w-15percent align-middle p-y-6">
                  <div>
                  </div>
                </div>
                <div class="w-35percent align-middle p-y-6" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()" (touchmove)="$event.stopPropagation()" (touchend)="$event.stopPropagation()"
                     *ngIf="checkChangeable(item.type); else changeableInput ">
                  <div>
                    <input class="w-100percent text-center"
                           type="text"
                           [(ngModel)]="item.name">
                  </div>
                </div>
                <ng-template #changeableInput>
                  <div class="w-35percent h-40 align-middle p-y-6" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()" (touchmove)="$event.stopPropagation()" (touchend)="$event.stopPropagation()">
                    <div>
                    {{ item.name | translate }}
                    </div>
                  </div>
                </ng-template>
                <div class="w-25percent h-40 align-middle p-y-6 align-middle" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()" (touchmove)="$event.stopPropagation()" (touchend)="$event.stopPropagation()">
                  <div>
                    <input type='checkbox'
                           [checked]='item.display_in_tab_1'
                           readonly
                           (click)='toggleCheckBoxFirstBox(dailyCheckIndex)'
                           class='h-20 w-20 mr-3'>
                  </div>
                </div>
                <div class="w-25percent h-40 align-middle p-y-6 align-middle" (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()" (touchmove)="$event.stopPropagation()" (touchend)="$event.stopPropagation()">
                  <div>
                    <input type='checkbox'
                           readonly
                           [checked]='item.display_in_tab_2_3_4_5'
                           (click)='toggleCheckBoxSecondBox(dailyCheckIndex)'
                           class='h-20 w-20 mr-3'>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

