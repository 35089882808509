import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductSet } from 'src/app/models/productSet.model';
import { CategoryService } from '../../category/category.service';
import { Category } from 'src/app/models/category.model';
import { BehaviorSubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmModalService } from 'src/app/components/confirm-modal/confirm-modal.service';
import { FormControl } from '@angular/forms';
import { ProductSetService } from 'src/app/services/product-set.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-set',
  templateUrl: './product-set.component.html',
  styleUrls: ['./product-set.component.scss']
})
export class ProductSetComponent implements OnInit {
  @Input() productSet: ProductSet;
  @Input() selectedSeller$: String;
  @Output() selectForUpload = new EventEmitter<{
    productSet: ProductSet;
    category: Category;
  }>();
  @Output() deleted = new EventEmitter();

  // Receive category list
  @Input() AllCategory: object[];
  // Call loadCategoryBySeller method
  @Output() addedCategory = new EventEmitter();

  edit = false;
  nameInput: FormControl;

  refresh$ = new BehaviorSubject(null);
  // categories$: Observable<Category[]>;
  categories: Category[];
  isLoading = false;
  modalLoading = false;

  categoryInput: Partial<Category> = {
    category_name: ''
  };

  categoryError = '';

  categoryNames: string[] = [];

  filteredCategoryList: object[];
  selectedCategoryList: object[];

  constructor(
    private categoryService: CategoryService,
    private confirmModalService: ConfirmModalService,
    private productSetService: ProductSetService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  filterCategory(event) {
    this.filteredCategoryList = [];
    const keyword = event.query.toLowerCase();
    this.AllCategory.forEach((category: any) => {
      if (category.category_name.toLowerCase().indexOf(keyword) !== -1) {
        category.name =  "(" + category.id + ") " + category.category_name;
        this.filteredCategoryList.push(category);
      }
    });
  }

  async addCategory() {
    this.categoryError = null;
    if (this.selectedCategoryList['id']) {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const res = await this.categoryService
            .addCategory({
              category_id: this.selectedCategoryList['id'],
              category_name: this.selectedCategoryList['category_name'],
              product_set: this.productSet.id
            })
            .toPromise();
          this.selectedCategoryList = [];
        } catch (error) {
          this.categoryError = 'Please enter valid information';
        } finally {
          this.loadCategories();
          this.isLoading = false;
        }
      }
    }
    else{
      this.categoryError = 'Please select from the drop-down list';
    }
    this.addedCategory.emit();
  }

  deleteCategory(category: Category, productSet: ProductSet) {
    this.confirmModalService.open(
      'Delete Confirmation',
      'Are you sure you want to delete?',
      () => {
        this.categoryService.deleteCategoryFromProductSet(category.category, productSet.id, category.id)
        .subscribe(res => {
          this.loadCategories();
          this.loadCategoryNames();
        });
      }
    );
  }

  async loadCategories() {
   this.categories = await (await this.categoryService.getCategories({product_set: this.productSet.id})
                      .toPromise()).results;
  }

  async loadCategoryNames(){
    this.categoryNames = await this.categoryService.getAllCategories({ some: true }).
    pipe(map(res => res.map(v => v.category_name))).toPromise()
  }

  startEdit() {
    this.nameInput = new FormControl(this.productSet.name);
    this.edit = true;
  }

  performEdit() {
    const payload = { name: this.nameInput.value }
    this.productSetService.update(this.productSet.id, payload).subscribe((res) => {
      this.edit = false;
      this.productSet.name = this.nameInput.value;
    },
    (error)=> {
      console.error(error)
    });
  }

  dismissEdit() {
    this.edit = false;
    this.nameInput = null;
  }

  openForDelete() {
    this.confirmModalService.open(
      'Delete Confirmation',
      'Are you sure you want to delete?',
      () => {
        this.productSetService.delete(this.productSet.id).subscribe(res => {
          this.deleted.emit(res);
        });
      }
    );
  }

  uploadPrice(category: Category) {
    this.selectForUpload.emit({ productSet: this.productSet, category });
  }

  async drop(event: CdkDragDrop<string[]>) {
    const seq = event.currentIndex;
    const id = event.item.data.id;
    const oldSeq = event.previousIndex

    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);

    const fd = new FormData();
    fd.append('seq', seq.toString())
    fd.append('oldSeq', oldSeq.toString())
    fd.append('changing_id', id)

    await this.categoryService.editSequence(id, fd).toPromise();
    this.loadCategories();
  }

  async openModel(view){
    this.modalLoading = true;
    const element = document.body;
    element.scrollTop = 0;
    this.categories = [];
    this.categoryNames = [];
    this.modalService.open(view).result.then((result) => {}, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK) {
          this.dismissModal();
          this.dismissEdit();
        }
    });
    await this.loadCategories();
    await this.loadCategoryNames();
    this.modalLoading = false;
  }

  dismissModal(){
    this.modalService.dismissAll();
  }
}
