import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import {Ios_deployComponent} from "./ios_deploy.component";


export const Ios_deployRoutes: Routes = [
  {
    path: 'ios_deploy',
    children: [
      {path: '', component: Ios_deployComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(Ios_deployRoutes)],
  exports: [RouterModule]
})
export class Ios_deployRoutingModule {
}
