<app-header [title]="'Product Image' | translate"> </app-header>
<div class="w-100">

  <div class="row mx-4 p-0 d-flex justify-content-end my-4">
    <div class="col-3 row p-0 m-0">
      <label class="row m-0 p-0 w-100" for="productCode">
        {{ 'product-image.product-code' |translate }}:
      </label>
      <div class="row w-100 p-0 m-0">
        <p-autoComplete
          class="w-100 h-100"
          [(ngModel)]="productCode"
          [multiple]="true"
          [suggestions]="productCodeList"
          [field]=""
          [placeholder]="'product-image.search-product-code' | translate"
          [emptyMessage]="'product-image.product-code-not-found' | translate"
          [forceSelection]="true"
          (completeMethod)="onProductCodeFiltering($event)"
          (onUnselect)="onProductCodeUnselectValue($event)"
        >
          <ng-template let-value pTemplate="selectedItem">
            <span class="ui-autocomplete-token-label">{{ value }}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <div class="col-5 d-flex align-items-end pb-1">
      <button class="btn btn-sm d-flex align-items-center btn-primary mr-3" (click)="loadProductImage(true)">
        <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
         {{'product-image.search' | translate}}
      </button>
      <button class="btn btn-sm d-flex align-items-center btn-secondary" (click)="resetProductImage()">
        <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
        {{'product-image.reset' | translate}}
      </button>
    </div>

    <div class="col-4 d-flex justify-content-end align-items-end pb-1">
      <button class="btn btn-sm d-flex align-items-center btn-primary" (click)="openUploadImage(uploadImage)">
        <img src="assets/svgs/white_download.svg" height="20" class="mr-1" width="auto">
        {{ 'product-image.import-zip' | translate }}
      </button>
    </div>

  </div>

  <app-loading *ngIf="loading"></app-loading>

  <app-list-empty
    title="{{ 'noDataFound' | translate }}"
    *ngIf="!loading && !productImageList.length">
  </app-list-empty>

  <div class="row my-3 mx-4 p-0">
    <div class="custom-border w-100">
      <table class="table table-hover text-center borderless table-striped" *ngIf="!loading && productImageList.length">
        <thead>
          <tr>
<!--            <th class="v-middle" width="18%" style="border-top-left-radius: 10px;">{{ 'user.username' | translate }}</th>-->
<!--            <th class="v-middle" width="18%">{{ 'user.email' | translate }}</th>-->
<!--            <th class="v-middle" width="18%">{{ 'user.shopName' | translate }}</th>-->
<!--            <th class="v-middle" width="5%" style="border-top-right-radius: 10px;">{{ 'Delete' | translate }}</th>-->
            <th class="v-middle" width="35%" style="border-top-left-radius: 10px;">{{ 'product-image.product-code' | translate}}</th>
            <th class="v-middle" width="35%">{{ 'product-image.image' | translate}}</th>
            <th class="v-middle" width="15%">{{ 'user.edit' | translate }}</th>
            <th class="v-middle" width="15%" style="border-top-right-radius: 10px;">{{ 'Delete' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of productImageList; let i = index">
            <td class="align-middle">{{ item.product_code || '-' }}</td>
            <td class="align-middle">
              <img
                *ngIf="item.product_image"
                [src]="item.product_image"
                height="75px"
                width="75px"
              />
            </td>
            <td class="align-middle">
              <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
                (click)="openEditProductImage(editProductImageModal, item)">
            </td>
            <td class="align-middle">
              <img src="assets/svgs/trash_bin.svg" height="28" width="auto"
                (click)="openDeleteProductImage(deleteProductImageModal, item)">
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-12">
      <ngb-pagination
        [collectionSize]="totalItems"
        [(page)]="currentPage"
        [pageSize]="itemPerPage"
        (pageChange)="loadProductImage(false)"
      ></ngb-pagination>
    </div>

</div>

<ng-template #uploadImage let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'product-image.import-zip' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="cancelUploadImage()"
    >
      <span class="white-text" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
<!--        <label for="dateOfBirth">{{ 'Select File' | translate }}</label>-->
        <label>Select File</label>
        <div class="input-group mb-3">
          <input
            type="file"
            (change)="currentFile.next($event.target.files[0])"
            accept=".zip"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="mr-4 w-75">
          <app-loading *ngIf="loading"></app-loading>
          <div
          class="mb-1 alert alert-success"
          role="alert"
          *ngIf="fileUploadSuccess | async as message"
          >
            {{ message }}
          </div>
          <div
          class="mb-1 alert alert-danger"
          role="alert"
          *ngIf="fileUploadError | async as error"
          >
            {{ error }}
          </div>
    </div>
    <div class="mr-2">
      <button
      type="button"
      class="btn btn-outline-dark btn-w-120"
      (click)="uploadFile()"
    >
      {{ 'product-image.import' | translate }}
    </button>
    </div>

  </div>
</ng-template>

<ng-template #editProductImageModal let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'product-image.edit-image' | translate }}
  </h4>
</div>
<div class="modal-body">

  <div class="form-group">
    <div class="mt-3 ml-3 mr-3">
      <div class="mb-3">
        <span class="font-weight-bold">{{'product-image.product-code' | translate}}</span>
      </div>
      <input
      disabled
      type="text"
      [(ngModel)]="editProductCode"
      class="form-control input-sm mb-3"
      />
      <div class="mb-3">
        <span class="font-weight-bold">{{ 'product-image.image' | translate }}</span>
      </div>
      <div>
        <img
          [src]="editProductImage"
          width="109"
          height="70"
          class="bor-ra-top-l-r"/>
        <div style="width: 109px">
          <label style="background-color: #8e8c84;width: 109px;text-align: center;color: floralwhite" class="clickable bor-ra-bot-l-r"
          (click)="uploadContentPopupImage('productImage')">{{ 'user.edit' | translate}}</label>
          <input type="file" accept="image/*"
                  (change)="onImageFileChange($event)"
                  id="productImage" style="display: none;"/>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="mr-4 w-75">
          <div
          class="mb-1 alert alert-success"
          role="alert"
          *ngIf="successMessage"
          >
            {{ successMessage }}
          </div>
          <div
          class="mb-1 alert alert-danger"
          role="alert"
          *ngIf="errorMessage"
          >
            {{ errorMessage }}
          </div>
    </div>
  <button
    type="button"
    class="btn btn-light btn-lg rounded border"
    (click)="cancelEditImage()"
  >
      {{ 'Cancel' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-secondary btn-lg rounded"
    (click)="saveEditImage()"
  >
      {{ 'Save' | translate }}
  </button>
</div>
</ng-template>

  <ng-template #deleteProductImageModal let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'product-image.delete-image' | translate }}
  </h4>
</div>
<div class="modal-body">

  <div class="form-group">
    <div class="text-center mt-3">
      <span class="font-weight-bold">{{ 'product-image.delete-message' | translate }}</span>
    </div>
  </div>

</div>
<div class="modal-footer">
  <div class="mr-4 w-75">
          <div
          class="mb-1 alert alert-success"
          role="alert"
          *ngIf="successMessage"
          >
            {{ successMessage }}
          </div>
          <div
          class="mb-1 alert alert-danger"
          role="alert"
          *ngIf="errorMessage"
          >
            {{ errorMessage }}
          </div>
    </div>
  <button
    type="button"
    class="btn btn-danger btn-lg rounded mr-2 w-25"
    (click)="cancelDeleteImage()"
  >
      {{ 'product-image.no' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary btn-lg rounded w-25"
    (click)="deleteProductImage()"
  >
      {{ 'product-image.yes' | translate }}
  </button>
</div>

