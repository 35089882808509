import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { mainRoutes } from './main/main-routing.module';
import { AuthGuard } from './auth.guard';
import { RegisterComponent } from './register/register.component';
import { Ios_deployComponent } from './features/ios_deploy/ios_deploy.component';
import { RegisterSuccessComponent } from './register/register-success/register-success.component';
import { RedirectComponent } from './redirect/redirect.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'redirect/:uuid', component: RedirectComponent},
  { path: 'login/:msg', component: LoginComponent },
  {
    path: 'register',
    children: [
      { path: 'success', component: RegisterSuccessComponent },
      { path: 'bigdealer', component: RegisterComponent },
      {
        path: '',
        component: RegisterComponent
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ]
  },
  { path: 'downloads/etr', component: Ios_deployComponent },
  {
    path: '',
    children: [...mainRoutes],
    canActivateChild: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
