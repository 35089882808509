import {Component, OnInit} from '@angular/core';
import {Seller} from "../seller.model";
import {HttpClientService} from "../../../services/http-client.service";
import {TranslateService} from "@ngx-translate/core";
import {SellerService} from "../../../services/seller.service";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import * as _ from 'lodash';
import {SellerEmail, SubSeller} from "../../../models/seller.model";
import {API_CONSTANT} from "../../../services/api.constants";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-edit-seller',
  templateUrl: './edit-seller.component.html',
  styleUrls: ['./edit-seller.component.scss']
})
export class EditSellerComponent implements OnInit {
  public loading = false;
  dataLength = 0;
  dataList = [];
  sellerList: SellerEmail[];
  subSellerList: SubSeller[];
  page = 1;
  type = 'add';
  editingID = null;
  emailError = false;
  saveError = {};
  productString: string;
  subSellerName: string = '';
  email: string = '';
  shopID: number = null;
  shoppID: number = null;
  shops = [];
  error: string = '';
  subSellerID = null;

  constructor(
    private api: HttpClientService,
    private translate: TranslateService,
    private sellerService: SellerService,
    private modalService: NgbModal,
    private tostrService: ToastrService,
    public router: Router,) {
  }

  ngOnInit() {
    this.loadShops();
    this.loadSubSeller();
  }

  loadShops() {
    this.api.get(API_CONSTANT.BASE_URL + '/shops/?eneos=true').subscribe(
      (res: any) => {
        this.shops = res;
        this.dataLength = res.length;
      },
      (err) => {
      }
    );
  }

  editEmail(view, item) {
    this.type = 'edit';
    this.editingID = item.id;
    this.email = item.email;
    this.shoppID = item.shop;
    this.shopID = item.shop_name;
    this.openEmailPopup(view);
  }

  submit() {
    const fd = new FormData();
    this.email == '' ? this.error = 'Email' :
      this.email != '' ? fd.append('email', this.email) : {};
    this.subSellerID != '' ? fd.append('sub_seller', this.subSellerID) : {};

    if (this.type === 'add') {
      this.shopID == null ? this.error = 'Select Shop' : this.error = '';
      this.shopID ? fd.append('shop', this.shopID.toString()) : {};
    } else {
      // this.shopID == null ? this.error = 'Select Shop' : this.error = '';
      // this.shopID ? fd.append('shop', this.shoppID.toString()) : {};
    }

    if (this.type == 'add' && this.error == '') {
      this.sellerService.addEmail(fd).subscribe((res) => {
          this.tostrService.success(this.translate.instant("Adding Successful"));
          this.loadSellerEmail(this.subSellerID);
          this.loadShops();
          this.dismissEditOrCreate();
        },
        (error) => {
          this.tostrService.error(this.translate.instant('Adding Failed'));
        })
    } else if (this.type == 'edit' && this.error == '') {
      this.sellerService.updateEmail(this.editingID, fd).subscribe((res) => {
          this.tostrService.success(this.translate.instant("Updated Successfully"));
          this.loadSellerEmail(this.subSellerID);
          this.loadShops();
          this.dismissEditOrCreate();
        },
        (error) => {
          this.tostrService.error(this.translate.instant('Update Failed'));
        })
    } else {
      this.tostrService.error(this.error);
    }
  }

  addEmail(view) {
    this.type = 'add';
    this.email = '';
    this.shopID = null;
    this.openEmailPopup(view);
  }

  openEmailPopup(view) {
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  edit() {
    this.router.navigateByUrl('seller/edit/');
  }

  addSubSeller(view) {
    this.type = 'add';
    this.subSellerName = '';
    this.openSubSellerPopup(view);
  }

  openSubSellerPopup(view) {
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  onProductSelect(event) {
    this.subSellerID = event.id
    this.productString = event.name;
    this.loadSellerEmail(event.id)
  }

  loadSubSeller() {
    this.loading = true;
    let param = new HttpParams();

    this.sellerService.getSubSeller(param).subscribe(
      (res: any) => {
        this.subSellerList = res;
        this.dataLength = res.length;
        this.loading = false;
        this.productString = res[0].name;
        this.loadSellerEmail(res[0].id);
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  loadSellerEmail(id) {
    this.loading = true;
    let param = new HttpParams();
    param = param.append('sub_seller', id.toString())

    this.sellerService.getEmail(param).subscribe(
      (res: any) => {
        this.sellerList = res;
        this.dataLength = res.length;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }


  save() {
    let fd = new FormData();
    this.subSellerName == '' ? this.saveError['sub seller name'] = true : fd.append('name', this.subSellerName);
    this.loading = true;
    this.sellerService.createSubSeller(fd).subscribe(
      (res: any) => {
        this.loading = false;
        this.dismissEditOrCreate();
        this.loadSubSeller();
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  dismissEditOrCreate() {
    this.subSellerName = '';
    this.type = 'add';
    this.shopID = null;
    this.editingID = null;
    this.email = '';
    this.removeError();
    this.modalService.dismissAll();
  }

  removeError() {
    this.emailError = false;
  }

  removeSaveError(type) {
    delete this.saveError[type];
  }

}
