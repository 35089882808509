import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './category.component';
import {EditCategoryComponent} from "./edit-category/edit-category.component";


export const CategoryRoutes: Routes = [
  {
    path: 'category',
    children: [
      { path: ':page', component: CategoryComponent },
      { path: '', component: CategoryComponent },
      { path: 'edit/:id/', component: EditCategoryComponent},
      { path: 'edit/:id/:page', component: EditCategoryComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(CategoryRoutes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule {}
