export interface Dealer {
  name: string;
  id: number;
  branch_name: string;
  full_name: string;
  is_daily_check_dealer: boolean;
  is_monotaro_enabled: boolean;
  is_purchasing_function: boolean;
  is_etr_sugkik: boolean;
}

export interface DealerList {
  dealers: Dealer[];
}

export class DealerEditItem {
  name_list: string[];
  id_list: number[];
  is_daily_check_dealer: boolean;
  is_monotaro_enabled: boolean;
  is_etr_sugkik: boolean;
  is_purchasing_function: boolean;

  constructor() {
    this.name_list = [];
    this.id_list = [];
    this.is_daily_check_dealer = false;
    this.is_monotaro_enabled = false;
    this.is_purchasing_function = false;
    this.is_etr_sugkik = false;
  }
}

export interface EditDealerSettingBody {
  id: number[];
  is_daily_check_dealer: boolean;
  is_monotaro_enabled: boolean;
  is_purchasing_function: boolean;
  is_etr_sugkik: boolean;
}
