import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared.module';
import { CoreModule } from '../../components/core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MonotaroComponent } from './monotaro.component';
import { MonotaroRoutingModule } from './monotaro-routing.module';
import { PurchaseOrderDetailComponent } from './purchase-order-detail/purchase-order-detail.component';

@NgModule({
  declarations: [
    MonotaroComponent,
    PurchaseOrderDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbPaginationModule,
    MonotaroRoutingModule,
    CoreModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    ChipsModule,
    CalendarModule,
    InputTextModule,
    AutoCompleteModule,
    NgbDatepickerModule,
    NgbModule,
  ],
  providers: []
})
export class MonotaroModule {}
