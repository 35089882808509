import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../components/core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../shared.module';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RegisterComponent, RegisterSuccessComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgSelectModule,
    SharedModule,
    RouterModule
  ]
})
export class RegisterModule {}
