<app-header [title]="'seller.Seller' | translate">
</app-header>
<!--background-color: #485460-->
<div class="row p-0 m-3 d-flex justify-content-end">
  <button class="btn btn-primary d-flex align-items-center"
    (click)="openPopup(editOrCreate)">
    <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
    {{ 'seller.addSeller' | translate }}
  </button>
</div>
<app-loading style="position: center;vertical-align: middle; text-align: center;" *ngIf="loading"></app-loading>
<app-list-empty *ngIf="!loading && !dataLength" title="{{ 'noDataFound' | translate }}"></app-list-empty>
<div class="row mb-4 mx-3">
  <table class="table table-hover text-center table-striped" style="border-radius: 5px;" *ngIf="!loading && dataLength">
    <thead class="thead-light">
      <tr>
        <th>{{ 'seller.number' | translate }}</th>
        <th>{{ 'seller.name' | translate }}</th>
        <th>{{ 'seller.email' | translate }}</th>
        <th>{{ 'CC Email' | translate }}</th>
        <th>{{ 'edit' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="
                  let item of sellerList
                    | slice: (page - 1) * 40:(page - 1) * 40 + 40; let i = index;">
        <td *ngIf="page==1" class="align-middle">{{ i + 1 }}</td>
        <td *ngIf="page>1" class="align-middle">{{ i + (40 * (page - 1)) + 1 }}</td>
        <td class="align-middle">{{ item.name }}</td>
        <td *ngIf="item.id !== 1" class="align-middle">{{ item.email_name }}  ({{ item.seller_web_user }}) </td>
        <td *ngIf="item.id === 1" class="align-middle">{{'Check the seller email in category module' | translate }}</td>
        <td *ngIf="item.id !== 1" class="align-middle">
          <ul class="m-0 p-0" *ngFor="let email of item.cc_email">
            {{ email.email }} ({{ email.id }})
          </ul>
        </td>
        <td *ngIf="item.id === 1" class="align-middle">{{'Check the seller email in category module' | translate }}</td>
        <td *ngIf="item.id !== 1" class="v-middle">
          <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
            (click)="openPopup(editOrCreate, item)">
        </td>
        <td *ngIf="item.id === 1"></td>
<!--        <td class="align-middle" *ngIf="item.id === 23" class="v-middle" (click)="edit()">-->
<!--          <button class="btn btn-sm btn-outline-primary d-flex align-items-center mx-auto"> {{ "Email List" | translate}}</button>-->
<!--        </td>-->
      </tr>
    </tbody>
  </table>
  <div>
    <ngb-pagination
      [collectionSize]="dataLength"
      [(page)]="page"
      [pageSize]="40"
      [maxSize]="3"
      [rotate]="true"
      [boundaryLinks]="true"
      [ellipses]="false"
    ></ngb-pagination>
  </div>
</div>


<ng-template #editOrCreate>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0" *ngIf="type=='add'"> {{  'seller.addSeller' | translate }}</h4>
      <h4 class="m-0" *ngIf="type=='edit'"> {{ 'seller.editSeller' | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-3">
      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{ 'seller.name' | translate }}
        </label>
        <input type="text" class="col-8 form-control" [(ngModel)]="sellerName"
          [placeholder]="'seller.enterSellerName' | translate"
          [ngClass]="{'is-invalid' : saveError['name'] }">
      </div>

      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{ 'seller.email' | translate }}
        </label>
        <ng-select
          *ngIf="type === 'add'"
          class='col-8 p-0'
          [clearable]="true"
          [items]="sellers"
          [placeholder]="'Select Email' | translate "
          bindLabel="showValue"
          bindValue="id"
          [(ngModel)]="sellerEmail"
          [notFoundText]="'notFound' | translate">
        </ng-select>
        <label *ngIf="type === 'edit'" class="m-0">{{ sellerEmail || '-' }}</label>
      </div>

      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 mt-2 d-flex align-items-start">
          {{ 'CC Email' | translate }}
        </label>
        <ng-select
          class='col-8 p-0'
          [clearable]="false"
          [items]="allSellerWebUsers"
          [placeholder]="'Select CC Email' | translate"
          bindLabel="showValue"
          bindValue="email"
          [multiple]="true"
          [(ngModel)]="cc_emails"
          [notFoundText]="'notFound' | translate">
        </ng-select>
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="save()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>
