import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DailyCheckDataManagementComponent } from './daily-check-data-management.component';


export const DailyCheckDataManagementRoutes: Routes = [
  {
    path: 'daily-check-data-management',
    children: [
      { path: '', component: DailyCheckDataManagementComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(DailyCheckDataManagementRoutes)],
  exports: [RouterModule]
})
export class DailyCheckDataManagementRoutingModule {}
