import { Component, OnInit } from '@angular/core';
import { MaintenanceSimulationService } from '../maintenance-simulation/maintenance-simulation.service';
import { ExportFileService } from '../../services/export-file.service';
import { ToastrService } from 'ngx-toastr';
import { AnalysisItem } from '../maintenance-simulation/maintenance-simulation.model';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-calculation-history',
  templateUrl: './calculation-history.component.html',
  styleUrls: ['./calculation-history.component.scss'],
})
export class CalculationHistoryComponent implements OnInit {
  loading: boolean = false;

  currentPage: Number = 1;
  noOfPage: Number = 1;
  count = 3;

  selecedOperation: string = 'self';
  gasoline: number = 0;
  lightOil: number = 0;
  itemList: AnalysisItem[] = [];

  constructor(
    private maintenanceService: MaintenanceSimulationService,
    private exportExcelService: ExportFileService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchItem();
  }

  exportFile() {
    this.maintenanceService.exportExcel().subscribe(
      (res) => {
        this.exportExcelService.saveFile(res, 'Shop Potential Export.xlsx');
      },
      (error) => {
        this.toastrService.error(error);
      }
    );
  }

  searchItem(): void {
    this.loading = true;
    let param = new HttpParams();
    param = param.append('gasoline', this.gasoline.toString());
    this.maintenanceService.get(param).subscribe(
      (res: any) => {
        this.itemList = res.results;
        this.loading = false;
      },
      (err) => {
        this.toastrService.error('error');
        this.loading = false;
      }
    );
  }
}
