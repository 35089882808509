import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDatepickerModule, NgbModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../components/core/core.module';
import { CategoryComponent } from './category.component';
import { CategoryRoutingModule } from './category-routing.module';
import { CategoryService } from './category.service';
import { SharedModule } from 'src/app/shared.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [CategoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    CategoryRoutingModule,
    CoreModule,
    SharedModule,
    NgxFileDropModule,
    AutoCompleteModule,
    NgbModule,
  ],
  providers: [CategoryService]
})
export class NewCategoryCodeModule {}
