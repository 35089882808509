import {Injectable} from '@angular/core';
import {HttpClientService} from './http-client.service';
import {API_CONSTANT} from './api.constants';
import {forkJoin, Observable} from 'rxjs';
import {Product, ProductSet} from '../models/productSet.model';
import {PaginatedResult} from '../components/pagination/pagination.interface';
import {Seller} from '../models/seller.model';
import {Category} from '../models/category.model';
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProductSetService {
  static API_URL = API_CONSTANT.BASE_URL + '/product_sets/';

  constructor(private api: HttpClientService) {
  }

  deleteSellerEmail(id): Observable<any> {
    return forkJoin([
      this.api.delete(API_CONSTANT.BASE_URL + `/product-seller-email/${id}/`)
    ]);
  }

  addSellerEmail(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/product-seller-email/', data);
  }

  updateSellerEmail(id, fd) {
    return this.api.put(API_CONSTANT.BASE_URL + '/product-seller-email/' + id + '/', fd);
  }

  getProducts(params?: any): Observable<PaginatedResult<Product>> {
    return this.api.get(API_CONSTANT.BASE_URL + '/products/', params);
  }

  get(params?: any): Observable<PaginatedResult<ProductSet>> {
    return this.api.get(ProductSetService.API_URL, params);
  }

  getAll(params?: any): Observable<ProductSet[]> {
    return this.api.get(ProductSetService.API_URL, {...params, all: true});
  }

  create(data) {
    return this.api.post(ProductSetService.API_URL, data);
  }

  update(id, data) {
    return this.api.patch(ProductSetService.API_URL + id + '/', data);
  }

  delete(id) {
    return this.api.delete(ProductSetService.API_URL + id);
  }

  deleteProductSetCategory(data) {
    return this.api.post(API_CONSTANT.BASE_URL + `/delete-productset-category`, data);
  }

  getProductSetById(id: number) {
    let params = new HttpParams();
    params = params.append('product_set_id', id.toString());
    return this.api.get(API_CONSTANT.BASE_URL + `/get-productset-details/`, params);
  }

  getAllProductsets() {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-all-productsets/');
  }

  getAllProductcodes(id: number) {
    let params = new HttpParams();
    params = params.append('category_id', id.toString());
    return this.api.get(API_CONSTANT.BASE_URL + '/get-all-productcodes/', params);
  }

  importProducts(
    seller: Seller,
    category: Category,
    productSet: ProductSet,
    file: File
  ) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('seller_id', seller.id.toString());
    formData.append('category_id', category.id.toString());
    formData.append('product_set_id', productSet.id.toString());
    return this.api.post(API_CONSTANT.BASE_URL + '/import-product/', formData);
  }

  getProductSellerEmails(params: HttpParams) {
    return this.api.get(
      API_CONSTANT.BASE_URL + '/products/seller-emails',
      params
    );
  }
}
