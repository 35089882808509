export const activityRankingTables: string[] = [
    'Greetings_TOP5',
    'Checkup_TOP5',
    'Estimation_TOP5',
    'Confirmed_Order_TOP5'
]

export const positionList: any = [
    { name: 'Manager 1', role: 1 },
    { name: 'Sub-Manager 1', role: 2 },
    { name: 'Shop Manager', role: 3 },
    { name: 'Mate', role: 4 },
    { name: 'Part-Time Worker', role: 5 },
    { name: 'Employee', role: 6 },
    { name: 'Staff', role: 7 },
    { name: 'Manager 2', role: 8 },
    { name: 'Sub-Manager 2', role: 9 },
];