import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: 'input'
})
export class EnterInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('document:keydown.enter', ['$event'])
  onKeyDown(event) {
    event.preventDefault();
    event.stopPropagation();
    console.log('enter click');
    let all_input = document.getElementsByTagName('input');
    for (let i = 0; i < all_input.length; i++) {
      if (all_input[i] == event.path[0]) {
        if (i == all_input.length - 1) { all_input[0].focus(); }
        else { all_input[i + 1].focus(); }
      }
    }
  }

}
