import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { User } from './user.model';
import { Shop } from '../../models/shop.model';
import { ShopService } from '../../services/shop.service';
import { UserService } from './user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/services/translate.service';
import { ConfirmModalService } from 'src/app/components/confirm-modal/confirm-modal.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  userList: User[];
  originalUsers: User[];
  shopNameList = []
  userNameList = []
  originalUserNames = []
  originalShopNames = []
  dataLength: number;
  loading = true;
  currentPage = 1;
  auto_emptymsg = 'User not found';

  constructor(
    private userService: UserService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private shopService: ShopService,
    private translationService: TranslationService,
    private confirmModal: ConfirmModalService
  ) {}

  ngOnInit() {
    this.loadUsers();
    this.loadUsersAndShops();
    this.userService.gritUserData = null;
    this.userService.is_grit = false;
  }

  exportLoginList() {
    this.userService.downloadLoginList()
  }

  navigateTo(page: number) {
    this.router.navigateByUrl(`/user/${page}`);
  }

  loadUsersAndShops() {
    let params = new HttpParams();
    params = params.append('getAllUsersAndShops', 'true');
    this.userService.getAllUsers(params).subscribe((res: any) => {
      res.all_shops.forEach(element => {
        if(this.shopNameList.indexOf(element) == -1 && element != null) {
          this.shopNameList.push(element);
          this.originalShopNames.push(element);
        }
      });
      res.all_users.forEach(element => {
        if(this.userNameList.indexOf(element) == -1 && element != null) {
          this.userNameList.push(element);
          this.originalUserNames.push(element);
        }
      });
    },
    (error)=> {
      console.warn(error);

    });
  }

  loadUsers() {
    this.loading = true;
    let params = new HttpParams();
    let pageNo = this.user_names.length || this.shop_names.length ? '1' : this.currentPage.toString()
    params = params.append('page', pageNo);
    params = params.append('tablet_user', '');
    this.user_names.length ? params = params.append('username', this.user_names.toString()) : {};
    this.shop_names.length ? params = params.append('shopname', this.shop_names.toString()) : {};
    this.userService.getAllUsers(params).subscribe((res: any) => {
      this.dataLength = res.count;
      this.userList = res.results;
      this.originalUsers = res.results;
      this.loading = false;
    });
  }

  translateMessage(msg) {
    return this.translationService.transformMessage(msg);
  }

  openForDelete(user: User, row: number) {
    this.confirmModal.open(
      'Delete Confirmation',
      'Are you sure you want to delete?',
      () => {
        this.userService.delete(user.id).subscribe(res => {
          this.userList.splice(row, 1);
        });
      }
    );
  }

  edit(user, index:number) {
    if(user.is_grit){
      this.userService.is_grit = true;
      this.userService.gritUserData = user;
    }
    this.router.navigateByUrl('user/edit/' + user.id + '/');
  }

  addNewUser() {
    this.router.navigateByUrl('user/add/')
  }

  user_names = []
  shop_names = []
  // for username search suggestions

  onUserUnselectValue(event) {
    this.user_names = this.user_names.filter(
      (user_name: number) => user_name != event
    );
  }

  onUserFiltering(e){
    this.userNameList = []
    let searchingName = e.query.toLowerCase();
    this.userNameList = this.originalUserNames.filter(x => x.toLowerCase().indexOf(searchingName) >= 0);
    this.userNameList.sort();
  }


  // for shopname search suggestions

  onShopUnselectValue(event) {
    this.shop_names = this.shop_names.filter(
      (shop_name: number) => shop_name != event
    );
  }

  onShopFiltering(e){
    this.shopNameList = []
    let searchingName = e.query.toLowerCase();
    this.shopNameList = this.originalShopNames.filter(x => x.toLowerCase().indexOf(searchingName) >= 0)
  }

  reset() {
    this.user_names = [];
    this.shop_names = [];
    this.loadUsers();
  }
}
