<app-header [title]=" 'Dealer/Shop' | translate"></app-header>
<div class='content'>

  <div class='row no-gutter d-flex justify-content-end my-3'>
    <!-- <button class="col-auto btn btn-outline-primary hover-detect"
            (click)='openImportModal(importModal)'>
      <div class="plus-icon"></div>
      {{'Import' | translate}}
    </button>

    <div class="col-auto">
      <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button class="btn btn-primary" id="dropdownBasic1" ngbDropdownToggle>
          {{'Create' | translate}}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
             class='dropdown-menu-right'>
          <button ngbDropdownItem (click)='openDealer(createOrEditDealer)'>{{'Dealer' | translate }}</button>
          <button ngbDropdownItem (click)='openShop(createOrEditShop)'>{{'Shop' | translate }}</button>
        </div>
      </div>
    </div> -->
  </div>

  <div class='row no-gutter'>

    <!---------------Dealer Col ---------------------------------------------->
    <div class='col-4'>
      <div class='one-col-table shadow'>
        <div class='col-header'>
          {{ 'Select Dealer' | translate }}
        </div>

        <div class='row no-gutter m-2'>
          <input type='text'
                 class='w-100 form-control'
                 placeholder="{{ 'dealer.searchDealer' | translate }}"
                 [(ngModel)]='searchDealer'
                 (ngModelChange)='search()'>
        </div>

        <div class='col-body'>
          <div class='row no-gutter dealer-row'
               *ngFor='let dealer of dealers; let editingRow = index'
               [ngClass]="{'selected': checkInSelectDealer(dealer)}">
            <div class="col-12 row no-gutter">
              <span class='col-11 px-0 py-2 m-0'
                    (click)='selectDealer(dealer)'>
                 <b>({{ dealer.is_etr ? "ETR" : "CTA" }})</b> {{ dealer.name }} {{ dealer.branch_name }}
              </span>
            </div>
          </div>
          <app-loading *ngIf='loadingDealers'></app-loading>
        </div>
      </div>
    </div>

    <!------------------------------Shop Col ----------------------------------->
    <div class='col-4' [ngClass]="{'hide': !selectedDealerID.id}">
      <div class='one-col-table shadow'>
        <div class='col-header'>
          {{ 'Select Shop' | translate }}
        </div>

        <div class='row no-gutter d-flex justify-content-between align-items-center p-2 m-2 h-35'>
          <h4 class='m-0'>
            {{'Select All' | translate }}
          </h4>
          <input type='checkbox'
                 [checked]='selectedAll'
                 class='h-20 w-20 mr-3'
                 (click)='selectAllShops()'>
        </div>

        <div class='col-body'>
          <div class='row no-gutter dealer-row'
               *ngFor='let shop of shops; let editingRow = index;'
               [ngClass]="{'selected': checkInSelectedShop(shop.id)}">
            <div class="col-12 row no-gutter" *ngIf="editingShopRow !== editingRow; else editingShop">
              <div class="col-1 p-0 m-0 d-flex justify-content-start">
                <img src='assets/svgs/black_info_circle.svg' 
                  width='24' 
                  height='auto'
                  class='mr-2'
                  container='body'
                  [placement]="['bottom-left', 'top-left']"
                  [ngbTooltip]="tipContent"
                  tooltipClass="my-custom-topic">

                <ng-template #tipContent>
                  <div class='row no-gutter tooltip-header'>
                    {{'More Details' | translate }}
                  </div>
                  <div class='row no-gutter'>
                    <div class='col-md-6 px-0 h-100 controlled-left'>
                      <div class='w-100'>{{'Product Set' | translate}}</div>
                      <div class='w-100'>{{'Price Set' | translate}}</div>
                    </div>
                    <div class='col-md-6 px-0 h-100 controlled-right middle-line'>
                      <div class='w-100'>{{ shop.product_set_name ? shop.product_set_name : "&nbsp;" }}</div>
                      <div class='w-100'>{{ shop.package_set_name ? shop.package_set_name : "&nbsp;" }}</div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <span class='col-10 px-0 py-2 m-0'
                    (click)='selectShop(shop.id)'>
                {{ shop.name }}
              </span>
              <span *ngIf="!is_cta" class='col-1 p-0 m-0 d-flex justify-content-end clickable' (click)="editShop(editingRow)">
                <img src='assets/svgs/edit_pen.svg' width='20' height='auto' class='mr-2'>
              </span>
            </div>
            <ng-template #editingShop>
              <input type="text"
                     class="col-10 my-1"
                     [(ngModel)]="editingShopName">
              <span class="col-2 d-flex justify-contents-between align-items-center px-2">
                <img src="assets/svgs/blue_check.svg" width="20" height="20" class="mr-1 clickable" (click)="saveEditingShop()">
                <img src="assets/svgs/red_cross.svg" width="20" height="20" class="clickable" (click)="dismissShopEdit()">
              </span>
            </ng-template>
          </div>
          <app-loading *ngIf='loadingShops'></app-loading>
        </div>

      </div>
    </div>

    <!----------------------------Package Set Col------------------------------->
    <div class='col-4' [ngClass]="{'hide': !selectedShops || !selectedShops.length}">
      <div class='one-col-table'>
        <div class='col-header'>
          {{ 'dealer.selectSalePackage' | translate }}
        </div>

        <ng-select
          class='row no-gutter m-2'
          [clearable]="false"
          [items]="productSets"
          [loading]='loadingProductSets'
          bindLabel="name"
          bindValue='id'
          (change)='selectProductSet($event)'
          [(ngModel)]='selectedProductSetID'
          [placeholder]="'product-set.selectProductSet' | translate"
          [notFoundText]="'notFound' | translate">
        </ng-select>

        <div class='col-body'
             [ngClass]="{'confirm-box': selectedSalePackageID}">
          <div class='row no-gutter dealer-row py-2'
               *ngFor='let salePackage of salePackages'
               (click)='selectSalePackage(salePackage)'
               [ngClass]="{'selected': selectedSalePackageID === salePackage.id}">
            {{ salePackage.name }}
          </div>
        </div>
        <div class='confirm-row' *ngIf='selectedSalePackageID'>
          <button class='btn btn-primary'
                  [disabled]="savingSelections"
                  (click)='saveSelections()'>
            {{'Save' | translate }}
            <img src="assets/images/loading.svg"
                 width="auto" height="20"
                 class="ml-2"
                 *ngIf="savingSelections">
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #createOrEditDealer>
    <div class='modal-content'>
      <div class='modal-header' *ngIf="editOrCreateDealer == 'create'">
        {{'dealer.addDealer' | translate }}
      </div>
      <div class='modal-header' *ngIf="editOrCreateDealer == 'edit'">
        {{'Edit Dealer' | translate }}
      </div>
      <div class='modal-body'>
        <input
          type="text"
          placeholder="{{ 'dealer.name' | translate }}"
          [(ngModel)]="dealerInput.name"
          class="form-control input-sm my-2"/>
        <!-- <div *ngIf="editOrCreateDealer === 'edit'">
          <label class="form-group">{{ 'Use MONOTARO' | translate }}</label>
          <input type='checkbox'
                   [checked]='dealerInput.is_monotaro_enabled'
                   class='h-20 w-20 ml-3'
                   (click)='isMonotaroEnabled()'>
        </div> -->
      </div>

      <div class='modal-footer'>
        <button class="btn btn-secondary" (click)="dismissDealer()">
          {{ 'Cancel' | translate }}
        </button>
        <button class="btn btn-primary"
                [disabled]="creatingDealer || !dealerInput.name"
                (click)="addOrEditDealer()">
          <i class="fas fa-save"></i> <span style="margin-left: 5px" translate>Create</span>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #createOrEditShop>
    <div class='modal-content'>
      <div class='modal-header'>
        {{'shop.addShop' | translate }}
      </div>
      <div class='modal-body'>
        <ng-select
          class="my-2"
          [clearable]="true"
          [items]="dealers"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="shopInput.dealer"
          [placeholder]="'dealer.selectDealer' | translate"
          [notFoundText]="'dealer.notFound' | translate">
        </ng-select>
        <input
          type="text"
          placeholder="{{ 'shop.name' | translate }}"
          [(ngModel)]="shopInput.name"
          [readOnly]="!shopInput.dealer"
          class="form-control input-sm"
        />
      </div>
      <div class='modal-footer'>
        <button class="btn btn-secondary" (click)="dismissShop()">
          {{ 'Cancel' | translate }}
        </button>
        <button class="btn btn-primary"
                [disabled]="creatingShop || !shopInput.dealer || !shopInput.name"
                (click)="addShop()">
          <i class="fas fa-save"></i> <span style="margin-left: 5px" translate>
          {{'Create' | translate }}
        </span>
        </button>
      </div>
    </div>
  </ng-template>
</div>


<ng-template #importModal>
  <div class="modal-header">
    {{'Import' | translate }}
  </div>
  <div class="modal-body">
    <input type="file"
           id="file"
           class="row no-gutter"
           (change)="handleFileInput($event.target.files)">

    <ng-select
      [clearable]="false"
      class="row no-gutter mt-2"
      [items]="dealerCustomerType"
      bindLabel="name"
      bindValue="value"
      [(ngModel)]="dealerType"
      [placeholder]="'shop.customerType' | translate"
      [notFoundText]="'notFound' | translate">
    </ng-select>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="close()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button"
            class="btn btn-outline-primary"
            [disabled]="importing || !this.file"
            (click)="uploadFile()">
      {{ 'Upload' | translate }}
    </button>
  </div>
</ng-template>
