import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AnalysisItem} from './maintenance-simulation.model';
import {API_CONSTANT} from '../../services/api.constants';
import {HttpClientService} from '../../services/http-client.service';
import {PaginatedResult} from '../../components/pagination/pagination.interface';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceSimulationService {
  static API_URL = API_CONSTANT.BASE_URL + '/maintenance-simulations/';

  constructor(private api: HttpClientService) {
  }

  get(params?: any): Observable<PaginatedResult<AnalysisItem>> {
    return this.api.get(MaintenanceSimulationService.API_URL, params);
  }

  post(data) {
    return this.api.post(MaintenanceSimulationService.API_URL, data);
  }

  exportExcel(params?: Object) {
    return this.api.postFile(API_CONSTANT.BASE_URL + '/export-maintenance-simulations/', params);
  }
}
