import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { API_CONSTANT } from 'src/app/services/api.constants';
import { Branch } from './branch.model';
import { BranchService } from './branch.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  public loading = false;
  dataLength = 0;
  newBranchName = '';
  newBranchSellerEmail = '';
  dataList = [];
  error_message = '';
  error_list: Array<string> = null;
  branchForm: FormGroup;

  constructor(private branch: BranchService, private fb: FormBuilder) {}

  ngOnInit() {
    this.branchForm = this.fb.group({
      name: ['', Validators.required],
      email_seller: ['', [Validators.required, Validators.email]]
    });
    this.loadSeller();
  }

  loadSeller() {
    this.loading = true;
    this.branch.getBranches().subscribe(
      (res: any) => {
        this.dataList = res.results;
        this.dataLength = res.count;
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  importSeller() {
    if (this.branchForm.valid) {
      const data: Branch = this.branchForm.value;

      this.loading = true;

      this.branch.addBranch(data).subscribe(
        (res: any) => {
          this.branchForm.reset();
          this.loading = false;
          this.loadSeller();
        },
        err => {
          this.loading = false;
        }
      );
    }
  }
}
