import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ExportResultComponent } from './export-result.component';


export const exportResultRoutes: Routes = [
  {
    path: 'export-result',
    children: [
      {path: '', component: ExportResultComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(exportResultRoutes)],
  exports: [RouterModule]
})
export class ExportResultRoutingModule {
}
