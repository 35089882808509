import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {ProductSetService} from '../../../services/product-set.service';
import {SalePackageService} from '../../../services/sale-package.service';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {ModalDismissReasons, NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {TranslationService} from '../../../services/translate.service';
import {API_CONSTANT} from '../../../services/api.constants';
import {HttpClientService} from '../../../services/http-client.service';
import {ToastrService} from 'ngx-toastr';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {CategoryService} from '../../category/category.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-product-master',
  templateUrl: './edit-product-master.component.html',
  styleUrls: ['./edit-product-master.component.scss']
})
export class EditProductMasterComponent implements OnInit {
  id: number;
  type = '';
  productset: any;
  category_data = []
  priceset_data = []
  priceset_id: number;
  categories = [];
  dataLength = 0;
  categoryID: number = null;
  error = '';
  packagesetName = '';
  originalName = ''
  editPSName = false;
  importFile: File | null = null;
  uploadFileError: string;
  uploading = false;
  startTime = {'hour': 0, 'minute': 0};
  startDate = null;
  selectedCheckBox = 'Normal';
  originalPPName = '';
  editingPPName = '';
  editingPricePackage = false;
  editingPPRow = null;
  minDate: NgbDateStruct = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

  constructor(
    private api: HttpClientService,
    public activatedRoute: ActivatedRoute,
    private productSetService: ProductSetService,
    private salePackageService: SalePackageService,
    private modalService: NgbModal,
    private translationSerice: TranslationService,
    private tostrService: ToastrService,
    private categoryService: CategoryService,
    private router: Router,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
      }
    );
    this.getCategory()
    this.getPriceSet()
    this.loadCategories()
  }

  checked(selected) {
    this.selectedCheckBox = selected;
  }

  chooseFile(event) {
    this.importFile = event.target.files.item(0);
  }

  save() {
    let params = new HttpParams();
    params = params.append('name', this.productset.name);
    this.productSetService.update(this.id, params).subscribe((res) => {
      this.tostrService.success(this.translate.instant('Successfully Updated'));
      this.editPSName = false;
    }, (error) => {
      this.tostrService.error(this.translate.instant('Update Failed'));
    })
  }

  async deletePrice(content, item) {
    try {
      const result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title'
      }).result;
      this.deletePriceSet(item.id);
    } catch (e) {
    }
  }

  deletePriceSet(id) {
    this.salePackageService.delete(id).subscribe(res => {
        this.tostrService.success(this.translate.instant('Deleted Price Set Successful'));
        this.getPriceSet();
      },
      (error) => {
        this.tostrService.error(this.translate.instant('Deletion Failed'));
      }
    );
  }

  async deleteCategory(content, item) {
    try {
      const result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title'
      }).result;
      this.deleteProductSetCategory(item.id);
    } catch (e) {
    }
  }

  deleteProductSetCategory(id) {
    let params = new HttpParams();
    params = params.append('category_id', String(id));
    params = params.append('productset_id', String(this.id));
    this.productSetService.deleteProductSetCategory(params).subscribe(res => {
        this.tostrService.success(this.translate.instant('Deleted Category Successful'));
        this.getCategory();
        this.loadCategories();
      },
      (error) => {
        this.tostrService.error(this.translate.instant('Deletion Failed'));
      }
    );
  }


  savePackage() {
    let params = new HttpParams();
    params = params.append('name', this.packagesetName);
    params = params.append('product_set', String(this.id));
    this.salePackageService.create(params).subscribe((res) => {
        this.getPriceSet()
        this.tostrService.success(this.translate.instant('Adding Successful'));
        this.packagesetName = '';
        this.modalService.dismissAll();
      },
      (error) => {
        this.tostrService.error(error)
      })
  }

  submit() {
    const fd = new FormData();
    this.categoryID === null ? this.error = 'Select Category' : this.error = '';
    fd.append('id', this.id.toString());
    this.categoryID ? fd.append('category_id', this.categoryID.toString()) : {};
    this.productSetService.update(this.id, fd).subscribe((res) => {
        this.getCategory()
        this.loadCategories()
        this.tostrService.success(this.translate.instant('Updated Successfully'));
        this.dismissEditOrCreate();
      },
      (error) => {
        this.tostrService.error(this.translate.instant('Update Failed'));
      })
  }


  loadCategories() {
    this.api.get(API_CONSTANT.BASE_URL + `/categories/?all=true&product_set=${this.id}`).subscribe(
      (res: any) => {
        this.categories = res;
        this.dataLength = res.length;
      },
      (err) => {
      }
    );
  }

  addCategory(view) {
    this.type = 'add';
    this.openCategoryPopup(view);
  }

  openCategoryPopup(view) {
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  dismissEditOrCreate() {
    this.categoryID = null;
    this.modalService.dismissAll();
  }

  openDialog(content, item: any) {
    this.priceset_id = item.id;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  getDateNTime(date, time, type) {
    if (!date) {
        return '';
    }
    !time ? type === 'end' ? (time = {hour: 23, minute: 59}) : (time = {hour: 0, minute: 0}) : {};
    time.second = 0;
    const dateStr = _.values(date).map(x => +x < 10 ? '0' + x : x).join('-');
    const timeStr = _.values(time).join(':');
    return dateStr + 'T' + timeStr;
  }

  uploadFile() {
    const is_scheduled = this.selectedCheckBox !== 'Normal';
    const time = this.getDateNTime(this.startDate, this.startTime, 'start');
    this.uploading = true;
    this.uploadFileError = null;
    this.salePackageService.import(this.id, this.priceset_id, this.importFile, is_scheduled, time).subscribe(
      () => {
        this.modalService.dismissAll();
        this.uploading = false;
        this.getPriceSet();
        this.router.navigate(['/import-product']);
      },
      (error) => {
        this.uploadFileError = error.error?.message?.toString();
        this.uploading = false;
      }
    );
  }

  getCategory() {
    if (this.id) {
      this.type = 'editing';
      this.productSetService.getProductSetById(this.id).subscribe((res) => {
          this.productset = res;
          this.category_data = this.productset.category_data;
        },
        (error) => {
          this.tostrService.error(error)
        })
    }
  }

  translateMessage(messages) {
    const message_split = messages.split('\n');
    const messageList = [];
    for (const message of message_split) {
      messageList.push(
        this.translationSerice.transformMessage(message) || message
      );
    }
    return messageList;
  }

  getPriceSet() {
    let params = new HttpParams();
    params = params.append('product_set', String(this.id));
    this.salePackageService.get(params).subscribe((res) => {
        this.priceset_data = res;
      },
      (error) => {
        this.tostrService.error(error)
      })
  }

  async drop(event: CdkDragDrop<string[]>) {
    const seq = event.currentIndex;
    const id = event.item.data.id;
    const oldSeq = event.previousIndex
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    const fd = new FormData();
    fd.append('seq', seq.toString())
    fd.append('oldSeq', oldSeq.toString())
    fd.append('changing_id', id)
    fd.append('productset_id', this.id.toString())
    await this.categoryService.editSequence(id, fd).toPromise();
    this.getCategory()

  }

  editProductSetName() {
    this.originalName = this.productset.name;
    this.editPSName = true;
  }

  resetName() {
    this.productset.name = this.originalName;
    this.editPSName = false;
  }

  editPricePackageName(rowIndex: number) {
    this.editingPPRow = rowIndex;
    this.editingPPName = this.priceset_data[rowIndex].name;
    this.editingPricePackage = true;
  }

  savePricePackage() {
    const id = this.priceset_data[this.editingPPRow].id;
    const fd = new FormData();
    fd.append('name', this.editingPPName);
    this.salePackageService.update(id, fd).subscribe((res: any) => {
      this.tostrService.success(this.translate.instant('Edit Price Package Name Successful'));
      this.priceset_data[this.editingPPRow].name = res.name;
      this.resetPricePackage();
    },
    (error) => {
      this.tostrService.error(error)
    })
  }

  resetPricePackage() {
    this.editingPPRow = null;
    this.editingPricePackage = false;
    this.originalPPName = '';
    this.editingPPName = '';
  }
}
