<app-header [title]="'Category' | translate"></app-header>

<div class="row w-100 p-3 px-4 m-0 d-flex justify-content-end">
  <!-- IDSearch  -->
  <div class="w-15percent row p-0 m-0 align-self-start">
    <label class="row w-100 m-0 p-0 pl-1">
      {{ 'category.number' | translate }}
    </label>
    <input type="number" class="row w-100 m-0 mr-3 form-control" [(ngModel)]="searchID"
      [placeholder]="'enterNumber' | translate">
  </div>

   <!-- UserNameSearch  -->
   <div class="w-25percent row p-0 m-0 align-self-start">
    <label class="row w-100 m-0 p-0 pl-1">
      {{ 'Category' |translate}}:
    </label>
    <div class="row w-100 p-0 m-0 pr-3">
      <p-autoComplete
        class="w-100"
        [(ngModel)]="category_names"
        [multiple]="true"
        [suggestions]="categoryNameList"
        [field]=""
        [placeholder]="'searchCategory' | translate"
        [emptyMessage]="auto_emptymsg"
        [forceSelection]="true"
        (completeMethod)="onCategoryFiltering($event)"
        (onUnselect)="onCategoryUnselectValue($event)"
      >
        <ng-template let-value pTemplate="selectedItem">
          <span class="ui-autocomplete-token-label">{{ value }}</span>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>

  <!-- SelllerNameSearch  -->
  <div class="w-25percent row p-0 m-0 align-self-start">
    <label class="row w-100 m-0 p-0 pl-1">
      {{ 'category.seller' |translate}}:
    </label>
    <div class="row w-100 p-0 m-0 pr-3">
      <ng-select
          class="w-100 p-0"
          [clearable]="false"
          [items]="originalSellerNames"
          bindLabel="seller__name"
          bindValue="seller_id"
          [(ngModel)]="seller_ids"
          [multiple]="true"
          [placeholder]="'selectSeller' | translate"
          [notFoundText]="'notFound' | translate"
        ></ng-select>
    </div>
  </div>

  <div class="w-35percent d-flex align-items-start justify-content-between">
    <div class="d-flex align-items-start">
      <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center"
        (click)="loadCategories()">
      <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
        {{'search' | translate}}
      </button>
      <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center"
        (click)="reset()">
      <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
        {{'reset' | translate}}
      </button>
    </div>
    <button class="btn mt-4 mr-2 btn-sm btn-primary d-flex align-items-center"
      (click)="addCategory(editOrCreate)">
      <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
      {{'category.addCategory' | translate}}
    </button>
  </div>
</div>

<app-loading
  style="position: center;vertical-align: middle; text-align: center;"
  *ngIf="loading"
></app-loading>
<app-list-empty
  *ngIf="!loading && !dataLength"
  title="{{ 'noDataFound' | translate }}"
></app-list-empty>
<div class="row mb-4 mx-4">
  <table
    class="table table-hover text-center table-borderless table-striped"
    style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
    *ngIf="!loading && dataLength"
  >
    <thead >
      <tr>
        <th width="5%">
          {{ 'category.number' | translate }}
        </th>
        <th width="18%">
          {{ 'Category' | translate }}
        </th>
        <th width="18%">
          {{ 'category.seller' | translate }}
        </th>
        <th width="18%">
          {{ 'category.image' | translate }}
        </th>
        <th width="12%">
          {{ 'category.edit' | translate }}
        </th>
        <th width="12%">
          {{ 'category.upload' | translate }}
        </th>
        <th width="13%">
          {{ 'category.appropriatedStockAtShop' | translate }}
        </th>
        <th width="12%">
          {{ 'category.deleteCategory' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of dataList; let i = index; trackBy: trackByFn">
      <td class="align-middle">{{ item.id }}</td>
      <td class="align-middle">{{ item.category_name }}</td>
      <td class="align-middle">{{ item.seller_name }}</td>
      <td class="align-middle">
        <div class="image-field" *ngIf="item.category_image">
          <img [src]="item.category_image"/>
        </div>
        <i class="fas fa-image" *ngIf="!item.category_image"></i>
      </td>
      <td *ngIf="item.seller != 1" class="align-middle">
        <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
        (click)="editCategory(editOrCreate, item)">
      </td>
      <td *ngIf="item.seller == 1" class="align-middle">
        <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
        (click)="edit(item)">
      </td>

      <td class="align-middle">
        <img src="assets/svgs/download.svg" height="28" width="auto"
          (click)="openDialog(content, item)">
      </td>

      <td class="align-middle" *ngIf="item.type!=2">
        <img src="assets/svgs/box_plus.svg" height="28" width="auto"
        (click)="openStockDialog(stock, item)">
      </td>

      <td class="align-middle" *ngIf="item.type==2"> <!-- Other Category Type -->
        <img src="assets/svgs/box_plus_gray.svg" height="28" width="auto" style="cursor: default;">
      </td>

      <td class="align-middle">
        <img src="assets/svgs/trash_bin.svg" height="28" width="auto"
          (click)="openDelete(confirmDelete, item)">
      </td>
    </tr>
    </tbody>
  </table>
  <app-pagination
    [current]="page"
    [pages]="pageCount"
    (navigate)="navigateTo($event)"
  ></app-pagination>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'product-master.uploadFile' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="uploadFileError"
        >
          {{ 'Cannot upload file' | translate }}. {{ 'Error' | translate }}: {{ uploadFileError }}
        </div>
        <label class="form-group">{{ 'Select File' | translate }}</label>
        <input class="form-control-file"
               type="file"
               (change)="chooseFile($event)"
               accept=".zip, .xls, .xlsx">
      </div>
    </form>
    <div class="row no-gutters mt-3">
      <div class="col-5 align-self-center">
        <div class="btn-group" role="group" aria-label="First group">
          <button type="button"
                  class="btn"
                  (click)="checked('Normal')"
                  [ngClass]="selectedCheckBox === 'Normal' ? 'btn-secondary' : 'btn-outline-secondary' ">
            {{ 'Normal' | translate }}
          </button>
          <button type="button"
                  class="btn"
                  (click)="checked('Scheduled')"
                  [ngClass]="selectedCheckBox === 'Scheduled' ? 'btn-primary' : 'btn-outline-secondary' ">
            {{ 'Scheduled' | translate }}
          </button>
        </div>
      </div>
      <div class="col-7 row no-gutters">
        <input
          class="col-6 from-control h-35 align-self-center border rounded px-1"
          type='text'
          (click)="d.toggle()"
          name="dp"
          ngbDatepicker
          [disabled]="selectedCheckBox == 'Normal'"
          [(ngModel)]="startDate"
          [minDate]="minDate"
          #d="ngbDatepicker">

        <div class='col-6 px-2'>
          <ngb-timepicker
            [(ngModel)]="startTime"
            [disabled]="selectedCheckBox == 'Normal'"
          >
          </ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="uploadFile()"
      translate
      [disabled]="!importFile || importingFile"
    >
      {{ 'Import' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #stock let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-stock-title">
      {{ 'product-master.uploadStockFile' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="uploadStockFileError"
        >
          {{ 'Cannot upload file' | translate }}. {{ 'Error' | translate }}: {{ uploadStockFileError }}
        </div>
        <label class="form-group">{{ 'Select File' | translate }}</label>
        <input class="form-control-file"
               type="file"
               (change)="chooseFile($event)"
               accept=".xlsx">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="uploadStockFile()"
      translate
      [disabled]="!importFile || importingFile"
    >
      {{ 'Import' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #confirmDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'category.deleteConfirmation' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'category.areYouSure' | translate }}</p>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ 'category.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="modal.close('Ok click')"
      >
        {{ 'category.confirm' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #uploading>
  <app-loading
    style="position: center;vertical-align: middle; text-align: center;"
  ></app-loading>
</ng-template>

<ng-template #editOrCreate>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0" *ngIf="type=='add'"> {{ 'category.addCategory' | translate}}</h4>
      <h4 class="m-0" *ngIf="type=='edit'"> {{ 'category.editCategory' | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-2">
      <div class="row p-0 m-0 mt-3">
        <div class="center w-100">
          <ngx-file-drop dropZoneLabel="Drop files here"
              (onFileDrop)="dropped($event)"
              [multiple]="false"
              accept=".png,.jpg">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="w-100 h-100 clickable"
                 (click)="openFileSelector()" *ngIf="!imageUrl">
                  <div class="row my-4 d-flex justify-content-center">
                    <img src="assets/svgs/file_upload.svg" height="60px" width="auto">
                  </div>
                  <h4 class="row d-flex justify-content-center text-secondary">
                    {{'browseOrDrop' | translate}}
                  </h4>
                </div>

                <div class="w-100 h-100 row d-flex justify-content-center align-items-center clickable"
                  *ngIf="imageUrl">
                  <div class="col-12 d-flex justify-content-center mt-1" (click)="openFileSelector()">
                    <img [src]="imageUrl" alt="" class="preview-img">
                  </div>
                  <div class="rmv-img" (click)="removeImage()" *ngIf="type=='add'">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
              </ng-template>
          </ngx-file-drop>
        </div>
      </div>

      <div class="row p-0 m-0 mt-4">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{'category.categoryName' | translate}}</label>
        <input type="text" class="col-8 form-control" [(ngModel)]="categoryName"
          [placeholder]="'inputCategory' | translate">
      </div>

      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{'category.seller' | translate}}</label>
        <ng-select
          *ngIf="type!=='add'"
          class="col-8 w-100 p-0"
          [clearable]="false"
          [items]="sellers"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="selerID"
          [placeholder]="'selectSeller' | translate"
          [notFoundText]="'notFound' | translate"
        ></ng-select>
        <ng-select
          *ngIf="type=='add'"
          class="col-8 w-100 p-0"
          [clearable]="false"
          [items]="sellers"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="sellerID"
          [placeholder]="'selectSeller' | translate"
          [notFoundText]="'notFound' | translate"
        ></ng-select>
      </div>

      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{ 'Type' | translate }}
        </label>
        <ng-select class="col-8 w-100 p-0"
            [clearable]="false"
            [items]="productImportType"
            bindLabel="name"
            bindValue="value"
            [(ngModel)]="selectedProductImportType"
            [disabled]="type=='edit'"
            [notFoundText]="'notFound' | translate">
          <ng-template ng-label-tmp
              let-item="item">
            <span> {{ item.name | translate }}</span>
          </ng-template>
          <ng-template ng-option-tmp
              let-item="item"
              let-search="searchTerm"
              let-index="index">
            <span class="d-flex align-items-center">
              {{ item.name | translate }}
            </span>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="submit()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>
