<app-header title="{{ 'Order Api Disable Date' | translate }}">
</app-header>

<div class="main-content">
  <div class="row mx-4 p-0 d-flex justify-content-end my-4">
    <button id="addUserButton"
        class="btn btn-sm btn-primary  d-flex align-items-center"
        [disabled]="addNewLoading"
        (click)="addNewDates(addNewModal)">
      <img src="assets/svgs/plus_icon.svg"
          height="20"
          class="mr-1"
          width="auto">
      {{ 'Add Disabled Date Range' | translate }}
    </button>
  </div>
  <div class='row no-gutter p-3'>
    <div class='disabled-dates-table'
        *ngIf="!loadingDisabledDates">
      <table class="table table-hover text-center table-borderless table-striped mb-5"
          style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
          *ngIf="!loadingDisabledDates && disabledDates.length">
        <thead>
          <tr>
            <th width="5%"></th>
            <th width='35%'>{{'API Start Date' | translate }}</th>
            <th width="10%"></th>
            <th width="35%">{{'API End Date' | translate }}</th>
            <th width="10%">{{'Delete' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let disabledDate of disabledDates; let i = index;">
            <td class="align-middle detail-icn">
              {{ i + 1 }}
            </td>
            <td class="align-middle">{{ disabledDate.startDate }}</td>
            <td class="align-middle">~</td>
            <td class="align-middle">{{ disabledDate.endDate}}</td>
            <td class="align-middle">
              <img [ngClass]="{'disabled': isInvalidToDelete(disabledDate)}" src='assets/svgs/red_trash.svg' width='25' height='25'
              (click)='confirmDelete(disabledDate.id)'>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class='row no-gutter d-flex justify-content-center w-100percent'
        *ngIf='!loadingDisabledDates && !disabledDates.length'>
      <h1 class='text-muted w-100percent text-center'>{{ 'noDataFound' | translate }}</h1>
    </div>
    <app-loading class="w-100percent my-3"
        *ngIf="loadingDisabledDates">
    </app-loading>
  </div>
</div>

<ng-template #addNewModal>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="d-flex text-center m-0">
        {{ 'Order Api Disable Date' | translate }}
      </h4>
    </div>
  </div>
  <div class="modal-body mx-3 p-t-15 mb-3 container">
    <div class="row my-3">
      <span class="col-3 my-3 d-flex align-items-center">{{ "Start Date" | translate}} </span>
      <input class="d-inline col-4 form-control h-35 align-self-center border rounded px-1"
          type='text'
          (click)="sd.toggle()"
          name="sdp"
          [minDate]="currentDate"
          [(ngModel)]="addNewProps.startDate"
          ngbDatepicker
          #sd="ngbDatepicker">
      <div class="col-3">
        <ngb-timepicker [(ngModel)]="addNewProps.startTime">
        </ngb-timepicker>
      </div>
    </div>
    <div class="row my-3">
      <span class="col-3 my-3 d-flex align-items-center">{{ "End Date" | translate}}</span>
      <input class="d-inline col-4 form-control h-35 align-self-center border rounded px-1"
          type='text'
          (click)="ed.toggle()"
          name="edp"
          [minDate]="addNewProps.startDate"
          [disabled]="!addNewProps.startDate"
          [(ngModel)]="addNewProps.endDate"
          ngbDatepicker
          #ed="ngbDatepicker">
      <div class="col-3">
        <ngb-timepicker [(ngModel)]="addNewProps.endTime"
            [disabled]="!addNewProps.startDate">
        </ngb-timepicker>
      </div>
    </div>
  </div>
  <div class="modal-footer border-top-0">
    <button class="btn btn-secondary"
        (click)="dismiss()">{{'Cancel' | translate}}</button>
    <button class="btn btn-danger"
        [disabled]="!(addNewProps.startDate && addNewProps.endDate) || addNewLoading"
        (click)="addNewDatesConfirmed()">{{ 'Add API' | translate}}</button>
  </div>
</ng-template>
