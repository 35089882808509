import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared.module';
import { CoreModule } from '../../components/core/core.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { InputTextModule } from 'primeng/inputtext';
import { SellerWebuserComponent } from './seller-webuser.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    SellerWebuserComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    CoreModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    InputTextModule,
    NgbModule,
    NgSelectModule,
  ],
  providers: []
})
export class SellerWebUserModule {}
