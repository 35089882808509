import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { API_CONSTANT } from 'src/app/services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class SellerWebuserService {
  constructor(private api: HttpClientService) {}

  getSellerWebUsers(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/seller-web-users/', params);
  }

  createNewSellerWebUser(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/seller-web-users/', data);
  }

  updateSellerWebUser(id, data) {
    return this.api.patch(API_CONSTANT.BASE_URL + '/seller-web-users/' + id + '/', data);
  }

  importSellerWebUser(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-seller-web-user/', data)
  }
}
