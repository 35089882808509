import { Injectable } from '@angular/core';
import { SidebarFeatures } from './sidebar.model';
import { AdminUserRoutes, CustomENEOSTradingWebUserRoutes, HandleRoutes, NormalUserRoutes, ShopAdminMultipleTRAdminRoutes, TRAdminUsernames, customENEOSTradingWebUser } from './routes.constants';

@Injectable({
  providedIn: 'root',
})
export class SidebarRoutesService {
  UserAllowedRoutes: SidebarFeatures[] = [];

  constructor() {}

  setUserAllowedRoutes(): void {
    const username: string = localStorage.getItem('username');
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    const isAdmin: boolean = localStorage.getItem('isSuperuser') === 'true';
    if (isAdmin) {
      this.UserAllowedRoutes = AdminUserRoutes;
    } else if (TRAdminUsernames.includes(username)) {
      this.UserAllowedRoutes = ShopAdminMultipleTRAdminRoutes;
    } else if (customENEOSTradingWebUser.includes(username)) {
      this.UserAllowedRoutes = CustomENEOSTradingWebUserRoutes;
    } else {
      this.UserAllowedRoutes = HandleRoutes[group[0]] ?? NormalUserRoutes;
    }
  }

  getUserAllowedRoutes(): SidebarFeatures[] {
    return this.UserAllowedRoutes;
  }
}
