<nav aria-label="...">
  <ul class="pagination">
    <li class="page-item" [ngClass]="{ disabled: current === 1 }">
      <a class="page-link" (click)="navigateTo(current - 1)">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li
      *ngFor="let time of times"
      class="page-item"
      [ngClass]="{ active: time === current }"
    >
      <span class="sr-only" *ngIf="time === current">(current)</span>
      <a class="page-link" (click)="navigateTo(time)">{{ time }}</a>
    </li>
    <li class="page-item" [ngClass]="{ disabled: current === pages }">
      <a class="page-link" (click)="navigateTo(current + 1)">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</nav>
