import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-empty',
  templateUrl: './list-empty.component.html',
  styleUrls: ['./list-empty.component.scss']
})
export class ListEmptyComponent implements OnInit {
  @Input() title: string = '';
  @Input() addButtonTitle: string = '';
  @Input() addButtonURL: string = '';
  
  constructor() { }

  ngOnInit() {
  }

}
