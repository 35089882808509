import {Injectable} from '@angular/core';
import {HttpClientService} from 'src/app/services/http-client.service';
import {User} from './user.model';
import {API_CONSTANT} from 'src/app/services/api.constants';
import {Observable} from 'rxjs';
import {PaginatedResult} from 'src/app/components/pagination/pagination.interface';
import {HttpClient} from "@angular/common/http";
import {debug} from "util";

@Injectable({
  providedIn: 'root'
})

/** @description Service providing user data */
export class UserService {

  gritUserData: any;
  is_grit: boolean;
  constructor(private api: HttpClientService) {}

  getAllUsers(params?): Observable<PaginatedResult<User>> {
    return this.api.get<PaginatedResult<User>>(
      API_CONSTANT.BASE_URL + '/get-all-users/',
      params
    );
  }

  getUsers(params?): Observable<PaginatedResult<User>> {
    return this.api.get<PaginatedResult<User>>(
      API_CONSTANT.BASE_URL + '/users/',
      params
    );
  }

  getPendingUsers(params?): Observable<PaginatedResult<User>> {
    return this.api.get<PaginatedResult<User>>(
      API_CONSTANT.BASE_URL + '/register_users/',
      params
    );
  }

  addUser(data: User, shopName?: string, dealer_id?, is_grit?) {
    return this.api
      .post(API_CONSTANT.BASE_URL + `/users/`, {
        ...data,
        full_family_given_shop_name: `${data.family_name} ${data.given_name}, ${shopName}`,
        dealer: dealer_id,
        is_grit: is_grit
      })
      .toPromise();
  }

  editUser(data: User, shopName: string, id: number) {
    return this.api
      .patch(API_CONSTANT.BASE_URL + `/users/${id}/`, {
        ...data,
        full_family_given_shop_name: `${data.family_name} ${data.given_name}, ${shopName}`
      })
      .toPromise();
  }

  approveUser(id: number, data: Partial<User>) {
    return this.api.patch(API_CONSTANT.BASE_URL + `/register_users/${id}/`, {
      ...data
    });
  }

  delete(id: number) {
    return this.api.delete(API_CONSTANT.BASE_URL + `/users/${id}/`);
  }
  uploadDealer(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-big-dealer/', data);
  }

  getErrorMessage(err) {
    return this.api.getErrorMessage(err);
  }

  downloadLoginList() {
    this.getLedger(API_CONSTANT.BASE_URL + '/export-excel-login/');
  }

  getLedger(url: string) {
    window.open(url, '_blank');
  }

  getUserById(id: number){
    return this.api.get(API_CONSTANT.BASE_URL + `/users/${id}/`);
  }

  // User Emails
  getUserEmails(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + `/user_emails/`, params);
  }

  addUserEmails(data) {
    return this.api.post(API_CONSTANT.BASE_URL + `/user_emails/`, data);
  }

  editUserEmails(id, data, user_id) {
    return this.api.patch(API_CONSTANT.BASE_URL + `/user_emails/${id}/?user_id=${user_id}`, data);
  }

  deleteUserEmails(id, user_id) {
    return this.api.delete(API_CONSTANT.BASE_URL + `/user_emails/${id}/?user_id=${user_id}`);
  }
}
