import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {switchMap, take, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ProductImageService} from './product-image.service';
import {ProductImage} from './product-image.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent implements OnInit {

  // File Upload
  currentFile = new BehaviorSubject<File>(undefined);
  fileUploading = new BehaviorSubject<boolean>(false);
  fileUploadError = new BehaviorSubject<string>(undefined);
  fileUploadSuccess = new BehaviorSubject<string>(undefined);

  // message
  errorChangeImage: string;
  successMessage: string;
  errorMessage: string;
  deleteMessage: string;

  // pagination
  itemPerPage = 40;
  currentPage = 1;
  totalItems: number;

  // other
  productCode = [];
  productCodeList = [];
  originalProductCode = [];
  destroy = new Subject();
  loading = true;
  productImageList: ProductImage[];

  // edit
  editProductCode: string;
  editProductImage;
  instanceProductImage;

  // model
  productImageID: number;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService,
    private productImageService: ProductImageService,
    private notification: ToastrService,
    private router: Router
  ) {
    this.currentFile.pipe(takeUntil(this.destroy)).subscribe(_ => {
      this.fileUploadError.next(undefined);
      this.fileUploadSuccess.next(undefined);
    });
  }

  ngOnInit() {
    this.loadProductImage();
    this.getProductCode();
  }

  loadProductImage(search = false) {
    this.loading = true;
    if (search) {
      this.currentPage = 1;
    }
    const params = {
      product_code: this.productCode.join(', '),
      page: this.currentPage,
      item_per_page: this.itemPerPage,
    };
    this.productImageService.apiProductImage(params).subscribe(
      (res: any) => {
        this.totalItems = res.count;
        this.productImageList = res.results;
        this.loading = false;
      },
      (error) => {
        console.warn(error);
      }
    );
  }

  getProductCode() {
    const params = {
      product_code: '',
    };
    this.productImageService.apiProductCode(params).subscribe(
      (res: string[]) => {
        this.originalProductCode = res;
      },
      (error) => {
        console.warn(error);
      }
    );
  }

  onProductCodeFiltering(event) {
    this.productCodeList = this.originalProductCode.filter(
      (productCode: string) =>
        productCode.toLowerCase().indexOf(event.query.toLowerCase()) !== -1
    );
  }

  onProductCodeUnselectValue(event) {
    this.productCode = this.productCode.filter(
      (productCode: number) => productCode !== event
    );
  }

  resetProductImage() {
    this.productCodeList = [];
    this.originalProductCode = [];
    this.productCode = [];
    this.currentPage = 1;
    this.loadProductImage();
  }

  openUploadImage(content) {
    this.clearMessage();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard:false})
  }

  openEditProductImage(content, data) {
    this.clearMessage();
    this.instanceProductImage = data.product_image;
    this.getSelectedProductImage(data);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false});
  }

  openDeleteProductImage(content, data) {
    this.clearMessage();
    this.getSelectedProductImage(data);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false});
  }

  getSelectedProductImage(data) {
    this.productImageID = data.id;
    this.editProductCode = data.product_code;
    this.editProductImage = data.product_image;
  }

  uploadContentPopupImage(elementId: string) {
    document.getElementById(elementId).click();
  }

  onImageFileChange(fileInput: any) {
    const imageFile = fileInput.target.files;
    if (imageFile.length === 0) {
      return;
    }
    const mimeType = imageFile[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.errorChangeImage = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(imageFile[0]);
    reader.onload = (_fileInput) => {
      this.editProductImage = reader.result;
    }
  }

  uploadFile() {
    const lang = this.translate.currentLang;
    this.loading = true;
    this.fileUploadError.next(undefined);
    this.fileUploadSuccess.next(undefined);
    of(undefined)
      .pipe(
        withLatestFrom(this.translate.currentLang),
        withLatestFrom(this.currentFile),
        tap(() => {
          this.fileUploading.next(true);
        }),
        switchMap(([_, file]) =>
          this.productImageService.ImportProductImage(lang, file)
        ),
        take(1)
      )
      .subscribe(
        res => {
          this.loading = false;
          this.fileUploadError.next(undefined);
          this.fileUploading.next(false);
          this.notification.success(this.translate.instant('Upload file success'));
          this.cancelUploadImage(); // Close upload modal
          this.router.navigateByUrl('import-product');
        },
        error => {
          this.loading = false;
          this.fileUploadSuccess.next(undefined);
          this.fileUploading.next(false);
          this.notification.error(error);
        },
        () => {
          this.fileUploading.next(false);
          this.loadProductImage();
        }
      );
  }

  clearMessage() {
    this.errorMessage = null;
    this.successMessage = null;
    this.fileUploadError.next(undefined);
    this.fileUploadSuccess.next(undefined);
  }

  cancelUploadImage() {
    this.clearMessage();
    this.currentFile.next(undefined);
    this.modalService.dismissAll();
  }

  cancelEditImage() {
    this.editProductImage = null;
    this.editProductCode = null;
    this.instanceProductImage = null;
    this.clearMessage();
    this.modalService.dismissAll();
  }

  cancelDeleteImage() {
    this.clearMessage();
    this.modalService.dismissAll();
  }

  saveEditImage() {
    if (this.editProductImage === this.instanceProductImage) {
      this.errorMessage = 'Cannot import instance image';
      return;
    }
    const id = this.productImageID;
    const params = {
      product_id: id,
      product_code: this.editProductCode,
      product_image: this.editProductImage
    };
    this.productImageService.apiUpdateProductImage(id, params).subscribe(
      (res) => {
        this.successMessage = 'Save Successful';
      },
      (error) => {
        this.errorMessage = 'Save Failed';
      },
      () => {
        this.loadProductImage();
        this.modalService.dismissAll();
      }
    )
  }

  deleteProductImage() {
    const id = this.productImageID;
    this.productImageService.apiDeleteProductImage(id).subscribe(
      (res) => {
        this.successMessage = 'Delete Successful';
      },
      (error) => {
        this.errorMessage = 'Delete Failed';
      },
      () => {
        this.loadProductImage();
        this.modalService.dismissAll();
      }
    )
  }

}
