import { Component, OnInit } from "@angular/core";
import { ModalDismissReasons, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import * as _ from 'lodash';
import { SupervisorDealerManagerService } from "./supervisor-dealer-manager.service";
import { ToastrService } from "ngx-toastr";
import { Dealer, DealerManager } from "./supervisor-manager.model";
import { DealerService } from "../../dealer/dealer.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-supervisors-manager-list",
  templateUrl: "./supervisor-dealer-manager-list.component.html",
  styleUrls: ["./supervisor-dealer-manager-list.component.scss"],
})
export class SupervisorManagerListComponent implements OnInit {
  currentpage = 1;
  sellers = [];
  pageCount = 0;
  loading = false;
  deleting = false;
  saveError = {};
  user = {};
  dealerManagers: DealerManager[];
  managerToDeleteId: number;
  dealers: Dealer[] = [];
  errorMsg = {};
  type = '';
  search = {
    userName: '',
    email: '',
  }
  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private dealerService: DealerService,
    private dealerManagerService: SupervisorDealerManagerService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getDealers();
    this.getSuperVisorDealerManagerList();
  }

  getSuperVisorDealerManagerList() {
    this.loading = true;
    this.dealerManagerService.getSuperVisorDealerManagers().subscribe(
      (res: any) => {
        this.dealerManagers = res;
        this.loading = false;
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  getDealers() {
    this.loading = true;
    this.dealerService.getAllDealersName().subscribe(
      (res: any) => {
        this.dealers = res.dealers;
        this.loading = false;
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  showErrorMsg(msg) {
    this.toastrService.error(_.values(msg.error)[0].toString());
  }

  openModal(view, user = null) {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.type = user ? 'edit' : 'add';
    this.setUser(user);
    this.modalService.open(view, option).result.then((result) => { }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissUser();
      }
    });
  }

  openDeleteModal(view, managerId: number) {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.managerToDeleteId = managerId
    this.modalService.open(view, option);
  }

  cancel() {
    this.modalService.dismissAll();
  }

  saveUser() {
    const data = {};
      this.user['given_name'] ? data['given_name'] = this.user['given_name'] : this.errorMsg['name'] = true;
      this.user['family_name'] ? data['family_name'] = this.user['family_name'] : this.errorMsg['user'] = true;
      this.user['email'] && this.checkEmail(this.user['email']) ?
      data['email'] = this.user['email'] : this.errorMsg['email'] = true;

    if (_.values(this.errorMsg).filter(x => x).length) {
      return;
    }

  }

  updateUser(id, data) {
    this.dealerManagerService.updateSupervisorDealerManager(id, data).subscribe(
      (res) => {
        this.dismissUser();
        this.getSuperVisorDealerManagerList();
        this.toastrService.success(this.translate.instant('Successfully Updated'));
      },
      (error) => {
        this.toastrService.error(this.translate.instant('username/email already exists in the system'));
      }
    );
  }

  deleteUser() {
    this.deleting = true
    this.dealerManagerService.deleteSupervisorDealerManager(this.managerToDeleteId).subscribe(
      (res: any) => {
        this.managerToDeleteId = null;
        this.toastrService.success(this.translate.instant('Successfully Delete Manager'));
        this.deleting = false
        this.modalService.dismissAll();
        this.getSuperVisorDealerManagerList();
      },
      (error: any) => {
        this.toastrService.error(error);
        this.deleting = false
        this.modalService.dismissAll();
      }
    )
  }

  checkEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase())
  }

  dismissUser() {
    this.setUser();
    this.removeError();
    this.modalService.dismissAll();
  }

  removeError() {
    this.errorMsg = {};
  }

  setUser(user = null) {
    user ? user['password'] = 'unchangedPassword' : {};
    this.user = user || {
      'name': '',
      'userName': '',
      'email': '',
      'dealer': '',
      'password': '',
    }
  }

  navigateTo(event) {
    this.currentpage = event;
    this.getSuperVisorDealerManagerList();
  }

  resetSearch() {
    this.search = {
      userName: '',
      email: '',
    }
    this.getSuperVisorDealerManagerList();
  }

}
