import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterName',
  pure: false
})

export class FilterDealersNamePipe implements PipeTransform {

  // transform(values: string): any {
  //   return 'test'
  // }
  transform(items: any, filters: Object): any {
    if (!items || !filters || !items.length) {
      return items;
    }
    return items.filter(item => item.name.toUpperCase().indexOf(filters['name'].toUpperCase()) !== -1)
  }
}
