<div class="title">
  <h4 class="col-11 word-wrap" style="max-width: 900px;">{{ productSet.name }}</h4>
  <div class="col-1 d-flex justify-content-around">
    <i class="clickable far fa-edit" (click)="openModel(popupModel)"></i>
    <i class="clickable fa fa-trash" (click)="openForDelete()"></i>
  </div>
</div>

<ng-template #popupModel>
  <div class="modal-content">
    <div class="modal-header p-2 justify-content-start">
      <ng-container *ngIf="!edit; else editName">
        <h4 class="m-0 word-wrap">{{ productSet.name }}</h4>
        <i class="edit-icon fas fa-pen clickable" (click)="startEdit()"></i>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </ng-container>
      <ng-template #editName>
        <input
          type="text"
          placeholder="{{ 'product-set.categoryName' | translate }}"
          class="form-control edit-box"
          [formControl]="nameInput"
        />
        <button type="button" class="btn btn-sm btn-primary rounded-right mr-2 clickable" (click)="performEdit()">
          <i class="fa fa-check" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-sm btn-danger clickable" (click)="dismissEdit()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </ng-template>
    </div>

    <div class="modal-body" *ngIf="!modalLoading">
      <div class="content">
        <div class="alert alert-danger" role="alert" *ngIf="categoryError">
          {{ categoryError | translate}}
        </div>

        <!-- Search Box  -->
        <div class="form-group">
          <div class="col-md-auto w-100">
            <p-autoComplete
              [(ngModel)]="selectedCategoryList"
              [suggestions]="filteredCategoryList"
              [field]="'name'"
              [multiple]="false"
              (completeMethod)="filterCategory($event)"
              (onSelect)="addCategory()"
              [minLength]="1"
              [placeholder]="'product-set.categoryName' | translate"
              [dropdown]="true"
              [style]="{'width': '100%'}"
              [inputStyle]="{'width': '90%','height': '100%'}"
              class="category-dropdown">
              <ng-template let-value pTemplate="item">
                <div class="ui-autocomplete-token-label row d-flex">
                  <p class="col-1 d-flex justify-content-center ml-2">{{ value.id }}</p>
                  <p class="col pl-0">{{ value.category_name }}</p>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <!-- Sequence -->
        <div class="scroll-wrapper" style="overflow: auto;">
          <div class="categories">
            <h5><strong translate>category.Category</strong></h5>
            <span *ngIf="(categories$ | async)?.length == 0">{{
              'product-set.noCategory' | translate
            }}</span>
            
            <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="categories.length">
              <div class="category row mr-1 my-2" [cdkDragData]="category" *ngFor="let category of categories" cdkDrag>
                <span class="col-2 example-custom-placeholder pr-0"> 
                  <i class="fa fa-bars" style="margin-right: 10px;cursor: move;" aria-hidden="true" cdkDragHandle></i>
                  {{ category.category }}
                 </span>
                <span class="col-8 p-0 my-auto">{{ category.category_name }}</span>
                <div class="icons col-2 d-flex justify-content-end align-items-center">
                  <i class="fas fa-trash" (click)="deleteCategory(category, productSet)"></i>
                </div>
              </div>
            </div>

            <div *ngIf="!categories.length">
              <div class="p-0 m-0">
                <i class="mx-3 fas fa-box-open"></i>
                {{'categoryIsEmpty' | translate}}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="modal-body" *ngIf="modalLoading">
      <h4 class="m-2 p-2 d-flex justify-content-between align-items-center">
        Loading...
        <i class="fas fa-spinner fa-pulse p-2"></i>
      </h4>
    </div>
  </div>
  
</ng-template>