import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../components/core/core.module';
import { ExportResultComponent } from './export-result.component';
import { ExportResultRoutingModule } from './export-result-routing.module';
import { SharedModule } from 'src/app/shared.module';


@NgModule({
  declarations: [
    ExportResultComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    ExportResultRoutingModule,
    CoreModule,
    SharedModule,
  ],
  providers: []
})
export class ExportResultModule {
}
