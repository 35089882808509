<div id="circularG">
  <div id="circularG_1" class="circularG"></div>
  <div id="circularG_2" class="circularG"></div>
  <div id="circularG_3" class="circularG"></div>
  <div id="circularG_4" class="circularG"></div>
  <div id="circularG_5" class="circularG"></div>
  <div id="circularG_6" class="circularG"></div>
  <div id="circularG_7" class="circularG"></div>
  <div id="circularG_8" class="circularG"></div>
</div>
