import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../components/core/core.module';
import { SharedModule } from 'src/app/shared.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CarSpecificationComponent} from './car-specification.component';
import {CarSpecificationRoutingModule} from './car-specification-routing.module';

@NgModule({
  declarations: [
    CarSpecificationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    CarSpecificationRoutingModule,
    CoreModule,
    SharedModule,
    NgxFileDropModule,
    NgSelectModule,
    NgbDatepickerModule,
  ],
  providers: []
})
export class CarSpecificationModule {}
