import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailyCheckDataManagementComponent } from './daily-check-data-management.component';
import { DailyCheckDataManagementRoutingModule } from './daily-check-data-management-routing.module';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SharedModule } from 'src/app/shared.module';
import { CoreModule } from 'src/app/components/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableComponent } from './data-table/data-table.component';
import { DailyCheckItemMapPipe } from './pipes/daily-check-item-map.pipe';



@NgModule({
  declarations: [
    DailyCheckDataManagementComponent,
    SearchBoxComponent,
    DataTableComponent,
    DailyCheckItemMapPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    CoreModule,
    SharedModule,
    TranslateModule,
    NgbModule,
    DailyCheckDataManagementRoutingModule,
  ]
})
export class DailyCheckDataManagementModule { }
