import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {
  parseGivenFamilyShopName,
  validateGivenFamilyShopName,
  nameAndShopNameFilled
} from './register.functions';
import { RegisterService } from './register.service';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { Router, ActivatedRoute } from '@angular/router';
import { RegisterInput } from './register.input';
import { TranslateService } from '@ngx-translate/core';
import { take, map, pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public registerForm: FormGroup;
  public errorMessage: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private service: RegisterService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.pipe(take(1)).subscribe(console.log);
    this.registerForm = this.fb.group(
      {
        name: [''],
        shop_name: [''],
        username: ['', Validators.required],
        password: ['', Validators.required],
        email: ['', Validators.email],
        default_address: ['', Validators.required],
        default_address_prefecture: ['', Validators.required],
        default_address_postal_code: ['', Validators.required],
        delivery_address_name: [''],
        delivery_address: [''],
        delivery_address_prefecture: [''],
        delivery_address_postal_code: [''],
        phone_number_1: ['', Validators.required],
        phone_number_2: ['']
      },
      { validators: [nameAndShopNameFilled] }
    );
  }

  async register() {
    Object.keys(this.registerForm.controls).forEach(e => {
      this.registerForm.controls[e].markAsDirty();
      this.registerForm.controls[e].updateValueAndValidity();
    });
    if (this.registerForm.valid) {
      const [family_name, ...given_name] = this.registerForm.value.name.split(
        /\s+/
      );
      const data: RegisterInput = {
        ...this.registerForm.value,
        given_name: given_name.join(' '),
        family_name,
        shop: this.registerForm.value.shop_name
      };
      try {
        const res = await this.service.register(data).toPromise();
        this.router.navigateByUrl('/register/success');
      } catch ({ error }) {
        if (error.error) {
          this.registerForm.controls.username.setErrors({ duplicate: true });
          this.errorMessage = error.error;
        }
      }
    } else {
      this.errorMessage = this.translate.instant('register.notComplete');
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }
}
