import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailyCheckHistoryComponent } from './daily-check-history.component';
import { CoreModule } from '../../components/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared.module';
import { DailyCheckHistoryRoutingModule } from './daily-check-history-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import { SearchBoxComponent } from './search-box/search-box.component';
import { DailyCheckTableComponent } from './daily-check-table/daily-check-table.component';
import { FlyerDistributionComponent } from './flyer-distribution/flyer-distribution.component';
import { StaffTableComponent } from './staff-table/staff-table.component';

@NgModule({
  declarations: [
    DailyCheckHistoryComponent,
    SearchBoxComponent,
    DailyCheckTableComponent,
    FlyerDistributionComponent,
    StaffTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    CoreModule,
    SharedModule,
    TranslateModule,
    NgbModule,
    DailyCheckHistoryRoutingModule,
  ]
})
export class DailyCheckHistoryModule { }
