
import {SidebarFeatures} from './sidebar.model';

export const MONOTAROUserRoutes: SidebarFeatures[] = [
  {
    name: 'MONOTARO',
    path: '/monotaro',
    showChild: false,
    hasChild: false,
  },
];


export const BuyerDealerUserRoutes: SidebarFeatures[] = [
  {
    name: 'Settings',
    path: '/settings',
    showChild: false,
    hasChild: false,
  },
];

export const BuyerDealerExportUserRoutes: SidebarFeatures[] = [
  {
    name: 'Daily Check Analysis Export',
    path: '/daily-check',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Daily-Check Data Management',
    path: '/daily-check-data-management',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
];

export const ENEOSTradingWebUserRoutes: SidebarFeatures[] = [
  {
    name: 'Sellers',
    path: '/seller',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Seller Web Users',
    path: '/seller-webuser',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Category',
    path: '/category',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Announcements',
    path: '/announcements',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Import Results',
    path: '/import-product',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'shop-actual-stock.shopActualStock',
    path: '/shop-actual-stock',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Product Master',
    path: '/product-master',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Product Image',
    path: '/product-image',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Supervisor',
    path: '/supervisor',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Order History',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Tablet Users',
    path: '/user',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Dealer/Shop',
    path: '/dealer',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Dealer Setting',
    path: '/dealer-setting',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Daily Check Web User',
        path: '/buyer-dealer-web-user'
      },
      {
        name: 'Settings',
        path: '/settings'
      },
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check'
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ]
  },
];


export const DailyCheckAdminRoutes: SidebarFeatures[] = [
  {
    name: 'Daily Check Web User',
    path: '/buyer-dealer-web-user',
    showChild: false,
    hasChild: false
  },
  {
    name: 'Settings',
    path: '/settings',
    showChild: false,
    hasChild: false
  },
  {
    name: 'Daily Check Analysis Export',
    path: '/daily-check',
    showChild: false,
    hasChild: false
  },
  {
    name: 'Daily-Check Data Management',
    path: '/daily-check-data-management',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },

];


export const NormalUserRoutes: SidebarFeatures[] = [
  {
    name: 'Order History',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Order Api Disable Date',
    path: '/order-api-disable-date',
    showChild: false,
    hasChild: false,
  },
];

export const SellerWebUserRoutes: SidebarFeatures[] = [
  {
    name: 'Order History',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
]

export const AdminUserRoutes: SidebarFeatures[] = [
  {
    name: 'Sellers',
    path: '/seller',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Seller Web Users',
    path: '/seller-webuser',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Seller Email',
    path: '/seller-email',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Category',
    path: '/category',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Car Specification',
    path: '/car-specifications',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Announcements',
    path: '/announcements',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Import Results',
    path: '/import-product',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'shop-actual-stock.shopActualStock',
    path: '/shop-actual-stock',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Product Master',
    path: '/product-master',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Product Image',
    path: '/product-image',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Supervisor',
    path: '/supervisor',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Order History',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'MONOTARO',
    path: '/monotaro',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Tablet Users',
    path: '/user',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Dealer/Shop',
    path: '/dealer',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Dealer Setting',
    path: '/dealer-setting',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Order Api Disable Date',
    path: '/order-api-disable-date',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Daily Check Web User',
        path: '/buyer-dealer-web-user'
      },
      {
        name: 'Settings',
        path: '/settings'
      },
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check'
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ]
  },
  {
    name: 'Application Download',
    path: '/downloads/etr',
    showChild: false,
    hasChild: false,
  },
];


export const ENEOSTradingSubWebUserRoutes: SidebarFeatures[] = [
  {
    name: 'Sellers',
    path: '/seller',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Seller Web Users',
    path: '/seller-webuser',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Announcements',
    path: '/announcements',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Import Results',
    path: '/import-product',
    showChild: false,
    hasChild: false,
  },
    {
    name: 'shop-actual-stock.shopActualStock',
    path: '/shop-actual-stock',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Product Master',
    path: '/product-master',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Product Image',
    path: '/product-image',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Supervisor',
    path: '/supervisor',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Order History',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Tablet Users',
    path: '/user',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Dealer/Shop',
    path: '/dealer',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Dealer Setting',
    path: '/dealer-setting',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Daily Check Web User',
        path: '/buyer-dealer-web-user'
      },
      {
        name: 'Settings',
        path: '/settings'
      },
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check'
      },
    ]
  },
];

export const ShopManagerRoutes: SidebarFeatures[] = [
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Settings',
        path: '/settings',
      },
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check',
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ],
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },

];

export const DealerAdminRoutes: SidebarFeatures[] = [
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Settings',
        path: '/settings',
      },
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check',
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ],
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
];

export const ShopAdminSingleRoutes: SidebarFeatures[] =[
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Settings',
        path: '/settings',
      },
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check',
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ],
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
]

export const TabletUserRoutes: SidebarFeatures[] = [
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check',
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ],
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
]


export const ShopAdminMultipleRoutes: SidebarFeatures[] = [
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Settings',
        path: '/settings',
      },
      {
        name: 'Daily Check Analysis Export',
        path: '/daily-check',
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ],
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
]

export const ShopAdminMultipleTRAdminRoutes: SidebarFeatures[] = [
  {
    name: 'Daily Check',
    path: '',
    showChild: true,
    hasChild: true,
    childrenPaths: [
      {
        name: 'Settings',
        path: '/settings',
      },
      {
        name: 'Daily-Check Data Management',
        path: '/daily-check-data-management'
      },
    ],
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
];

export const CustomENEOSTradingWebUserRoutes: SidebarFeatures[] = [
  {
    name: 'Order History',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
];

export const SellerWebUserByDealerRoutes: SidebarFeatures[] = [
  {
    name: 'orderHistory',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
];

export const SupervisorDealerManagerRoutes: SidebarFeatures[] = [
  {
    name: 'Order History',
    path: '/order-history',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Supervisor',
    path: '/supervisor',
    showChild: false,
    hasChild: false,
  },
  {
    name: 'Export Results',
    path: '/export-result',
    showChild: false,
    hasChild: false,
  },
];

export const HandleRoutes = {
  BuyerDealerWebUser: BuyerDealerUserRoutes,
  BuyerDealerExportWebUser: BuyerDealerExportUserRoutes,
  MONOTAROWebUser: MONOTAROUserRoutes,
  ENEOSTradingWebUser: ENEOSTradingWebUserRoutes,
  ENEOSTradingSubWebUser: ENEOSTradingSubWebUserRoutes,
  DailyCheckAdmin: DailyCheckAdminRoutes,
  ShopManager: ShopManagerRoutes,
  DealerAdmin: DealerAdminRoutes,
  ShopAdminSingleDealer: ShopAdminSingleRoutes,
  ShopAdminMultipleDealer: ShopAdminMultipleRoutes,
  TabletUser: TabletUserRoutes,
  SellerWebUser: SellerWebUserRoutes,
  SellerWebUserByDealer: SellerWebUserByDealerRoutes,
  SupervisorDealerManager: SupervisorDealerManagerRoutes
};

export const TRAdminUsernames = [
  'TR11-Admin',
  'TR10-Admin',
  'TR9-Admin',
  'TR8-Admin',
  'TR7-Admin',
  'TR6-Admin',
  'TR5-Admin',
  'TR4-Admin',
  'TR3-Admin',
  'TR2-Admin',
  'TR1-Admin',
];

export const customENEOSTradingWebUser = ['ETRcc2'];
