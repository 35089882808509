import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { API_CONSTANT } from 'src/app/services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class ImportUpdateHistoryService {
  constructor(private api: HttpClientService) {}

  getImportUpdateHistory(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/import-update-history/', params);
  }

  getExportFileName() {
    return this.api.get(API_CONSTANT.BASE_URL + '/import-update-history/file-name/');
  }

  importImportUpdateHistory(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-import-update-history/', data)
  }
}
