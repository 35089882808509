import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellerEmailComponent } from './seller-email.component';
import { FormsModule } from '@angular/forms';
import { SellerEmailRoutingModule } from './seller-email-routing.module';
import { CoreModule } from 'src/app/components/core/core.module';
import { SharedModule } from 'src/app/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SellerEmailService } from './seller-email.service';



@NgModule({
  declarations: [
    SellerEmailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SellerEmailRoutingModule,
    CoreModule,
    SharedModule,
    NgSelectModule,
    NgbPaginationModule,
    NgbModalModule
  ],
  providers: [SellerEmailService]
})
export class SellerEmailModule { }
