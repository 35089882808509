<ng-sidebar-container>

  <!-- A sidebar -->
  <ng-sidebar [(opened)]="sidebarOpened" mode="push" [dock]="true" dockedSize="53px" [autoCollapseWidth]="300"
              (openedChange)="detectChanges()">
    <app-sidebar [(sidebarOpened)]="sidebarOpened"></app-sidebar>
  </ng-sidebar>

  <!-- Page content -->
  <div ng-sidebar-content>
    <router-outlet></router-outlet>
  </div>

</ng-sidebar-container>
