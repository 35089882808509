import {Injectable} from '@angular/core';
import {API_CONSTANT} from './api.constants';
import {HttpClientService} from './http-client.service';
import {Observable} from 'rxjs';
import {PaginatedResult} from '../components/pagination/pagination.interface';
import {ProductSet} from '../models/productSet.model';
import {ProductSetService} from './product-set.service';
import {Seller, SellerEmail} from '../models/seller.model';

@Injectable({
  providedIn: 'root'
})
export class SellerService {
  static API_URL = API_CONSTANT.BASE_URL + '/sellers/';

  constructor(private api: HttpClientService) {
  }

  get(params?: any): Observable<PaginatedResult<Seller>> {
    return this.api.get(SellerService.API_URL, params);
  }

  getEmail(params?: any): Observable<SellerEmail[]> {
    return this.api.get(API_CONSTANT.BASE_URL + '/eneos-seller-email/', params);
  }

  getSubSeller(params?: any): Observable<SellerEmail[]> {
    return this.api.get(API_CONSTANT.BASE_URL + '/sub-seller/', params);
  }

  getAll(params?: any): Observable<Seller[]> {
    return this.api.get(SellerService.API_URL, {all: true, ...params});
  }

  getALlFromSellerWebUser(): Observable<Seller[]> {
    return this.api.get(SellerService.API_URL, {seller_web_user: true});
  }

  createSubSeller(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/sub-seller/', data);
  }

  addEmail(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/eneos-seller-email/', data);
  }

  updateEmail(id, data) {
    return this.api.put(API_CONSTANT.BASE_URL + '/eneos-seller-email/' + id + '/', data);
  }

  create(data) {
    return this.api.post(SellerService.API_URL, data);
  }

  update(id, data) {
    return this.api.patch(SellerService.API_URL + id + '/', data);
  }
}
