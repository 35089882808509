<app-header title="{{'Edit Export User' | translate}}"></app-header>

<div class="w-100percent p-3 px-4"
    *ngIf="exportUser">
  <div class="row no-gutter">
    <div class="p-y-15 p-x-0 col-5 d-flex align-items-center">
      <div class="col-4">
        {{'Name' | translate}}
      </div>
      <div class="col-8">
        <input type="text"
            class="w-100 form-control"
            [placeholder]="'Name'|translate"
            [(ngModel)]="exportUser.name">
      </div>
    </div>
    <div class="col-7 d-flex justify-content-end align-items-center p-x-0">
      <button class="btn btn-secondary"
          (click)="cancelEditExportWebUserData()">
        {{ 'Cancel' | translate }}
      </button>
      <button class="btn btn-primary ml-3"
          (click)="saveEditExportWebUserData()">
        {{ 'Save' | translate}}
        <i class="fa fa-spinner fa-spin"
            *ngIf="savingUser"></i>
      </button>
    </div>
  </div>
  <div class="row no-gutter p-y-15 col-5 d-flex align-items-center">
    <div class="col-4">
      {{'Username' | translate}}
    </div>
    <div class="col-8">
      <input type="text"
          class="w-100 form-control"
          [placeholder]="'Username'|translate"
          [(ngModel)]="exportUser.username">
    </div>
  </div>
  <div class="row no-gutter p-y-15 col-5 d-flex align-items-center">
    <div class="col-4">
      {{'Password' | translate}}
    </div>
    <div class="col-8">
      <input type="password"
          class="w-100 form-control"
          [placeholder]="'Password'|translate"
          [(ngModel)]="exportUser.password">
    </div>
  </div>
  <div class="row no-gutter d-flex justify-content-between p-y-15 p-l-15">
    <h5 class="m-0 d-flex align-items-center">{{'Dealers and Shops' | translate}}</h5>
    <button class="btn btn-primary d-flex align-items-center"
        (click)="addOrEditDealersAndShops(AddOrEditExportUserDealersAndShops)">
      <img src="assets/svgs/plus_icon.svg"
          height="20"
          alt="plus icon"
          class="mr-1"
          width="auto">
      {{'Add Dealers and Shops' | translate}}
    </button>
  </div>
  <app-loading *ngIf="loading"></app-loading>
  <app-list-empty title="{{ 'noDataFound' | translate }}"
      *ngIf="!loading && !userShopsAndDealers.length">
  </app-list-empty>
  <div class="overflow-x-auto"
      *ngIf="!loading && userShopsAndDealers.length">
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col-5 p-l-15">{{'Dealers' | translate}}</th>
          <th class="col-5">{{'Shops' | translate}}</th>
          <th class="col-1 text-center">{{'edit' | translate}}</th>
          <th class="col-1 text-center">{{'Delete' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dealer of userShopsAndDealers">
          <td class="p-l-15">{{dealer.name}} {{dealer.branch_name || ''}}</td>
          <td>
            <span *ngFor="let shop of dealer.shops">• {{shop.name}}<br></span>
          </td>
          <td class="text-center">
            <img src="assets/svgs/edit_pen.svg"
                height="25"
                width="auto"
                (click)="addOrEditDealersAndShops(AddOrEditExportUserDealersAndShops,dealer)">
          </td>
          <td class="text-center">
            <img src="assets/svgs/red_trash.svg"
                height="25"
                width="auto"
                (click)="delete(dealer)">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #AddOrEditExportUserDealersAndShops>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ ( popUpMode === 'add' ? 'Add Dealers and Shops' : 'Edit Dealers and Shop') | translate }}
      </h4>
    </div>
    <div class="modal-body">
      <div class="row no-gutter p-b-15">
        <div class="col-4 d-flex align-items-center">
          {{ 'Dealer' | translate}}
        </div>
        <div class="col-8">
          <ng-select [clearable]="true"
              [multiple]="false"
              [loading]="loadingDealers"
              [searchable]="true"
              class="w-100 p-0"
              [items]="dealers"
              (change)="getShops()"
              [disabled]="loadingDealers"
              [placeholder]="'Select Dealer'| translate"
              [notFoundText]="'notFound' | translate"
              bindLabel="name"
              bindValue="id"
              [(ngModel)]="selectedDealer">
            <ng-template ng-label-tmp
                let-item="item">
              <span class="d-flex align-items-center">
                {{ item.name}} {{item.branch_name}}
              </span>
            </ng-template>
            <ng-template ng-option-tmp
                let-item="item"
                let-search="searchTerm"
                let-index="index">
              <span class="d-flex align-items-center">
                {{ item.name}} {{item.branch_name}}
              </span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row no-gutter">
        <div class="col-4 d-flex align-items-center">
          {{ 'Shop' | translate}}
        </div>
        <div class="col-8">
          <ng-select [clearable]="true"
              [multiple]="true"
              [loading]="loadingShops"
              [searchable]="true"
              [items]="shops"
              bindLabel="name"
              [disabled]="!selectedDealer || loadingShops"
              bindValue="id"
              class="w-100 p-0"
              [placeholder]="'Select Shop'| translate"
              [notFoundText]="'notFound' | translate"
              [(ngModel)]="selectedShop">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary"
          (click)="close()">
        {{ 'Cancel' | translate }}</button>
      <button class="btn btn-primary"
          [disabled]="!selectedShop.length"
          (click)="popUpMode === 'add' ? saveNewDealerAndShop() : saveEditDealerAndShop()">
        {{ 'Save' | translate}}
        <i class="fa fa-spinner fa-spin"
            *ngIf="saving"></i>
      </button>
    </div>
  </div>
</ng-template>