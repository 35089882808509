import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CategoryService } from '../category.service';
import { SellerEmailService } from '../../seller-email/seller-email.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import {
  ProductSellerEmail,
  SellerEmail,
} from '../../seller-email/seller-email.model';
import { ShopService } from 'src/app/services/shop.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ProductSetService } from 'src/app/services/product-set.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
})
export class EditCategoryComponent implements OnInit {
  id: number;
  categoryName: string = '';
  categoryImage: string = '';
  page: number = 1;
  dataLength: number = 0;

  productSellerEmailList: ProductSellerEmail[] = [];
  shopSelectList: any[] = [];
  shopList: any[] = [];
  emailList: SellerEmail[] = [];

  search = {
    shops: [],
    ctaShops: [],
    emails: [],
  };

  editCategoryName: string = '';
  editCategoryFile = null;
  editCategoryFileChanged: boolean = false;
  editCategoryImageUrl = null;

  selectedCheckBox: string = 'Normal';
  startTime = { hour: 0, minute: 0 };
  startDate = null;
  importFile: File | null = null;

  type: string = 'add';
  selectedShop: number = null;
  isETR: boolean = false;
  selectedSellerEmail: number = null;
  selectedCCEmails: number[] = [];
  ctaShopName: string = '';

  editProductSellerEmailId: number = null;
  editProductSellerEmailShop: string = '';

  deleteProductSellerEmailId: number = null;

  loadingProductSellerEmail: boolean = false;
  loadingShopSelect: boolean = false;
  loadingShop: boolean = false;
  loadingEmail: boolean = false;
  loadingImport: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private sellerEmailService: SellerEmailService,
    private toastr: ToastrService,
    private shopService: ShopService,
    private modelSerivce: NgbModal,
    private productService: ProductSetService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = +params['id'];
    });
    this.getCategory();
    this.getShopList();
    this.getEmails();
    this.getProductSellerEmail();
  }

  addOrEditSellerEmail(
    view,
    add_type: boolean,
    item?: ProductSellerEmail
  ): void {
    if (add_type) {
      this.type = 'add';
      this.getShopSelectList();
    } else {
      this.type = 'edit';
      this.selectedShop = item.is_etr ? item.shop : item.cta_shop_id;
      this.isETR = item.is_etr;
      this.selectedSellerEmail = item.email;
      this.selectedCCEmails = item.cc_email.map((cc) => cc.id);
      this.ctaShopName = !item.is_etr ? item.cta_shop_name : '';
      this.editProductSellerEmailId = item.id;
      this.editProductSellerEmailShop = item.shop_name;
    }
    this.openModal(view);
  }

  openDeleteModel(view, id: number): void {
    this.deleteProductSellerEmailId = id;
    this.openModal(view);
  }

  editingCategory(view): void {
    this.editCategoryName = this.categoryName;
    this.editCategoryImageUrl = this.categoryImage;

    this.openModal(view);
  }

  openModal(view): void {
    this.modelSerivce.open(view).result.then(
      (result) => {},
      (reason) => {
        if (
          reason === ModalDismissReasons.ESC ||
          reason === ModalDismissReasons.BACKDROP_CLICK
        ) {
          this.dismissModal();
        }
      }
    );
  }

  dismissModal(): void {
    this.type = 'add';
    this.selectedShop = null;
    this.selectedSellerEmail = null;
    this.isETR = false;
    this.ctaShopName = '';
    this.selectedCCEmails = [];

    this.editProductSellerEmailId = null;
    this.editProductSellerEmailShop = '';

    this.deleteProductSellerEmailId = null;

    this.editCategoryName = '';
    this.editCategoryImageUrl = null;
    this.editCategoryFile = null;
    this.editCategoryFileChanged = false;
    this.modelSerivce.dismissAll();
  }

  getShopList(): void {
    this.loadingShop = true;
    const param = {
      all: 'true',
    };
    this.shopService.getCTAETRShopList(param).subscribe(
      (res: any) => {
        this.shopList = res;
        this.loadingShop = false;
      },
      (err) => {
        this.toastr.error(err.error.message);
        this.loadingShop = false;
      }
    );
  }

  getShopSelectList(): void {
    this.loadingShopSelect = true;
    const params = {
      eneos: 'true',
      category_id: this.id,
    };
    this.shopService.getCTAETRShopList(params).subscribe(
      (data: any) => {
        this.shopSelectList = data;
        this.loadingShopSelect = false;
      },
      (error) => {
        this.toastr.error(error.error.message);
        this.loadingShopSelect = false;
      }
    );
  }

  getCategory(): void {
    this.categoryService.getCategoryByID(this.id).subscribe(
      (data) => {
        this.categoryName = data.category_name;
        this.categoryImage = data.category_image;
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  getProductSellerEmail(): void {
    this.loadingProductSellerEmail = true;
    const params = {
      page: this.page,
      category_id: this.id,
      shop_id: this.search.shops.toString(),
      cta_shop_id: this.search.ctaShops.toString(),
      email_id: this.search.emails.toString(),
    };
    this.sellerEmailService.getProductSellerEmails(params).subscribe(
      (data) => {
        this.dataLength = data.count;
        this.productSellerEmailList = data.results;
        this.loadingProductSellerEmail = false;
      },
      (error) => {
        this.toastr.error(error.error.message);
        this.loadingProductSellerEmail = false;
      }
    );
  }

  getEmails(): void {
    this.loadingEmail = true;
    this.sellerEmailService.getAllSellersEmail().subscribe(
      (res: any) => {
        this.emailList = res;
        this.emailList.forEach((seller) => {
          seller.showValue = seller.email + ' (' + seller.id + ')';
        });
        this.loadingEmail = false;
      },
      (err) => {
        this.toastr.error(err.error.message);
        this.loadingEmail = false;
      }
    );
  }

  resetSearch(): void {
    this.page = 1;
    this.search = {
      shops: [],
      ctaShops: [],
      emails: [],
    };
    this.getProductSellerEmail();
  }

  changePage(event: number): void {
    this.page = event;
    this.getProductSellerEmail();
  }

  hadleShopFilterChange(event: any): void {
    const etrShop = [];
    const ctaShop = [];
    event.forEach((shop) => {
      if (shop.is_etr) {
        etrShop.push(shop.id);
      } else {
        ctaShop.push(shop.id);
      }
    });
    this.search.shops = etrShop;
    this.search.ctaShops = ctaShop;
  }

  handleSelectShop(event: any): void {
    this.selectedShop = event.id;
    this.isETR = event.is_etr;
    this.ctaShopName = event.name;
  }

  saveCategory(): void {
    if (!this.editCategoryName) {
      this.toastr.error(this.translate.instant('Please input category name'));
      return;
    }
    const fd = new FormData();
    fd.append('category_name', this.editCategoryName);
    if (this.editCategoryFileChanged) {
      fd.append('category_image', this.editCategoryFile['base64']);
      fd.append('category_image_type', 'data:image/png;base64');
    }
    this.categoryService.updateCategory(this.id, fd).subscribe(
      (res) => {
        this.toastr.success(this.translate.instant('Update category successfully'));
        this.getCategory();
        this.dismissModal();
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  removeImage(): void {
    this.editCategoryImageUrl = null;
    this.editCategoryFile = null;
  }

  droppedImageFile(file: NgxFileDropEntry[]): void {
    for (const droppedFile of file) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.editCategoryFile = file;
        this.editCategoryFileChanged = true;
        const reader = new FileReader();
        fileEntry.file((file: File) => {
          reader.readAsDataURL(file);
          reader.onload = () => {
            let image_result = reader.result as string;
            this.editCategoryFile['base64'] = image_result.split(',')[1];
            this.editCategoryImageUrl = reader.result;
          };
        });
      });
    }
  }

  createOrEditProductSellerEmail(): void {
    if (!this.selectedSellerEmail) {
      this.toastr.error(this.translate.instant('Please select seller email'));
      return;
    }
    if (!this.selectedShop) {
      this.toastr.error(this.translate.instant('Please select shop'));
      return;
    }
    const fd = new FormData();
    fd.append('category', this.id.toString());
    fd.append('shop', this.isETR ? this.selectedShop.toString() : '');
    fd.append('email', this.selectedSellerEmail.toString());
    fd.append('cc_email', this.selectedCCEmails.toString());
    fd.append('cta_shop_id', !this.isETR ? this.selectedShop.toString() : '');
    fd.append('cta_shop_name', !this.isETR ? this.ctaShopName : '');

    if (this.type === 'add') {
      this.productService.addSellerEmail(fd).subscribe(
        (res) => {
          this.toastr.success(this.translate.instant('Add seller email successfully'));
          this.getProductSellerEmail();
          this.dismissModal();
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
    } else {
      this.productService
        .updateSellerEmail(this.editProductSellerEmailId, fd)
        .subscribe(
          (res) => {
            this.toastr.success(this.translate.instant('Update seller email successfully'));
            this.getProductSellerEmail();
            this.dismissModal();
          },
          (err) => {
            this.toastr.error(err.error.message);
          }
        );
    }
  }

  deleteProductSellerEmail(): void {
    this.productService
      .deleteSellerEmail(this.deleteProductSellerEmailId)
      .subscribe(
        (res) => {
          this.toastr.success(this.translate.instant('Delete seller email successfully'));
          this.getProductSellerEmail();
          this.dismissModal();
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
  }

  checked(selected) {
    this.selectedCheckBox = selected;
  }

  chooseFile(event) {
    this.importFile = event.target.files.item(0);
  }

  getDateNTime(date, time, type) {
    if (!date) {
      return '';
    }
    !time
      ? type === 'end'
        ? (time = { hour: 23, minute: 59 })
        : (time = { hour: 0, minute: 0 })
      : {};
    time.second = 0;
    const dateStr = _.values(date)
      .map((x) => (+x < 10 ? '0' + x : x))
      .join('-');
    const timeStr = _.values(time).join(':');
    return dateStr + 'T' + timeStr;
  }

  uploadFile(): void {
    const is_scheduled = this.selectedCheckBox !== 'Normal';
    const time = this.getDateNTime(this.startDate, this.startTime, 'start');
    this.loadingImport = true;
    this.categoryService
      .importProductEmail(this.id, this.importFile, is_scheduled, time)
      .subscribe(
        () => {
          this.modelSerivce.dismissAll();
          this.loadingImport = false;
          this.router.navigateByUrl('/import-product');
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.loadingImport = false;
        }
      );
  }
}
