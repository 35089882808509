import {Component, OnInit} from '@angular/core';
import {HttpClientService} from '../../services/http-client.service';
import {TranslateService} from '@ngx-translate/core';
import {Seller} from './seller.model';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SellerService} from 'src/app/services/seller.service';
import {HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SellerWebuserService } from '../seller-webuser/seller-webuser.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss']
})
export class SellerComponent implements OnInit {
  public loading = false;
  dataLength = 0;
  dataList = [];
  sellerList: Seller[];
  page = 1;
  type = 'add';
  editingID = null;
  sellerName = '';
  sellerEmail = null;
  emailError = false;
  saveError = {};
  sellers = [];
  allSellerWebUsers = [];
  cc_emails = [];

  constructor(
    private api: HttpClientService,
    private translate: TranslateService,
    private sellerService: SellerService,
    private modalService: NgbModal,
    private notiService: ToastrService,
    private sellerWebUserService: SellerWebuserService,
    public router: Router,
    ) {
  }

  ngOnInit() {
    this.loadAllData();
  }

  loadAllData() {
    this.loadSeller();
    this.loadSellerEmails();
    this.loadAllSellerWebUser();
  }

  loadSeller() {
    this.loading = true;
    let param = new HttpParams();
    param = param.append('all', 'true')

    this.sellerService.get(param).subscribe(
      (res: any) => {
        this.sellerList = res;
        this.dataLength = res.length;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      }
    );
  }

  loadSellerEmails() {
    this.sellerWebUserService.getSellerWebUsers({'sellers': true}).subscribe(
      (res: any) => {
        this.sellers = res;
        this.sellers.forEach(seller => {
          seller.showValue = seller.email + ' (' + seller.id + ')';
        })
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  loadAllSellerWebUser() {
    this.sellerWebUserService.getSellerWebUsers({'all': true}).subscribe(
      (res: any) => {
        this.allSellerWebUsers = res;
        this.allSellerWebUsers.forEach(seller => {
          seller.showValue = seller.email + ' (' + seller.id + ')';
        });
      },
      (err) => {
        this.showError(err);
      }
    );
  }


  save() {
    const fd = new FormData();
    this.sellerName === '' ? this.saveError['name'] = true : fd.append('name', this.sellerName);
    this.cc_emails.length ? fd.append('cc_emails', JSON.stringify(this.cc_emails)) : {};
    if (Object.keys(this.saveError).length === 0) {
      this.loading = true;
      this.type === 'add' ? this.createNewSeller(fd) : this.updateSeller(fd);
    }
  }

  createNewSeller(fd) {
    fd.append('seller_web_user', this.sellerEmail);
    this.sellerService.create(fd).subscribe(
      (res: any) => {
        this.loading = false;
        this.dismissEditOrCreate();
        this.loadAllData();
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      }
    );
  }

  updateSeller(fd) {
    fd.append('id', this.editingID)
    this.sellerService.update(this.editingID, fd).subscribe(
      (res: any) => {
        this.loading = false;
        this.dismissEditOrCreate();
        this.loadAllData();
      },
      (err) => {
        this.loading = false;
        this.showError(err);
      }
    );
  }

  showError(error) {
    this.notiService.error(_.values(error.error)[0].toString());
  }

  openPopup(view, item?) {
    if (item) {
      this.cc_emails = [];
      this.type = 'edit';
      this.editingID = item.id;
      this.sellerName = item.name;
      this.sellerEmail = item.email_name;
      item.cc_email.forEach(emailItem => {
        this.cc_emails.push(emailItem.email);
      });
      this.allSellerWebUsers = this.allSellerWebUsers.filter(x => x.email !== item.email_name);
    }

    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  dismissEditOrCreate() {
    this.type = 'add';
    this.editingID = null;
    this.sellerName = '';
    this.sellerEmail = null;
    this.cc_emails = [];
    this.loadAllSellerWebUser();
    this.modalService.dismissAll();
  }
}

