import { Pipe, PipeTransform } from '@angular/core';
import { DailyCheckItemsConstants } from '../../daily-check-history/daily-check-items.constants';

@Pipe({
  name: 'dailyCheckItemMap'
})
export class DailyCheckItemMapPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    const dailyCheckItemsType =  DailyCheckItemsConstants[value];
    return dailyCheckItemsType || 'unknown';
  }

}
