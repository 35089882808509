import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {
  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  useLanguage(language: string) {
    this.translate.use(language);
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }
}
