import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainModule } from './main/main.module';
import { AuthGuard } from './auth.guard';
import { AuthApiService } from './services/auth-api.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { RegisterModule } from './register/register.module';
import {NewIosDeployCodeModule} from './features/ios_deploy/ios_deploy.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SupervisorComponent } from './features/supervisor/supervisor.component';
import {CoreModule} from './components/core/core.module';
import {NewDealerCodeModule} from './features/dealer/dealer.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {ShowHidePasswordModule} from 'ngx-show-hide-password';
import {CheckboxModule} from 'primeng/checkbox';
import {NewUserCodeModule} from './features/user/user.module';
import {NgSelectModule} from '@ng-select/ng-select';
import { EditProductMasterComponent } from './features/product-master/edit-product-master/edit-product-master.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditSellerComponent } from './features/seller/edit-seller/edit-seller.component';
import { EditCategoryComponent } from './features/category/edit-category/edit-category.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {NgxFileDropModule} from 'ngx-file-drop';
import {FilterDealersNamePipe} from './shared/pipe/filter-dealers-name.pipe';
import {EditShopSettingsComponent} from './features/settings/edit-shop-settings/edit-shop-settings.component';
import { EditExportUserComponent } from './features/buyer-dealer-web-user/edit-export-user/edit-export-user.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SupervisorDealerManagerListComponent } from './features/supervisor/supervisor-dealer-manager-list/supervisor-dealer-manager-list.component';
import {
  SupervisorManagerListComponent
} from './features/supervisor/supervisor-manager-list/supervisor-dealer-manager-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RedirectComponent,
    SupervisorComponent,
    SupervisorDealerManagerListComponent,
    SupervisorManagerListComponent,
    EditProductMasterComponent,
    EditSellerComponent,
    EditCategoryComponent,
    EditExportUserComponent,
    EditShopSettingsComponent,
    FilterDealersNamePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    RegisterModule,
    MainModule,
    NgbModule,
    ToastrModule.forRoot(),
    NewIosDeployCodeModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    NewDealerCodeModule,
    ReactiveFormsModule,
    ShowHidePasswordModule,
    CheckboxModule,
    NewUserCodeModule,
    NgSelectModule,
    DragDropModule,
    AutoCompleteModule,
    NgxFileDropModule
  ],
  providers: [
    AuthGuard,
    AuthApiService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true}
  ],
  exports: [
    FilterDealersNamePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
