<div class="modal-header">
  <h4 class="modal-title" >{{ title | translate }}</h4>
</div>
<div class="modal-body">
  <p translate>{{ content | translate}}</p>
</div>
<div class="modal-footer">

  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    {{ 'Cancel' | translate }}
  </button>
  <button type="button" class="btn btn-danger" (click)="close()">
    {{ 'Confirm' | translate }}
  </button>

</div>
