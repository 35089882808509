<app-header [title]="'Daily Check Web User' | translate"></app-header>
<div class="content">
  <div class="bg-white custom-radius p-l-15 p-y-15">
    <div class="tabs-row">
      <div class="tab"
          [ngClass]="{'selected': selectedTab === 'setting'}"
          (click)="changeTab('setting')">
        {{'For Setting' | translate}}
      </div>
      <div class="tab"
          [ngClass]="{'selected': selectedTab === 'export'}"
          (click)="changeTab('export')">
        {{'For Export' | translate}}
      </div>
      <div class="tab"
          [ngClass]="{'selected': selectedTab === 'shopAndDealer'}"
          (click)="changeTab('shopAndDealer')">
        {{ 'Shop Manager / Dealer Admin' | translate }}
      </div>
    </div>
  </div>
</div>

<div class="w-100percent">
  <div class="row w-100 p-3 px-4 m-0 d-flex justify-content-start">
    <div class="w-23percent row p-0 m-0 align-self-start">
      <label class="row w-100 m-0 p-0 pl-1">
        {{ "Username" | translate }}
      </label>
      <div class="row w-100 p-0 m-0 pr-3">
        <input type="text"
            class="form-control w-100"
            [(ngModel)]="searchUserName" />
      </div>
    </div>
    <div class="w-23percent row p-0 m-0 align-self-start">
      <label class="row w-100 m-0 p-0 pl-1">
        {{ "Dealer" | translate }}
      </label>
      <div class="row w-100 p-0 m-0 pr-3">
        <ng-select class="w-100 p-0"
            [clearable]="false"
            [items]="dealersName"
            [loading]="loadingDealer"
            [placeholder]="'Dealer' | translate"
            [notFoundText]="'notFound' | translate"
            (change)="getBranchName()"
            [(ngModel)]="selectedDealer"></ng-select>
      </div>
    </div>
    <div class="w-23percent row p-0 m-0 align-self-start">
      <label class="row w-100 m-0 p-0 pl-1">
        {{ "Branch" | translate }}
      </label>
      <div class="row w-100 p-0 m-0 pr-3">
        <ng-select class="w-100 p-0"
            [clearable]="false"
            [items]="branchNames"
            [disabled]="!selectedDealer || loadingBranch"
            [placeholder]="'Branch' | translate"
            [notFoundText]="'notFound' | translate"
            [(ngModel)]="selectedBranch"></ng-select>
      </div>
    </div>
    <div class="w-30percent d-flex align-items-start justify-content-end">
      <div class="d-flex align-items-start">
        <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center"
            (click)="getUserData()">
          <img src="assets/svgs/search_len.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'search' | translate}}
        </button>
        <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center"
            (click)="reset()">
          <img src="assets/svgs/reset_icon.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'reset' | translate}}
        </button>

        <button class="btn btn-sm mt-4 btn-outline-primary mr-2 d-flex align-items-center"
            (click)='import(importTemplate)'>
          <img src="assets/svgs/blue_plus_icon.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'Import' | translate}}
        </button>
      </div>
    </div>
  </div>
  <app-loading *ngIf="loading"></app-loading>
  <app-list-empty title="{{ 'noDataFound' | translate }}"
      *ngIf="!loading && !buyerDealerWebUsers.length">
  </app-list-empty>
  <div class="w-100percent px-3"
      *ngIf="!loading && buyerDealerWebUsers.length">
    <table class="w-100percent table-striped">
      <thead>
        <tr>
          <th class="col-2 text-center top-left-round">
            {{'Name' | translate}}
          </th>
          <th class="col-2 text-center">
            {{'Username' | translate }}
          </th>
          <th class="col-2 text-center"
              *ngIf="selectedTab === 'shopAndDealer'">
            {{ 'Role' | translate }}
          </th>
          <th class="col-2 text-center"
              *ngIf="selectedTab === 'setting' || selectedTab === 'shopAndDealer'">
            {{'Dealer' | translate}}
          </th>
          <th class="col-2 text-center"
              *ngIf="selectedTab === 'shopAndDealer'">
            {{'Shop' | translate}}
          </th>
          <th class="col-1 text-center">
            {{'edit' | translate}}
          </th>
          <th class="col-1 text-center top-right-round">
            {{'Delete' | translate}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of buyerDealerWebUsers let index = index;">
          <td class="text-center"
              [ngClass]="{'vertical-top': selectedTab === 'shopAndDealer'}">
            {{ user.name }}
          </td>
          <td class="text-center"
              [ngClass]="{'vertical-top': selectedTab === 'shopAndDealer'}">
            {{ user.username }}
          </td>
          <td class="text-center"
              *ngIf="selectedTab === 'shopAndDealer'"
              [ngClass]="{'vertical-top': selectedTab === 'shopAndDealer'}">
            {{ user.role | translate }}
          </td>
          <td *ngIf="user.username !== 'egsweb' && selectedTab === 'setting'"
              class="text-center">
            {{ user.dealer }}
          </td>
          <td *ngIf="user.username === 'egsweb' && selectedTab === 'setting'"
              class="text-center">
            <ul class="m-0 p-0"
                *ngFor="let item of user.dealer">
              {{ item }}
            </ul>
          </td>
          <td *ngIf="selectedTab === 'shopAndDealer'"
              [ngClass]="{'vertical-top': selectedTab === 'shopAndDealer'}">
            <div class="row no-gutters">
              <div class="col-11"
                  [ngClass]="user.show_dealer ? 'show-height' : 'hide-height'">
                <div *ngFor="let dealer of user.dealer">
                  • {{ dealer }}
                </div>
              </div>
              <div class="col-1 align-items-center"
                  *ngIf="user.dealer.length > 4"
                  (click)="showCellChild('dealer', index)">
                <i class="fas"
                    [ngClass]="user.show_dealer ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
              </div>
            </div>
          </td>
          <td *ngIf="selectedTab === 'shopAndDealer'"
              [ngClass]="{'vertical-top': selectedTab === 'shopAndDealer'}">
            <div class="row no-gutters">
              <div class="col-11"
                  [ngClass]="user.show_shop ? 'show-height' : 'hide-height'">
                <div *ngFor="let shop of user.shop">
                  • {{ shop }}
                </div>
              </div>
              <div class="col-1 align-items-center"
                  *ngIf="user.shop.length > 4"
                  (click)="showCellChild('shop', index)">
                <i class="fas"
                    [ngClass]="user.show_shop ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
              </div>
            </div>
          </td>
          <td class="text-center pointer"
              [ngClass]="{'vertical-top': selectedTab === 'shopAndDealer'}">
            <img src="assets/svgs/edit_pen.svg"
                height="28"
                width="auto"
                (click)="selectedTab === 'setting' || selectedTab === 'shopAndDealer' ? edit(editTemplate, user) : exportUserEdit(user.id)">
          </td>
          <td class="text-center pointer"
              [ngClass]="{'vertical-top': selectedTab === 'shopAndDealer'}">
            <img src="assets/svgs/red_trash.svg"
                height="27"
                width="auto"
                (click)="delete(user.id)">
          </td>
        </tr>
      </tbody>
    </table>
    <div class='row no-gutter'
        *ngIf="!loading && buyerDealerWebUsers.length">
      <ngb-pagination [collectionSize]="count"
          [(page)]="currentPage"
          [maxSize]="5"
          [pageSize]="40"
          (pageChange)='navigateTo($event)'
          [boundaryLinks]="true">
      </ngb-pagination>
    </div>
  </div>
</div>

<ng-template #editTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-center">
      {{'Buyer Dealer Web User' | translate}}
    </h4>
  </div>
  <div class="modal-body">
    <div class="row no-gutter mb-3"
        *ngIf="selectedTab === 'shopAndDealer'">
      <label class="col-4 m-0 align-self-center">
        {{'Role' | translate}}
      </label>
      <div class="col-8 p-l-0">
        {{ editingUser?.role | translate }}
      </div>

    </div>
    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 vertical-top">
        {{'Dealer' | translate}}
      </label>
      <div class="col-8 p-l-0"
          *ngIf="selectedTab !== 'shopAndDealer'">
        {{ editingUser?.dealer }}
      </div>
      <div class="col-8 p-l-0 show-height"
          *ngIf="selectedTab === 'shopAndDealer'">
        <div class="row no-gutters"
            *ngFor="let dealer of editingUser?.dealer">
          • {{ dealer }}
        </div>
      </div>
    </div>
    <div class="row no-gutter mb-3"
        *ngIf="selectedTab === 'shopAndDealer'">
      <label class="col-4 m-0 vertical-top">
        {{'Shop' | translate}}
      </label>
      <div class="col-8 p-l-0 show-height">
        <div class="row no-gutters"
            *ngFor="let shop of editingUser?.shop">
          • {{ shop }}
        </div>
      </div>
    </div>
    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 align-self-center">
        {{'Name' | translate}}
      </label>
      <input type='text'
          class="col-8 form-control"
          [(ngModel)]="editingUser.name"
          [placeholder]="'Input name' | translate">
    </div>

    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 align-self-center">
        {{'Username' | translate}}
      </label>
      <input type='text'
          class="col-8 form-control"
          [(ngModel)]="editingUser.username"
          [placeholder]="'Input Username' | translate">
    </div>

    <div class="row no-gutter mb-3">
      <label class="col-4 m-0 align-self-center">
        {{'Password' | translate}}
      </label>
      <input autocomplete="new-password"
          type='password'
          class="col-8 form-control"
          [(ngModel)]="editingUser.password"
          [placeholder]="'Input Password' | translate">
    </div>

  </div>
  <div class="modal-footer border-top-0 mr-3">
    <button class="btn btn-secondary"
        (click)="cancelEdit()">
      {{'Cancel' | translate}}
    </button>
    <button class="btn btn-primary"
        (click)="selectedTab === 'setting' ? saveEdit(): saveShopAndDealerEdit()">
      {{'Save' | translate}}
    </button>
  </div>
</ng-template>


<ng-template #importTemplate>
  <div class="modal-header"
      [ngSwitch]="selectedTab">
    <span *ngSwitchCase="'setting'">{{ 'Daily Check Web User' | translate }}</span>
    <span *ngSwitchCase="'export'">{{ 'Export Web User' | translate }}</span>
    <span *ngSwitchCase="'shopAndDealer'">{{ 'Shop Manager / Dealer Admin Web User' | translate }}</span>
  </div>
  <div class="modal-body">
    <input type="file"
        id="file"
        (change)="handleFileInput($event.target.files)"
        accept=".xls, .xlsx">
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button"
        class="btn btn-outline-secondary"
        (click)="exportImportTemplate()">
      {{ 'Template' | translate }}
    </button>
    <div>
      <button class="btn btn-secondary m-r-5"
          (click)="dismissFileSelection()">
        {{ 'Cancel' | translate }}
      </button>
      <button type="button"
          class="btn btn-outline-primary"
          (click)="uploadFile()">
        {{ 'Upload' | translate }}
        <i class="fa fa-spinner fa-spin"
            *ngIf="importing"></i>
      </button>
    </div>
  </div>
</ng-template>