import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../components/core/core.module';
import { SharedModule } from 'src/app/shared.module';
import {SettingsComponent} from './settings.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SettingRoutes} from './settings-routing.module';


@NgModule({
  declarations: [
    SettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgbPaginationModule,
    DragDropModule,
    SettingRoutes,
    CoreModule,
    SharedModule,
  ],
  providers: []
})
export class SettingsModule {
}
