import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../app/services/http-client.service';
import { API_CONSTANT } from 'src/app/services/api.constants';

@Injectable({
  providedIn: 'root',
})
export class OrderApiDisableDateService {
  static API_URL = API_CONSTANT.BASE_URL + '/etr_api_disabled_date/';

  constructor(private api: HttpClientService) {}

  getOrderApiDisableDate(param?) {
    return this.api.get(OrderApiDisableDateService.API_URL, param);
  }

  createOrderApiDisableDate(data) {
    return this.api.post(OrderApiDisableDateService.API_URL, data);
  }

  deleteOrderApiDisableDate(id) {
    return this.api.delete(OrderApiDisableDateService.API_URL + id);
  }
}
