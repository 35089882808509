import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import {ShopActualStockComponent} from "./shop-actual-stock.component";


export const ShopActualStockRoutes: Routes = [
  {
    path: 'shop-actual-stock',
    children: [
      {path: '', component: ShopActualStockComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ShopActualStockRoutes)],
  exports: [RouterModule]
})
export class ShopActualStockRoutingModule {
}
