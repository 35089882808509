/** 0: Big Dealer, 1:  */
export enum ShopCustomerType {
  None = 0,
  'Big Dealer' = 1,
  'Grit User' = 2,
  'General User' = 3
}

export enum DealerCustomerType {
  'Big Dealer' = 0,
  'General User' = 1
}

export enum EtrDealerType {
  'MSI' = 0,
  'SB' = 1,
  'Grit' = 2
}
