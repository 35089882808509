import {Injectable} from '@angular/core';
import {API_CONSTANT} from '../../services/api.constants';
import {HttpClientService} from '../../services/http-client.service';
import {Observable} from 'rxjs';
import {PaginatedResult} from '../../components/pagination/pagination.interface';
import {ProductImage} from './product-image.model';

@Injectable({
  providedIn: 'root'
})

export class ProductImageService {

  constructor(private api: HttpClientService) {}

  ImportProductImage(lang, file: File) {
    const formData = new FormData();
    formData.append('current_lang', lang);
    formData.append('file', file);
    return this.api.post(API_CONSTANT.BASE_URL + '/import-product-image/', formData)
  }

  apiProductImage(params) {
    return this.api.get(API_CONSTANT.BASE_URL + '/product-image/', params)
  }

  apiProductCode(params) {
    return this.api.get(API_CONSTANT.BASE_URL + '/product-image/product-code-filter/', params)
  }

  apiUpdateProductImage(id, data) {
    return this.api.patch(API_CONSTANT.BASE_URL + '/import-product-image/' + id + '/', data);
  }

  apiDeleteProductImage(id) {
    return this.api.delete(API_CONSTANT.BASE_URL + '/import-product-image/' + id + '/');
  }
}
