export enum OrderStatusType {
  'Pending' = 1,
  'Rejected' = 2,
  'Approved' = 3,
  'Confirmed' = 4,
}

export interface Orders {
  id: string;
  dealer: string;
  shop: string;
  date: string;
  price: number;
  complete_or_not?: number;
  puchese_id?: number;
  order_snapshot?: any;
  created_at?: string;
  price_total?: number;
  partial_cancel?: boolean;
  status: number;
}
export interface OrderItems {
  category_name: string;
  created_at: string;
  dealer_name: string;
  delivery_date: string;
  delivery_number: string;
  id: number;
  jan_code: string;
  order_id: number;
  order_number: string;
  price: number;
  price_total: number;
  product_code: string;
  product_maker: string;
  product_name: string;
  quantity: number;
  shop_name: string;
  status: string;
  type: string;
  is_selected: boolean;
  order_snapshot: OrderSnapshot;
}

export interface OrderSnapshot {
  address: string;
  buyer_name: string;
  customer_code: string | null;
  dealer_name: string;
  delivery_address: string;
  delivery_name: string;
  delivery_phone_number: string;
  delivery_place_code: string;
  delivery_postal_code: string;
  delivery_prefecture: string;
  delivery_remark: string;
  email: string;
  phone_number: string;
  postal_code: string;
  prefecture: string;
  remarks: string;
  seller_info: string[];
  six_digit_code: string | null;
  username: string;
}
