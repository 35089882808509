import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { SidebarModule } from 'ng-sidebar';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewProductCodeModule } from '../features/import-product/import-product.module';
import { ShopActualStockModule } from '../features/shop-actual-stock/shop-actual-stock.module';
import { NewCategoryCodeModule } from '../features/category/category.module';
import { NewSellerCodeModule } from '../features/seller/seller.module';
import { NewUserCodeModule } from '../features/user/user.module';
import { NewDealerCodeModule } from '../features/dealer/dealer.module';
import { NewBranchCodeModule } from '../features/branch/branch.module';
import { SharedModule } from '../shared.module';
import { ProductMasterModule } from '../features/product-master/product-master.module';
import {SupervisorModule} from '../features/supervisor/supervisor.module';
import {ProductImageModule} from '../features/product-image/product-image.module';
import { OrderHistoryModule } from '../features/order-history/order-history.module';
import { SellerWebUserModule } from '../features/seller-webuser/seller-webuser.module';
import { AnnouncementModule } from '../features/announcement/announcement.module';
import {CarSpecificationModule} from '../features/car-specification/car-specification.module';
import { MonotaroModule } from '../features/monotaro/monotaro.module';
import { ExportResultModule } from '../features/export-result/export-result.module';
import {ImportUpdateHistoryModule} from '../features/import-update-history/import-update-history.module';
import {SettingsModule} from '../features/settings/settings.module';
import {DailyCheckHistoryModule} from '../features/daily-check-history/daily-check-history.module';
import {BuyerDealerWebUserModule} from '../features/buyer-dealer-web-user/buyer-dealer-web-user.module';
import { DailyCheckTabletUserModule } from '../features/daily-check-tablet-user/daily-check-tablet-user.module';
import { MaintenanceSimulationModule } from '../features/maintenance-simulation/maintenance-simulation.module';
import { CalculationHistoryModule } from '../features/calculation-history/calculation-history.module';
import { DealerSettingModule } from '../features/dealer-setting/dealer-setting.module';
import { OrderApiDisableDateModule } from '../features/order-api-disable-date/order-api-disable-date.module';
import { SellerEmailModule } from '../features/seller-email/seller-email.module';
import { DailyCheckDataManagementModule } from '../features/daily-check-data-management/daily-check-data-management.module';

@NgModule({
  declarations: [MainComponent, SidebarComponent],
  imports: [
    NgbModule,
    CommonModule,
    AngularSvgIconModule,
    HttpClientModule,
    MainRoutingModule,
    SidebarModule.forRoot(),
    MonotaroModule,
    NgbDropdownModule,
    NewProductCodeModule,
    ShopActualStockModule,
    NewCategoryCodeModule,
    NewSellerCodeModule,
    NewUserCodeModule,
    NewDealerCodeModule,
    NewBranchCodeModule,
    SupervisorModule,
    OrderHistoryModule,
    ProductMasterModule,
    ProductImageModule,
    SharedModule,
    SellerWebUserModule,
    AnnouncementModule,
    CarSpecificationModule,
    ExportResultModule,
    ImportUpdateHistoryModule,
    SettingsModule,
    DailyCheckHistoryModule,
    BuyerDealerWebUserModule,
    DailyCheckTabletUserModule,
    MaintenanceSimulationModule,
    CalculationHistoryModule,
    DealerSettingModule,
    OrderApiDisableDateModule,
    SellerEmailModule,
    DailyCheckDataManagementModule
  ]
})
export class MainModule {}
