import { Component, Input, OnInit } from '@angular/core';
import { SearchData } from '../../daily-check-history/daily-check.models';
import { DailyCheckDataManagementResponse } from '../daily-check-data-management.models';
import { activityRankingTables } from '../daily-check-data-management.constants';

const allowAll = ['etradmin1', 'etr-admin'];
const allowBranch = [
  'etradmin2',
  'etradmin3',
  'etradmin4',
  'etradmin5',
  'etradmin6',
  'etradmin7',
  'etradmin8',
  'etradmin9',
  'etradmin10',
];

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @Input() resultData: DailyCheckDataManagementResponse;
  @Input() searchData: SearchData;
  
  activityRankingTables: string[];
  username: string;

  constructor() {}

  ngOnInit(): void {
    this.activityRankingTables = activityRankingTables;
    this.username = localStorage.getItem('username')
  }

  getTabName(key: number) {
    switch (key) {
      case 1: return 'Greet';
      case 2: return 'Checkup';
      case 3: return 'Estimation';
      case 4: return 'Confirmed Order';
      case 5: return 'Actual Work';
      default: return '';
    }
  }

  getRoleName(key: number): string {
    const roleMappings: Record<number, string> = {
      1: 'Manager 1',
      2: 'Sub-Manager 1',
      3: 'Shop Manager',
      4: 'Mate',
      5: 'Part-Time Worker',
      6: 'Employee',
      7: 'Staff',
      8: 'Manager 2',
      9: 'Sub-Manager 2',
    };
  
    return roleMappings[key] || '';
  }

  checkMaxNumber(rowIndex: number, type_index: number | null, tab, isRanking?: boolean) {
    return(
      !isRanking
        ? this.resultData.summary_data['types'][type_index][`${tab}_max_indexes`].includes(rowIndex)
        : this.resultData.summary_data[`${tab}_max_indexes`].includes(rowIndex)
    );
  }

  checkEmptyData(): boolean {
    if ((this.resultData.data as any[]).length === 0 || this.resultData.data === null) {
      return true
    }
    return false;
  }

  checkDisplayColumn(columnName: string): boolean {
    if (this.searchData.tableType !== 2) {
      return true;
    }
    if (
      allowAll.includes(this.username) ||
      (allowBranch.includes(this.username) && columnName !== 'branch')
    ) {
      return true;
    }
    return false;
  }

  getTotalColSpan(): number {
    if (this.searchData.tableType === 1) {
      return 3;
    } else if (this.searchData.tableType === 2) {
      if (allowAll.includes(this.username)) {
        return 5;
      } else if (allowBranch.includes(this.username)) {
        return 4;
      }
      return 3;
    }
    return 1;
  }
}
  
