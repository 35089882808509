import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../components/core/core.module';
import { UserComponent } from './user.component';
import { UserRoutingModule } from './user-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared.module';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { CheckboxModule } from 'primeng/checkbox';
import { EditOrCreateComponent } from './edit-or-create/edit-or-create.component';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [UserComponent, EditOrCreateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    NgSelectModule,
    NgbPaginationModule,
    SharedModule,
    ShowHidePasswordModule,
    CheckboxModule,
    InputTextModule,
    AutoCompleteModule
  ],
  exports: [],
  providers: []
})
export class NewUserCodeModule {}
