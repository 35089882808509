import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  transformMessage(msg) {
    // debugger
    msg = msg.trim();
    if (msg === 'File is not a zip file') {
      return this.translate.instant('fileIsNotAZipFile');
    } else if (msg === 'No error message') {
      return this.translate.instant('noErrorMessage');
    } else {
      var msg_slice = msg.substring(0, msg.indexOf('|'));
      var msg_slice_split = msg_slice.split('?');

      switch (msg_slice_split[0]) {
        case 'errCode0': {
          this.translate
            .get('errCode0', {
              sheet_name: msg_slice_split[1],
              row_number: msg_slice_split[2],
              column_name: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode00': {
          this.translate
            .get('errCode00', {
              sheet_name: msg_slice_split[1],
              row_number: msg_slice_split[2],
              column_name: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode000': {
          this.translate
            .get('errCode000', {
              sheet_name: msg_slice_split[1],
              row_number: msg_slice_split[2],
              column_name: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode0000': {
          this.translate
            .get('errCode0000', {
              sheet_name: msg_slice_split[1],
              row_number: msg_slice_split[2],
              column_name: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode1': {
          this.translate
            .get('errCode1', {
              sheet_name: msg_slice_split[1],
              row_number: msg_slice_split[2],
              column_name: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode2': {
          this.translate
            .get('errCode2', {
              row_number: msg_slice_split[1],
              column_name: msg_slice_split[2],
              sub_data: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode3': {
          this.translate
            .get('errCode3', {
              row_number: msg_slice_split[1],
              column_name_1: msg_slice_split[3],
              data_product_recomm_title: msg_slice_split[2]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode4': {
          this.translate
            .get('errCode4', {
              row_number: msg_slice_split[1],
              column_name: msg_slice_split[2],
              sub_data: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }

        case 'errCode5': {
          this.translate
            .get('errCode5', {
              row_number: msg_slice_split[1],
              column_name_1: msg_slice_split[2],
              column_name_2: msg_slice_split[3]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode6': {
          this.translate
            .get('errCode6', {
              row_number: msg_slice_split[1],
              column_name_1: msg_slice_split[2],
              sub_data_list_1_data: msg_slice_split[3],
              column_name_2: msg_slice_split[4],
              sub_data_list_2_data: msg_slice_split[5]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode7': {
          this.translate
            .get('errCode7', {
              row_number: msg_slice_split[1],
              column_name_1: msg_slice_split[3],
              data_product_recomm_title: msg_slice_split[2]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode8': {
          this.translate
            .get('errCode3', {
              row_number: msg_slice_split[1]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode9': {
          this.translate
            .get('errCode9', {
              row_number: msg_slice_split[1],
              column_name_1: msg_slice_split[2]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode10': {
          this.translate
            .get('errCode10', {
              row_number: msg_slice_split[1],
              column_name: msg_slice_split[2]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode11': {
          this.translate
            .get('errCode11', {
              row_number: msg_slice_split[1],
              column_name: msg_slice_split[2]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
        case 'errCode12': {
          this.translate
            .get('errCode12', {
              row_number: msg_slice_split[1],
              column_name: msg_slice_split[2]
            })
            .subscribe(res => {
              msg = res;
            });
          return msg;
        }
      }
    }
  }
}
