import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { User } from './user.model';
import { API_CONSTANT } from 'src/app/services/api.constants';
import { PaginatedResult } from 'src/app/components/pagination/pagination.interface';

@Injectable({
  providedIn: 'root'
})

/** @description Service providing user data */
export class UserDeliveryAddressService {
  constructor(private api: HttpClientService) {}

  getDeliveryAddresses(user: User) {
    return this.api.get<PaginatedResult<DeliveryAddressInfo>>(
      API_CONSTANT.BASE_URL + '/user-delivery-address/',
      { user_id: user.id, all: true }
    );
  }

  add(data: Partial<DeliveryAddressInfo>, user: User) {
    return this.api.post<PaginatedResult<DeliveryAddressInfo>>(
      API_CONSTANT.BASE_URL + '/user-delivery-address/',
      { ...data, user: user.id }
    );
  }

  delete(id: string) {
    return this.api.delete<PaginatedResult<DeliveryAddressInfo>>(
      API_CONSTANT.BASE_URL + '/user-delivery-address/' + id + '/'
    );
  }

  update(data: Partial<DeliveryAddressInfo>) {
    return this.api.patch<PaginatedResult<DeliveryAddressInfo>>(
      API_CONSTANT.BASE_URL + '/user-delivery-address/' + data.id + '/', data);
  }

  getAddressesForGritUser(id: number) {
    return this.api.get(API_CONSTANT.BASE_URL + '/user-delivery-address/',
      { cta_user_id: id }
    );
  }
}

export interface DeliveryAddressInfo {
  id: string;
  name: string;
  delivery_address: string;
  delivery_address_postal_code: string;
  delivery_address_prefecture: string;
  phone_number: string;
  delivery_address_remarks: string;
  user: string;
  cta_user_id: number;
}
