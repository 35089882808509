import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() pages = 1;
  @Input() current = 1;
  @Output() navigate = new EventEmitter<number>();

  navigateTo(page: number) {
    this.navigate.emit(page);
  }
  constructor() {}

  get times(): number[] {
    return Array(this.pages)
      .fill(null)
      .map<number>((x, i) => i + 1);
  }
  ngOnInit() {}
}
