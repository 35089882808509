import {
  EtrDealerType,
  DealerCustomerType,
} from 'src/app/models/customerTypes.model';

export interface Dealer {
  id: number;
  name: string;
  full_name?: string;
  branch_name?: string;
  name_jp?: string;
  branch?: number;
  tax_rate?: number;
  user_type?: DealerCustomerType;
  is_etr?: boolean;
  is_grit?: boolean;
  is_approval_function?: boolean;
  dealer_type?: EtrDealerType;
  tab_1?: string;
  tab_2?: string;
  tab_3?: string;
  tab_4?: string;
  tab_5?: string;
  is_monotaro_enabled?: boolean;
  unique_name_id?: number[];
}

export interface Branch {
  id: number;
  branch_name: string;
}

export interface DealerNames {
  dealers: string[];
}

export class SelectedDealer {
  id: number;
  is_cta: boolean;

  constructor(id: number = 0, is_cta: boolean = false) {
    this.id = id;
    this.is_cta = is_cta;
  }
}
