<app-header [title]="'Seller Email' | translate">
</app-header>

<div class='main-content px-3'>
  <!-- Filter -->
  <div class='row no-gutter d-flex justify-content-between my-3'>
    <div class='col-12 row no-gutter'>
      <div class='col-3 pl-0'>
        <label class='pl-1'>{{'Categories' | translate }}</label>
        <ng-select [clearable]="true"
            [loading]="loadingCategory"
            [items]="allCategories"
            placeholder='Select Categories'
            bindLabel="category_name"
            bindValue="id"
            [multiple]="true"
            [(ngModel)]="search['categories']"
            [notFoundText]="'notFound' | translate">
        </ng-select>
      </div>
      <div class='col-3 pl-0'>
        <label class='pl-1'>{{'Shops' | translate }}</label>
        <ng-select [clearable]="true"
            [loading]="loadingShop"
            [items]="allShops"
            placeholder='Select Shops'
            bindLabel="name"
            bindValue="id"
            [multiple]="true"
            (change)="hadleShopFilterChange($event)"
            [notFoundText]="'notFound' | translate">
          <ng-template ng-label-tmp
              let-item="item">
            <span class="d-flex align-items-center">
              {{ item.is_etr ? "(ETR)" : "(CTA)"}} {{item.name}}
            </span>
          </ng-template>
          <ng-template ng-option-tmp
              let-item="item"
              let-search="searchTerm"
              let-index="index">
            <span class="d-flex align-items-center">
              {{ item.is_etr ? "(ETR)" : "(CTA)"}} {{item.name}}
            </span>
          </ng-template>
        </ng-select>
      </div>
      <div class='col-3 pl-0'>
        <label class='pl-1'>{{'Emails' | translate }}</label>
        <ng-select [clearable]="true"
            [loading]="loadingEmail"
            [items]="allEmails"
            placeholder='Select Emails'
            bindLabel="showValue"
            bindValue="id"
            [multiple]="true"
            [(ngModel)]="search['emails']"
            [notFoundText]="'notFound' | translate">
        </ng-select>
      </div>
      <div class='col-3 p-0 d-flex align-self-end justify-content-end'>
        <button class="btn btn-sm d-flex align-items-center btn-primary mr-3 h-30"
            (click)="getProductSellerEmails()">
          <img src="assets/svgs/search_len.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'product-image.search' | translate}}
        </button>
        <button class="btn btn-sm d-flex align-items-center btn-secondary mr-3 h-30"
            (click)="resetSearch()">
          <img src="assets/svgs/reset_icon.svg"
              height="20"
              class="mr-1"
              width="auto">
          {{'product-image.reset' | translate}}
        </button>
        <button class="btn btn-sm d-flex align-items-center btn-primary mr-3 h-30"
            (click)="changeEmail(edit)">
          {{'Change Email' | translate}}
        </button>
      </div>
    </div>
  </div>

  <app-loading style="position: center;vertical-align: middle; text-align: center;"
      *ngIf="loadingSellerEmail"></app-loading>
  <app-list-empty *ngIf="!loadingSellerEmail && !sellerEmailList.length"
      title="{{ 'noDataFound' | translate }}"></app-list-empty>

  <div class="row mb-4 mx-3">
    <table class="table table-hover text-center table-striped"
        style="border-radius: 5px;"
        *ngIf="!loadingSellerEmail && sellerEmailList.length">
      <thead class="thead-light">
        <tr>
          <th>{{ 'Select' }}</th>
          <th>{{ 'Category' | translate }}</th>
          <th>{{ 'Shop' | translate }}</th>
          <th>{{ 'seller.email' | translate }}</th>
          <th>{{ 'CC Email' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of sellerEmailList;let i = index;">
          <td class="align-middle">
            <input type="checkbox"
                [checked]="item.checked"
                (click)="toggleCheck(item.id)" />
          </td>
          <td class="align-middle">{{ item.category_name }} </td>
          <td class="align-middle">{{ item.shop_name }} ({{item.is_etr ? 'ETR' : 'CTA'}})</td>
          <td class="align-middle">{{ item.email_name }} ({{ item.email }})</td>
          <td class="align-middle">
            <ul class="m-0 p-0"
                *ngFor="let email of item.cc_email">
              {{ email.email }} ({{ email.id }})
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <ngb-pagination [collectionSize]="dataLength"
          (pageChange)="changePage($event)"
          [(page)]="page"
          [pageSize]="40"
          [maxSize]="3"
          [rotate]="true"
          [boundaryLinks]="true"
          [ellipses]="false"></ngb-pagination>
    </div>
  </div>
</div>


<ng-template #edit>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0"> {{ 'Edit Seller Email' | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-3">
      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{ 'seller.email' | translate }}
        </label>
        <ng-select class='col-8 p-0'
            [clearable]="true"
            [items]="allEmails"
            [placeholder]="'Select Email' | translate "
            bindLabel="showValue"
            bindValue="id"
            [(ngModel)]="change['email']"
            [notFoundText]="'notFound' | translate">
        </ng-select>
      </div>

      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 mt-2 d-flex align-items-start">
          {{ 'CC Email' | translate }}
        </label>
        <ng-select class='col-8 p-0'
            [clearable]="false"
            [items]="allEmails"
            [placeholder]="'Select CC Email' | translate"
            bindLabel="showValue"
            bindValue="id"
            [multiple]="true"
            [(ngModel)]="change['cc_emails']"
            [notFoundText]="'notFound' | translate">
        </ng-select>
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary"
          (click)="dismissEdit()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary"
          (click)="saveEdit()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>