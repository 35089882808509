import {Injectable} from '@angular/core';
import {API_CONSTANT} from "../../services/api.constants";
import {HttpClientService} from "../../services/http-client.service";
import {Observable} from "rxjs";
import {PaginatedResult} from "../../components/pagination/pagination.interface";
import {Shop} from "../../models/shop.model";
import {Supervisor} from "./supervisor.model";
import {ProductSet} from "../../models/productSet.model";
import {SalePackage} from "../../models/salePackage.model";
import {Dealer} from "../dealer/dealer.model";

@Injectable({
  providedIn: 'root'
})
export class SupervisorService {
  static URL = API_CONSTANT.BASE_URL + '/supervisors/';

  constructor(private api: HttpClientService) {
  }

  get(params?: any): Observable<PaginatedResult<Supervisor>> {
    return this.api.get(SupervisorService.URL, params);
  }

  import(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.post(
      API_CONSTANT.BASE_URL + '/supervisor-import/',
      formData
    );
  }
}
