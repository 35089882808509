import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { API_CONSTANT } from './api.constants';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../components/pagination/pagination.interface';
import { SalePackage } from '../models/salePackage.model';
import { ProductSet } from '../models/productSet.model';

@Injectable({
  providedIn: 'root'
})
export class SalePackageService {
  static API_URL = API_CONSTANT.BASE_URL + '/package_sets/';

  constructor(private api: HttpClientService) {}

  get(params?: any): Observable<SalePackage[]> {
    return this.api.get<SalePackage[]>(
      SalePackageService.API_URL,
      params
    );
  }

  getAll(params?: any): Observable<SalePackage[]> {
    return this.api.get<SalePackage[]>(SalePackageService.API_URL, {
      all: true,
      ...params
    });
  }

  create(data: any) {
    return this.api.post(SalePackageService.API_URL, data);
  }

  delete(data) {
    return this.api.delete(SalePackageService.API_URL + `${data}/`);
  }

  import(productSet, salePackage, file: File, is_scheduled: Boolean, time?: any) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('product_set_id', productSet.toString());
    formData.append('package_set_id', salePackage.toString());
    formData.append('is_scheduled', is_scheduled.toString());
    formData.append('time', time);
    return this.api.post(
      API_CONSTANT.BASE_URL + '/import-product-price/',
      formData
    );
  }

  update(id, data) {
    return this.api.patch(SalePackageService.API_URL + id + '/', data);
  }
}
