import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DailyCheckHistoryComponent} from './daily-check-history.component';

export const dailyCheckRoutes: Routes = [
  {
    path: 'daily-check',
    component: DailyCheckHistoryComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(dailyCheckRoutes)],
  exports: [RouterModule]
})

export class DailyCheckHistoryRoutingModule {}
