import { Injectable } from '@angular/core';
import { HttpClientService } from '../../services/http-client.service';
import { API_CONSTANT } from '../../services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  constructor(private api: HttpClientService) {
  }

  getOrders(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/orders/', param);
  }

  getOrderItems(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/order_history/', param);
  }

  updateOrderItem(id, data) {
    return this.api.patch(API_CONSTANT.BASE_URL + '/order_history/' + id + '/', data);
  }

  getDealersForOrderHistory(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-dealer-by-orders/', param);
  }

  getShopsForOrderHistory(param?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-shop-by-orders/', param);
  }

  updateProductType(data) {
    return this.api.patch(
      API_CONSTANT.BASE_URL + '/order_history/change-product-types/',
      data
    );
  }

  importOrderDeliveryDate(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-delivery-date/', data);
  }
}
