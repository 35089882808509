import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ShopService} from '../../../services/shop.service';
import {DailyChecks, Shop} from '../../../models/shop.model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Location} from '@angular/common';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Staff} from '../settings.model';
import { ConfirmModalService } from '../../../../app/components/confirm-modal/confirm-modal.service';
import { DailyCheckHistoryService } from '../../daily-check-history/daily-check-history.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-shop-settings',
  templateUrl: './edit-shop-settings.component.html',
  styleUrls: ['./edit-shop-settings.component.scss']
})

export class EditShopSettingsComponent implements OnInit {
  id: number;
  shop: Shop;
  savingMonthlyVisit: boolean = false;
  savingMonthlyTarget: boolean = false;
  savingStaff: boolean = false;
  savingDailyCheckItems: boolean = false;
  monthlyVisitDate: NgbDate;
  dailyVisitNumber: number;
  totalMonthlyVisit: number;
  actualTotalMonthlyVisit: number;
  positionList = [
    { name: 'Manager 1', jp_name: '店長', role: 1 },
    { name: 'Sub-Manager 1', jp_name: '副店長', role: 2 },
    { name: 'Mate', jp_name: 'メイト', role: 4 },
    { name: 'Part-Time Worker', jp_name: 'パート', role: 5 },
    { name: 'Employee', jp_name: '社員', role: 6 },
    { name: 'Staff', jp_name: 'スタッフ', role: 7 },
    { name: 'Manager 2', jp_name: 'マネージャー', role: 8 },
    { name: 'Sub-Manager 2', jp_name: 'サブマネージャー', role: 9 },
  ];
  basic8ItemType = [2,3,4,5,6,7,8,9];

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public shopService: ShopService,
    private notification: ToastrService,
    private translate: TranslateService,
    private _location: Location,
    private confirmModal: ConfirmModalService,
    private dailyCheckService: DailyCheckHistoryService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.id = +params['id'];
      }
    );
    this.dateInitialize()
    this.getShop(this.id);
  }

  dateInitialize(): void {
    const currentDate = new Date();
    const objCurrentDate = new NgbDate(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    this.monthlyVisitDate = objCurrentDate;
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  removeStaff(index): void {
    this.confirmModal.open(
      'Delete Confirmation',
      'Are you sure you want to delete?',
      () => {
        this.shop.staffs.splice(index, 1);
      }
    );
  }

  addNewStaff(): void {
    const newStaff = new Staff();
    newStaff.shop = this.id;
    newStaff.sequence = this.shop.staffs.length + 1;
    newStaff.working_hour = 0;
    this.shop.staffs.push(newStaff);
  }

  backClicked() {
    this._location.back();
  }

  showErrorMsg(msg) {
    this.notification.error(_.values(msg.error)[0].toString());
  }

  getShop(id) {
    this.shopService.getShopByID(id).subscribe(res => {
      this.shop = res;
      this.getMonthlyVisit();
    });
  }

  changeSequence(event: CdkDragDrop<Staff[]>): void {
    moveItemInArray(this.shop.staffs, event.previousIndex, event.currentIndex);
  }

  changeTableSequence(event: CdkDragDrop<DailyChecks[]>): void {
    moveItemInArray(this.shop.daily_checks, event.previousIndex, event.currentIndex);
    this.shop.daily_checks.map((eachItem, index) => {
      eachItem.sequence = index + 1;
    });
  }

  toggleCheckBoxFirstBox(index: number): void {
    this.shop.daily_checks[index].display_in_tab_1 =
      !this.shop.daily_checks[index].display_in_tab_1;
    if (this.shop.daily_checks[index].type === 1) {
      this.toggleBasic8item(this.shop.daily_checks[index].display_in_tab_1);
    } else {
      this.checkBasic8item();
    }
  }

  toggleCheckBoxSecondBox(index: number): void {
    this.shop.daily_checks[index].display_in_tab_2_3_4_5 =
      !this.shop.daily_checks[index].display_in_tab_2_3_4_5;
    if (this.shop.daily_checks[index].type === 1) {
      this.toggleBasic8item(this.shop.daily_checks[index].display_in_tab_2_3_4_5, false)
    } else {
      this.checkBasic8item(false)
    }
  }

  checkChangeable(type: number): boolean {
    if (type < 20) {
      return false;
    }
    return true;
  }

  toggleBasic8item(display: boolean, tab_1: boolean = true): void {
    this.shop.daily_checks.forEach((item) => {
      if (this.basic8ItemType.includes(item.type)) {
        tab_1
          ? (item.display_in_tab_1 = display)
          : (item.display_in_tab_2_3_4_5 = display);
      }
    });
  }

  checkBasic8item(tab_1: boolean = true): void {
    let basic8Item = true;
    this.shop.daily_checks.forEach((item) => {
      if (
        this.basic8ItemType.includes(item.type) &&
        ((item.display_in_tab_1 === false && tab_1) ||
          (item.display_in_tab_2_3_4_5 === false && !tab_1))
      ) {
        basic8Item = false;
      }
    });
    this.shop.daily_checks.forEach((item) => {
      if (item.type === 1) {
        tab_1
          ? (item.display_in_tab_1 = basic8Item)
          : (item.display_in_tab_2_3_4_5 = basic8Item);
      }
    });
  }

  getDate(date: NgbDate): string {
    return `${date.year}-${date.month < 10 ? '0' + date.month : date.month}-${
      date.day < 10 ? '0' + date.day : date.day
    }`;
  }

  getMonthlyVisit(): void {
    const params = {
      date: this.getDate(this.monthlyVisitDate),
      shop_id: this.id,
    };
    this.dailyCheckService.getMonthlyVisit(params).subscribe(
      (res: any) => {
        this.dailyVisitNumber = res.daily_visit_number;
        this.actualTotalMonthlyVisit = res.total_monthly_visit;
        this.updateTotalMonthlyVisit();
      },
      (error) => {
        this.notification.error(error);
      }
    );
  }

  saveMonthlyVisit(): void {
    this.savingMonthlyVisit = true;
    const data = {
      visit_date: this.getDate(this.monthlyVisitDate),
      visit: this.dailyVisitNumber,
    };
    this.shopService.update(this.shop.id, data).subscribe(
      (res) => {
        this.notification.success(
          this.translate.instant('Successfully Updated')
        );
        this.savingMonthlyVisit = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.savingMonthlyVisit = false;
      }
    );
  }

  saveMonthlyTarget(): void {
    this.savingMonthlyTarget = true;
    const data = {
      flyer: this.shop.flyer,
      tab_3: this.shop.tab_3,
    };
    this.shopService.update(this.shop.id, data).subscribe(
      (res) => {
        this.notification.success(
          this.translate.instant('Successfully Updated')
        );
        this.savingMonthlyTarget = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.savingMonthlyTarget = false;
      }
    );
  }

  saveStaff(): void {
    this.savingStaff = true;
    const hasNullRole = this.shop.staffs.some(staff => staff.role === null);
    if (hasNullRole) {
      this.notification.error(
        this.translate.instant("Position is required")
      );
      this.savingStaff = false;
      return;
    }
    const data = {
      staffs: this.shop.staffs,
    }
    this.shopService.update(this.shop.id, data).subscribe(
      (res) => {
        res.staffs.forEach((staff, index) => {
          this.shop.staffs[index].id = staff.id;
          this.shop.staffs[index].role_name = staff.role_name;
        });
        this.notification.success(
          this.translate.instant('Successfully Updated')
        );
        this.savingStaff = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.savingStaff = false;
      }
    );
  }

  saveDailyCheckItems(): void {
    this.savingDailyCheckItems = true;
    const data = {
      daily_checks: this.shop.daily_checks,
    };
    this.shopService.update(this.shop.id, data).subscribe(
      (res) => {
        this.notification.success(
          this.translate.instant('Successfully Updated')
        );
        this.savingDailyCheckItems = false;
      },
      (err) => {
        this.showErrorMsg(err);
        this.savingDailyCheckItems = false;
      }
    );
  }

  updateTotalMonthlyVisit(): void {
    this.totalMonthlyVisit =
          this.actualTotalMonthlyVisit + this.dailyVisitNumber;
  }
}
