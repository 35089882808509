<app-header [title]=" 'Settings' | translate"></app-header>
<div class='content'>
  <div class="bg-white custom-radius m-t-15 p-x-25 p-y-10">
    <div class="tabs-row">
      <div class="tab"
           [ngClass]="{'selected': selectedTab === '0', 'full-width': isShopManager()}"
           (click)="changeTab('0')">
        {{'Shop Settings'| translate }}
      </div>
      <div class="tab"
           *ngIf="!isShopManager()"
           [ngClass]="{'selected': !selectedTab}"
           (click)="changeTab(null)">
        {{'Dealer Settings' | translate }}
      </div>
    </div>
  </div>

  <div class="row w-100 p-3 px-4 m-0 d-flex justify-content-start" *ngIf="!selectedTab">
    <div class="w-30percent row p-0 m-0 align-self-start">
      <label class="row w-100 m-0 p-0 pl-1">
        {{ "Dealer" | translate }}
      </label>
      <div class="row w-100 p-0 m-0 pr-3">
        <ng-select class="w-100 p-0"
            [clearable]="false"
            [disabled]="disableDealer"
            [items]="dealersName"
            [placeholder]="'Select Dealer' | translate"
            bindLabel="name"
            bindValue="id"
            [notFoundText]="'notFound' | translate"
            [(ngModel)]="selectedDealer">
          <ng-template ng-label-tmp
              let-item="item">
            <span class="d-flex align-items-center">
              {{ item.name}} {{item.branch_name}}
            </span>
          </ng-template>
          <ng-template ng-option-tmp
              let-item="item"
              let-search="searchTerm"
              let-index="index">
            <span class="d-flex align-items-center">
              {{ item.name}} {{item.branch_name}}
            </span>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="w-70percent d-flex align-items-start justify-content-end">
      <div class="d-flex align-items-start">
        <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center" (click)="getShopsAndDealers()"
        >
          <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
          {{'search' | translate}}
        </button>
        <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center"  (click)="resetDealer()"
        >
          <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
          {{'reset' | translate}}
        </button>
        <button class="btn btn-sm mt-4 btn-outline-primary mr-2 d-flex align-items-center" (click)="import(importTemplate)"
        >
          <img src="assets/svgs/blue_plus_icon.svg" height="20" class="mr-1" width="auto">
          {{'Import' | translate}}
        </button>
      </div>
    </div>
  </div>


  <div class="row w-100 p-3 px-4 m-0 d-flex justify-content-start" *ngIf="selectedTab == '0'">
    <div class="w-30percent row p-0 m-0 align-self-start">
      <label class="row w-100 m-0 p-0 pl-1">
        {{ "Dealer" | translate }}
      </label>
      <div class="row w-100 p-0 m-0 pr-3">
        <ng-select class="w-100 p-0"
            [disabled]="disableDealer"
            [clearable]="false"
            [items]="dealersName"
            [placeholder]="'Select Dealer' | translate"
            [notFoundText]="'notFound' | translate"
            [(ngModel)]="selectedDealer"
            bindLabel="name"
            bindValue="id"
            (change)="getShopsName()">
          <ng-template ng-label-tmp
              let-item="item">
            <span class="d-flex align-items-center">
              {{ item.name}} {{item.branch_name}}
            </span>
          </ng-template>
          <ng-template ng-option-tmp
              let-item="item"
              let-search="searchTerm"
              let-index="index">
            <span class="d-flex align-items-center">
              {{ item.name}} {{item.branch_name}}
            </span>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="w-30percent row p-0 m-0 align-self-start">
      <label class="row w-100 m-0 p-0 pl-1">
        {{ "Shop" | translate }}
      </label>
      <div class="row w-100 p-0 m-0 pr-3">
        <ng-select
          class="w-100 p-0"
          [clearable]="false"
          [loading]="loadingShopName"
          [disabled]="!selectedDealer"
          [items]="shopsName"
          [placeholder]="'Select Shop' | translate"
          [notFoundText]="'notFound' | translate"
          [(ngModel)]="selectedShop"
        ></ng-select>
      </div>
    </div>

    <div class="w-40percent d-flex align-items-start justify-content-end">
      <div class="d-flex align-items-start">
        <button class="btn btn-sm mt-4 mr-2 btn-primary d-flex align-items-center" (click)="getShopsAndDealers()"
        >
          <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
          {{'search' | translate}}
        </button>
        <button class="btn btn-sm mt-4 mr-2 btn-secondary d-flex align-items-center" (click)="resetShop()"
        >
          <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
          {{'reset' | translate}}
        </button>
        <button class="btn btn-sm mt-4 btn-outline-primary mr-2 d-flex align-items-center" (click)="import(importTemplate)"
        >
          <img src="assets/svgs/blue_plus_icon.svg" height="20" class="mr-1" width="auto">
          {{'Import' | translate}}
        </button>
      </div>
    </div>
  </div>

  <app-loading
    style="position: center;vertical-align: middle; text-align: center;"
    *ngIf="loadingDealers && !selectedTab"
  ></app-loading>
  <app-list-empty
    *ngIf="!loadingDealers && !dealers.length && !selectedTab"
    title="{{ 'noDataFound' | translate }}"
  ></app-list-empty>

  <div class="row mb-4 mx-4" *ngIf="!selectedTab">
    <table
      class="table table-hover text-center table-borderless table-striped">
      <thead>
      <tr>
        <th class="w-22percent top-left-round">
          {{ 'Dealer' | translate }}
        </th>
        <th class="w-14percent">
          {{ 'Tab 1' | translate }}
        </th>
        <th class="w-14percent">
          {{ 'Tab 2' | translate }}
        </th>
        <th class="w-14percent">
          {{ 'Tab 3' | translate }}
        </th>
        <th class="w-14percent">
          {{ 'Tab 4' | translate }}
        </th>
        <th class="w-14percent">
          {{ 'Tab 5' | translate }}
        </th>
        <th class="w-8percent top-right-round">
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let dealer of dealers">
        <td class="align-middle">{{ dealer.full_name }}</td>
        <td class="align-middle">{{ dealer.tab_1 | translate }}</td>
        <td class="align-middle">{{ dealer.tab_2 | translate }}</td>
        <td class="align-middle">{{ dealer.tab_3 | translate }}</td>
        <td class="align-middle">{{ dealer.tab_4 | translate }}</td>
        <td class="align-middle">{{ dealer.tab_5 | translate }}</td>
        <td class="align-middle">
          <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
               (click)="editDealer(editOrCreate, dealer)">
        </td>
      </tr>
      </tbody>
    </table>
  </div>


  <app-loading
    style="position: center;vertical-align: middle; text-align: center;"
    *ngIf="loadingShops && selectedTab == '0'"
  ></app-loading>
  <app-list-empty
    *ngIf="!loadingShops && !dataLength && selectedTab == '0'"
    title="{{ 'noDataFound' | translate }}"
  ></app-list-empty>

  <div class="row mb-4 mx-4" *ngIf="selectedTab == '0'">
    <table
      class="table table-hover text-center table-borderless table-striped"
      style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
    >
      <thead>
      <tr>
        <th colspan="1" class="w-23percent top-left-round border-right border-bottom" rowspan="2">
          {{"Shop" | translate}}
        </th>
        <th colspan="1" class="w-10percent border-right border-bottom" rowspan="2">
          {{"Actual Visit" | translate}}
        </th>
        <th colspan="2" class="w-23percent border-bottom border-right">
          {{"Monthly Target Number" | translate}}
        </th>
        <th colspan="1" class="w-23percent border-bottom border-right" rowspan="2">
          {{"Staff" | translate}}
        </th>
        <th colspan="2" class="w-23percent border-bottom border-right">
          {{"Add Daily Check Item" | translate}}
        </th>
        <th colspan="1" class="w-8percent border-bottom top-right-round" rowspan="2"></th>
      </tr>
      <tr>
        <th class="border-right border-bottom">{{"Flyer" | translate}}</th>
        <th class="border-right border-bottom">{{"Tab 3" | translate}}</th>
        <th class="border-right border-bottom">{{"Tab 1" | translate}}</th>
        <th class="border-right border-bottom">{{"Tab 2,3,4,5" | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let shop of shops">
        <td class="align-middle">{{ shop.name }}</td>
        <td class="align-middle">{{ shop.actual_visit }}</td>
        <td class="align-middle">{{ shop.flyer }}</td>
        <td class="align-middle">{{ shop.tab_3 }}</td>
        <td class="align-middle">
          <ul class="mb-0" *ngFor="let staff of shop.staffs">
            <li class="text-left">{{staff.role_name | translate}} : {{staff.name}}</li>
          </ul>
        </td>
        <td class="align-middle">
          <ul class="m-b-0" *ngFor="let tab of shop.tab_1">
            <li class="text-left">{{tab}}</li>
          </ul>
        </td>
        <td class="align-middle">
          <ul class="m-b-0" *ngFor="let tab of shop.other_tabs">
            <li class="text-left">{{tab}}</li>
          </ul>
        </td>
        <td class="align-middle">
          <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
               (click)="editShop(shop)">
        </td>
      </tr>
      </tbody>
    </table>
    <div class='row no-gutter' *ngIf="!loadingShops && shops.length">
          <ngb-pagination
            [collectionSize]="count"
            [(page)]="currentPage"
            [maxSize]="5"
            [pageSize]="40"
            (pageChange)="getShops()"
            [boundaryLinks]="true">
          </ngb-pagination>
        </div>

  </div>
</div>

<ng-template #editOrCreate>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0"> {{ 'Edit Tab Name' | translate}}</h4>
    </div>
    <div class="modal-body px-4 py-0 mb-2">
      <div class="row w-100percent p-0 m-0 mt-4 align-items-center">
        <label class="col-3 p-0 m-0 d-flex align-items-center">
          {{'Dealer Name' | translate}}
        </label>
        <div class="col-7 px-0">
          <input type="text"
                 class="w-100percent form-control"
                 [(ngModel)]="editingDealer['name']"
                 [placeholder]="'inputCategory' | translate"
                 [disabled]="true">
        </div>
      </div>

      <div class="row w-100percent p-0 m-0 mt-4 align-items-center">
        <label class="col-3 p-0 m-0 d-flex align-items-center">
          {{'Tab 1' | translate}}
        </label>
        <div class="col-7 px-0">
          <input type="text"
                 class="w-100percent form-control"
                 [(ngModel)]="editingDealer['tab_1']"
                 [placeholder]="'inputCategory' | translate">
        </div>
        <div class="col-2 p-r-0">
          <button class="btn btn-primary p-0"
                  (click)="editingDealer['tab_1'] = '声掛け' ">
            {{"Default Name"| translate}}
          </button>
        </div>
      </div>

      <div class="row w-100percent p-0 m-0 mt-4 align-items-center">
        <label class="col-3 p-0 m-0 d-flex align-items-center">
          {{'Tab 2' | translate}}
        </label>
        <div class="col-7 px-0">
          <input type="text"
                 class="w-100percent form-control"
                 [(ngModel)]="editingDealer['tab_2']"
                 [placeholder]="'inputCategory' | translate">
        </div>
        <div class="col-2 p-r-0">
          <button class="btn btn-primary p-0"
                  (click)="editingDealer['tab_2'] = '点検' ">
            {{"Default Name"| translate}}
          </button>
        </div>
      </div>

      <div class="row w-100percent p-0 m-0 mt-4 align-items-center">
        <label class="col-3 p-0 m-0 d-flex align-items-center">
          {{'Tab 3' | translate}}
        </label>
        <div class="col-7 px-0">
          <input type="text"
                 class="w-100percent form-control"
                 [(ngModel)]="editingDealer['tab_3']"
                 [placeholder]="'inputCategory' | translate">
        </div>
        <div class="col-2 p-r-0">
          <button class="btn btn-primary p-0"
                  (click)="editingDealer['tab_3'] = '見積' ">
            {{"Default Name"| translate}}
          </button>
        </div>
      </div>

      <div class="row w-100percent p-0 m-0 mt-4 align-items-center">
        <label class="col-3 p-0 m-0 d-flex align-items-center">
          {{'Tab 4' | translate}}
        </label>
        <div class="col-7 px-0">
          <input type="text"
                 class="w-100percent form-control"
                 [(ngModel)]="editingDealer['tab_4']"
                 [placeholder]="'inputCategory' | translate">
        </div>
        <div class="col-2 p-r-0">
          <button class="btn btn-primary p-0"
                  (click)="editingDealer['tab_4'] = '成約' ">
            {{"Default Name"| translate}}
          </button>
        </div>
      </div>

      <div class="row w-100percent p-0 m-0 mt-4 align-items-center">
        <label class="col-3 p-0 m-0 d-flex align-items-center">
          {{'Tab 5' | translate}}
        </label>
        <div class="col-7 px-0">
          <input type="text"
                 class="w-100percent form-control"
                 [(ngModel)]="editingDealer['tab_5']"
                 [placeholder]="'inputCategory' | translate">
        </div>
        <div class="col-2 p-r-0">
          <button class="btn btn-primary p-0"
                  (click)="editingDealer['tab_5'] = '実作業' ">
            {{"Default Name"| translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="modal-footer border-top-0 px-4">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="updateDealer()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>



<ng-template #importTemplate>
  <div class="modal-header">
    {{ selectedTab === '0' ? "Import Shop Settings" : "Import Dealer Settings" | translate }}
  </div>
  <div class="modal-body">
    <input type="file"
        id="file"
        (change)="handleFileInput($event.target.files)"
        accept=".xls, .xlsx">
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary"
        (click)="dismissFileSelection()">
      {{ 'Cancel' | translate }}
    </button>
    <button type="button"
        class="btn btn-outline-primary"
        (click)="selectedTab === '0' ? uploadFile() : uploadDealerSettings()">
      {{ 'Upload' | translate }}
      <i class="fa fa-spinner fa-spin"
          *ngIf="importing"></i>
    </button>
  </div>
</ng-template>
