import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { OrderHistoryComponent } from './order-history.component';


export const orderHistoryRoutes: Routes = [
  {
    path: 'order-history',
    children: [
      {path: '', component: OrderHistoryComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(orderHistoryRoutes)],
  exports: [RouterModule]
})
export class OrderHistoryRoutingModule {
}
