import {
  NgbDate,
  NgbDateStruct,
  NgbTimeStruct
} from '@ng-bootstrap/ng-bootstrap';

export class DateTime {
  fromDate: NgbDateStruct;
  fromTime: NgbTimeStruct;
  toDate: NgbDateStruct;
  toTime: NgbTimeStruct;

  constructor() {
    const today = new Date();

    this.fromDate = new NgbDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    this.fromTime = { hour: 0, minute: 0, second: 0 };
    this.toDate = new NgbDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    this.toTime = { hour: 23, minute: 59, second: 59 };
  }

  get fromValue(): string {
    const date =
      this.fromDate.year +
      '-' +
      String(this.fromDate.month).padStart(2, '0') +
      '-' +
      String(this.fromDate.day).padStart(2, '0');
    const time =
      this.fromTime.hour +
      ':' +
      String(this.fromTime.minute).padStart(2, '0') +
      ':' +
      String(this.fromTime.second).padStart(2, '0');

    return date + ' ' + time;
  }

  get toValue(): string {
    const date =
      this.toDate.year +
      '-' +
      String(this.toDate.month).padStart(2, '0') +
      '-' +
      String(this.toDate.day).padStart(2, '0');
    const time =
      this.toTime.hour +
      ':' +
      String(this.toTime.minute).padStart(2, '0') +
      ':' +
      String(this.toTime.second).padStart(2, '0');

    return date + ' ' + time;
  }

  get invalid(): boolean {
    if (NgbDate.from(this.fromDate).after(this.toDate)) {
      return true;
    }

    if (!NgbDate.from(this.fromDate).equals(this.toDate)) {
      return false;
    }

    return (
      this.fromTime.hour > this.toTime.hour ||
      (this.fromTime.hour === this.toTime.hour &&
        this.fromTime.minute > this.toTime.minute)
    );
  }
}
