import { Injectable } from "@angular/core";
import { API_CONSTANT } from "src/app/services/api.constants";
import { HttpClientService } from "src/app/services/http-client.service";

@Injectable({
  providedIn: 'root'
})
export class SupervisorDealerManagerService {
  constructor(private api: HttpClientService) { }

  getSuperVisorDealerManagers(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/supervisor-dealer-manager/', params);
  }

  createSupervisorDealerManager(data) {
    return this.api.post(API_CONSTANT.BASE_URL + '/supervisor-dealer-manager/', data);
  }

  updateSupervisorDealerManager(id, data) {
    return this.api.patch(API_CONSTANT.BASE_URL + '/supervisor-dealer-manager/' + id + '/', data);
  }

  deleteSupervisorDealerManager(id) {
    return this.api.delete(API_CONSTANT.BASE_URL + '/supervisor-dealer-manager/' + id + '/')
  }
}