<app-header [title]="'Daily-Check Data Management' | translate"></app-header>

<div class="main-content p-3">
  <app-search-box (searchEvent)="onSearch($event)"></app-search-box>
  
  <div class="w-100percent overflow-auto pb-3">
    <app-data-table 
      *ngIf="resultData && isNotEmpty(resultData.data)"
      [searchData]="searchData"
      [resultData]="resultData"
    ></app-data-table>
    <app-loading class="w-100 my-3" *ngIf="isLoadingData"></app-loading>
  </div>
</div>