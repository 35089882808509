import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import {API_CONSTANT} from '../../services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class ImportProductService {
  constructor(private api: HttpClientService) {
  }

  getImportResults(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/import-results/', params);
  }
}
