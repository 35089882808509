<app-header title="{{ 'export-result.export-result' | translate }}">
</app-header>

<div class="row mt-3 mb-4 ml-1 mr-1">
  <div class="col-md-12">
    <div>
      <app-loading
        style="position: center;vertical-align: middle; text-align: center;"
        *ngIf="loading"
      ></app-loading>
      <app-list-empty
        *ngIf="!loading && !exportResultLength"
        title="{{ 'noDataFound' | translate }}"
      ></app-list-empty>
      <div class="row no-gutter" *ngIf="!loading">
        <div class="col-12 d-flex justify-content-end align-items-end">
          <button class="btn btn-sm d-flex align-items-center btn-secondary" (click)="refresh()">
            <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
            Refresh
          </button>
        </div>
      </div>
      <div class="mt-3 mb-4">
        <table
          class="table text-center table-striped table-borderless"
          *ngIf="!loading && exportResultLength">
          <thead class="thead-light">
            <tr>
              <th>
                {{ 'export-result.export-type' | translate }}
              </th>
              <th>
                {{ 'export-result.exporter' | translate }}
              </th>
              <th>
                {{ 'export-result.exported-date' | translate }}
              </th>
              <th>
                {{ 'export-result.status' | translate }}
              </th>
              <th>
                {{ 'export-result.file' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of exportResultList
                  | slice: (page - 1) * 30:(page - 1) * 30 + 30
              "
            >
              <td class="align-middle">
                {{ item.export_type || '-'  | translate}}
              </td>
              <td class="align-middle">
                {{ item.created_user || '-' }}
              </td>
              <td class="align-middle">
                {{item.created_at | date: 'yyyy-MM-dd h:mm a' : '+0900' || ''}}
              </td>
              <td class="align-middle {{statusColorMapping[item.status]}}">
                <div
                  (click)="item.status === 'Failed' ? openErrorPopup(errPopup, item.error) : {}">
                  {{ item.status | translate }}
                </div>
              </td>
              <td class="align-middle">
                <button class="export-file-btn" (click)="exportFile(item.file)" [disabled]="item.status !== 'Success'">
                  <i class="fas fa-file-download fa-lg"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex">
          <ngb-pagination
            [collectionSize]="count"
            [(page)]="currentPage"
            [pageSize]="40"
            [maxSize]="5"
            (pageChange)='navigateTo($event)'
            [boundaryLinks]="true"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #errPopup let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{'Message' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body reason-box mb-3" *ngIf="reason">
      {{ reason }}
    </div>
    <div class="modal-body mb-3" *ngIf="!reason">
      {{'noErrorMessage' | translate}}
    </div>
  </div>
</ng-template>
