<app-header [title]="type == 'adding' ? ( 'user.addUser' | translate ):( 'user.editUser' | translate)"> </app-header>
<div class="row w-100">

  <!-- Content  -->
  <div class="row mx-4" *ngIf="user">

    <!-- 1st Row  -->
    <div class="row w-100 m-3">
      <div class="col-4 row">
        <label class="col-5 pt-1 pl-1 w-100">{{'Buyer Username'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.username ? 'is-invalid' :'' "
          [(ngModel)]="user.username" [readonly]="type=='editing' || gritUser"
          (ngModelChange)="removeWarning('username')">
      </div>
      <div class="col-4 row">
        <label class="col-5 pt-1 pl-1 w-100">{{'user.password'| translate}}*</label>
        <div class="col-6 w-100 p-0">
          <input class="w-100 form-control" type="password"
            [ngClass]="errorMsg && errorMsg.password ? 'is-invalid' :'' "
            [(ngModel)]="user.password" [readonly]="type=='editing' || gritUser"
            (ngModelChange)="removeWarning('password')">
          <label class="small-pops change-pwd" (click)="openChangePassword(changePasswordModal)"
            *ngIf="type=='editing'">
            <i class="fa fa-key mr-1"></i>
            {{'changePass' | translate}}
          </label>
        </div>
      </div>
      <div class="col-4 row">
        <label class="col-5 pt-1 pl-1 w-100">{{'Shop'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.shop_name ? 'is-invalid' :'' "
          *ngIf="type=='editing' || gritUser"
          [(ngModel)]="user.shop_name" readonly>

        <ng-select
          *ngIf="type=='adding'"
          [(ngModel)]="selectedShop"
          (change)="setShopData($event)"
          [clearable]="false"
          class="col-6 w-90 p-0"
          [items]="shopList"
          bindLabel="name"
          bindValue="id"
          placeholder="{{ 'user.selectShop' | translate }}"
          [openOnEnter]="false"
          [loading]="shopSearching"
          notFoundText="{{ 'noDataFound' | translate }}">
        </ng-select>
      </div>
    </div>


    <!-- 2nd Row  -->
    <div class="row w-100" [ngClass]="type=='editing' ? 'mx-3 mt-2 mb-3' : 'm-3' ">
      <div class="col-4 row d-flex align-items-center">
        <label class="col-5 pl-1 w-100">{{'user.familyName'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.family_name ? 'is-invalid' :'' "
          [(ngModel)]="user.family_name"
          (ngModelChange)="removeWarning('family_name')" [readonly]="gritUser">
      </div>
      <div class="col-4 row d-flex align-items-center">
        <label class="col-5 pl-1 w-100">{{'user.givenName'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.given_name ? 'is-invalid' :'' "
          [(ngModel)]="user.given_name"
          (ngModelChange)="removeWarning('given_name')" [readonly]="gritUser">
      </div>
      <div class="col-4 row d-flex align-items-center">
        <label class="col-5 pl-1 w-100">{{'user.email'| translate}}</label>
        <div class="col-6 w-100 p-0">
          <input class="w-100 form-control" type="email"
            [(ngModel)]="user.email"
            (ngModelChange)="removeWarning('email')" [readonly]="type=='editing' || gritUser">
          <label class="small-pops see-more" (click)="openMoreEmails(emailModel)"
            *ngIf="type=='editing'">
            <i class="fas fa-eye mr-1"></i>
            {{'seeMore' | translate}}
          </label>
        </div>
      </div>
    </div>

    <!-- 3rd Row  -->
    <div class="row w-100 m-3">
      <div class="col-4 row d-flex align-items-center">
        <label class="col-5 px-0 m-0 w-100">{{'register.registeredShopName'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.default_address ? 'is-invalid' :'' "
          [(ngModel)]="user.default_address" [readonly]="type=='editing' || gritUser">
      </div>
      <div class="col-4 row d-flex align-items-center">
        <label class="col-5 px-0 m-0 w-100">{{'register.defaultAddressPrefecture'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.default_address_prefecture ? 'is-invalid' :'' "
          [(ngModel)]="user.default_address_prefecture" [readonly]="type=='editing' || gritUser">
      </div>
      <div class="col-4 row d-flex align-items-center ">
        <label class="col-5 px-0 m-0 w-100">{{'register.defaultAddressPostalCode'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.default_address_postal_code ? 'is-invalid' :'' "
          [(ngModel)]="user.default_address_postal_code" [readonly]="type=='editing' || gritUser">
      </div>
    </div>

    <!-- 4th Row  -->
    <div class="row w-100 m-3">
      <div class="col-4 row d-flex align-items-center">
        <label class="col-5 pl-1 w-100">{{'register.registeredAddressPhNo'| translate}}*</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [ngClass]="errorMsg && errorMsg.phone_number_1 ? 'is-invalid' :'' "
          [(ngModel)]="user.phone_number_1" *ngIf="type=='editing' || gritUser" readonly>
          <input class="col-6 w-90 form-control p-3" type="number"
          [ngClass]="errorMsg && errorMsg.phone_number_1 ? 'is-invalid' :'' "
          [(ngModel)]="user.phone_number_1" *ngIf="type=='adding'">
      </div>
      <div class="col-4 row d-flex align-items-center">
        <label class="col-5 pl-1 w-100">{{'user.customerCode'| translate}}</label>
        <input class="col-6 w-90 form-control p-3" type="text"
          [(ngModel)]="user.customer_code"
          (ngModelChange)="removeWarning('customer_code')" [readOnly]="gritUser">
      </div>
      <div class="col-4 row d-flex align-items-center" *ngIf="gritUser">
        <h3 class="text-danger m-0">
          {{'delivery.onlyEtrMessage' | translate}}
        </h3>
      </div>
    </div>
  </div>


  <!-- Delivery Table  -->
  <div class="row w-100 my-3" *ngIf="user">
    <div class="row w-100 my-2 mx-5">
      {{ 'register.deliveryAddress' | translate}}
    </div>
    <div class="table-border px-2" *ngIf="!renderingTable">
      <table class="table borderless text-center">
        <thead>
          <th>{{ 'delivery.number' | translate}}</th>
          <th>{{ 'Delivery Name' | translate}}</th>
          <th>{{ 'delivery.postalCode' |translate }}</th>
          <th>{{ 'delivery.prefecture' |translate }}</th>
          <th class="text-left">{{ 'delivery.address' |translate }}</th>
          <th>{{ 'Delivery Phone Number' |translate }}</th>
          <th>{{ 'delivery.addressRemarks' |translate }}</th>
          <th></th>
        </thead>
        <tbody *ngIf="deliveryAddresses.length">
          <tr *ngFor="let address of deliveryAddresses; let i = index">
            <td class="v-middle"> {{ i + 1 }}</td>
            <td class="v-middle">
              <h6 *ngIf="editingIndex !== i">
                {{ address.name || '-' }}
              </h6>
              <input type="text"
                class="form-control"
                [ngClass]="{'is-invalid': editingIndex == i &&
                                          deliveryErrorMsg && deliveryErrorMsg.name }"
                [(ngModel)]="address.name"
                (ngModelChange)="removeError('name')"
                *ngIf="editingIndex === i ">
            </td>
            <td class="v-middle">
              <h6 *ngIf="editingIndex !== i">
                {{ address.delivery_address_postal_code || '-' }}
              </h6>
              <input type="text"
                class="form-control"
                [ngClass]="{'is-invalid': editingIndex == i &&
                                          deliveryErrorMsg && deliveryErrorMsg.delivery_address_postal_code }"
                [(ngModel)]="address.delivery_address_postal_code"
                (ngModelChange)="removeError('delivery_address_postal_code')"
                *ngIf="editingIndex === i ">
            </td>
            <td class="v-middle">
              <h6 *ngIf="editingIndex !== i">
                {{ address.delivery_address_prefecture || '-' }}
              </h6>
              <input type="text"
                class="form-control"
                [ngClass]="{'is-invalid': editingIndex == i &&
                                          deliveryErrorMsg && deliveryErrorMsg.delivery_address_prefecture }"
                [(ngModel)]="address.delivery_address_prefecture"
                (ngModelChange)="removeError('delivery_address_prefecture')"
                *ngIf="editingIndex === i ">
            </td>
            <td class="v-middle text-left">
              <h6 *ngIf="editingIndex !== i">
                {{ address.delivery_address || '-' }}
              </h6>
              <input type="text"
                class="form-control"
                [ngClass]="{'is-invalid': editingIndex == i &&
                                          deliveryErrorMsg && deliveryErrorMsg.delivery_address }"
                [(ngModel)]="address.delivery_address"
                (ngModelChange)="removeError('delivery_address')"
                *ngIf="editingIndex === i ">
            </td>
            <td class="v-middle">
              <h6 *ngIf="editingIndex !== i">
                {{ address.phone_number || '-' }}
              </h6>
              <input type="text"
                class="form-control"
                [(ngModel)]="address.phone_number"
                *ngIf="editingIndex === i ">
            </td>
            <td class="v-middle">
              <h6 *ngIf="editingIndex !== i">
                {{ address.delivery_address_remarks || '-' }}
              </h6>
              <input type="text"
                class="form-control"
                [(ngModel)]="address.delivery_address_remarks"
                *ngIf="editingIndex === i ">
            </td>
            <td class="v-middle">
              <div class="row" style="min-width: 80px;">
                <div class="w-50 clickable" (click)="editingIndex != i ? editRow(i) : saveRow(i)">
                  <i class="text-secondary fa-lg"
                  [ngClass]="editingIndex != i ? 'far fa-edit' : 'fas fa-check'"></i>
                </div>
                <div class="w-50 clickable" (click)="address.id ? openDeleteConfirmModal(i) : removeUnsavedRow(i)">
                  <i class="fa fa-trash text-secondary fa-lg"></i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!deliveryAddresses.length">
          <tr>
            <td colspan="8">
              <div class="w-100 row no-address">
                <div class="w-100 h-auto align-self-end mb-3">
                  <i class="fas fa-map-marked-alt" aria-hidden="true"></i>
                </div>
                <div class="row">
                  {{ 'delivery.deliveryAddressMessage' | translate}}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row w-100 m-2 my-4">
        <button class="btn btn-primary" (click)="addNewDeliveryAddress()">
          <i class="fa fa-plus"></i>
          {{ 'delivery.addDeliveryAddress' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="row w-100 my-3 mx-4 d-flex justify-content-end">
    <button class="btn btn-outline-secondary mr-3" (click)="goBack()"> {{'Cancel' | translate }}</button>
    <button class="btn btn-secondary" (click)="saveUser()"> {{'Save' | translate }}</button>
  </div>
</div>

<ng-template #changePasswordModal let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <p>Change Password</p>
      <show-hide-password btnStyle="secondary" [btnOutline]="false">
        <input type="password" class="form-control" [(ngModel)]="newPassword" />
      </show-hide-password>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="saveNewPassword()">Change</button>
  </div>
</ng-template>

<ng-template #emailModel>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0"> {{ 'user.email' | translate}} </h4>
    </div>
    <app-loading *ngIf="userEmailsLoading"></app-loading>
    <div class="modal-body mx-3 py-0 mb-3" *ngIf="!userEmailsLoading">
      <div class="row p-0 m-0 mt-3">
        <div class="col-12 p-0 border rounded-lg">
          <div class="row m-2 mx-3 p-0" *ngFor="let email of editingEmails; let idx = index">
            <div class="word-wrapper" *ngIf="editingEmailRow !== idx">
              {{email.email}}
            </div>

            <div class="w-75percent" *ngIf="editingEmailRow == idx">
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="editingEmail"
                [placeholder]="'seller.enterSellerEmail' | translate"
                (ngModelChange)="removeError()"
                [ngClass]="{'is-invalid' : emailError }">
            </div>

            <div class="w-25percent d-flex align-items-center justify-content-start"
              *ngIf="editingEmails.length">
              <img src="assets/svgs/blue_pen.svg" height="20" class="ml-2 clickable" width="auto"
                (click)="editEmailRow(idx)" *ngIf="editingEmailRow != idx">
              <img src="assets/svgs/red_trash.svg" height="20" class="ml-2 clickable" width="auto"
                (click)="email.id ? openConfirm(email.id) : removeEmail(idx)" *ngIf="editingEmailRow != idx">
              <img src="assets/svgs/blue_check.svg" height="20" class="ml-2 clickable" width="auto"
                (click)="saveEmailEdit()" *ngIf="editingEmailRow == idx">
              <img src="assets/svgs/red_cross.svg" height="20" class="ml-2 clickable" width="auto"
                (click)="resetEmailEdit(idx)" *ngIf="editingEmailRow == idx">
            </div>
          </div>

          <hr class="m-0 mx-3" *ngIf="editingEmails.length">
          <div class="row m-2 p-0 d-flex justify-content-end">
            <button class="btn btn-sm btn-outline-primary d-flex align-items-center"
             (click)="addNewEmail()">
              <img src="assets/svgs/blue_plus_icon.svg" height="20" width="auto">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditEmails()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="saveEmails()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>
