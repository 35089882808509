import {Component, OnInit, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Seller} from 'src/app/models/seller.model';
import {SellerService} from 'src/app/services/seller.service';
import {ProductSetService} from 'src/app/services/product-set.service';
import {Pagination} from 'src/app/components/pagination/pagination.interface';
import {getCurrentPage} from 'src/app/components/pagination/pagination.functions';
import {Subject} from 'rxjs';
import {HttpClientService} from '../../services/http-client.service';
import {ActivatedRoute, Router} from "@angular/router";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {API_CONSTANT} from "../../services/api.constants";
import {ToastrService} from "ngx-toastr";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.scss']
})
export class ProductMasterComponent implements OnInit, Pagination, OnDestroy {
  // New Seller
  sellerInput: Partial<Seller> = {
    name: '',
    email: ''
  };
  // Other stuff
  isLoading = false;
  destroy$ = new Subject();
  stockFile: File = null;
  sellerLoading = false;
  public loading = false;
  dataList = [];
  dataLength = 0;
  page = 1;
  pageCount = 1;
  type: string = 'Edit';
  productsetName: string = '';
  categoryID: number = null;
  editingID: number = null;
  categories = [];
  error: string = '';
  productsetNameList = []
  originalproductsetNameList = []
  productset_names = []
  auto_emptymsg = 'Result not found';
  stockErrorMessage = '';
  stockErrorList: Array<string> = null;
  stockImportSuccessful = false;
  stockImporting = false;

  constructor(
    private api: HttpClientService,
    private translate: TranslateService,
    private sellerService: SellerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private productSetService: ProductSetService,
    private modalService: NgbModal,
    private tostrService: ToastrService,
  ) {
  }

  importStock() {
    this.stockErrorMessage = '';
    this.stockErrorList = null;
    this.stockImportSuccessful = false;

    if (this.stockFile == null) {
      this.stockErrorMessage = this.translate.instant('fileIsEmpty');
      return;
    }

    this.stockImporting = true;
    const document = new FormData();
    document.append('file', this.stockFile);
    document.append('current_lang', this.translate.currentLang);

    this.api
      .post(API_CONSTANT.BASE_URL + '/import-product-update/', document)
      .subscribe(
        (res: any) => {
          this.stockImporting = false;
          this.stockImportSuccessful = true;
        },
        err => {
          this.stockImporting = false;
          let message = this.api.getErrorMessage(err);

          if (Array.isArray(message)) this.stockErrorList = message;
          else if (typeof message === 'string' || message instanceof String)
            this.stockErrorMessage = message.toString();
          else
            this.stockErrorMessage = this.translate.instant(
              'errorOccoredPleaseTryAgain'
            );
        }
      );
  }

  onStockFileInputChange(event: EventTarget) {
    this.stockFile = null;

    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files.length > 0) this.stockFile = files[0];
  }

  openStockModal(view) {
    this.stockFile = null;
    this.stockImportSuccessful = false;
    this.stockErrorMessage = '';
    this.stockErrorList = null;
    this.stockImporting = false;

    this.modalService.open(view, {ariaLabelledBy: 'modal-basic-title'});
  }

  ngOnInit() {
    getCurrentPage(this, this.loadProductSets);
    this.loadCategories()
    this.getAllProductSets()
  }

  reset() {
    this.productset_names = [];
    this.loadProductSets();
  }

  getAllProductSets() {
    this.productSetService.getAllProductsets().subscribe((res: any) => {
      this.originalproductsetNameList = res.productsets;
    })
  }

  onProductsetUnselectValue(event) {
    this.productset_names = this.productset_names.filter(
      (x: string) => x !== event
    );
  }

  onProductsetFiltering(e) {
    this.productsetNameList = []
    let searchingName = e.query.toLowerCase();
    this.productsetNameList = this.originalproductsetNameList.filter(x =>
      x.toLowerCase().indexOf(searchingName) >= 0)
  }

  async openDelete(content, item) {
    try {
      const result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title'
      }).result;
      this.deleteProductSet(item.id);
    } catch (e) {
    }
  }

  deleteProductSet(id) {
    this.productSetService.delete(id).subscribe(res => {
        this.tostrService.success(this.translate.instant('Deleted'));
        this.loadProductSets();
      },
      (error) => {
        this.tostrService.error(this.translate.instant('Deletion Failed'));
      }
    );
  }

  edit(productset, index: number) {
    this.router.navigate(['product-master/edit/' + productset.id + '/']);
  }

  submit() {
    const fd = new FormData();
    this.productsetName === '' ? this.error = 'Input Product Set Name' :
      this.categoryID === null ? this.error = 'Select Category' : this.error = '';
    this.productsetName !== '' ? fd.append('name', this.productsetName) : {};
    this.categoryID ? fd.append('category_id', this.categoryID.toString()) : {};
    if (this.type === 'add') {
      this.productSetService.create(fd).subscribe((res) => {
          this.loadProductSets();
          this.getAllProductSets();
          this.tostrService.success(this.translate.instant('Adding Successful'));
          this.dismissEditOrCreate();
        },
        (error) => {
          this.tostrService.error('Adding Failed');
        })
    } else if (this.type === 'edit' && this.error === '') {
      this.productSetService.update(this.editingID, fd).subscribe((res) => {
          this.loadCategories();
          this.tostrService.success(this.translate.instant('Updated successfully'));
          this.dismissEditOrCreate();
        },
        (error) => {
          this.tostrService.error(this.translate.instant('Update Failed'));
        })
    } else {
      this.tostrService.error(this.error);
    }
  }

  loadCategories() {
    this.api.get(API_CONSTANT.BASE_URL + '/categories/?all=true').subscribe(
      (res: any) => {
        this.categories = res;
        this.dataLength = res.length;
      },
      (err) => {
      }
    );
  }

  loadProductSets = async () => {
    this.loading = true;
    let param = new HttpParams()
    this.productset_names.length ?
      param = param.append('name', this.productset_names.toString()) : {};
    try {
      const res = await this.productSetService.get(param).toPromise();
      this.dataList = res.results;
      this.dataLength = res.count;
      this.pageCount = res.total_pages;
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  };

  addProductSet(view) {
    this.type = 'add';
    this.productsetName = '';
    this.openProductSetPopup(view);
  }

  openProductSetPopup(view) {
    this.modalService.open(view).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissEditOrCreate();
      }
    });
  }

  dismissEditOrCreate() {
    this.productsetName = '';
    this.editingID = null;
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  navigateTo(page: number) {
    this.page = page;
    this.router.navigateByUrl(`/product-master/${page}`);
  }
}
