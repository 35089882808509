<!-------------------------------Search Box--------------------------------->
<div class='w-100 p-3'>
  <div class='detail-box'>
    <!----------------- 1st Row ------------------->
    <div class='row no-gutter h-auto px-3 mh-80'>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Dealer' | translate}} : {{ orderDetail.order_snapshot.dealer_name || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Order Number' | translate}} : {{ orderDetail.puchese_id || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Order Date' | translate}} : {{ (orderDetail.created_at | date: 'yyyy-MM-dd') || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Order Remarks' | translate}} : {{ orderDetail.order_snapshot.remarks || '-' }}
      </div>
    </div>

    <!----------------- 2nd Row ------------------->
    <div class='row no-gutter h-auto px-3 mh-80'>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Buyer Username' | translate }} : {{ orderDetail.order_snapshot.username || '-'}}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Shop Name2' | translate }} : {{ orderDetail.order_snapshot.buyer_name || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Registered Address' | translate}} : {{ orderDetail.order_snapshot.address || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Phone Number' | translate}} : {{ orderDetail.order_snapshot.phone_number || '-' }}
      </div>
    </div>

    <!----------------- 3rd Row ------------------->
    <div class='row no-gutter h-auto px-3 mh-80'>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Delivery Name' | translate}} : {{ orderDetail.order_snapshot.delivery_name || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Delivery Address' | translate}} : {{ orderDetail.order_snapshot.delivery_address || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Delivery Phone Number' | translate}} : {{ orderDetail.order_snapshot.delivery_phone_number || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Delivery Address Remarks' | translate}} : {{ orderDetail.order_snapshot.delivery_remark || '-' }}
      </div>
    </div>
  </div>
</div>

<!---------------------------Items Table--------------------------------->
<div class='order-table px-3' *ngIf="!loadingOrderItems">
  <table
    class="table table-hover text-center table-borderless table-striped mb-3"
    style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
    *ngIf="!loadingOrderItems && orderItems.length">
    <thead>
    <tr>
      <th></th>
      <th>No</th>
      <th>{{ 'Status' | translate }}</th>
      <th>{{ 'Product Name' | translate }}</th>
      <th>{{ 'Product Code' | translate }}</th>
      <th>{{ 'Unit' | translate }}</th>
      <th>{{ 'Unit Price' | translate }}</th>
      <th>{{ 'Total Price' | translate }}</th>
      <th>{{ 'Planned Delivery Date' | translate }}</th>
      <th>{{ 'Confirm Delivered' | translate }}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of orderItems; let i = index;">
        <td class="align-middle detail-icn">
          <img
              src='assets/svgs/black_info_circle.svg'
               width='24'
               height='24'
              container='body'
               [placement]="['bottom-left', 'top-left']"
               [ngbTooltip]="tipContent"
               tooltipClass="my-custom-topic">
          <ng-template #tipContent>
            <div class='row no-gutter header'>
              {{'More Details' | translate }}
            </div>
            <div class='row no-gutter'>
              <div class='col-md-6 px-0 h-100 controlled-left'>
                <div class='w-100'>{{'Category Name' | translate}}</div>
                <div class='w-100'>{{'Updated by' | translate}}</div>
                <div class='w-100'>{{'Updated Time' | translate}}</div>
                <div class='w-100'>{{'Seller Email' | translate}}</div>
              </div>
              <div class='col-md-6 px-0 h-100 controlled-right middle-line'>
                <div class='w-100'>{{ item.category.category_name }}</div>
                <div class='w-100'>{{ item.updated_user_name }}</div>
                <div class='w-100'>{{ item.updated_at | date: "yyyy/MM/dd H:mm"}}</div>
                <div class='w-100'>{{ item.seller_email_snapshot.email_name }}</div>
              </div>
            </div>
          </ng-template>
        </td>

        <td class="align-middle">{{ i + 1 }}</td>
        <td class="align-middle px-0">
          <div class='shipment-status'
               [ngStyle]="{'background-color': shipmentColors[item.status - 1],
                            'color': shipmentFontColors[item.status -1]}">
            {{ shipmentStatus[item.status - 1]  | translate }}
          </div>
        </td>
        <td class="align-middle">{{ item.product_name }}</td>
        <td class="align-middle">{{ item.product_recommendation_name }}</td>
        <td class="align-middle">{{ item.quantity }}</td>
        <td class="align-middle">¥ {{ item.price | number: '1.0-0' }}</td>
        <td class="align-middle">¥ {{ item.price_total | number: '1.0-0' }}</td>
        <td class="align-middle px-0"
          [ngClass]="getLangNWidth()">
          <div class="d-flex align-items-center justify-content-around">
            <div class="col-auto round"
                 (click)="item.status === 1 ? openConfirmation(item.id, decidedOrNot, i, 2) : '' ">
              <input type="checkbox" id="{{ item.id }}"
                     [checked]="item.status === 2"
                     [disabled]='true'/>
              <label for="{{ item.id }}"></label>
            </div>
            <label class='col-auto no-gutter' [ngClass]="{'text-muted': item.decided}">
              {{'Not Decided' | translate}}
            </label>
            <div class="col-auto round"
                 (click)="item.status < 3 && openConfirmation(item.id, decidedOrNot, i, 3, d)">
              <input type="checkbox"
                     id="{{ item.id }} + 'sec'"
                     [checked]='item.status === 3 || item.status === 4'
                     [disabled]='true' />
              <label for="{{ item.id }} + 'sec'"></label>
            </div>
            <input
              type='text'
              class='col-4 px-1 min-width-100'
              (click)="(!item.delivery_date || d.isOpen()) ? d.toggle() : openConfirmation(item.id, decidedOrNot, i, 3, d)"
              name="dp"
              [placeholder]="item.delivery_date || '' "
              [(ngModel)]="item.delivery_date"
              [disabled]='item.status !== 3'
              (dateSelect)='updateDeliveryDate(item.id, i, item.delivery_date)'
              ngbDatepicker
              readonly
              #d="ngbDatepicker">
          </div>
        </td>

        <td class="align-middle px-0">
          <div class='row no-gutter w-100' *ngIf='item.status !== 5'>
            <div class='row no-gutter w-100 row no-gutter w-100 d-flex justify-content-center'
                 *ngIf='item.status === 4; else notdelivered'>
              <div class="col-auto round">
                <input type="checkbox" id="{{ item.id }} + 'delivered'" [checked]="true" disabled/>
                <label for="{{ item.id }} + 'delivered'" class='green'></label>
              </div>
              <label class='col-auto no-gutter'> {{ 'Delivered1' | translate}} </label>
            </div>
            <ng-template #notdelivered>
              <a href='javascript:void(0)' class='green-text w-100'
                 (click)='openConfirmation(item.id , decidedOrNot, i, 4)'>
                {{'Click If Delivered' | translate }}
              </a>
            </ng-template>
          </div>
          <div class='row no-gutter text-danger d-flex justify-content-center align-items-center'
               *ngIf='item.status === 5'>
            <img src='assets/svgs/red_circle.svg' width='30' height='30' class='mr-2'>
            {{'Cancelled' | translate}}
          </div>
        </td>

        <td class="align-middle px-0">
          <button *ngIf="isItemCancellable(item)" class="btn btn-danger mr-2" (click)="onCancelOrder(item.id)">
            {{ 'Cancel' | translate }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class='row no-gutter d-flex justify-content-center'
       *ngIf="!loadingOrderItems && !orderItems.length">
    <h1 class='text-muted'>
      {{ 'noDataFound' | translate }}
    </h1>
  </div>

  <div class='row no-gutter d-flex justify-content-end'>
    <div class='col-auto custom-width left'>
      <div class='row'>{{'Subtotal (without tax)' | translate }}</div>
      <div class='row'>{{'Shipping Fee (without tax)' | translate }}</div>
      <div class='row'>{{'Total Price (without tax)' | translate }}</div>
    </div>
    <div class='col-auto custom-width right'>
      <div>¥ {{ orderDetail.price_total | number: '1.0-0'}}</div>
      <div>¥ {{ orderDetail.transportation_fee | number: '1.0-0' }}</div>
      <div>¥ {{ orderDetail.totalAmount | number: '1.0-0' }}</div>
    </div>
  </div>
</div>
<app-loading class="w-100 my-3" *ngIf="loadingOrderItems"></app-loading>

<ng-template #decidedOrNot>
  <div class='modal-content'>
    <div class='modal-header'>
      {{'Confirmation' | translate }}
    </div>
    <div class='modal-body' *ngIf="modalName == 'not decided'">
      {{'Are you sure you want to select Not Decided?' | translate}}
    </div>
    <div class='modal-body' *ngIf="modalName == 'date selected'">
      {{'Are you sure you want to select the Delivery date?' | translate}}
    </div>
    <div class='modal-body' *ngIf="modalName == 'date reselected'">
      {{'Are you sure you want to re-select the Delivery date?' | translate}}
    </div>
    <div class='modal-body' *ngIf="modalName == 'other'">
      {{'Are you sure you want to change ?' | translate}}
    </div>
    <div class='modal-footer'>
      <button class='btn btn-secondary mr-1' (click)="dismissDecision()">
        {{ 'Cancel' | translate }}
      </button>
      <button class='btn btn-primary'
              (click)='save()'>
        {{ 'Sure' | translate }}
      </button>
    </div>
  </div>
</ng-template>
