<!-------------------------------Search Box--------------------------------->
<div class='w-100 p-3'>
  <div class='detail-box'>
    <!----------------- 1st Row ------------------->
    <div class='row no-gutter h-auto px-3 mh-80'>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'Order Date' | translate}} : {{ (purchaseOrder.order_date | date: 'yyyy-MM-dd') || '-' }}
      </div>
      <div class='col-4 row no-gutter align-self-center pr-4 break-all'>
        {{'Order Number' | translate}} : {{ purchaseOrder.order_number || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.remark1' | translate}} : {{ purchaseOrder.remarks1 || '-' }}
      </div>
    </div>

    <!----------------- 2nd Row ------------------->
    <div class='row no-gutter h-auto px-3 mh-80'>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.userID' | translate }} : {{ purchaseOrder.user_id || '-'}}
      </div>
      <div class='col-4 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.shop' | translate }} : {{ purchaseOrder.shop.name || '-' }}
      </div>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.remark2' | translate}} : {{ purchaseOrder.remarks2 || '-' }}
      </div>
    </div>

    <!----------------- 3rd Row ------------------->
    <div class='row no-gutter h-auto px-3 mh-80'>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.shopPostalCode' | translate}} : {{ purchaseOrder.shop.postal_code || '-' }}
      </div>
      <div class='col-5 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.shopAddress' | translate}} : {{ purchaseOrder.shop.address || '-' }}
      </div>
    </div>

    <!----------------- 4th Row ------------------->
    <div class='row no-gutter h-auto px-3 mh-80'>
      <div class='col-3 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.deliveryPostalCode' | translate}} : {{ purchaseOrder.delivery_destination_postal_code || '-' }}
      </div>
      <div class='col-5 row no-gutter align-self-center pr-4 break-all'>
        {{'monotaro.deliveryAddress' | translate}} : {{ purchaseOrder.delivery_destination_address || '-' }}
      </div>
    </div>
  </div>
</div>
<!---------------------------Items Table--------------------------------->
<div class='order-table px-3' *ngIf="!loadingOrderDetails">
  <table
    class="table table-hover text-center table-borderless table-striped mb-3"
    style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
    *ngIf="!loadingOrderDetails && purchaseOrderDetails.length">
    <thead>
    <tr>
      <th>No</th>
      <th>{{ 'monotaro.productName' | translate }}</th>
      <th>{{ 'monotaro.productCode' | translate }}</th>
      <th>{{ 'Unit' | translate }}</th>
      <th>{{ 'Unit Price' | translate }}</th>
      <th class="font-weight-bold">{{ 'monotaro.totalPriceWithTax' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of purchaseOrderDetails; let i = index;">
      <td class="align-middle">{{ i + 1 }}</td>
      <td class="align-middle">{{ item.product_name }}</td>
      <td class="align-middle">{{ item.product_code }}</td>
      <td class="align-middle">{{ item.unit }}</td>
      <td class="align-middle">{{ item.unit_price | currency: 'JPY' }}</td>
      <td class="align-middle">{{ item.total_price_with_tax | currency: 'JPY' }}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr class="font-weight-bold">
      <td colspan="4"></td>
      <td>{{'monotaro.totalPriceWithTax' | translate }}</td>
      <td>{{ purchaseOrder.total_price | currency: 'JPY'}}</td>
    </tr>
    </tfoot>
  </table>

  <div class='row no-gutter d-flex justify-content-center'
       *ngIf="!loadingOrderDetails && !purchaseOrderDetails.length">
    <h1 class='text-muted'>
      {{ 'noDataFound' | translate }}
    </h1>
  </div>
</div>
<app-loading class="w-100 my-3" *ngIf="loadingOrderDetails"></app-loading>
