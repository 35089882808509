import {Component, OnInit} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {ImportUpdateHistoryService} from "./import-update-history.service";
import {ModalDismissReasons, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";

import {ToastrService} from "ngx-toastr";
import * as _ from 'lodash';
import {API_CONSTANT} from "../../services/api.constants";
import {HttpClientService} from "../../services/http-client.service";
import {ExportFileService} from '../../services/export-file.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-import-update-history',
  templateUrl: './import-update-history.component.html',
  styleUrls: ['./import-update-history.component.scss']
})
export class ImportUpdateHistoryComponent implements OnInit {
  public loading = false;
  dataList = [];
  currentpage = 1;
  pageCount = 0;
  fileToUpload: File = null;
  admin = true;
  fileName = '';

  constructor(
    private modalService: NgbModal,
    private importUpdateHistoryService: ImportUpdateHistoryService,
    private notiService: ToastrService,
    private api: HttpClientService,
    private exportExcelService: ExportFileService,
    private translate: TranslateService
  ) {
  }


  ngOnInit(): void {
    this.getData();
    this.admin = this.isAdmin();
    this.getFileName();
  }

  isAdmin() {
    return JSON.parse(localStorage.getItem('isSuperuser'));
  }


  getFileName() {
    this.importUpdateHistoryService.getExportFileName().subscribe(
      (res: any) => {
        this.fileName = res;
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  getData() {
    this.loading = true;
    let params = new HttpParams();
    params = params.append('page', this.currentpage.toString());
    this.importUpdateHistoryService.getImportUpdateHistory(params).subscribe(
      (res: any) => {
        this.pageCount = res.total_pages;
        this.dataList = res.results;
        this.loading = false;
        console.log(this.dataList)
      },
      (error) => {
        this.showErrorMsg(error);
      }
    )
  }

  openModal(view, user = null) {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalService.open(view, option).result.then((result) => {
    }, (reason) => {
      if (reason === ModalDismissReasons.ESC ||
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.dismissFileSelection();
      }
    });
  }

  export() {
    const api = '/export-import-update-history/';
    this.api.postFile(`${API_CONSTANT.BASE_URL}${api}`, {}).subscribe(
      (res) => this.exportExcelService.saveFile(res),
      (err) => this.showErrorMsg(err)
    );
  }

  uploadFile() {
    const data = new FormData();
    data.append('file', this.fileToUpload);
    this.importUpdateHistoryService.importImportUpdateHistory(data).subscribe(
      () => {
        this.modalService.dismissAll();
        this.notiService.success(this.translate.instant('Successfully Imported'));
        this.getData();
      },
      (err) => {
        this.showErrorMsg(err);
      }
    )
  }

  dismissFileSelection() {
    this.fileToUpload = null;
    this.modalService.dismissAll();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  showErrorMsg(msg) {
    this.notiService.error(_.values(msg.error)[0].toString());
  }
}

