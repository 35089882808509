import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DealerCustomerType } from 'src/app/models/customerTypes.model';
import { DealerService } from '../dealer/dealer.service';
import {
  DealerEditItem,
  DealerList,
  EditDealerSettingBody,
} from './dealer-setting.model';
import { DealerSettingService } from './dealer-setting.service';
import {DealerNames} from "../dealer/dealer.model";
import { Router } from '@angular/router';

@Component({
  selector: 'app-dealer-setting',
  templateUrl: './dealer-setting.component.html',
  styleUrls: ['./dealer-setting.component.scss'],
})
export class DealerSettingComponent implements OnInit {
  loading: boolean = false;
  saving: boolean = false;
  loadingCompany: boolean = false;

  file: File = null;
  dealerType = null;
  importing: boolean = false;
  dealerCustomerType = [
    {
      name: 'Big Dealer',
      jp_name: '大手ディーラー',
      value: DealerCustomerType['Big Dealer'],
    },
    {
      name: 'General User',
      jp_name: '一般ユーザー',
      value: DealerCustomerType['General User'],
    },
  ];

  dealerSettingData: DealerList[] = [];
  editingData: DealerEditItem = new DealerEditItem();
  companyList: string[] = [];
  selectCompanyName: string = null;

  constructor(
    private modalService: NgbModal,
    private dealerService: DealerService,
    private notification: ToastrService,
    private translate: TranslateService,
    private dealerSettingService: DealerSettingService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getDealerSettingData();
    this.getAllCompanyNameList();
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  openImportModal(view): void {
    this.file = null;
    this.dealerType = 0;
    this.modalService.open(view);
  }

  openEditModal(view, dealers: DealerList): void {
    this.editingData = new DealerEditItem();
    dealers.dealers.forEach((item) => {
      this.editingData.name_list.push(item.full_name);
      this.editingData.id_list.push(item.id);
      this.editingData.is_daily_check_dealer = item.is_daily_check_dealer;
      this.editingData.is_monotaro_enabled = item.is_monotaro_enabled;
      this.editingData.is_purchasing_function = item.is_purchasing_function;
      this.editingData.is_etr_sugkik = item.is_etr_sugkik;
    });
    this.modalService.open(view);
  }

  handleFileInput(files: FileList): void {
    this.file = files.item(0);
  }

  close(): void {
    this.modalService.dismissAll();
  }

  uploadFile(): void {
    this.importing = true;
    this.dealerService.import(this.file, this.dealerType).subscribe(
      (res) => {
        this.importing = false;
        this.getDealerSettingData();
        this.router.navigate(['/import-product']);
        this.modalService.dismissAll();
      },
      (err) => {
        this.importing = false;
        this.notification.error(this.translate.instant('Upload Failed'));
      }
    );
  }

  getDealerSettingData(): void {
    this.loading = true;
    let params = new HttpParams();
    params = params.append(
      'name',
      this.selectCompanyName ? this.selectCompanyName : ''
    );
    this.dealerSettingService.getDealerSettingList(params).subscribe(
      (res: DealerList[]) => {
        this.dealerSettingData = res;
        this.loading = false;
      },
      (err) => {
        this.notification.error(err);
        this.loading = false;
      }
    );
  }

  resetSearch(): void {
    this.selectCompanyName = null;
    this.getDealerSettingData();
  }

  saveEditData(): void {
    this.saving = true;
    const data: EditDealerSettingBody = {
      id: this.editingData.id_list,
      is_daily_check_dealer: this.editingData.is_daily_check_dealer,
      is_monotaro_enabled: this.editingData.is_monotaro_enabled,
      is_purchasing_function: this.editingData.is_purchasing_function,
      is_etr_sugkik: this.editingData.is_etr_sugkik
    };
    this.dealerSettingService.editDealerSetting(data).subscribe(
      (res) => {
        this.close();
        this.saving = false;
        this.getDealerSettingData();
      },
      (err) => {
        this.notification.error(err);
        this.close();
        this.saving = false;
      }
    );
  }

  getAllCompanyNameList() {
    this.loadingCompany = true;
    this.dealerSettingService.getAllCompanyName().subscribe(
      (res: DealerNames) => {
        this.companyList = res.dealers;
        this.loadingCompany = false;
      },
      (err) => {
        this.notification.error(err);
        this.loadingCompany = false;
      }
    );
  }
}
