export const DailyCheckItemsConstants = {
  1: 'Basic 8 Items', // type = 1
  2: 'Brake Fluid', // type = 2
  3: 'Cooling Water', // type = 3 
  4: 'Battery', // type = 4
  5: 'Engine Oil', // type = 5
  6: 'AV/CVT Fluid', // type = 6
  7: 'Washer Fluid', // type = 7
  8: 'Wiper Blade',// type = 8
  9: 'Tire', // type = 9
  10: 'Air-Clean Filter', // type = 10
  11: 'Car Wash', // type = 11
  12: 'Coating', // type = 12
  13: 'Repair', // type = 13
  14: 'ENEOS Electric', // type = 14
  15: 'ENEOS Card', // type = 15
  16: 'EneKey', // type = 16
  17: 'Engine Cleaner', // type = 17
  18: 'Car Inspection', // type = 18
  19: 'LINE Member', // type = 19
  20: 'Others 1', // type = 20
  21: 'Others 2', // type = 21
  22: 'Others 3', // type = 22
  25: 'Absolute Number' // type = 25
}