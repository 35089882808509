import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { RouterModule } from '@angular/router';
import { ListEmptyComponent } from '../list-empty/list-empty.component';
import { LoadingComponent } from '../loading/loading.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EnterInputDirective } from './enter-input.directive';
import { PaginationComponent } from '../pagination/pagination.component';
import {
  NgbDatepickerModule,
  NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { FileLoadingComponent } from '../fileLoading/fileLoading.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    HeaderComponent,
    ListEmptyComponent,
    LoadingComponent,
    EnterInputDirective,
    PaginationComponent,
    DateFilterComponent,
    FileLoadingComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    TranslateModule
  ],
  exports: [
    HeaderComponent,
    ListEmptyComponent,
    LoadingComponent,
    PaginationComponent,
    DateFilterComponent,
    FileLoadingComponent,
    ConfirmModalComponent
  ],
  providers: [],
  entryComponents: [ConfirmModalComponent]
})
export class CoreModule {}
