import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../app/shared.module';
import { CoreModule } from '../../../app/components/core/core.module';
import { CalculationHistoryRoutesModule } from './calculation-history-routing.module';
import { CalculationHistoryComponent } from './calculation-history.component';
import {
  NgbDatepickerModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CalculationHistoryComponent],
  imports: [
    CommonModule,
    CalculationHistoryRoutesModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    CoreModule,
    FormsModule,
    SharedModule,
  ],
})
export class CalculationHistoryModule {}
