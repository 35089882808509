import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/services/http-client.service';
import { API_CONSTANT } from 'src/app/services/api.constants';
import { Shop } from '../models/shop.model';
import { Observable } from 'rxjs';
import { PaginatedResult } from 'src/app/components/pagination/pagination.interface';
import { pluck } from 'rxjs/operators';
import {Staff} from '../features/settings/settings.model';

@Injectable({
  providedIn: 'root'
})
/** @description service providing user data */
export class ShopService {
  static URL = API_CONSTANT.BASE_URL + '/shops/';
  static staffURL = API_CONSTANT.BASE_URL + '/staffs/';
  static CTAETRShopListURL = API_CONSTANT.BASE_URL + '/get-cta-etr-shops/';

  constructor(private api: HttpClientService) {}

  get(params?: any): Observable<PaginatedResult<Shop>> {
    return this.api.get(ShopService.URL, params);
  }

  getAll(params?: any): Observable<Shop[]> {
    return this.api.get(ShopService.URL, {
      all: true,
      ...params
    });
  }

  getAllShopsName(params?: Object) {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-all-shops/', params);
  }

  getAllStaff(params?: any): Observable<Staff[]> {
    return this.api.get(ShopService.staffURL, {
      ...params
    });
  }

  getShopByID(id): Observable<any> {
    return this.api.get(API_CONSTANT.BASE_URL + '/shops/' + id + '/');
  }

  searchShops(params?: any): Observable<Shop[]> {
    return this.api
      .get<{ results: Shop[] }>(
        API_CONSTANT.BASE_URL + '/search-shops/',
        params
      )
      .pipe(pluck('results'));
  }

  importStaffs(data?) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-staffs/', data);
  }

  importDealerSettings(data?) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-dealer-settings/', data);
  }

  updateSalePackage(data: object): Observable<Shop> {
    return this.api.patch(
      API_CONSTANT.BASE_URL + '/update-shop-package/',
      data
    );
  }

  update(id: number, data: Partial<Shop>, params?: any): Observable<Shop> {
    return this.api.patch(ShopService.URL + `${id}/`, data);
  }

  create(data: Partial<Shop>) {
    return this.api.post(ShopService.URL, data);
  }

  createStaff(data: Partial<Staff>) {
    return this.api.post(ShopService.staffURL, data);
  }

  updateStaff(id: number, data: Partial<Staff>, params?: any): Observable<Staff> {
    return this.api.patch(ShopService.staffURL + `${id}/`, data);
  }

  delete(data: Partial<Shop>) {
    return this.api.delete(ShopService.URL + `${data.id}/`);
  }

  deleteStaff(id: number) {
    return this.api.delete(ShopService.staffURL + `${id}/`);
  }

  getCTAETRShopList(params: Object) {
    return this.api.get(ShopService.CTAETRShopListURL, params);
  }
}
