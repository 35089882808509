<app-header [title]="'ENEOSトレーディング株式会社' | translate">
</app-header>
<!--background-color: #485460-->
<div class="row p-0 m-3 d-flex justify-content-end">
  <button *ngIf="subSellerID" class="btn btn-primary d-flex align-items-center"
          (click)="addEmail(editOrCreate)">
    <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
    {{ 'Add Email' | translate }}
  </button>
</div>

<div class="row p-0 ml-3 mr-2">
  <div class="px-0 col-auto">
    <div class="row p-0 m-0 h-100">
      <div class="col-auto p-0" *ngFor="let item of subSellerList">
        <div class="tab" [ngClass]="{'active' : productString == item.name}" (click)="onProductSelect(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-auto d-flex justify-content-end align-self-center">
    <button class="btn btn-secondary d-flex align-items-center"
            (click)="openSubSellerPopup(addSubSeller)">
      <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
      {{ 'Add' | translate }}
    </button>
  </div>
</div>

<app-loading style="position: center;vertical-align: middle; text-align: center;" *ngIf="loading"></app-loading>
<app-list-empty *ngIf="!loading && !dataLength" title="{{ 'noDataFound' | translate }}"></app-list-empty>
<div class="row mb-4 mx-3">
  <table class="table table-hover text-center table-striped" style="border-radius: 5px;" *ngIf="!loading && dataLength">
    <thead class="thead-light">
    <tr>
      <th>{{ 'seller.number' | translate }}</th>
      <th>{{ 'Shop Name' | translate }}</th>
      <th>{{ 'Seller Email' | translate }}</th>
      <th>{{ 'edit' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="
                  let item of sellerList
                    | slice: (page - 1) * 40:(page - 1) * 40 + 40; let i = index;">
      <td *ngIf="page==1" class="align-middle">{{ i + 1 }}</td>
      <td *ngIf="page>1" class="align-middle">{{ i + (40 * (page - 1)) + 1 }}</td>
      <td class="align-middle">{{ item.shop_name }}</td>
      <td *ngIf="item.id !== 23" class="align-middle">{{ item.email }}</td>
      <td *ngIf="item.id === 23" class="align-middle" style="color: red">click the email list button for details</td>
      <td *ngIf="item.id !== 23" class="v-middle">
        <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
             (click)="editEmail(editOrCreate, item)">
      </td>
      <td *ngIf="item.id === 23" class="v-middle" (click)="edit()">
        <button class="btn btn-primary d-flex align-items-center"> Click me</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>


<ng-template #editOrCreate>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0" *ngIf="type=='add'"> {{ 'Add Email' | translate}}</h4>
      <h4 class="m-0" *ngIf="type=='edit'"> {{ 'Edit Email' | translate}}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-2">
      <div *ngIf="type=='add'" class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{'Shops' | translate}}</label>
        <ng-select
          *ngIf="type!=='add'"
          class="col-8 w-100 p-0"
          [clearable]="false"
          [items]="shops"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="shoppID"
          [placeholder]="'user.selectShop' | translate"
          [notFoundText]="'notFound' | translate"
        ></ng-select>
        <ng-select
          *ngIf="type=='add'"
          class="col-8 w-100 p-0"
          [clearable]="false"
          [items]="shops"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="shopID"
          [placeholder]="'user.selectShop' | translate"
          [notFoundText]="'notFound' | translate"
        ></ng-select>
      </div>

      <div class="row p-0 m-0 mt-4">
        <label class="col-4 p-0 m-0 d-flex align-items-center">{{'shop.email' | translate}}</label>
        <input type="text" class="col-8 form-control" [(ngModel)]="email"
               [placeholder]="'Input Email' | translate">
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="submit()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>


<ng-template #addSubSeller>
  <div class="modal-content">
    <div class="modal-header border-bottom-0">
      <h4 class="m-0" *ngIf="type=='add'"> {{  'Add Sub Seller' | translate }}</h4>
    </div>
    <div class="modal-body mx-3 py-0 mb-3">
      <div class="row p-0 m-0 mt-3">
        <label class="col-4 p-0 m-0 d-flex align-items-center">
          {{ 'Sub Seller Name' | translate }}
        </label>
        <input type="text" class="col-8 form-control" [(ngModel)]="subSellerName"
               [placeholder]="'Sub Seller Name' | translate"
               (ngModelChange)="removeSaveError('name')"
               [ngClass]="{'is-invalid' : saveError['name'] }">
      </div>
    </div>
    <div class="modal-footer border-top-0 mr-3">
      <button class="btn btn-secondary" (click)="dismissEditOrCreate()">{{'Cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="save()">{{'Save' | translate}}</button>
    </div>
  </div>
</ng-template>
