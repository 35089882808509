import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {SettingsComponent} from './settings.component';
import {EditShopSettingsComponent} from './edit-shop-settings/edit-shop-settings.component';


export const settingRoutes: Routes = [
  {
    path: 'settings',
    children: [
      {path: '', component: SettingsComponent},
      { path: 'edit/:id/', component: EditShopSettingsComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(settingRoutes)],
  exports: [RouterModule]
})
export class SettingRoutes {
}
