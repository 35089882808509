import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  headers: HttpHeaders;

  constructor(private http: HttpClient, private router: Router) {}

  checkError<T>(apiFunction: Observable<T>): Observable<T> {
    return new Observable<T>(observer => {
      apiFunction.subscribe(
        res => {
          observer.next(res);
          observer.complete();
        },
        err => {
          if (
            _.get(err, 'error.detail') === 'Signature has expired.' ||
            _.get(err, 'error.detail') === 'Error decoding signature.' ||
            _.get(err, 'error.detail') === 'Invalid signature.' ||
            _.get(err, 'error.detail') === 'User account is disabled.' ||
            _.get(err, 'error.detail') === 'บัญชีผู้ใช้ถูกระงับ'
          ) {
            localStorage.clear();
            this.router.navigate(
              ['login', 'Login expired. Please login again'],
              { skipLocationChange: true }
            );
          }
          if (err.status == 401) {
            this.router.navigate(
              ['login', 'Unauthorized, Please login again'],
              { skipLocationChange: true }
            );
          }
          observer.error(err);
        }
      );
    });
  }

  checkErrorFile<T>(apiFunction) {
    return new Observable<T>(observer => {
      apiFunction.subscribe(
        res => {
          observer.next(res);
          observer.complete();
        },
        err => {
          if (
            _.get(err, 'error.detail') === 'Signature has expired.' ||
            _.get(err, 'error.detail') === 'Error decoding signature.' ||
            _.get(err, 'error.detail') === 'Invalid signature.' ||
            _.get(err, 'error.detail') === 'User account is disabled.' ||
            _.get(err, 'error.detail') === 'บัญชีผู้ใช้ถูกระงับ'
          ) {
            localStorage.clear();
            this.router.navigate(
              ['login', 'Login expired. Please login again'],
              { skipLocationChange: true }
            );
          }
          observer.error(err);
        }
      );
    });
  }

  getErrorMessage(error): any {
    if (error != null && error.error != null && error.error.message != null) {
      return error.error.message;
    }

    if (error != null && error.error != null) {
      return error.error;
    }
    // error.message is default message in angular
    if (error != null && error.message != null) {
      return error.message;
    }

    return null;
  }

  postFile(url: string, data: any, params?: any): Observable<ArrayBuffer> {
    return this.checkErrorFile(
      this.http.get(url, {
        params: data,
        headers: this.headers,
        responseType: 'arraybuffer'
      })
    );
  }

  get<T>(url: string, params?: any): Observable<T> {
    return this.checkError<T>(
      this.http.get<T>(url, { params: params, headers: this.headers })
    );
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.checkError<T>(
      this.http.post<T>(url, body, { headers: this.headers })
    );
  }

  patch<T>(url: string, body: any): Observable<T> {
    return this.checkError<T>(
      this.http.patch<T>(url, body, { headers: this.headers })
    );
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.checkError<T>(
      this.http.put<T>(url, body, { headers: this.headers })
    );
  }

  delete<T>(url: string): Observable<T> {
    return this.checkError<T>(
      this.http.delete<T>(url, { headers: this.headers })
    );
  }
}
