<app-header title="{{ 'shop-actual-stock.shopActualStock' | translate }}">
</app-header>

<div class="row w-100 p-3 px-4 m-0 d-flex justify-content-end">

  <div class="d-flex align-items-start justify-content-between">
    <button class="btn mt-4 mr-2 btn-sm btn-primary d-flex align-items-center"
     (click)="importAcutalStock(importStock)">
      <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
      {{ 'Import' | translate }}
    </button>
  </div>
</div>

<div class="row mb-4 ml-1 mr-1">
  <div class="col-md-12">
    <div>
      <app-loading
        style="position: center;vertical-align: middle; text-align: center;"
        *ngIf="loading"
      ></app-loading>
      <app-list-empty
        *ngIf="!loading && !productlistLength"
        title="{{ 'noDataFound' | translate }}"
      ></app-list-empty>
      <div class="mt-3 mb-4">
        <table
          class="table table-hover text-center table-striped table-borderless"
          *ngIf="!loading && productlistLength">
          <thead class="thead-light">
            <tr>
              <th>
                {{ 'shop-actual-stock.Shop' | translate }}
              </th>
              <th>{{ 'shop-actual-stock.CategoryName' | translate }}</th>
              <th>
                {{ 'product-import.date' | translate }}
              </th>
              <th>
                {{ 'File Name' | translate }}
              </th>
              <th>
                {{ 'product-import.status' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of productList
                  | slice: (page - 1) * 40:(page - 1) * 40 + 40
              "
            >
              <td class="align-middle">
                {{ item.display.shop_name || '-'  | translate}}
              </td>
              <td class="align-middle">
                {{ item.display.category_name || '-'}}
              </td>
              <td class="align-middle">
                {{item.created_at | date: 'yyyy-MM-dd h:mm a' : '+0900' || ''}}
              </td>

              <td class="align-middle">
                {{item.file_name}}
              </td>
              <td ngClass="align-middle {{ item.processing }}"
                  (click)="item.processing === 'Failed' ? openErrorPopup(errPopup, item.message) : {}">
                {{ item.processing }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class='d-flex'>
          <ngb-pagination
            [collectionSize]="count"
            [(page)]="currentPage"
            [maxSize]="5"
            [pageSize]="40"
            (pageChange)='navigateTo($event)'
            [boundaryLinks]="true"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #errPopup let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{'Message' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body reason-box mb-3" *ngIf="reason.length">
      <div class="row no-gutter mb-1" *ngFor="let message of reason">
        {{ message }}
      </div>
    </div>
    <div class="modal-body mb-3" *ngIf="!reason.length">
      {{'noErrorMessage' | translate}}
    </div>
  </div>
</ng-template>


<ng-template #importStock let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'shop-actual-stock.uploadFile' | translate }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row no-gutters">
      <div class="col-4 d-flex align-items-center">{{"product-import.category" | translate}}</div>
      <ng-select class="col-8 w-100 p-0"
          [placeholder]="'product-set.selectCategory' | translate"
          [notFoundText]="'notFound' | translate"
          bindLabel="number_and_name"
          [clearable]="false"
          bindValue="id"
          [items]="categories"
          [(ngModel)]="categoryID"></ng-select>
    </div>
    <form class="p-t-15">
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="uploadFileError"
        >
          {{ 'Cannot upload file' | translate }}. {{ 'Error' | translate }}: {{ uploadFileError }}
        </div>
        <input class="form-control-file"
               type="file"
               (change)="chooseFile($event)"
               accept=".xlsx">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="uploadStockFile()"
      translate
      [disabled]="!importFile || !categoryID"
    >
      {{ 'Import' | translate }}
    </button>
  </div>
</ng-template>
