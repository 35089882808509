import { Injectable } from '@angular/core';
import {HttpClientService} from '../../services/http-client.service';
import {API_CONSTANT} from '../../services/api.constants';
import {
  DailyCheckFirstLayerResponse, DailyCheckResponse,
  DailyCheckSecondLayer,
  DailyCheckThirdLayer, DailyCheckThirdLayerResponse,
  FlyerFirstLayerItems,
  FlyerSecondLayerItems,
  SearchData, StaffFlyer
} from './daily-check.models';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DailyCheckHistoryService {

  parentParams = new BehaviorSubject<SearchData>(null);

  constructor(
    private http: HttpClientService
  ) { }

  getDailyCheckFirstLayer(param?: object): Observable<DailyCheckFirstLayerResponse> {
    return this.http.get(API_CONSTANT.BASE_URL + '/daily-check-history/first-layer/', param);
  }

  getDailyCheckSecondLayer(param: object): Observable<DailyCheckResponse> {
    return this.http.get(API_CONSTANT.BASE_URL + '/daily-check-history/second-layer/', param);
  }

  getDailyCheckThirdLayer(param: object): Observable<DailyCheckThirdLayerResponse> {
    return this.http.get(API_CONSTANT.BASE_URL + '/daily-check-history/third-layer/', param);
  }

  getFlyerDistributionFirstLayer(param?: object): Observable<FlyerFirstLayerItems[]> {
    return this.http.get(API_CONSTANT.BASE_URL + '/flyer-distribution/first-layer/', param);
  }

  getFlyerDistributionSecondLayer(param?: object): Observable<FlyerSecondLayerItems[]> {
    return this.http.get(API_CONSTANT.BASE_URL + '/flyer-distribution/second-layer/', param);
  }

  getStaffFlyerDistribution(param?: object): Observable<StaffFlyer[]> {
    return this.http.get(API_CONSTANT.BASE_URL + '/daily-check-staff/', param);
  }

  exportDailyChecks(params: Object): Observable<ArrayBuffer> {
    return this.http.postFile(API_CONSTANT.BASE_URL + '/export-daily-check/', params);
  }

  exportStaff(params: Object): Observable<ArrayBuffer> {
    return this.http.postFile(API_CONSTANT.BASE_URL + '/export-flyer-distribution-with-staff/', params);
  }

  exportFlyerDistribution(params: Object): Observable<ArrayBuffer> {
    return this.http.postFile(API_CONSTANT.BASE_URL + '/export-flyer-distribution/', params);
  }

  getMonthlyVisit(params: Object) {
    return this.http.get(API_CONSTANT.BASE_URL + '/get-monthly-visit/', params);
  }

  getStaffWorkingHour(params: Object) {
    return this.http.get(
      API_CONSTANT.BASE_URL + '/staff-working-hour/',
      params
    );
  }

  updateStaffWorkingHour(body: Object) {
    return this.http.post(API_CONSTANT.BASE_URL + '/staff-working-hour/', body);
  }
}
