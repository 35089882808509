<app-header [title]="'user.User' | translate"> </app-header>
<div class="w-100">

  <div class="row mx-4 p-0 d-flex justify-content-end my-4">
    <!-- UserNameSearch  -->
    <div class="w-25percent row p-0 m-0">
      <label class="row w-100 m-0 p-0 align-self-center" for="userName">
        {{'Buyer Username' |translate}}:
      </label>
      <div class="row w-100 p-0 m-0 mr-3">
        <p-autoComplete
          class="w-100 h-100"
          [(ngModel)]="user_names"
          [multiple]="true"
          [suggestions]="userNameList"
          [field]=""
          [placeholder]="'Search Buyer Username' | translate"
          [emptyMessage]="auto_emptymsg"
          [forceSelection]="true"
          (completeMethod)="onUserFiltering($event)"
          (onUnselect)="onUserUnselectValue($event)"
        >
          <ng-template let-value pTemplate="selectedItem">
            <span class="ui-autocomplete-token-label">{{ value }}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <!-- ShopNameSearch  -->
    <div class="w-25percent row p-0 m-0">
      <label class="row w-100 m-0 p-0 align-self-center" for="shopName">
        {{'Shop' |translate}}:
      </label>
      <div class="row w-100 p-0 m-0 mr-3">
        <p-autoComplete
          class="w-100 h-100"
          [(ngModel)]="shop_names"
          [multiple]="true"
          [suggestions]="shopNameList"
          [field]=""
          [placeholder]="'user.shopnamePlaceholder' | translate"
          [emptyMessage]="auto_emptymsg"
          [forceSelection]="true"
          (completeMethod)="onShopFiltering($event)"
          (onUnselect)="onShopUnselectValue($event)"
        >
          <ng-template let-value pTemplate="selectedItem">
            <span class="ui-autocomplete-token-label">{{ value }}</span>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <!-- Four Buttons  -->
    <div class="w-50percent d-flex align-items-center justify-content-between p-0 pt-4">
      <div class="d-flex align-items-start">
        <button class="btn btn-sm btn-primary mr-2  d-flex align-items-center"
         (click)="loadUsers()">
          <img src="assets/svgs/search_len.svg" height="20" class="mr-1" width="auto">
          {{'search' | translate}}
        </button>
        <button class="btn btn-sm btn-secondary mr-2  d-flex align-items-center"
         (click)="reset()">
          <img src="assets/svgs/reset_icon.svg" height="20" class="mr-1" width="auto">
          {{'reset' | translate}}
        </button>
      </div>
      <div class="d-flex align-items-start">
        <button id="LoginListButton" class="btn btn-sm btn-outline-primary mr-2 d-flex align-items-center"
         (click)="exportLoginList()" [disabled]="loading">
          <i class="far fa-file mr-2" style="font-size: 16px;"></i>
          {{ 'Login list' | translate }}
        </button>
        <button id="addUserButton" class="btn btn-sm btn-primary  d-flex align-items-center"
         (click)="addNewUser()" [disabled]="loading">
          <img src="assets/svgs/plus_icon.svg" height="20" class="mr-1" width="auto">
          {{ 'user.addUser' | translate }}
        </button>
      </div>
    </div>
  </div>

  <app-loading *ngIf="loading"></app-loading>

  <app-list-empty
    title="{{ 'noDataFound' | translate }}"
    *ngIf="!loading && !userList.length">
  </app-list-empty>

  <div class="row my-3 mx-4 p-0">
    <div class="custom-border w-100">
      <table class="table table-hover text-center borderless table-striped" *ngIf="!loading && userList.length">
        <thead>
          <tr>
            <th class="v-middle" width="18%" style="border-top-left-radius: 10px;">{{ 'Buyer Username' | translate }}</th>
            <th class="v-middle" width="18%">{{ 'user.email' | translate }}</th>
            <th class="v-middle" width="18%">{{ 'Shop' | translate }}</th>
            <th class="v-middle" width="18%">{{ 'user.registerdAddress' | translate }}</th>
            <th class="v-middle" width="18%">{{ 'product-set.pricePackageName' | translate }}</th>
            <th class="v-middle" width="5%">{{ 'user.edit' | translate }}</th>
            <th class="v-middle" width="5%" style="border-top-right-radius: 10px;">{{ 'Delete' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of userList; let i = index">
            <td class="align-middle">
              {{ user.username || '-' }} <br>
              <span class="font-weight-bold">
                ({{ user.is_grit ? 'Grit' : user.user_type == 0 ? 'Buyrect' : 'ETR' }})
              </span>
            </td>
            <td class="align-middle">{{ user.email || '-' }}</td>
            <td class="align-middle">{{ user.shop_name || '-' }}</td>
            <td class="align-middle">
              {{ user.default_address_postal_code || '-' }}<br />
              {{ user.default_address_prefecture || '-' }}<br />
              {{ user.default_address || '-' }}
            </td>
            <td class="align-middle">{{ user.shops__package_set__name || '-' }}</td>
            <td class="align-middle">
              <img src="assets/svgs/edit_pen.svg" height="28" width="auto"
                (click)="edit(user, i)">
            </td>
            <td class="align-middle" (click)="!user.is_grit ? openForDelete(user, i) : '' ">
              <img src="assets/svgs/grey_trash.svg" height="28" width="auto" *ngIf="user.is_grit">
              <img src="assets/svgs/trash_bin.svg" height="28" width="auto" *ngIf="!user.is_grit">
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex">
      <ngb-pagination
        [collectionSize]="dataLength"
        [(page)]="currentPage"
        [pageSize]="40"
        [maxSize]="5"
        (pageChange)="loadUsers()"
        *ngIf="!loading && dataLength"
      ></ngb-pagination>
    </div>
  </div>
</div>
