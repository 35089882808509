import { Injectable } from "@angular/core";
import { API_CONSTANT } from "../../services/api.constants";
import { HttpClientService } from "../../services/http-client.service";
import { Observable } from "rxjs";
import { PaginatedResult } from "src/app/components/pagination/pagination.interface";
import { ApprovalModel, BulkDeleteParams } from "./supervisor.model";


@Injectable({
    providedIn: 'root'
})
export class ApprovalService {
    static URL = API_CONSTANT.BASE_URL + '/approval-route/';

    constructor(private api: HttpClientService) {
    }

    get(params?: any): Observable<PaginatedResult<ApprovalModel>> {
        return this.api.get(ApprovalService.URL, params);
    }

    saveOrUpdate(approvalRoute: ApprovalModel): Observable<any> {
        if (approvalRoute.id) {
            return this.patch(approvalRoute);
        } else {
            return this.create(approvalRoute);
        }
    }

    bulkCreate(approvalRoutes: ApprovalModel[]): Observable<any> {
        return this.api.post(ApprovalService.URL, approvalRoutes);
    }

    create(approvalRoute: ApprovalModel): Observable<any> {
        return this.api.post(ApprovalService.URL, approvalRoute);
    }

    patch(approvalRoute: ApprovalModel): Observable<any> {
        const id = approvalRoute.id;
        delete approvalRoute.id; // Remove the id property from the object to avoid sending it in the body
        return this.api.patch(`${ApprovalService.URL + id}/`, approvalRoute);
    }

    delete(id: number): Observable<any> {
        return this.api.delete(`${ApprovalService.URL + id}/`);
    }

    bulkDelete(ids: BulkDeleteParams): Observable<any> {
        return this.api.post(ApprovalService.URL + 'bulk-delete/', ids);
    }
}
