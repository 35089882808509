<div class="row no-gutter">
  <table>
    <thead>
    <tr>
      <th class="w-34percent text-center top-left-round border-bottom">
        {{"Date"|translate}}
      </th>
      <th class="w-34percent text-center border-left border-right border-bottom">
        {{"Flyer Number"| translate}}
      </th>
      <th class="w-34percent text-center top-right-round border-bottom">
        {{"Work Hours" | translate}}
      </th>
     </tr>
    </thead>
    <tbody>

    <tr *ngFor="let eachStaff of staff; let last = last;"
        [ngClass]="last ? 'darker-grey-bg p-y-5' : 'first-layer-bg p-y-5'">
      <td class="text-center">
        <span *ngIf="eachStaff.date && !eachStaff.name; else showTotal;">
          {{ eachStaff.date | date: 'yyyy-MM-dd' }}
        </span>
        <ng-template #showTotal>
          {{ eachStaff.name | translate }}
        </ng-template>
      </td>
      <td class="text-center">
        {{ eachStaff.flyer_distribution }}
      </td>
      <td class="text-center">
        {{ eachStaff.working_hour }}
      </td>
    </tr>
    </tbody>
  </table>
</div>
