export interface Category {
  id: number;
  category_name: string;
  category_image: string;
  sequence: number;
  category: number;
  number_and_name: string;
  has_route: boolean;
  selected: boolean;
}

// adding enum type for category type
export enum ProductImportType {
  normal = 0,
  monotaro = 1,
  other = 2,
}
