import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SidebarFeatures } from './sidebar.model';
import { SidebarRoutesService } from './sidebar.service';
import { SupervisorDealerManagerService } from 'src/app/features/supervisor/supervisor-dealer-manager-list/supervisor-dealer-manager.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  routes: SidebarFeatures[] = [];
  public sidebarOpenedValue: boolean;
  username: string;
  dealer: string;
  showDealer: boolean;

  @Input()
  get sidebarOpened() {
    return this.sidebarOpenedValue;
  }

  @Output() sidebarOpenedChange = new EventEmitter<boolean>();

  set sidebarOpened(value: boolean) {
    this.sidebarOpenedValue = value;
    this.sidebarOpenedChange.emit(value);
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private translate: TranslateService,
    private sidebarRoutesService: SidebarRoutesService,
    private supervisorDealerManagerService: SupervisorDealerManagerService,
  ) {}

  ngOnInit() {
    this.username = localStorage.getItem('username');
    this.getDealer();
    this.sidebarRoutesService.setUserAllowedRoutes();
    this.routes = this.sidebarRoutesService.getUserAllowedRoutes();
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      if (result === 'logout') {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  getDealer() {
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    this.showDealer = group[0] == 'SupervisorDealerManager';
    if (this.showDealer) {
      this.fetchDealer();
    }
  }

  fetchDealer() {
    const email = localStorage.getItem('email');
    let params = new HttpParams()
    params = params.append('email', email);
    params = params.append('username', this.username);
    this.supervisorDealerManagerService.getSuperVisorDealerManagers(params).subscribe(
      (res: any) => {
        this.dealer = res.results ? res.results[0].dealer_name : '';
      },
      (error) => {
        console.error('Error fetching supervisor dealer: ', error);
      }
    )
  }

  goToPath(feature: SidebarFeatures): void {
    if (feature.hasChild) {
      feature.showChild = !feature.showChild;
      return;
    }
    this.router.navigate([feature.path]).then();
  }

  isActived(path: string): boolean {
    return this.router.url === path;
  }
}
