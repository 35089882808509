export function tokenExpired(
  token: string,
  minutesBeforeExpired: number = 5
): boolean {
  if (!token) {
    return true;
  }
  try {
    const base64URL = token.split('.')[1];
    const base64 = base64URL.replace('-', '+').replace('_', '/');
    const tokenJson = JSON.parse(window.atob(base64));
    const expiryDate = new Date(0);
    expiryDate.setUTCSeconds(tokenJson.exp as number);
    const nowDate = new Date();

    return (
      nowDate.getTime() >
      expiryDate.getTime() - 1000 * (60 * minutesBeforeExpired)
    );
  } catch (error) {
    return true;
  }
  // Refresh before expired
}
