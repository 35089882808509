import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { SellerEmailComponent } from "./seller-email.component";


export const SellerEmailRoutes: Routes = [
  {
    path: 'seller-email',
    children: [
      {path: '', component: SellerEmailComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(SellerEmailRoutes)],
  exports: [RouterModule]
})
export class SellerEmailRoutingModule {
}
