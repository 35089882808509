import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDatepickerModule,
  NgbPaginationModule
} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../components/core/core.module';
import { SellerComponent } from './seller.component';
import { SellerRoutingModule } from './seller-routing.module';
import { SharedModule } from 'src/app/shared.module';

@NgModule({
  declarations: [SellerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    SellerRoutingModule,
    CoreModule,
    SharedModule
  ],
  providers: []
})
export class NewSellerCodeModule {}
