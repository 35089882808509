import {Component, OnDestroy, OnInit} from '@angular/core';
import {DailyCheckHistoryService} from '../daily-check-history.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {DailyCheckFirstLayerResponse, SearchData} from '../daily-check.models';
import {Subscription} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-daily-check-table',
  templateUrl: './daily-check-table.component.html',
  styleUrls: ['./daily-check-table.component.scss']
})
export class DailyCheckTableComponent implements OnInit, OnDestroy {

  parentSearchData: SearchData = null;
  firstLayer: DailyCheckFirstLayerResponse | null = null;

  loadingFirstLayerIndex: number | null = null;
  loadingSecondLayerIndex: number | null = null;

  currentSortingField = '';

  loadingTable: boolean = false;

  monthlyVisit: any = null;
  showMonthlyVisit: boolean = false;

  underlineIndex: number = -1;

  param = new Subscription(null);

  isLoadingData: boolean = false;
  formattedGreetingRate: string = '0';

  constructor(
    private notiService: ToastrService,
    private dailyCheckService: DailyCheckHistoryService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.param = this.dailyCheckService.parentParams.subscribe((event) => {
      this.parentSearchData = event;
      this.getFirstLayer();
    });
  }

  ngOnDestroy(): void {
    this.param.unsubscribe();
  }

  getFirstLayer(): void {
    this.loadingTable = true;
    const param = {
      branch: this.parentSearchData?.branches || '',
      type: this.parentSearchData?.dailyCheckItems || '',
      dealer: this.parentSearchData?.dealer || '',
      shop: this.parentSearchData?.shop || '',
      staff: this.parentSearchData?.staff || '',
      start_date: this.parentSearchData?.start_date || '',
      end_date: this.parentSearchData?.end_date || '',
      sort: this.currentSortingField || '',
      roles: this.parentSearchData?.roles || '',
      one_or_more_achievement: this.parentSearchData?.one_or_more_achievement || false,
    }
    this.dailyCheckService.getDailyCheckFirstLayer(param).subscribe(
      (res) => {
        const addedShowChild = res.data_items.map(eachItem => {
          eachItem.showChild = false;
          return eachItem;
        });
        this.underlineIndex = res.data_items.findIndex(
          (eachItem) => eachItem.name === '絶対台数'
        );
        this.firstLayer = {...res, data_items: addedShowChild};
        // format greeting ratio numbers
        this.firstLayer.monthly_visit = this.firstLayer.monthly_visit.toLocaleString('en-US');
        // Remove the percentage sign and parse the string to a number
        const numberValue: number = parseFloat(this.firstLayer.greeting_rate.toString().replace('%', ''));
        // Round the number to one decimal place
        const roundedNumber: number = Math.round(numberValue * 10) / 10;
        // Format the rounded number as a percentage with one decimal place
        this.formattedGreetingRate = `${roundedNumber.toFixed(1)}%`;

        this.monthlyVisit = null
        this.showMonthlyVisit = false;
        this.loadingTable = false;
      },
      (error) => {
        this.loadingTable = false;
        this.showErrorMsg(error);
      }
    )
  }

  getSecondLayer(): void {
    const param = {
      branch: this.parentSearchData?.branches || '',
      dealer: this.parentSearchData.dealer || '',
      type: this.firstLayer.data_items[this.loadingSecondLayerIndex]?.type || '',
      shop: this.parentSearchData?.shop || '',
      staff: this.parentSearchData?.staff || '',
      start_date: this.parentSearchData?.start_date || '',
      roles: this.parentSearchData?.roles || '',
      end_date: this.parentSearchData?.end_date || '',
      one_or_more_achievement: this.parentSearchData?.one_or_more_achievement || false,
    };
    this.dailyCheckService.getDailyCheckSecondLayer(param).subscribe(
      (res) => {
        this.firstLayer.data_items[this.loadingSecondLayerIndex].shops = res.data_items;
        this.firstLayer.data_items[this.loadingSecondLayerIndex].maxNumbers = res.max_numbers;
        this.loadingSecondLayerIndex = null;
      },
      (error) => {
        this.loadingSecondLayerIndex = null;
        this.showErrorMsg(error);
      }
    )
  }

  getMonthlyVisit(): any {
    const param = {
      branch: this.parentSearchData?.branches || '',
      dealer_id: this.parentSearchData?.dealer || '',
      shop_id: this.parentSearchData?.shop || '',
      start_date: this.parentSearchData?.start_date || '',
      end_date: this.parentSearchData?.end_date || '',
      is_daily_check: true,
      one_or_more_achievement: this.parentSearchData?.one_or_more_achievement || false,
    };
    this.dailyCheckService.getMonthlyVisit(param).subscribe(
      (res) => {
        this.monthlyVisit = res
      },
      (error) => {
        this.showErrorMsg(error);
      }
    );
  }

  getThirdLayer(shopID: number): void {
    const param = {
      shop: shopID,
      type: this.firstLayer?.data_items[this.loadingFirstLayerIndex]?.type || '',
      staff : this.parentSearchData.staff || '',
      start_date: this.parentSearchData?.start_date || '',
      end_date: this.parentSearchData?.end_date || '',
      roles: this.parentSearchData?.roles || '',
      one_or_more_achievement: this.parentSearchData?.one_or_more_achievement || false,
    }
    this.dailyCheckService.getDailyCheckThirdLayer(param).subscribe(
      (res) => {
        this.firstLayer.data_items[this.loadingFirstLayerIndex].shops[this.loadingSecondLayerIndex].staffs = res.data_items;
        this.firstLayer.data_items[this.loadingFirstLayerIndex].shops[this.loadingSecondLayerIndex].maxNumbers = res.max_numbers;
        this.loadingFirstLayerIndex = null;
        this.loadingSecondLayerIndex = null;
      },
      (error) => {
        this.loadingFirstLayerIndex = null;
        this.loadingSecondLayerIndex = null;
        this.showErrorMsg(error);
      }
    );
  }

  showErrorMsg(error: HttpErrorResponse): void {
    this.notiService.error(error?.error?.message || error?.message  || error);
  }

  toggleFirstLayer(index: number): void {
    this.firstLayer.data_items[index].showChild =
      !this.firstLayer.data_items[index].showChild;
    this.loadingSecondLayerIndex = index;
    this.getSecondLayer();
  }

  toggleSecondLayer(firstLayerIndex: number, secondLayerIndex: number): void {
    this.firstLayer.data_items[firstLayerIndex].shops[secondLayerIndex].showChild =
      !this.firstLayer?.data_items[firstLayerIndex].shops[secondLayerIndex].showChild;
    this.loadingFirstLayerIndex = firstLayerIndex;
    this.loadingSecondLayerIndex = secondLayerIndex;
    const shopID = this.firstLayer?.data_items[firstLayerIndex]?.shops[secondLayerIndex]?.id;
    if (!shopID) {
      this.notiService.error(this.translate.instant('Shop ID not Found'));
      return;
    }
    this.getThirdLayer(shopID);
  }

  toggleMonthlyVisit(): void {
    this.showMonthlyVisit = !this.showMonthlyVisit;
    if (this.monthlyVisit) {
      return;
    }
    this.getMonthlyVisit();
  }

  setSortingField(sortingField: string): void {
    if (this.currentSortingField.replace('-', '') === sortingField) {
      this.currentSortingField = this.currentSortingField.includes('-') ? sortingField : ('-' + sortingField);
    } else {
      this.currentSortingField = sortingField;
    }
    this.getFirstLayer();
  }

  createRange(number: number): number[] {
    return new Array(number).fill(0).map((n, index) => index + 1);
  }

  isUnderline(index: number = -1): boolean {
    return this.underlineIndex === index;
  }
}
