import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/http-client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/services/translate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportResultService } from './export-result.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { HttpParams } from '@angular/common/http';
import { ExportResult } from '../../models/export-result.model';

@Component({
  selector: 'app-export-result',
  templateUrl: './export-result.component.html',
  styleUrls: ['./export-result.component.scss']
})
export class ExportResultComponent implements OnInit {
  public loading = true;
  page = 1;
  exportResultList: ExportResult[] = [];
  exportResultLength = 0;
  reason = [];
  currentPage: Number = 1;
  noOfPage: Number = 1;
  count = 3;
  statusColorMapping = {
    'Processing': 'Processing',
    'Success': 'Success',
    'Failed': 'Failed',
    'In Queue': 'In-Queue'
  }

  constructor(
    private api: HttpClientService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private translationSerivce: TranslationService,
    private modalService: NgbModal,
    private exportResultService: ExportResultService,
    private notification: ToastrService,
  ) {
  }

  ngOnInit() {
    this.loadExportResults();
  }

  loadExportResults() {
    let params = new HttpParams();
    params = params.append('page', this.currentPage.toString());
    this.exportResultService.getExportResults(params).subscribe(
      (res: any) => {
        this.count = res.count;
        this.exportResultList = res.results;
        this.exportResultLength = res.results.length;
        this.noOfPage = res.total_pages;
        this.loading = false;
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  navigateTo(event) {
    this.loading = true;
    this.currentPage = event;
    this.loadExportResults();
  }

  handleError(err) {
    this.loading = false;
    this.notification.error(_.values(err)[0].toString());
  }

  openErrorPopup(view, reason) {
    this.reason = reason;
    this.modalService.open(view);
  }

  exportFile(fileURL: string) {
    window.open(fileURL, '_blank');
  }

  refresh() {
    this.currentPage = 1;
    this.loading = true;
    this.loadExportResults();
  }

}
