import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tokenExpired } from './shared/token-helper';
import { SidebarRoutesService } from './components/sidebar/sidebar.service';
import { SidebarFeatures } from './components/sidebar/sidebar.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private http: HttpClient,
    private router: Router,
    private sidebarRoutesService: SidebarRoutesService
  ) {}

  userTypes = [
    'Admin',
    'SellerWebUser',
    'MONOTAROWebUser',
    'ENEOSTradingWebUser',
    'ENEOSTradingSubWebUser',
    'BuyerDealerWebUser',
    'BuyerDealerExportWebUser',
    'DailyCheckAdmin',
    'ShopManager',
    'DealerAdmin',
    'CCUser',
    'ShopAdminSingleDealer',
    'ShopAdminMultipleDealer',
    'SellerWebUserByDealer',
    'TabletUser',
    'SupervisorDealerManager',
  ];

  tablerUserTypes = ['TabletUser'];

  routes: SidebarFeatures[] = [];

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const jwtToken = localStorage.getItem('jwtToken') as string;
    if (tokenExpired(jwtToken, 0)) {
      this.redirectToLogin(state);
      return false;
    }
    const group = JSON.parse(localStorage.getItem('groups')) as string[];
    if (!this.userTypes.includes(group[0])) {
      this.router.navigate(['/login'], {
        queryParams: {
          error: "You don't have permission to access admin page",
        },
      });
      return false;
    }
    this.sidebarRoutesService.setUserAllowedRoutes();
    // check if the user has authorization for the route
    const allowedPaths = this.getAllowedPaths(
      this.sidebarRoutesService.getUserAllowedRoutes()
    );
    if (
      !allowedPaths.some((allowedPath) => state.url.startsWith(allowedPath)) &&
      state.url !== '/'
    ) {
      // if not allowed, navigate to blank
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  redirectToLogin(state: RouterStateSnapshot) {
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  }

  getAllowedPaths(routes) {
    return routes.reduce(
      (paths, route) =>
        route.childrenPaths
          ? paths.concat(this.getAllowedPaths(route.childrenPaths))
          : paths.concat(route.path),
      []
    );
  }
}
