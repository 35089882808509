import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbDatepickerModule,
  NgbPaginationModule
} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../components/core/core.module';
import { Ios_deployComponent } from './ios_deploy.component';
import { Ios_deployRoutingModule } from './ios_deploy-routing.module';
import { SharedModule } from 'src/app/shared.module';

@NgModule({
  declarations: [Ios_deployComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    Ios_deployRoutingModule,
    CoreModule,
    SharedModule
  ],
  providers: []
})
export class NewIosDeployCodeModule {}
