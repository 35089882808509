import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthApiService } from '../services/auth-api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent {
  uuid: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthApiService,
    private notification: ToastrService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.uuid = params['uuid'];
      this.getCredentialsWithUUID(this.uuid);
    });
  }

  getCredentialsWithUUID(uuid: string) {
    this.authService.vefifyUUID(uuid).subscribe(
      (res: any) => {
        localStorage.clear();
        localStorage.jwtToken = res.token;
        localStorage.userId = res.id;
        res.dealer_id ? (localStorage.dealer_id = res.dealer_id) : {};
        localStorage.first_name = res.first_name;
        localStorage.last_name = res.last_name;
        localStorage.groups = JSON.stringify(res.groups);
        localStorage.isSuperuser = res.is_superuser;
        localStorage.username = res.username;
        localStorage.is_grit = false;
        localStorage.dealer = res.dealer;
        localStorage.email = res.email;
        this.router.navigate(['/daily-check']);
      },
      (err) => {
        this.notification.error('Invalid UUID');
        this.router.navigate(['/']);
      }
    );
  }
}
