<div class="bg">
  <div class="form-signin">
    <h3>ETR SYSTEM</h3>
    <div class="form-group mt-3">
      <label>{{ 'login.username' | translate }}</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="username"
        placeholder="{{ 'login.username' | translate }}"
      />
    </div>
    <div class="form-group">
      <label>{{ 'login.password' | translate }}</label>
      <input
        type="password"
        class="form-control"
        [(ngModel)]="password"
        placeholder="{{ 'login.password' | translate }}"
        (keydown.enter)="login()"
      />
    </div>
    <div *ngIf="errorMessage" class="text-danger text-center my-1 d-block">
      {{ (errorMessage ? errorMessage : '&nbsp;') | translate }}
    </div>
    <button class="btn btn-primary w-100 mt-3" (click)="login()">
      {{ 'login.login' | translate }}
    </button>
  </div>

  <div class="change-language">
    <button
      (click)="useLanguage('en')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'en' }"
    >
      English
    </button>
    |
    <button
      (click)="useLanguage('ja')"
      class="btn-language"
      [ngClass]="{ underlineText: getCurrentLanguage() === 'ja' }"
    >
      日本語
    </button>
  </div>
</div>
